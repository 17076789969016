import { useMemo } from 'react';
import { ApprovalStatus, ApprovalStepStatus } from '../types';

function useShouldRenderRequestChanges(approval, onClickRequestChanges) {
  const isOnClickRequestChangesFunction = typeof onClickRequestChanges === 'function';
  return useMemo(() => {
    return isOnClickRequestChangesFunction && approval.data && approval.data.approvalStatus === ApprovalStatus.APPROVAL_PENDING && approval.meta.stepStatusForCurrentUser === ApprovalStepStatus.APPROVAL_PENDING;
  }, [isOnClickRequestChangesFunction, approval]);
}

export { useShouldRenderRequestChanges };