'use es6';

export const CONTENT_ASSET_TYPE_CSS = 'CSS';
export const CONTENT_ASSET_TYPE_INLINE_CSS = 'INLINE_CSS';
export const CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS = 'INLINE_EDITOR_CSS';
export const CONTENT_ASSET_TYPE_INLINE_OTHER = 'INLINE_OTHER';
const NO_CONTENT_ASSET_TYPE = 'NO_CONTENT_ASSET_TYPE';
export const INLINE_EDITOR_CSS_ELEMENT_ID = 'hs_editor_style';
export const convertDomElementToAssetJSON = element => {
  const isExternalStyleSheet = element.tagName === 'LINK';
  const isStyleBlock = element.tagName === 'STYLE';

  if (isExternalStyleSheet) {
    return {
      type: CONTENT_ASSET_TYPE_CSS,
      url: element.getAttribute('href')
    };
  } else if (isStyleBlock) {
    const styleId = element.getAttribute('id');
    return {
      type: styleId === INLINE_EDITOR_CSS_ELEMENT_ID ? CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS : CONTENT_ASSET_TYPE_INLINE_CSS,
      snippet: element.innerHTML
    };
  } else {
    return {
      type: NO_CONTENT_ASSET_TYPE,
      snippet: null
    };
  }
};
export const convertAssetHtmlAsStringToAssetJSON = assetHtmlAsString => {
  const tempDiv = document.createElement('div');
  tempDiv.insertAdjacentHTML('afterbegin', assetHtmlAsString);
  const assetAsElement = tempDiv.firstElementChild;
  return convertDomElementToAssetJSON(assetAsElement);
};
export const assetsAreEqual = (asset1, asset2) => {
  if (asset1.type !== asset2.type) {
    return false;
  } // Since we know they are the same asset type,
  // if one asset has a url param, assume the second one does too
  // Are the external style sheet urls the same?


  if (asset1.url && asset1.url === asset2.url) {
    return true;
  } // Compare snippets strings


  if (asset1.snippet && asset2.snippet && asset1.snippet.trim() === asset2.snippet.trim()) {
    return true;
  }

  return false;
};