/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
//legacyModule:hubspot.ContentEditorUI.lib.eventBus

/*
 * decaffeinate suggestions:
 * DS001: Remove Babel/TypeScript constructor workaround
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS203: Remove `|| {}` from converted for-own loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use es6';

import Interframe from 'interframe';
import Raven from 'Raven';
import Backbone from 'Backbone';
import apiClient from 'hub-http/clients/apiClient';
import { apiResponderMiddleware } from 'interframe/proxy/api-responder';
import { getInpageStartupMessage, triggerInpageLoaded } from 'ContentEditorUI/legacyHoldingPattern/inpageInitLogic';
import apiProxyMiddlewareAllowlist from 'ContentEditorUI/constants/ApiProxyMiddlewareAllowlist';
import { uploadFile } from 'tinymce-data/api/FileManager';
import { DropZoneWithBrowseFileAccess } from 'FileManagerLib/enums/FileAccess';
import { LocalStoreHandlers } from 'rich-text-lib/utils/localStore';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import I18n from 'I18n';
import { trackAppLifecycleTimestamp, PARENT_SENDING_PREVIEW_FRAME_INITIAL_PAYLOAD } from 'ContentEditorUI/utils/lifecycleData';
import * as LifecycleData from 'ContentEditorUI/utils/lifecycleData';
import logSeleniumEvent from 'ContentEditorUI/utils/logSeleniumEvent';
import { getPortalDetails, getUser } from 'ContentEditorUI/redux/selectors/authSelectors';
import _ from 'underscore';
const http = apiClient.default != null ? apiClient.default : apiClient;

class InpageError extends Error {
  constructor({
    name,
    message,
    stack
  }) {
    super({
      name,
      message,
      stack
    });
    this.name = name;
    this.message = message;
    this.stack = stack;
  }

}

class EventBus {
  // Flag that can be used to determine when the document in the iframe has initialized
  constructor() {
    this.ready = false;
    this.onPostMessage = this.onPostMessage.bind(this);

    _.extend(this, Backbone.Events); // Stores messages that we attempt to send before the connection is established


    this.pendingMessages = [];
    this.on('post:inpageAppMessagingReady', () => {
      // Some helpful selenium debugging data (for finishIframeReloadAndBoostrap sometimes taking > 20s)
      logSeleniumEvent('parentGotInpageAppMessagingReadyHandshake'); // Enable the event bus and let the iframe know we're ready

      this.ready = true; // Send all messages that we attempted to send before the connection was set up

      for (const msg of Array.from(this.pendingMessages)) {
        this.postMessage(msg.message, msg.type, msg.callback);
      }

      this.pendingMessages = [];
      return this.postMessage({
        action: 'parentAppMessagingReady'
      });
    });
    this.on('post:inpageDomReady', message => {
      // Track inpage JS start/finish  and iframe DOM ready lifecycles (collected inside the IPEUI's JS)
      if (message.queuedInpageLifecycleTimestamps) {
        const parentAppNavigationStart = performance.timing.navigationStart;
        const result = [];

        for (const lifecycle of Object.keys(message.queuedInpageLifecycleTimestamps || {})) {
          const timestamp = message.queuedInpageLifecycleTimestamps[lifecycle];

          if (LifecycleData[lifecycle]) {
            result.push(trackAppLifecycleTimestamp(lifecycle, {
              // Need to subtract iframe's date timestamp with `performance.timing.navigationStart` to
              // simulate `performance.now()`
              timestamp: timestamp - parentAppNavigationStart,
              // Leaving the inpage JS start/finish lifecycle marks in the iframe
              shouldCreatePerformanceMark: false
            }));
          } else {
            result.push(undefined);
          }
        }
      }
    });
    this.on('post:inpageAppInitializationStarted', () => {
      return this.initInpageEditing();
    });
    this.on('post:error', message => {
      if (message.error) {
        const inpageError = new InpageError(message.error);
        console.log('%cInpage frame error:', 'color: red; background-color: rgb(255, 240, 240)');
        console.error(message.error.stack);
        Raven.captureException(inpageError, {
          tags: 'inpage'
        });
      }
    });
    this.on('post:uploadFile', message => {
      const {
        file
      } = message;

      const handleProgress = event => {
        return this.messenger.postMessage({
          action: 'uploadFileProgress',
          requestId: message.requestId,
          loaded: event.loaded,
          total: event.total,
          moduleId: message.moduleId
        });
      };

      return uploadFile(file, handleProgress, http, DropZoneWithBrowseFileAccess.VISIBLE_IN_APP_PUBLIC_TO_ALL_INDEXABLE).then(response => {
        return this.messenger.postResponse(message, response);
      }).catch(() => {
        FloatingAlertStore.addAlert({
          type: 'danger',
          titleText: I18n.text('fileUpload.error.title'),
          message: I18n.text('fileUpload.error.message', {
            filename: file.name
          })
        });
        return this.messenger.postResponse(message, {
          error: true
        });
      });
    });
    this.on('post:getStoreValues', message => {
      const {
        storeKeys,
        storeNamespace
      } = message.payload;
      const store = LocalStoreHandlers.getStore(window, storeKeys, storeNamespace);
      return this.messenger.postResponse(message, store);
    });
    this.on('post:setStoreValue', message => {
      const {
        key,
        value,
        storeKeys,
        storeNamespace
      } = message.payload;
      return LocalStoreHandlers.setValue(window, storeKeys, storeNamespace, {
        key,
        value
      });
    });
    this.on('post:logMceNewRelic', message => {
      const {
        error
      } = message.event;
      const {
        meta
      } = message.event;

      if (window.newrelic && window.newrelic.noticeError) {
        window.newrelic.noticeError(error, meta);
      }
    });
  }

  initInpageEditing() {
    trackAppLifecycleTimestamp(PARENT_SENDING_PREVIEW_FRAME_INITIAL_PAYLOAD); // Some helpful selenium debugging data (for finishIframeReloadAndBoostrap sometimes taking > 20s)

    logSeleniumEvent('parentSendingPayloadToIframe');
    const inpageMessage = getInpageStartupMessage();
    const state = window.hubspot.ContentEditorUI.store.getState();
    const user = getUser(state);
    const portal = getPortalDetails(state);
    const {
      locale,
      lang_enabled: langEnabled
    } = user;
    const {
      timezone
    } = portal;
    this.postMessage({
      action: 'initializeInpageTranslations',
      timezone,
      langEnabled,
      locale
    });
    this.postMessage(inpageMessage, 'json').then(returnMessage => {
      // Mark the iframe as "fully loaded" after it has been boostraped and synced with data from parent
      if (returnMessage.iframeFullyLoadedAndBoostraped) {
        triggerInpageLoaded();
      }
    });
  }

  setupMessagingToFrame(otherWindow, previewDomain) {
    this.messenger = new Interframe(`${document.location.protocol}//${previewDomain}`, otherWindow, {
      middleware: apiResponderMiddleware(http, apiProxyMiddlewareAllowlist)
    });
    this.messenger.subscribe(this.onPostMessage);
    return;
  }

  postMessage(message, type, callback) {
    if (this.messenger) {
      if (callback) {
        return this.messenger.postMessage(message, type).then(callback);
      } else {
        return this.messenger.postMessage(message, type);
      }
    } else {
      return this.pendingMessages.push({
        message,
        type
      });
    }
  }

  onPostMessage(message) {
    return this.trigger(`post:${message.action}`, message);
  }

  getComputedStyle(eventData, callback) {
    return this.postMessage(_.extend(eventData, {
      action: 'getComputedStyle'
    }), null, callback);
  }

  getComputedStylesForSelectors(eventData, callback) {
    return this.postMessage(_.extend(eventData, {
      action: 'getComputedStylesForSelectors'
    }), null, callback);
  }

  listenForComputedStyleChangesForRowOrColumn(eventData) {
    return this.postMessage(_.extend(eventData, {
      action: 'listenForComputedStyleChangesForRowOrColumn'
    }));
  }

  stopListeningForComputedStyleChangesForRowOrColumn(eventData) {
    return this.postMessage(_.extend(eventData, {
      action: 'stopListeningForComputedStyleChangesForRowOrColumn'
    }));
  }

  setInpageScroll(val) {
    return this.postMessage({
      action: 'setInpageScroll',
      value: val
    });
  }

}

export default new EventBus();