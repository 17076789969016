'use es6';

import { createHistory as history } from 'history';
import { useRouterHistory } from 'react-router';

const createHistory = rootUrl => {
  // useRouterHistory is not actually a custom hook, but an older API pre-hook, so ...
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useRouterHistory(history)({
    basename: rootUrl
  });
};

export default createHistory;