'use es6';

import { PREVIEW_IFRAME_WRAPPER, SIDEBAR_IFRAME_WRAPPER, BAYMAX_SKELETON_CLASSNAME, SIDEBAR_IFRAME_SPINNER_CLASSNAME } from 'ContentEditorUI/constants/dom';
export const getPreviewIframeWrapperElement = () => document.getElementsByClassName(PREVIEW_IFRAME_WRAPPER)[0];
export const getSidebarIframeWrapperElement = () => document.getElementsByClassName(SIDEBAR_IFRAME_WRAPPER)[0];
export const hideSidebarIframeWrapperElement = () => {
  const spinnerEl = getSidebarIframeWrapperElement();

  if (spinnerEl) {
    spinnerEl.style.display = 'none';
  }
};

const getBaymaxSkeletonRootElement = () => document.getElementsByClassName(BAYMAX_SKELETON_CLASSNAME)[0];

export const removeBaymaxSkeleton = () => {
  const el = getBaymaxSkeletonRootElement();

  if (el) {
    el.parentNode.removeChild(el);
  }
};
export const removeEarlyIframeWrappers = () => {
  const previewWrapper = getPreviewIframeWrapperElement();

  if (previewWrapper) {
    previewWrapper.parentNode.removeChild(previewWrapper);
  }

  const sidebarWrapper = getSidebarIframeWrapperElement();

  if (sidebarWrapper) {
    sidebarWrapper.parentNode.removeChild(sidebarWrapper);
  }
};
export const getSidebarIframeSpinnerElement = () => {
  return document.getElementsByClassName(SIDEBAR_IFRAME_SPINNER_CLASSNAME)[0];
};
export const showSidebarIframeSpinner = () => {
  const spinnerEl = getSidebarIframeSpinnerElement();

  if (spinnerEl) {
    spinnerEl.style.display = '';
  }
};
export const hideSidebarIframeSpinner = () => {
  const spinnerEl = getSidebarIframeSpinnerElement();

  if (spinnerEl) {
    spinnerEl.style.display = 'none';
  }
};
export const showPreviewLoadingOverlay = () => {
  document.querySelector('.contentEditor-preview-loading').style.display = 'block';
};
export const hidePreviewLoadingOverlay = () => {
  document.querySelector('.contentEditor-preview-loading').style.display = 'none';
};