import { AssetTypes, AssociatableAssetTypesMap, AssociatableAssetTypesWriteRequiredScopes } from 'campaigns-lib/constants/AssetTypes';
import CommentObjectType from './CommentObjectType'; // All AssetTypes associatable to a campaign

export const AssociatableAssetTypes = Object.assign({}, AssociatableAssetTypesMap, {
  [CommentObjectType.COMMENTING_SANDBOX]: CommentObjectType.COMMENTING_SANDBOX
}); // All AssetTypes write scopes associatable to a campaign

export const AssociatableAssetTypesWriteRequiredScopesWithSandbox = Object.assign({}, AssociatableAssetTypesWriteRequiredScopes, {
  [CommentObjectType.COMMENTING_SANDBOX]: []
}); // Map from AssetTypes to CommentObjectTypes

export const AssetTypesToCommentObjectTypes = {
  [AssetTypes.AD_CAMPAIGN]: CommentObjectType.AD_CAMPAIGN,
  [AssetTypes.BLOG_POST]: CommentObjectType.BLOG_POST,
  [AssetTypes.BROADCAST]: CommentObjectType.SOCIAL_BROADCAST,
  [AssetTypes.CTA]: CommentObjectType.CTA,
  [AssetTypes.EMAIL]: CommentObjectType.MARKETING_EMAIL,
  [AssetTypes.LANDING_PAGE]: CommentObjectType.LANDING_PAGE,
  [AssetTypes.SITE_PAGE]: CommentObjectType.WEBSITE_PAGE,
  [AssetTypes.WORKFLOW]: CommentObjectType.WORKFLOW,
  [AssetTypes.FORM]: CommentObjectType.FORM,
  [AssetTypes.MARKETING_EVENT]: CommentObjectType.MARKETING_EVENT
}; // Map from CommentObjectTypes to AssetTypes

export const CommentObjectTypesToAssetTypes = {
  [CommentObjectType.AD_CAMPAIGN]: AssociatableAssetTypes.AD_CAMPAIGN,
  [CommentObjectType.BLOG_POST]: AssociatableAssetTypes.BLOG_POST,
  [CommentObjectType.COMMENTING_SANDBOX]: AssociatableAssetTypes.COMMENTING_SANDBOX,
  [CommentObjectType.CTA]: AssociatableAssetTypes.CTA,
  [CommentObjectType.LANDING_PAGE]: AssociatableAssetTypes.LANDING_PAGE,
  [CommentObjectType.MARKETING_EMAIL]: AssociatableAssetTypes.EMAIL,
  [CommentObjectType.SOCIAL_BROADCAST]: AssociatableAssetTypes.BROADCAST,
  [CommentObjectType.WEBSITE_PAGE]: AssociatableAssetTypes.SITE_PAGE,
  [CommentObjectType.WORKFLOW]: AssociatableAssetTypes.WORKFLOW,
  [CommentObjectType.FORM]: AssociatableAssetTypes.FORM,
  [CommentObjectType.MARKETING_EVENT]: AssociatableAssetTypes.MARKETING_EVENT
};