import http from 'hub-http/clients/apiClient';
export function getTourStatus(tourKey) {
  return http.get(`/onboarding/tours/v1/status/${tourKey}`);
}
export function setTourStatus(tourKey, status) {
  return http.post(`/onboarding/tours/v1/status`, {
    data: {
      tourKey,
      status
    }
  });
}
export function deleteTourStatus(tourKey) {
  return http.delete(`/onboarding/tours/v1/status/${tourKey}`);
}