'use es6';

export const SETTING_REC_TYPE_TO_FIELD_MAP = {
  ADD_TITLE: 'name'
};
export const RECOMMENDATION_TARGET_TYPES = {
  MODULE: 'MODULE',
  SETTING: 'SETTING'
};
export const SETTING_REC_TYPE_TO_LABEL = {
  name: 'Give your page a title'
};