import { getBorderRadiusNumber, getBoundingVisibleRect, getSpacedRect } from './elementUtils';
/**
 * Generate SVG path for pulser background overlay and highlighted boarder
 *
 * For pulser background overlay:
 *   Two rectangle will be drawn, the background overlay will be the difference set of A to B:
 *      A. Rectangle with full screen size
 *      B. Rectangle covered highlighted area
 *
 * For highlighted boarder:
 *   A rectangle with solid line will be drawn around highlighted area
 *
 * M,V,H are all SVG path commands:
 * See {@link https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#path_commands}
 *
 * @param {Object} targetRect - The position and size info of highlighted area
 * @param {number} targetRect.width - width of the highlighted area
 * @param {number} targetRect.height - height of the highlighted area
 * @param {number} targetRect.x - x axis offset pixels
 * @param {number} targetRect.y - y axis offset pixels
 * @param {number} targetRect.r - radius pixels
 */

export const generatePulserPaths = ({
  width,
  height,
  x = 0,
  y = 0,
  r = 0
}) => {
  const {
    innerWidth: w,
    innerHeight: h
  } = window;
  const pulserBorder = [// Draw a rectangle around the target
  `M${x + r},${y}a${r},${r},0,0,0-${r},${r}`, `V${height + y - r}a${r},${r},0,0,0,${r},${r}`, `H${width + x - r}a${r},${r},0,0,0,${r}-${r}`, `V${y + r}a${r},${r},0,0,0-${r}-${r}Z`].join('');
  return {
    border: pulserBorder,
    // Draw background overlay outside the target rectangle
    overlay: `M${w},${h}H0V0H${w}V${h}Z${pulserBorder}`
  };
};
export const getPulserPaths = (target, scrollParents, {
  padding,
  distance,
  radius = getBorderRadiusNumber(target)
}) => {
  const pulserPaths = {
    start: {},
    end: {}
  };
  const targetRect = getBoundingVisibleRect(target, scrollParents, radius);
  const pulserStartRect = getSpacedRect(targetRect, padding);
  pulserPaths.start = generatePulserPaths(pulserStartRect);

  if (distance) {
    const pulserEndRect = getSpacedRect(targetRect, padding + distance);
    pulserPaths.end = generatePulserPaths(pulserEndRect);
  }

  return pulserPaths;
};