import { createSelector } from 'reselect';
import { SUCCEEDED, FAILED, PENDING } from 'ContentUtils/constants/RequestStatus';
export const getPaymentEligibility = state => state.resources.payments.paymentsEligibility;
export const getPaymentEligibilityFetchStatus = state => state.resources.payments.paymentsEligibilityRequestStatus;
export const getHasFetchedPaymentEligibility = createSelector([getPaymentEligibilityFetchStatus], paymentEligibilityFetchStatus => {
  return paymentEligibilityFetchStatus === SUCCEEDED || paymentEligibilityFetchStatus === FAILED;
});
export const getPayments = state => state.resources.payments.payments;
export const getPayment = (state, props) => state.resources.payments.payments[props.id];
export const getPaymentFetchStatus = (state, props) => state.resources.payments.requestStatus[props.id];
export const getPaymentsFetchStatus = state => state.resources.payments.paymentsRequestStatus;
export const getIsLoading = createSelector([getPaymentEligibilityFetchStatus, getPaymentsFetchStatus, getHasFetchedPaymentEligibility], (paymentEligibilityFetchStatus, paymentsFetchStatus, hasFetchedPaymentEligibility) => {
  return paymentEligibilityFetchStatus === PENDING || hasFetchedPaymentEligibility && paymentsFetchStatus === PENDING;
});
export const getHasNoPayments = createSelector([getPaymentsFetchStatus, getPayments], (paymentsFetchStatus, payments) => {
  return paymentsFetchStatus === SUCCEEDED && !Object.keys(payments).length;
});
export const getPaymentOptions = createSelector([getPayments], payments => {
  return Object.keys(payments).map(paymentId => {
    const payment = payments[paymentId];
    return {
      value: payment.id,
      text: payment.name,
      payment
    };
  });
});