'use es6';

import http from 'hub-http/clients/apiClient';
const DEFAULT = {
  options: {
    timeout: 20000
  },
  body: {
    userAgent: 'HubSpot Content Editor',
    useCache: true
  }
};
export const getScreenshot = (targetUrl, selector, options = {}, dataOverrides = {}) => {
  const data = Object.assign({}, DEFAULT.body, {
    targetUrl,
    selector
  }, dataOverrides);
  const requestOptions = Object.assign({}, DEFAULT.options, {}, options);
  return http.post('hubshot/v2/screenshots', {
    data
  }, requestOptions).then(([screenshot]) => screenshot);
};