import { debug } from './debugUtil';
import { getTourIdFromQueryParams } from './queryParams';
const ONBOARDING_NEXT_TOUR_KEY = 'ONBOARDING_NEXT_TOUR';

const buildTourItem = (tourId, prevTourId, prevTourLength, linkedToursTotalStepCount, returnUrl) => {
  return {
    tourId,
    prevTourId,
    prevTourLength,
    linkedToursTotalStepCount,
    returnUrl
  };
};

const getSessionStorage = () => window.sessionStorage || {
  setItem(key, value) {
    const encodedKey = encodeURIComponent(`TOUR:${key}`);
    const encodedValue = encodeURIComponent(value); // No expires and max-age specified, it will expire at the end of session.

    document.cookie = `${encodedKey}=${encodedValue}; path=/`;
  },

  getItem(key) {
    if (!document.cookie) return null; // document.cookie format is like: "foo=bar; baz=qux"

    const cookies = `; ${document.cookie}`;
    const encodedKey = encodeURIComponent(`TOUR:${key}`); //getItem('foo'): cookieValue will be 'bar; baz=qux'

    const [, cookieValue] = cookies.split(`; ${encodedKey}=`);

    if (cookieValue) {
      // getItem('foo'): return value will be 'bar'
      return decodeURIComponent(cookieValue.split(';')[0]);
    }

    return null;
  },

  removeItem(key) {
    this.setItem(key, '');
  }

};

export const removeNextTourToStorage = () => {
  const sessionStorage = getSessionStorage();
  sessionStorage.removeItem(ONBOARDING_NEXT_TOUR_KEY);
};

const getTourValueFromStorage = () => {
  const sessionStorage = getSessionStorage();
  const nextTourValue = sessionStorage.getItem(ONBOARDING_NEXT_TOUR_KEY);
  return nextTourValue;
};

export const getNextTourIdFromStorage = () => {
  const nextTourValue = getTourValueFromStorage();

  if (nextTourValue) {
    const nextTourData = JSON.parse(nextTourValue);
    return nextTourData.tourId;
  }

  return null;
};
export const getNextTourFromStorage = () => {
  const nextTourValue = getTourValueFromStorage();

  if (!nextTourValue) {
    return {};
  }

  const nextTourData = JSON.parse(nextTourValue);

  if (!nextTourData) {
    removeNextTourToStorage();
    return {};
  }

  const {
    tourId,
    prevTourId,
    prevTourLength,
    linkedToursTotalStepCount,
    returnUrl
  } = nextTourData;
  const [nextTour, targetPageRegex] = tourId.split(':');

  if (targetPageRegex && !document.location.pathname.match(targetPageRegex)) {
    // Not remove tour storage if current page is not matched target page
    return {};
  }

  removeNextTourToStorage();
  return {
    tourId: nextTour,
    prevTourId,
    prevTourLength,
    linkedToursTotalStepCount,
    returnUrl
  };
};
export const setNextTourToStorage = (nextTourId, currentTourId, completedStepsCount, linkedToursTotalStepCount, returnUrl) => {
  debug('Setting next tour show: ', nextTourId);
  const sessionStorage = getSessionStorage();
  const tourItem = JSON.stringify(buildTourItem(nextTourId, currentTourId, completedStepsCount, linkedToursTotalStepCount, returnUrl));
  sessionStorage.setItem(ONBOARDING_NEXT_TOUR_KEY, tourItem);
};
export const getShouldEnableTour = () => {
  if (getTourIdFromQueryParams()) {
    return true;
  }

  const tourId = getNextTourIdFromStorage();
  return Boolean(tourId);
};