'use es6';

import { createSelector } from 'reselect';
import { basicSelector } from './helpers';
const getSectionsInfo = basicSelector(state => state.sections);

const createSectionsInfoSelector = key => createSelector([getSectionsInfo], sectionsInfo => sectionsInfo && sectionsInfo.get(key));

export const getIsShowingCreateNewSectionPanel = createSectionsInfoSelector('showCreateNewSectionPanel');
export const getCurrentCreateNewSectionInfo = createSectionsInfoSelector('currentCreateNewSectionInfo');
export const getHasSaveNewSectionError = createSectionsInfoSelector('saveNewSectionError');
export const getShouldShowAddSectionPanel = createSectionsInfoSelector('showAddSectionPanel');
export const getCurrentLayoutSectionToAddTo = createSectionsInfoSelector('currentLayoutSectionToAddTo');
export const getHasLayoutSectionToAddTo = createSelector([getCurrentLayoutSectionToAddTo], currentLayoutSectionToAddTo => Boolean(currentLayoutSectionToAddTo));
export const getCurrentCopyFragmentAsHublInfo = createSectionsInfoSelector('currentCopyFragmentAsHublInfo');
export const getAddSectionMissingModuleSchemaErrors = createSectionsInfoSelector('addSectionMissingModuleSchemaErrors');