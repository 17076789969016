'use es6';

import { FETCH_PAGE_LIMIT_SUCCEEDED } from '../actions/actionTypes';

const pageLimitReducer = (state = null, {
  payload,
  type
}) => {
  switch (type) {
    case FETCH_PAGE_LIMIT_SUCCEEDED:
      {
        if (payload && payload.pageLimit) {
          return {
            pageLimit: payload.pageLimit,
            quantityUsed: payload.quantityUsed,
            hasExceededContentTypeLimit: payload.quantityUsed > payload.pageLimit
          };
        }

        return state;
      }

    default:
      return state;
  }
};

export default pageLimitReducer;