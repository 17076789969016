import invariant from 'react-utils/invariant';
import { NOT_STARTED, DONE } from '../constants/TourStates';
export default class ProgressStore {
  constructor() {
    this.subscriptions = [];
    this.progress = [];
  }

  subscribe(callback) {
    this.subscriptions.push(callback);
    return () => this.unsubscribe(callback);
  }

  unsubscribe(callback) {
    this.subscriptions = this.subscriptions.filter(value => value !== callback);
  }

  activateTour(tour) {
    this.subscriptions.forEach(callback => {
      callback(this.getStep(), tour);
    });
  }

  deactivate() {
    this.subscriptions.forEach(callback => {
      callback(null, null);
    });
  }

  updateProgress(step, tour) {
    this.progress.push(step);
    this.subscriptions.forEach(callback => {
      callback(step, tour);
    });

    if (step === DONE) {
      this.progress = [];
    }

    return step;
  }

  getStep() {
    return this.progress[this.progress.length - 1] || NOT_STARTED;
  }

  canGoBack() {
    return this.progress.length > 1;
  }

  goBack(tour) {
    const poppedPoint = this.progress.pop();
    invariant(typeof poppedPoint !== 'undefined', 'can not go back with no history');
    const lastStep = this.progress[this.progress.length - 1];
    this.subscriptions.forEach(callback => {
      callback(lastStep, tour);
    });
    return lastStep;
  }

}