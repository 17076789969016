import defer from 'hs-promise-utils/defer';
import SentryManager from '../manager/SentryManager';
import * as observerUtils from 'ui-shepherd-react/utils/observerUtils';
const MAX_WAIT_TIMEOUT = 10000;
export const waitForElementToAppear = observerUtils.waitForElementToAppear;
export const waitForAttachToElementToAppearOrFireSentry = (tourId, stepId, options, timeout = MAX_WAIT_TIMEOUT) => {
  const deferred = defer(); // If the element doesn't load in a certain period, error out

  const timeoutId = setTimeout(() => {
    const [error] = SentryManager.reportElementLoadTimeout(tourId, stepId);
    deferred.reject(error);
  }, timeout);
  waitForElementToAppear(options).then(maybeElement => {
    deferred.resolve(maybeElement);
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
  deferred.promise.finally(() => {
    window.clearTimeout(timeoutId);
  }).catch(error => SentryManager.reportSentry('waitForAttachToElementToAppearOrFireSentry', error.message, {
    tourId,
    stepId
  }));
  return deferred.promise;
};