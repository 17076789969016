'use es6';

import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getBrandColorsPayload = basicSelector(state => state.brandSettings.get('colors'));
export const getBrandColors = createSelector(getBrandColorsPayload, colorsPayload => {
  if (colorsPayload && colorsPayload.size > 0) {
    // Return only an array of hex colors, not any other metadata
    return colorsPayload.map(colorObj => colorObj.get('color')).toJS();
  }

  return undefined; //empty array will cause nothing to be rendered if you return undefined the favorites section is rendered.
});