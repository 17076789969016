import { isCmv2ModuleSchema, isV1GlobalModule } from './ModuleHelpers';
import ImageSizeTypes from '../constants/ImageSizeTypes';
export const createNewModuleFromSchema = (moduleSchema, {
  setMergeInDefaultFieldValuesToFalse
} = {}, id, name) => {
  const now = Date.now();
  const module = {
    name: name || `widget_${now}`,
    id: id || now,
    body: {},
    overrideable: true
  };

  if (isV1GlobalModule(moduleSchema)) {
    module.type = 'global_widget';
    module.body.global_widget_name = moduleSchema.name;
    module.overrideable = false;
  } else {
    if (isCmv2ModuleSchema(moduleSchema)) {
      module.type = 'module';

      if (moduleSchema.path === '@hubspot/linked_image') {
        const imageField = moduleSchema.fields.find(field => field.name === 'img');
        const imageFieldDefault = imageField && imageField.default || {};
        module.body.img = Object.assign({}, imageFieldDefault, {
          // Force newly dragged in image modules to load lazily and have a max-width (even though
          // those are not currently set as the module default for static/HubL image modules)
          loading: 'lazy',
          size_type: ImageSizeTypes.AUTO
        }); // Only support setting `merge_in_default_values` on default image modules to start

        if (setMergeInDefaultFieldValuesToFalse) {
          module.merge_in_default_values = false;
        }
      }
    } else {
      module.type = 'custom_widget';
    }

    module.module_id = moduleSchema.moduleId;
  }

  return module;
};
export const createNewModuleSchemaJson = (moduleSchema, module) => {
  return Object.assign({}, module, {
    type: module.type === 'module' ? 'custom_widget' : module.type,
    label: moduleSchema.displayName,
    body: Object.assign({}, module.body, {
      module_id: module.module_id,
      schema_version: moduleSchema.schemaVersion
    })
  });
};