import { createTracker } from 'usage-tracker';
import { isApiBlackListed } from '../navigation/isApiBlackListed';
import events from 'ui-addon-upgrades/events.yaml';
const AMPLITUDE_APP_NAME = 'upgrades';
export const tracker = createTracker({
  events,
  properties: {
    namespace: AMPLITUDE_APP_NAME
  }
});
export const beaconTracker = tracker.clone({
  isBeforeUnload: true
});

const _transformEventProperties = eventProperties => {
  if (!eventProperties) return {};

  if (eventProperties.associatedProductPurchaseMotions) {
    delete eventProperties.associatedProductPurchaseMotions;
  }

  const {
    repInfo
  } = eventProperties;

  if (repInfo) {
    const notAssigned = 'Not assigned';
    delete eventProperties.repInfo;
    eventProperties.repName = repInfo ? repInfo.name || notAssigned : notAssigned;
  }

  Object.keys(eventProperties).forEach(property => {
    if (eventProperties[property] === null || eventProperties[property] === undefined) {
      delete eventProperties[property];
    }
  });
  return eventProperties;
};

export const track = (eventKey, eventProperties) => {
  if (isApiBlackListed()) {
    return;
  }

  const transformedEventProperties = _transformEventProperties(eventProperties);

  tracker.track(eventKey, transformedEventProperties);
}; // Uses beacon to ensure events get sent if tracking just before the page unloads
// https://product.hubteam.com/docs/usage-tracking-manual/docs/javascript/external-link-click-tracking.html

export const trackBeforeUnload = (eventKey, eventProperties) => {
  if (isApiBlackListed()) {
    return;
  }

  const transformedEventProperties = _transformEventProperties(eventProperties);

  beaconTracker.track(eventKey, transformedEventProperties);
};