import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { ApprovalOperation } from '../types/ApprovalOperation';
const successAlertContent = {
  titleText: {
    [ApprovalOperation.REQUEST]: 'productApprovals.updateAlertTitle.CREATE',
    [ApprovalOperation.APPROVE]: 'productApprovals.updateAlertTitle.APPROVE',
    [ApprovalOperation.RECALL]: 'productApprovals.updateAlertTitle.RECALL'
  },
  message: {
    [ApprovalOperation.REQUEST]: 'productApprovals.updateAlertMessage.CREATE',
    [ApprovalOperation.APPROVE]: 'productApprovals.updateAlertMessage.APPROVE',
    [ApprovalOperation.RECALL]: 'productApprovals.updateAlertMessage.RECALL'
  }
};
export const getSuccessAlertTranslationKeys = approvalOperation => ({
  titleText: successAlertContent.titleText[approvalOperation],
  message: successAlertContent.message[approvalOperation]
});
export const getErrorAlertTranslationKeys = errorSubCategory => {
  const defaultTitleTextKey = 'productApprovals.ApprovalErrorAlert.titleText.default';
  const defaultMessageKey = 'productApprovals.ApprovalErrorAlert.message.default';
  const titleTextKey = `productApprovals.ApprovalErrorAlert.titleText.${errorSubCategory}`;
  const messageKey = `productApprovals.ApprovalErrorAlert.message.${errorSubCategory}`;
  return {
    titleText: isValidI18nKey(titleTextKey) ? titleTextKey : defaultTitleTextKey,
    message: isValidI18nKey(messageKey) ? messageKey : defaultMessageKey
  };
};