'use es6';

import { Map as ImmutableMap, Set as ImmutableSet, OrderedSet } from 'immutable';
import { UPDATE_EMAIL_TYPE, FETCH_CONTENT_SCHEMA_SUCCEEDED, SELECT_BREAKPOINT, SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SAVED_MODULE, NAVIGATE_TO_PREVIEW, OPENED_BAYMAX_SIDEBAR, CLOSED_BAYMAX_SIDEBAR, BAYMAX_SIDEBAR_ANIMATION_FINISHED, OPENED_PATAGONIA_SIDEBAR, CLOSED_PATAGONIA_SIDEBAR, PATAGONIA_SIDEBAR_ANIMATION_FINISHED, OPENED_PANEL, OPENED_PREVIOUS_PANEL, SELECTED_ITEM_REVERTED_TO_PREVIOUS, INPAGE_UI_STATE_CLEARED, SELECTED_ITEM_CLEARED, CLOSED_PANEL, SET_SCROLL_TO_IN_MODULE_LIST, FLUSH_SIDEBAR_HIDDEN_STATE, LAYOUT_SECTION_MODULE_DELETED, LAYOUT_SECTION_ROW_DELETED, LAYOUT_SECTION_COLUMN_DELETED, DELETED_MODULE, OPEN_TREE_NODES, CLOSE_TREE_NODES, SELECTED_SUB_CATEGORY_UPDATE, SELECTED_CATEGORY_UPDATE, SET_SIDEBAR_SEARCH_TERM, SET_SIDEBAR_ACTIVE_MATCH, SET_SIDEBAR_MATCH_IDS, OPEN_EXPANDABLE_MENU, CLEAR_OPEN_EXPANDABLE_MENU, SAVE_SIDEBAR_SCROLL_TOP, SET_PATAGONIA_ACTIVE_PANEL, SHOW_ALL_ROWS_FOR_DND_AREA, SHOW_STANDARD_ROWS_FOR_DND_AREA } from 'ContentEditorUI/redux/actions/actionTypes';
import { SIDEBAR_PANELS } from 'ContentEditorUI/lib/sidebar/constants';
import { getSidebarIframeWrapperElement } from 'ContentEditorUI/lib/layoutDom';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
import { NAV_TABS_SHOW_SIDEBAR } from 'ContentEditorUI/constants/Sidebar';
import { PATAGONIA_ADD_PANEL_CATEGORIES, PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar';
const categoryToSidebarIframeWidthMap = {
  [PATAGONIA_ADD_PANEL_CATEGORIES.MODULES]: '296px',
  [PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS]: '487px',
  [PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS]: '487px'
};

const updateSidebarIframeWidthForCategory = category => {
  const sidebarIframeWrapper = getSidebarIframeWrapperElement();
  sidebarIframeWrapper.style.width = categoryToSidebarIframeWidthMap[category];
};

const initialSidebarState = ImmutableMap({
  activePanel: '',
  defaultPanel: '',
  patagoniaActivePanel: '',
  previousPanel: '',
  patagoniaPreviousPanel: '',
  sidebarHidden: false,
  sidebarCollapsed: false,
  patagoniaSidebarCollapsed: true,
  scrollToModule: null,
  selectedModuleWasFakeModule: false,
  category: PATAGONIA_ADD_PANEL_CATEGORIES.MODULES,
  subCategory: PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.THEME,
  // Since the sidebar iframe is visible during the collapse animation, we use this animation end
  // state to wait until the iframe is hidden
  sidebarCollapseAnimationFinished: false,
  patagoniaSidebarCollapseAnimationFinished: true,
  // Store open and closed content sidebar tree nodes
  closedTreeNodes: ImmutableSet([]),
  // State for maintining search results between sidebar tabs
  sidebarSearchTerm: '',
  sidebarActiveMatch: '',
  sidebarMatchIds: OrderedSet([]),
  // State for managing what dnd areas show all rows
  dndAreasThatShowAllRows: ImmutableSet([]),
  // State for managing open dropdown menus
  openExpandableMenuNodeId: '',
  sidebarScrollTop: 0
});
let previousRoutePath = null;

const setPanelHelper = (existingState, newPanel, options = {}) => {
  const {
    clearOutPreviousPanel = false,
    scrollToModuleInList = null
  } = options;
  const existingPanel = clearOutPreviousPanel === true ? '' : existingState.get('activePanel'); // Don't change previous panel if it matches current panel

  const newPreviousPanel = newPanel !== existingPanel ? existingPanel : existingState.get('previousPanel');
  const newState = existingState.set('previousPanel', newPreviousPanel).set('activePanel', newPanel).set('scrollToModule', scrollToModuleInList);
  return newState;
};

const setPatagoniaPanelHelper = (existingState, newPanel, options = {}) => {
  const {
    clearOutPreviousPanel = false,
    scrollToModuleInList = null
  } = options;
  const existingPanel = clearOutPreviousPanel === true ? '' : existingState.get('patagoniaActivePanel'); // Don't change previous panel if it matches current panel

  const newPreviousPanel = newPanel !== existingPanel ? existingPanel : existingState.get('patagoniaPreviousPanel');
  const newState = existingState.set('patagoniaPreviousPanel', newPreviousPanel).set('patagoniaActivePanel', newPanel).set('scrollToModule', scrollToModuleInList);
  return newState;
};

const setPanelToDefaultHelper = (state, {
  clearOutPreviousPanel = true
} = {}) => {
  return setPanelHelper(state, state.get('defaultPanel'), {
    clearOutPreviousPanel
  });
};

const getPreviousPanelHelper = state => state.get('previousPanel') || state.get('defaultPanel');

const setPreviousPanelHelper = (state, {
  clearOutPreviousPanel = false
}) => setPanelHelper(state, getPreviousPanelHelper(state), {
  clearOutPreviousPanel
});

const isFakeModuleWithNoSidebarUI = id => ['post_body', 'name'].includes(id);

const sidebarStateReducer = (state = initialSidebarState, {
  type,
  name,
  payload,
  response
}) => {
  switch (type) {
    case UPDATE_EMAIL_TYPE:
      return setPanelToDefaultHelper(state);

    case LOCATION_CHANGE:
      {
        // TODO, eventually cleanup and unify with other logic that sets
        // `activePanel` at
        // https://git.hubteam.com/HubSpot/ContentEditorUI/blob/52a0c94d5cc1258744c6424b65afb8348096b423/PageEditorUI/static/js/Application.js#L102-L105
        const {
          pathname
        } = payload;
        const paneName = getPaneNameFromPath(pathname);

        if (paneName === 'optimize') {
          state = state.set('activePanel', 'optimizer');
        }

        if (previousRoutePath === 'preview' || previousRoutePath === 'revisions') {
          // When leaving preview or revisions, reset the sidebar panel to it's default.
          state = state.set('activePanel', state.get('defaultPanel'));
          state = state.set('sidebarHidden', false);
        } else if (!NAV_TABS_SHOW_SIDEBAR[paneName]) {
          state = state.set('sidebarHidden', true);
        } else if (state.get('sidebarHidden')) {
          state = state.set('sidebarHidden', false);
        } else if (paneName === 'optimize') {
          state = state.merge({
            sidebarCollapsed: false,
            sidebarCollapseAnimationFinished: false,
            patagoniaSidebarCollapsed: true,
            patagoniaSidebarCollapseAnimationFinished: true
          });
        }

        previousRoutePath = paneName;
        return state;
      }

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const contentSchema = response;
        const {
          schema,
          theme_path: themePath
        } = contentSchema;
        const previousDefaultPanel = state.get('defaultPanel');
        const hasAnyFlexColumns = schema.widget_containers && Object.keys(schema.widget_containers).length > 0;
        const hasAnyDndAreas = schema.layout_sections && Object.keys(schema.layout_sections).length > 0;
        const hasTheme = !!themePath;

        if (!hasTheme) {
          state = state.set('subCategory', PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES.COMMON);
        }

        if (!hasAnyFlexColumns && !hasAnyDndAreas && previousDefaultPanel && previousDefaultPanel === SIDEBAR_PANELS.addWidget) {
          const activePanelUnchanged = state.get('activePanel') === previousDefaultPanel;

          if (activePanelUnchanged) {
            state = state.set('activePanel', SIDEBAR_PANELS.widgetList);
          }

          return state.set('defaultPanel', SIDEBAR_PANELS.widgetList);
        }

        return state;
      }

    case SELECT_BREAKPOINT:
      {
        const {
          selectedBreakpoint
        } = payload;
        const activeSidebarPanel = state.get('activePanel');
        const isSwitchingToNonDefaultBreakpoint = selectedBreakpoint !== 'default';
        const currentPanelIsAddModule = activeSidebarPanel === SIDEBAR_PANELS.addWidget;
        return isSwitchingToNonDefaultBreakpoint && currentPanelIsAddModule ? state.set('activePanel', SIDEBAR_PANELS.widgetList) : state;
      }

    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
      {
        const {
          id,
          doNotShowSidebarForm,
          isPatagonia
        } = payload; // Handle selection of post body and name "fake" modules differently

        if (isFakeModuleWithNoSidebarUI(id)) {
          state = state.set('selectedModuleWasFakeModule', true);
          return setPanelToDefaultHelper(state, {
            clearOutPreviousPanel: false
          });
        } else if (state.get('selectedModuleWasFakeModule')) {
          state = state.set('selectedModuleWasFakeModule', false);
        }

        state = isPatagonia ? setPatagoniaPanelHelper(state, SIDEBAR_PANELS.edit) : setPanelHelper(state, SIDEBAR_PANELS.edit);

        if (doNotShowSidebarForm) {
          return state;
        }

        return state.merge({
          selectedItemId: id,
          sidebarCollapsed: false,
          sidebarCollapseAnimationFinished: false,
          patagoniaSidebarCollapsed: false,
          patagoniaSidebarCollapseAnimationFinished: false
        });
      }
    // Only happens in non-Baymax

    case SAVED_MODULE:
      return state.set('activePanel', SIDEBAR_PANELS.widgetList);

    case NAVIGATE_TO_PREVIEW:
      return setPanelHelper(state, SIDEBAR_PANELS.preview);

    case OPENED_BAYMAX_SIDEBAR:
      return state.merge({
        sidebarCollapsed: false,
        sidebarCollapseAnimationFinished: false
      });

    case CLOSED_BAYMAX_SIDEBAR:
      return state.merge({
        sidebarCollapsed: true,
        sidebarCollapseAnimationFinished: false
      });

    case BAYMAX_SIDEBAR_ANIMATION_FINISHED:
      return state.set('sidebarCollapseAnimationFinished', true);

    case OPENED_PATAGONIA_SIDEBAR:
      return state.merge({
        patagoniaSidebarCollapsed: false,
        patagoniaSidebarCollapseAnimationFinished: false
      });

    case CLOSED_PATAGONIA_SIDEBAR:
      return state.merge({
        patagoniaSidebarCollapsed: true,
        patagoniaSidebarCollapseAnimationFinished: false,
        patagoniaActivePanel: ''
      });

    case PATAGONIA_SIDEBAR_ANIMATION_FINISHED:
      return state.set('patagoniaSidebarCollapseAnimationFinished', true);

    case SET_PATAGONIA_ACTIVE_PANEL:
      return setPatagoniaPanelHelper(state, payload);

    case OPENED_PANEL:
      return setPanelHelper(state, name, payload);

    case OPENED_PREVIOUS_PANEL:
    case SELECTED_ITEM_REVERTED_TO_PREVIOUS:
      return setPreviousPanelHelper(state, {
        clearOutPreviousPanel: true
      });

    case INPAGE_UI_STATE_CLEARED:
      if (state.get('previousPanel') === SIDEBAR_PANELS.edit) {
        // Prevent going "back" to the edit panel when no module is selected
        // Note, this _should not_ happen, but does in some edge cases (like blurring in optimize)
        return setPanelToDefaultHelper(state);
      } else {
        return setPreviousPanelHelper(state, {
          clearOutPreviousPanel: true
        });
      }

    case SELECTED_ITEM_CLEARED:
      {
        state = state.set('selectedItemId', null);

        if (state.get('selectedModuleWasFakeModule')) {
          return state.set('selectedModuleWasFakeModule', false);
        } else {
          return setPreviousPanelHelper(state, {
            clearOutPreviousPanel: false
          });
        }
      }

    case DELETED_MODULE:
    case LAYOUT_SECTION_MODULE_DELETED:
    case LAYOUT_SECTION_COLUMN_DELETED:
    case LAYOUT_SECTION_ROW_DELETED:
      {
        const {
          id,
          moduleId,
          columnId,
          rowId
        } = payload;
        const itemId = id || moduleId || columnId || rowId;

        if (itemId === state.get('selectedItemId')) {
          state = state.set('selectedItemId', null);
          return setPanelToDefaultHelper(state);
        }

        return state;
      }

    case SET_SCROLL_TO_IN_MODULE_LIST:
      {
        const {
          moduleId
        } = payload;
        return state.set('scrollToModule', moduleId);
      }
    // Only used in non-Baymax (classic) editors

    case CLOSED_PANEL:
      return state.set('previousPanel', '').set('activePanel', '');

    case FLUSH_SIDEBAR_HIDDEN_STATE:
      {
        const {
          paneName
        } = payload;

        if (previousRoutePath === 'preview' || previousRoutePath === 'revisions') {
          // When leaving preview or revisions, reset the sidebar panel to it's default.
          state = state.set('sidebarHidden', false);
        } else if (!NAV_TABS_SHOW_SIDEBAR[paneName]) {
          state = state.set('sidebarHidden', true);
        } else if (state.get('sidebarHidden')) {
          state = state.set('sidebarHidden', false);
        } else if (paneName === 'optimize') {
          state = state.merge({
            sidebarCollapsed: false,
            sidebarCollapseAnimationFinished: false,
            patagoniaSidebarCollapsed: false,
            patagoniaSidebarCollapseAnimationFinished: false
          });
        }

        return state;
      }

    case OPEN_TREE_NODES:
      {
        const {
          nodesToOpen
        } = payload;
        const closedTreeNodes = state.get('closedTreeNodes');
        const newClosedTreeNodes = closedTreeNodes.subtract(nodesToOpen);
        state = state.set('closedTreeNodes', newClosedTreeNodes);
        return state;
      }

    case CLOSE_TREE_NODES:
      {
        const {
          nodesToClose
        } = payload;
        const closedTreeNodes = state.get('closedTreeNodes');
        const newClosedTreeNodes = closedTreeNodes.union(nodesToClose);
        state = state.set('closedTreeNodes', newClosedTreeNodes);
        return state;
      }

    case SELECTED_CATEGORY_UPDATE:
      state = state.set('category', payload.category); // A litle jarring, could definitely use a callback to be made better
      // But, it's necessary for rendering the sections sidebar correctly

      updateSidebarIframeWidthForCategory(payload.category);

      if (payload.subCategory) {
        state = state.set('subCategory', payload.subCategory);
      }

      return state;

    case SELECTED_SUB_CATEGORY_UPDATE:
      return state.set('subCategory', payload.subCategory);

    case SET_SIDEBAR_SEARCH_TERM:
      {
        const {
          newSearchTerm
        } = payload;
        return state.set('sidebarSearchTerm', newSearchTerm);
      }

    case SET_SIDEBAR_ACTIVE_MATCH:
      {
        const {
          newActiveMatch
        } = payload;
        return state.set('sidebarActiveMatch', newActiveMatch);
      }

    case SET_SIDEBAR_MATCH_IDS:
      {
        const {
          newMatchIds
        } = payload;
        const newImmutableMatchIds = OrderedSet(newMatchIds);
        return state.set('sidebarMatchIds', newImmutableMatchIds);
      }

    case OPEN_EXPANDABLE_MENU:
      {
        const {
          newOpenExpandableMenuNodeId
        } = payload;
        return state.merge({
          openExpandableMenuNodeId: newOpenExpandableMenuNodeId
        });
      }

    case CLEAR_OPEN_EXPANDABLE_MENU:
      {
        return state.merge({
          openExpandableMenuNodeId: ''
        });
      }

    case SAVE_SIDEBAR_SCROLL_TOP:
      {
        const {
          newSidebarScrollTop
        } = payload;
        return state.set('sidebarScrollTop', newSidebarScrollTop);
      }

    case SHOW_ALL_ROWS_FOR_DND_AREA:
      {
        const {
          idToAdd
        } = payload;
        const dndAreasThatShowAllRows = state.get('dndAreasThatShowAllRows');
        const newDndAreasThatShowAllRows = dndAreasThatShowAllRows.add(idToAdd);
        return state.set('dndAreasThatShowAllRows', newDndAreasThatShowAllRows);
      }

    case SHOW_STANDARD_ROWS_FOR_DND_AREA:
      {
        const {
          idToRemove
        } = payload;
        const dndAreasThatShowAllRows = state.get('dndAreasThatShowAllRows');
        const newDndAreasThatShowAllRows = dndAreasThatShowAllRows.delete(idToRemove);
        return state.set('dndAreasThatShowAllRows', newDndAreasThatShowAllRows);
      }

    default:
      return state;
  }
};

export { sidebarStateReducer, initialSidebarState };