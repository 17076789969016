import { createSelector } from 'reselect';
import { UNINITIALIZED } from '../requestStates';
import { invariant, isFunction } from '../lib/helperFunctions';
export default (getRequestStates => {
  invariant(isFunction(getRequestStates), 'A requestStates selector must be provided');
  return requestKey => {
    invariant(!!requestKey, 'A request key is required to create a requestStateSelector');
    return createSelector([getRequestStates], requestStates => {
      const artifact = requestStates.artifacts[requestStates.latestArtifacts[requestKey]];

      if (artifact) {
        const {
          requestState
        } = artifact;
        const legacyStateObj = {};

        if (requestState) {
          legacyStateObj.state = requestState;
        }

        return Object.assign({}, artifact, {}, legacyStateObj);
      }

      return {
        requestState: UNINITIALIZED,
        state: UNINITIALIZED
      };
    });
  };
});