'use es6';

import { buildDndAreaCellSelector, buildDndAreaUniqueRowSelector } from '../../dom/dndAreaDomSelectors';
import { ALIGNMENTS } from 'ContentUtils/constants/Alignments';
import SIZE_TYPES from 'ContentUtils/constants/BackgroundImageSizeTypes';
export const BG_IMAGE_SIZE_OPTIONS = [SIZE_TYPES.cover, SIZE_TYPES.contain, SIZE_TYPES.auto];

const convertPositionValueToPositionStyleString = positionValue => {
  switch (positionValue) {
    case ALIGNMENTS.TOP_LEFT:
      return 'left top';

    case ALIGNMENTS.TOP_CENTER:
      return 'center top';

    case ALIGNMENTS.TOP_RIGHT:
      return 'right top';

    case ALIGNMENTS.MIDDLE_LEFT:
      return 'left center';

    case ALIGNMENTS.MIDDLE_CENTER:
      return 'center center';

    case ALIGNMENTS.MIDDLE_RIGHT:
      return 'right center';

    case ALIGNMENTS.BOTTOM_LEFT:
      return 'left bottom';

    case ALIGNMENTS.BOTTOM_CENTER:
      return 'center bottom';

    case ALIGNMENTS.BOTTOM_RIGHT:
      return 'right bottom';

    default:
      throw new Error(`Invalid background position value: ${positionValue}`);
  }
};

export const isValidBackgroundImageValue = backgroundImage => backgroundImage && backgroundImage.imageUrl && backgroundImage.backgroundSize && backgroundImage.backgroundPosition;
export const calculateBackgroundImageClasses = (treeNode, styleValue) => {
  let domNodeSelector;
  let rendererName;

  if (!styleValue) {
    console.warn('Background image value must be an object in the format { imageUrl, backgroundSize, backgroundPosition, colorOverlay }');
    return [];
  }

  if (!isValidBackgroundImageValue(styleValue)) {
    console.warn('Background image value is not valid `imageUrl`, `backgroundSize`, and `backgroundPosition` keys are required');
    return [];
  }

  const {
    imageUrl,
    backgroundSize,
    backgroundPosition,
    colorOverlay
  } = styleValue;

  if (backgroundSize && !BG_IMAGE_SIZE_OPTIONS.includes(backgroundSize)) {
    console.warn(`Invalid Background image size value: ${backgroundSize}`);
    return [];
  }

  if (treeNode.isRow()) {
    rendererName = treeNode.getRendererCompatibleRowName();
    domNodeSelector = buildDndAreaUniqueRowSelector(treeNode.getParent().getName(), treeNode.getName());
  } else {
    rendererName = treeNode.getName();
    domNodeSelector = buildDndAreaCellSelector(rendererName);
  }

  const cssProperties = {
    'background-image': `url('${imageUrl}') !important`,
    'background-size': `${backgroundSize} !important`,
    'background-position': `${convertPositionValueToPositionStyleString(backgroundPosition)} !important`,
    'background-repeat': 'no-repeat !important'
  };

  if (colorOverlay) {
    console.warn('TODO, implement color overlay BG style');
  }

  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-background-image`,
    cssSelector: `.${rendererName}-background-image`,
    cssProperties
  }];
};