'use es6';

import enviro from 'enviro';
import httpNoAuth from 'hub-http/clients/noAuthApiClient';
import { formUrl } from 'self-service-api/core/utilities/links';
import { TRIAL_STATUS_TYPES } from './constants';
import HUBS from 'self-service-api/constants/Hubs';
import PortalIdParser from 'PortalIdParser';
import { MARKETING_PRO } from 'self-service-api/constants/UpgradeProducts';
import { getTrialGuideUrl, getReportsDashboardUrl } from '../utils/links';
export const getTrialModalUpgradeData = ({
  upgradeProduct,
  trialStatus
}) => {
  const uniqueId = trialStatus === TRIAL_STATUS_TYPES.EXPIRING_SOON ? 'expiring-soon-trial-modal' : 'expired-trial-modal';
  return {
    screen: 'index',
    uniqueId,
    app: 'reporting',
    upgradeProduct
  };
};
export const getSentenceCasedModalType = trialStatus => {
  switch (trialStatus) {
    case TRIAL_STATUS_TYPES.EXPIRING_SOON:
      return 'expiring soon modal';

    case TRIAL_STATUS_TYPES.EXPIRED:
      return 'expired modal';

    default:
      return null;
  }
};
const PROD_TRIALS_SURVEY_FORM_ATTRIBUTE = {
  formId: '5a8ff44d-cba9-4502-8a52-822e4ddbf96a',
  portalId: '53'
};
const QA_TRIALS_SURVEY_FORM_ATTRIBUTE = {
  formId: '15dbddff-6e30-4a7a-91df-b591cb5fe2c8',
  portalId: '99609805'
};

const getTrialSurveyFormAttributes = () => {
  if (!enviro.isProd()) {
    return QA_TRIALS_SURVEY_FORM_ATTRIBUTE;
  }

  return PROD_TRIALS_SURVEY_FORM_ATTRIBUTE;
};

export const submitTrialSurvey = data => {
  const formAttributes = getTrialSurveyFormAttributes();
  const formEndpoint = formUrl(formAttributes);

  if (!enviro.isProd()) {
    console.log('Submission to trial survey form', formEndpoint, data);
  }

  return httpNoAuth.post(formEndpoint, {
    data
  });
};
export const getTrialInstantActionRedirectUrl = hub => {
  const hubToUrlPath = {
    [HUBS.HUB_MARKETING]: `/workflows/${PortalIdParser.get()}/platform/create/template?flowTypeId=0-1&scrollToElementId=scroll-to-new-action-config-0-1`,
    [HUBS.HUB_SALES]: `/sequences/${PortalIdParser.get()}/create`
  };
  return hubToUrlPath[hub];
};
export const getAcademyTrialRedirectLink = upgradeProduct => upgradeProduct === MARKETING_PRO ? getTrialGuideUrl(upgradeProduct) : getReportsDashboardUrl();