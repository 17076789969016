import { createSelector } from 'reselect';
import http from 'hub-http/clients/apiClient';
import { PENDING, SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
export function tryParseNumber(val) {
  const parsed = Number(val);
  if (parsed) return parsed;
  return val;
}
/**
 * createGenericFetchAction
 * returns results/errors to be used in loadOptions
 * @param {string} type Action type
 * @param {function} fetch Fetch function from client
 * @returns {function} Generic action creator
 */

export function createGenericFetchAction(type, fetch, predicateFunction) {
  const genericAction = options => {
    const {
      request,
      receive,
      error
    } = genericAction;
    return (dispatch, getState, {
      httpClient = http
    } = {}) => {
      if (predicateFunction && predicateFunction(getState(), options)) {
        return null;
      }

      dispatch(request(options));
      return fetch(options, httpClient).then(response => {
        dispatch(receive(options, response));
        return response;
      }).catch(__error => {
        dispatch(error(options, __error));
        return __error;
      });
    };
  };

  genericAction.request = options => {
    return {
      type,
      options
    };
  };

  genericAction.receive = (options, response) => {
    return {
      type,
      response,
      options
    };
  };

  genericAction.error = (options, error) => {
    return {
      type,
      error,
      options
    };
  };

  return genericAction;
}
/**
 * @param {string} type Action type
 * @param {function} create Create function from client
 * @returns {function} Generic action creator
 * createGenericCreateAction
 */

export function createGenericCreateAction(type, create) {
  const genericAction = requestData => {
    const {
      request,
      receive,
      error
    } = genericAction;
    return (dispatch, getState, {
      httpClient = http
    } = {}) => {
      dispatch(request(requestData));
      return create(requestData, httpClient).then(response => {
        dispatch(receive(response));
        return response;
      }).catch(__error => {
        dispatch(error(__error));
        return __error;
      });
    };
  };

  genericAction.request = name => {
    return {
      type,
      name
    };
  };

  genericAction.receive = response => {
    return {
      type,
      response
    };
  };

  genericAction.error = error => {
    return {
      type,
      error
    };
  };

  return genericAction;
}

/**
 * createGenericOptionsSelector
 * @param {function} resourceSelector Resource selector
 * @return {function} generic options selector
 */
export function createGenericOptionsSelector(resourceSelector, options = {}) {
  const {
    disabledPredicate,
    getDisplayText,
    getHelpText
  } = options;
  return createSelector([resourceSelector], resources => {
    return Object.keys(resources).map(resourceId => {
      const resource = resources[resourceId];
      return {
        value: tryParseNumber(resourceId),
        text: getDisplayText ? getDisplayText(resource) : resource.name,
        disabled: disabledPredicate ? disabledPredicate(resource) : false,
        help: getHelpText && getHelpText(resource)
      };
    });
  });
}
/**
 * @param {string} type Action type
 * @return {function} reducer
 * createGenericRequestStatusReducer
 */

export function createGenericRequestStatusReducer(type) {
  return (state = {}, action) => {
    switch (action.type) {
      case type:
        {
          const {
            response,
            error,
            options: id
          } = action;
          let requestStatus = PENDING;
          if (response) requestStatus = SUCCEEDED;
          if (error) requestStatus = FAILED;
          return Object.assign({}, state, {
            [id]: requestStatus
          });
        }

      default:
        return state;
    }
  };
}