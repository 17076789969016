'use es6';

import enviro from 'enviro';
export const determineHubletSubdomain = () => {
  switch (enviro.getHublet()) {
    case 'na1':
      {
        return '';
      }

    case 'eu1':
      {
        return '-eu1';
      }

    default:
      {
        return '';
      }
  }
};
export const getAppDomain = () => {
  const thirdLevel = enviro.deployed() ? 'app' : 'local';
  const secondLevel = enviro.isQa() ? 'hubspotqa' : 'hubspot';
  return `${thirdLevel}${determineHubletSubdomain()}.${secondLevel}.com`;
};
export const constructSearchStringFromUrlParams = urlParams => {
  if (!urlParams || Object.keys(urlParams).length === 0) {
    return '';
  }

  return `?${Object.keys(urlParams).map(key => `${encodeURI(key)}=${encodeURIComponent(urlParams[key])}`).join('&')}`;
};