'use es6';

import { SHOULD_CLEAR_HEADER_ACTIVE_ITEM, UPDATE_HEADER_ACTIVE_ITEM } from 'ContentEditorUI/redux/actions/actionTypes';
export const updateHeaderActiveItem = activeItem => ({
  type: UPDATE_HEADER_ACTIVE_ITEM,
  payload: activeItem
});
export const setShouldClearHeaderActiveItem = shouldClear => ({
  type: SHOULD_CLEAR_HEADER_ACTIVE_ITEM,
  payload: shouldClear
});