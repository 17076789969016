'use es6';

const removeThemePathPrefix = (modulePath, themePath, parentThemePath) => {
  const modulePathWithoutFirstSlash = modulePath.replace(/^\//, '');

  if (modulePathWithoutFirstSlash.startsWith(themePath)) {
    return modulePathWithoutFirstSlash.replace(themePath, '');
  } else if (parentThemePath && modulePathWithoutFirstSlash.startsWith(parentThemePath)) {
    return modulePathWithoutFirstSlash.replace(parentThemePath, '');
  }

  return modulePath;
};

export const isModuleAPartOfTheme = (modulePath, themePath, parentThemePath) => {
  if (!modulePath || !themePath) {
    return false;
  }

  const modulePathWithoutFirstSlash = modulePath.toLowerCase().replace(/^\//, '');
  const matchesThemePath = modulePathWithoutFirstSlash.startsWith(`${themePath.toLowerCase()}/`);
  const matchesParentThemePath = !!parentThemePath && modulePathWithoutFirstSlash.startsWith(`${parentThemePath.toLowerCase()}/`);
  return matchesThemePath || matchesParentThemePath;
};
export const filterOutParentThemeModulesOverriddenInChildTheme = (moduleSpecs, themePath, parentThemePath) => {
  if (parentThemePath) {
    // Intentionally using Set in a manner that works with IE 11
    const pathsToFilterOut = new Set();
    const relativePathsInChildTheme = new Set();
    const relativePathsInParentTheme = new Set();
    moduleSpecs.forEach(spec => {
      // Bail early if there is no path (i.e for CMv1 globals)
      if (!spec.path) {
        return;
      }

      const relativePath = removeThemePathPrefix(spec.path, themePath, parentThemePath);
      const isInTheme = isModuleAPartOfTheme(spec.path, themePath);
      const isInParentTheme = isModuleAPartOfTheme(spec.path, parentThemePath);

      if (isInTheme) {
        relativePathsInChildTheme.add(relativePath);
      } else if (isInParentTheme) {
        relativePathsInParentTheme.add(relativePath);
      }

      if (isInTheme || isInParentTheme) {
        const moduleInParentAndChild = relativePathsInChildTheme.has(relativePath) && relativePathsInParentTheme.has(relativePath);

        if (moduleInParentAndChild) {
          let pathToFilterOut = `${parentThemePath}${relativePath}`; // Ensure all the paths to filter start with a `/`

          if (pathToFilterOut[0] !== '/') {
            pathToFilterOut = `/${pathToFilterOut}`;
          }

          pathsToFilterOut.add(pathToFilterOut);
        }
      }
    });

    if (pathsToFilterOut.size > 0) {
      return moduleSpecs.filter(spec => {
        const matchesPathToFilter = pathsToFilterOut.has(spec.path) || pathsToFilterOut.has(`/${spec.path}`);
        return !matchesPathToFilter;
      });
    }

    return moduleSpecs;
  }

  return moduleSpecs;
};