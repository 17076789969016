'use es6';

export const totalColumnWidthForRow = row => {
  return row.getColumns().reduce((totalWidth, col) => {
    if (col.getValue() && col.getValue().width != null) {
      return totalWidth + col.getValue().width;
    }

    return totalWidth;
  }, 0);
};
export const ensureIntField = (obj, field) => {
  if (typeof obj[field] === 'string') {
    obj[field] = parseInt(obj[field], 10);
  }
};
export const sortByXField = (a, b) => {
  if (a.x > b.x) return 1;
  if (a.x < b.x) return -1;
  return 0;
}; // New columns inserted will default to having being the size if _all_ the columns in the row
// had the same size

export const defaultSizeForNewColumn = (numExistingColumns, numColumns) => {
  return Math.floor(numColumns / (numExistingColumns + 1));
};
export const sortColumnsNamesBiggestToSmallestThenByDistance = (columnCells, newColumnName) => {
  const newColumnIndex = columnCells.findIndex(c => c.getName() === newColumnName); // Place to store the original index of each column, since they are needed for sort

  const origIndexByName = {};
  columnCells.forEach((c, i) => {
    origIndexByName[c.getName()] = i;
  });
  return columnCells.slice() // clone array before mutable sort
  .sort((c1, c2) => {
    if (c1.getWidth() !== c2.getWidth()) {
      return c2.getWidth() - c1.getWidth(); // sort wider columns first
    } // For columns of the same width sort columns located _closer_ to the new column first.
    // This way we "steal" from neighbors before further away columns


    const c1Dist = Math.abs(origIndexByName[c1.getName()] - newColumnIndex);
    const c2Dist = Math.abs(origIndexByName[c2.getName()] - newColumnIndex);

    if (c1Dist !== c2Dist) {
      return c1Dist - c2Dist;
    } // And if all is equal, favor stealing from the left before the right


    return origIndexByName[c1.getName()] - origIndexByName[c2.getName()];
  }).map(c => c.getName()).filter(colName => colName !== newColumnName); // omit the column just inserted
};
export const sortColumnsNamesSmallestToBiggestThenByDistance = (columnCells, columnNameToRemove) => {
  const removedColumnIndex = columnCells.findIndex(c => c.getName() === columnNameToRemove); // Place to store the original index of each column, since they are needed for sort

  const origIndexByName = {};
  columnCells.forEach((c, i) => {
    origIndexByName[c.getName()] = i;
  });
  return columnCells.slice() // clone array before mutable sort
  .sort((c1, c2) => {
    if (c1.getWidth() !== c2.getWidth()) {
      return c1.getWidth() - c2.getWidth(); // sort narrower columns first
    } // For columns of the same width sort columns located _closer_ to the new column first.
    // This way we "steal" from neighbors before further away columns


    const c1Dist = Math.abs(origIndexByName[c1.getName()] - removedColumnIndex);
    const c2Dist = Math.abs(origIndexByName[c2.getName()] - removedColumnIndex);

    if (c1Dist !== c2Dist) {
      return c1Dist - c2Dist;
    } // And if all is equal, favor stealing from the left before the right


    return origIndexByName[c1.getName()] - origIndexByName[c2.getName()];
  }).map(c => c.getName()).filter(colName => colName !== columnNameToRemove); // omit the column just inserted
};

const columnsIsntAlreadyTooBigOrTooSmall = (column, columnsAdjustment, adjustment, maxColSize) => {
  if (adjustment > 0) {
    return column.getWidth() + columnsAdjustment < maxColSize;
  }

  return column.getWidth() + columnsAdjustment > 1;
};

export const adjustWidthForAllColumns = (sortedColumnsToStealFrom, widthLeftToAdjust, adjustment, maxColSize) => {
  // Prep result adjustments object
  const widthAdjustmentByName = {};
  sortedColumnsToStealFrom.forEach(c => {
    widthAdjustmentByName[c.getName()] = 0;
  });
  let widthAdjustedThisloop;

  do {
    widthAdjustedThisloop = 0; // Note, speciallyOrderedColumns and its contents are itentionally regular arrays/object
    // for easier mutation in this loop

    for (const column of sortedColumnsToStealFrom) {
      if (columnsIsntAlreadyTooBigOrTooSmall(column, widthAdjustmentByName[column.getName()], adjustment, maxColSize) && widthLeftToAdjust !== 0) {
        widthAdjustmentByName[column.getName()] += adjustment;
        widthLeftToAdjust += adjustment;
        widthAdjustedThisloop -= adjustment;
      }
    }
  } while (widthLeftToAdjust !== 0 && widthAdjustedThisloop !== 0); // I don't _think_ the `widthAdjustedThisloop !== 0` check is necessary, but putting that in to
  // prevent infinite loop bugs for now


  return widthAdjustmentByName;
}; // Global storage of last timestamp-generated name/ID

let lastGeneratedTimestampNameGlobal;
export const nextUniqueTimestampBasedName = prefix => {
  if (prefix == null) {
    throw new Error('No prefix passed to nextUniqueTimestampBasedName');
  }

  const newTimetamp = `${new Date().valueOf()}`;
  let timestampForName = newTimetamp; // Prevent collisions when two names are generated within a single millisecond

  if (lastGeneratedTimestampNameGlobal && lastGeneratedTimestampNameGlobal.startsWith(timestampForName)) {
    // const [timestampPart, suffix] = lastGeneratedTimestampNameGlobal.split('-');
    const suffix = lastGeneratedTimestampNameGlobal.replace(timestampForName, '');

    if (suffix) {
      timestampForName = `${newTimetamp}${parseInt(suffix, 10) + 1}`;
    } else {
      timestampForName = `${newTimetamp}2`;
    }
  } // Save the last generated timestamp as a global, to prevent future collisions


  lastGeneratedTimestampNameGlobal = timestampForName;
  return `${prefix}${timestampForName}`;
};
export const hasAnyLayoutStyleData = cellOrRow => {
  const layoutStyleData = cellOrRow.getLayoutStyleData();
  return layoutStyleData && Object.keys(layoutStyleData).length > 0;
};
export const removeNullKeysFromObject = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] == null) {
      delete obj[key];
    }
  });
  return obj;
};