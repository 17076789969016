'use es6';

import Raven from 'Raven';
import devLogger from 'react-utils/devLogger';
import * as tracker from 'ui-addon-upgrades/_core/common/eventTracking/tracker';
import { startTrial } from 'self-service-api/api/startTrial';
import wrapWithErrorMonitoring from 'ui-addon-upgrades/_core/common/reliability/wrapWithErrorMonitoring';
export const activateTrial = wrapWithErrorMonitoring('activateTrial', ({
  activeTrials,
  expiredTrials,
  isMultiTrial,
  upgradeData
}) => {
  const {
    trialId,
    source
  } = upgradeData;
  const defaultDurationInDays = 14;
  const currentDate = new Date();
  const endDate = currentDate.setDate(currentDate.getDate() + defaultDurationInDays).valueOf();
  const trackingProperties = Object.assign({}, upgradeData, {
    activeTrials,
    expiredTrials,
    isMultiTrial
  });
  return startTrial({
    trialId,
    endDate,
    trialSource: source
  }).then(() => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.track('trialActivationInteraction', Object.assign({
      action: 'activation succeeded'
    }, trackingProperties));
  }).catch(e => {
    // ********** PUBLIC EVENT **********
    // Public Events help teams across HubSpot automate work and customize experiences based on user actions.
    // Speak with #product-insight and your PM before any shipping any changes to this event incl. event name, properties, values, and when it occurs.
    // Read more about Public Events on the wiki: https://wiki.hubspotcentral.net/display/PM/Public+Events+-+Amplitude+events+ready+for+HubSpot+team+use+and+automation
    tracker.track('trialActivationInteraction', Object.assign({
      action: 'activation failed'
    }, trackingProperties));
    devLogger.warn({
      message: e,
      key: 'TrialActivationButton'
    });
    Raven.captureMessage('Trial activation failed', {
      extra: {
        error: e
      }
    });
    throw e;
  });
});