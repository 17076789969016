'use es6';

import { addImageAltText, addPageTitle } from 'ContentEditorUI/redux/actions/recommenderActions';
import { RECOMMENDATION_TARGET_TYPES } from 'ContentEditorUI/lib/xray/constants';
const SEO_RECOMMENDATIONS = {
  ADD_IMAGE_ALT_TEXT: {
    action: addImageAltText,
    targetType: RECOMMENDATION_TARGET_TYPES.MODULE
  },
  ADD_TITLE: {
    action: addPageTitle,
    targetType: RECOMMENDATION_TARGET_TYPES.SETTING
  }
};
export default SEO_RECOMMENDATIONS;