import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import { na1 as DEFAULT_API_HUBLET } from 'hubspot-url-utils/hublets';
import { CDN_SWAP_GATE, PORTAL_ID_DOMAIN_GATE } from '../Constants';
export const SHARDS_LARGEST_INDEX = 4;
const SHARD_NUMERAL_LENGTH = 2;
const SHARD_NUMERAL_SUFFIX = '0';
const LARGEST_FORMATTED_SHARD_INDEX = 50;

function getEnv() {
  return enviro.getShort('filemanager');
}

const getIsUngatedForCdnDomainSwap = auth => auth.gates.includes(CDN_SWAP_GATE);

const getIsUngatedForPortalIdBasedDomain = auth => auth.gates.includes(PORTAL_ID_DOMAIN_GATE);

export const constructShard = (apexDomainSuffix, formattedShardIndex, auth) => {
  const swapSubdomains = getIsUngatedForCdnDomainSwap(auth);

  if (getIsUngatedForPortalIdBasedDomain(auth)) {
    return `${PortalIdParser.get()}.${swapSubdomains ? 'fs2' : 'fs1'}.hubspotusercontent${apexDomainSuffix}${formattedShardIndex}.net`;
  }

  if (formattedShardIndex === '00' && apexDomainSuffix !== 'qa') {
    return 'fs.hubspotusercontent00.net';
  }

  return `f.hubspotusercontent${apexDomainSuffix}${formattedShardIndex}.net`;
};
export const getShardDomainSuffixFromEnv = env => env === 'qa' ? 'qa' : '';
export const getFormattedShardIndex = index => {
  if (typeof String.prototype.padEnd === 'function') {
    return String(index).padEnd(SHARD_NUMERAL_LENGTH, SHARD_NUMERAL_SUFFIX);
  } else {
    if (String(index).length >= SHARD_NUMERAL_LENGTH) {
      return String(index);
    }

    return String(index * Math.pow(10, SHARD_NUMERAL_LENGTH - 1));
  }
};
export const getPortalShard = () => {
  return Math.floor(PortalIdParser.get() % LARGEST_FORMATTED_SHARD_INDEX / 10);
};
export const isNonDefaultHublet = () => enviro.getHublet() !== DEFAULT_API_HUBLET;
export function getCdnHostnameForNonDefaultHublet(auth) {
  const shardApexDomainSuffix = getShardDomainSuffixFromEnv(getEnv());
  return constructShard(shardApexDomainSuffix, `-${enviro.getHublet()}`, auth);
}
export const getCdnHostnameForPortal = auth => {
  if (isNonDefaultHublet() || getIsUngatedForPortalIdBasedDomain(auth)) {
    return getCdnHostnameForNonDefaultHublet(auth);
  }

  return constructShard(getShardDomainSuffixFromEnv(getEnv()), getFormattedShardIndex(getPortalShard()), auth);
};