import { createSelector } from 'reselect';
import { memoize, omit } from 'underscore';
import { createGenericOptionsSelector } from 'ContentData/helpers/reduxHelpers';
import { getWorkflowHybridPermissions, getFollowupEmailAction, isSimpleWorkflowForForm } from 'ContentData/helpers/workflowsHelpers';
import { getFormWorkflowDependencies } from 'ContentData/selectors/forms';
import { getEmailsCache } from 'ContentData/selectors/emails';
import { SUCCEEDED, FAILED } from 'ContentUtils/constants/RequestStatus';
export const getWorkflow = (state, props) => state.resources.workflows.workflows[props.id];
export const getWorkflows = state => state.resources.workflows.workflows;
export const getWorkflowsOptions = createGenericOptionsSelector(getWorkflows);
export const getWorkflowFetchStatus = (state, props) => state.resources.workflows.requestStatus[props.id];
export const getWorkflowHybrids = state => state.resources.workflows.hybrids;
export const getWorkflowHybridsRequestStatus = state => state.resources.workflows.hybridsRequestStatus;
export const getWorkflowHybrid = (state, props) => {
  return getWorkflowHybrids(state)[props.id];
};
export const getWorkflowSummaries = state => state.resources.workflows.summaries;
export const getAllWorkflowHybridsHaveLoaded = createSelector([getFormWorkflowDependencies, getWorkflowHybridsRequestStatus], (formWorkflowDependencies, workflowHybridsRequestStatus) => {
  if (!formWorkflowDependencies) return false;
  return formWorkflowDependencies.every(flowId => {
    const requestStatus = workflowHybridsRequestStatus[flowId];
    return requestStatus === SUCCEEDED || requestStatus === FAILED;
  });
});

const getFormGuid = (state, props) => props.formGuid;

const getContentId = (state, props) => props.contentId;

const memoizedGetFilteredHydratedFormWorkflowDependencies = memoize((formWorkflowDependencies, workflowHybrids, emailsCache, formGuid, contentId, isUngatedForFollowupEmailWorkflows) => {
  const genericWorkflows = [];
  const followupEmailWorkflows = [];

  if (formWorkflowDependencies && formWorkflowDependencies.length) {
    formWorkflowDependencies.forEach(flowId => {
      const workflowHybrid = workflowHybrids[flowId];
      const workflowHybridPermissions = workflowHybrid && getWorkflowHybridPermissions(workflowHybrid, formGuid, contentId);
      const {
        canDelete,
        canEditActions,
        canRemoveEnrollmentCriteria,
        shouldShow
      } = workflowHybridPermissions || {};

      if (shouldShow) {
        const hybridWithPermissions = Object.assign({}, workflowHybrid, {
          canDelete,
          canRemoveEnrollmentCriteria,
          canEditActions: false // Don't actually set this to true if it's not an email workflow

        });
        const emailAction = isUngatedForFollowupEmailWorkflows && getFollowupEmailAction(workflowHybrid);

        if (emailAction) {
          const {
            contentId: emailId
          } = emailAction.metadata || {};

          if (emailId) {
            hybridWithPermissions.emailId = emailId;
            const email = emailId && emailsCache[emailId];

            if (email) {
              hybridWithPermissions.emailName = email.name;
            }
          }

          if (canEditActions) {
            hybridWithPermissions.canEditActions = true;
          }

          followupEmailWorkflows.push(hybridWithPermissions);
        } else {
          genericWorkflows.push(hybridWithPermissions);
        }
      }
    });
  }

  return {
    genericWorkflows,
    followupEmailWorkflows
  };
}, (formWorkflowDependencies, workflowHybrids, emailsCache, formGuid, contentId) => {
  return `
      ${formWorkflowDependencies && formWorkflowDependencies.join('.')}-
      ${JSON.stringify(workflowHybrids)}-
      ${formGuid}-${contentId}-
      ${Object.keys(emailsCache).join('-')}
      `;
});

const getIsUngatedForFollowupEmailWorkflows = (state, props) => props.isUngatedForFollowupEmailWorkflows;

export const getFilteredHydratedFormWorkflowDependencies = createSelector([getFormWorkflowDependencies, getWorkflowHybrids, getEmailsCache, getFormGuid, getContentId, getIsUngatedForFollowupEmailWorkflows], memoizedGetFilteredHydratedFormWorkflowDependencies);
const memoizedGetFormSimpleWorkflowsCount = memoize((formWorkflowDependencies, workflowHybrids, formGuid) => {
  if (formWorkflowDependencies && formWorkflowDependencies.length) {
    return formWorkflowDependencies.reduce((acc, flowId) => {
      const workflowHybrid = workflowHybrids[flowId];
      return workflowHybrid && isSimpleWorkflowForForm(workflowHybrid, formGuid) ? acc + 1 : acc;
    }, 0);
  }

  return 0;
}, (formWorkflowDependencies, workflowHybrids, formGuid) => {
  return `
      ${formWorkflowDependencies && formWorkflowDependencies.join('.')}-
      ${JSON.stringify(workflowHybrids)}-
      ${formGuid}
      `;
});
export const getFormSimpleWorkflowsCount = createSelector([getFormWorkflowDependencies, getWorkflowHybrids, getFormGuid], memoizedGetFormSimpleWorkflowsCount);
const memoizedGetAddableWorkflowSummaries = memoize((filteredFormWorkflowDependencyFlowIds, workflowSummaries) => {
  if (filteredFormWorkflowDependencyFlowIds.length) {
    const flowIds = filteredFormWorkflowDependencyFlowIds.map(id => `${id}`);
    return omit(workflowSummaries, flowIds);
  }

  return workflowSummaries;
}, (filteredFormWorkflowDependencyFlowIds, workflowSummaries) => {
  return `
      ${filteredFormWorkflowDependencyFlowIds.join('.')}-
      ${workflowSummaries && Object.keys(workflowSummaries).length}
      `;
});
const getFilteredFormWorkflowDependencyFlowIds = createSelector([getFilteredHydratedFormWorkflowDependencies], filteredHydratedFormWorkflowDependencies => {
  const {
    followupEmailWorkflows = [],
    genericWorkflows = []
  } = filteredHydratedFormWorkflowDependencies || {};
  return followupEmailWorkflows.concat(genericWorkflows).map(hybrid => hybrid.flowId);
}); // Only include workflows that don't already reference the
// selected form in their enrollment criteria

const getAddableWorkflowSummaries = createSelector([getFilteredFormWorkflowDependencyFlowIds, getWorkflowSummaries], memoizedGetAddableWorkflowSummaries);
export const getWorkflowSummariesOptions = createGenericOptionsSelector(getAddableWorkflowSummaries);