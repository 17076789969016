import * as PropTypes from 'prop-types';
import * as decorators from './decorators';
/**
 * Takes a component and returns the propTypes for that component
 * where all required props with a default value are made optional.
 * This is intended to make wrapper component prop types easier
 * to construct.
 */

export function wrapPropTypes({
  propTypes,
  defaultProps
}, omitted) {
  const result = {};
  const omittedKeys = omitted ? new Set(omitted) : undefined;

  for (const key of Object.keys(propTypes)) {
    if (omittedKeys && omittedKeys.has(key)) {
      continue;
    }

    let original = propTypes[key];

    if (typeof original !== 'function') {
      original = PropTypes.any;
    }

    const required = !original.isRequired;
    const hasDefault = Object.prototype.hasOwnProperty.call(defaultProps, key);

    if (required && hasDefault) {
      // in this case we have no reference back to the non-required version
      result[key] = decorators.notRequired(original);
    } else {
      result[key] = original || propTypes.any;
    }
  }

  return result;
}