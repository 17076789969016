module.exports = {
  "productApprovalsInteraction": {
    "name": "product-approvals-interaction",
    "class": "interaction",
    "properties": {
      "app": {
        "type": "string",
        "isOptional": true
      },
      "interaction": "string",
      "type": "string"
    },
    "namespace": "product-approvals"
  }
};