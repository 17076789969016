'use es6'; // copied from ContentComponents/components/cmv2/utils

const isNullOrUndefined = value => {
  return value === null || value === undefined;
}; // copied from ContentComponents/components/cmv2/utils


const getDeepValue = (searchObject, valueKey) => {
  if (!Array.isArray(valueKey) || isNullOrUndefined(searchObject) || typeof searchObject !== 'object') {
    return undefined;
  }

  let current = JSON.parse(JSON.stringify(searchObject));
  let i = 0;

  while (i < valueKey.length) {
    if (isNullOrUndefined(current) || typeof current !== 'object') {
      return undefined;
    }

    current = current[valueKey[i]];
    i = i + 1;
  }

  return current;
};

export const getDataVariableKey = fieldKey => `data-hs-theme-${fieldKey.join('-')}`;

const getFieldKeys = (field, parentFieldKey = []) => {
  const fieldKey = parentFieldKey.concat(field.name);

  if (field.children) {
    return field.children.reduce((fieldKeys, child) => {
      const childMap = getFieldKeys(child, fieldKey);
      return [...fieldKeys, ...childMap];
    }, []);
  }

  const fieldVariableKey = getDataVariableKey(fieldKey);
  return [fieldVariableKey];
};

export const getThemeSettingsKeys = theme => {
  const {
    fields
  } = theme;
  if (!fields) return [];
  return fields.reduce((fieldKeys, field) => {
    const fieldMap = getFieldKeys(field);
    return [...fieldKeys, ...fieldMap];
  }, []);
}; // Grabs the first parent fieldkey that has actual setting if not resets using the first key present

export const getClosestNonEmptyParentKey = (key, formData) => {
  if (!key) return key;
  formData = formData.deleteIn(key);

  for (let i = key.length - 1; i > 0; i--) {
    const fieldKey = key.slice(0, i);
    const value = getDeepValue(formData, fieldKey);
    if (i === key.length - 1 && Object.keys(value).length > 0) return key;

    if (value !== undefined) {
      if (typeof value === 'object' && Object.keys(value).length > 0) return key.slice(0, i + 1);
      if (typeof value === 'object' && Object.keys(value).length === 0) formData = formData.deleteIn(fieldKey);
    }
  }

  return [key[0]];
};