var ErrorSubCategory;

(function (ErrorSubCategory) {
  ErrorSubCategory["ACTIVE_APPROVAL_ALREADY_EXISTS"] = "ACTIVE_APPROVAL_ALREADY_EXISTS";
  ErrorSubCategory["APPROVAL_TRANSITION_NOT_PERMITTED"] = "APPROVAL_TRANSITION_NOT_PERMITTED";
  ErrorSubCategory["APPROVER_NOT_VALID"] = "APPROVER_NOT_VALID";
  ErrorSubCategory["REQUESTER_NON_VALID_FOR_CANCELLATION"] = "REQUESTER_NON_VALID_FOR_CANCELLATION";
  ErrorSubCategory["REQUESTER_SAME_USER_AS_THE_APPROVER"] = "REQUESTER_SAME_USER_AS_THE_APPROVER";
  ErrorSubCategory["USER_IS_NOT_AN_APPROVER"] = "USER_IS_NOT_AN_APPROVER";
  ErrorSubCategory["APPROVALS_NOT_ENABLED"] = "APPROVALS_NOT_ENABLED";
  ErrorSubCategory["APPROVER_OWNER_ID_DOES_NOT_EXIST"] = "APPROVER_OWNER_ID_DOES_NOT_EXIST";
  ErrorSubCategory["PORTAL_NOT_ALLOWED"] = "PORTAL_NOT_ALLOWED";
  ErrorSubCategory["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
})(ErrorSubCategory || (ErrorSubCategory = {}));

export default ErrorSubCategory;