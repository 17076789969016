'use es6';

import I18n from 'I18n';
const DEFAULT_COUNTRY = 'us';
const LANG_TO_ISO2 = {
  de: 'de',
  en: 'us',
  es: 'es',
  fi: 'fi',
  fr: 'fr',
  it: 'it',
  ja: 'jp',
  nl: 'nl',
  sv: 'se'
};
export default (() => {
  const [lang, country] = I18n.locale.split('-');

  if (country) {
    return country;
  } else if (LANG_TO_ISO2[lang]) {
    return LANG_TO_ISO2[lang];
  }

  return DEFAULT_COUNTRY;
});