'use es6';

import { useEffect, useState } from 'react';
const DEFAULT_NAV_HEIGHT = 54;
export default function useNavBottomPosition() {
  const [navBottomPosition, setNavBottomPosition] = useState(DEFAULT_NAV_HEIGHT);

  const updateNavBottomPositionValue = () => {
    const placeholderNav = document.getElementsByClassName('placeholder-nav');

    if (placeholderNav[0]) {
      const newNavBottomPosition = placeholderNav[0].getBoundingClientRect().bottom;
      setNavBottomPosition(Math.max(newNavBottomPosition, 0));
    }
  };

  useEffect(() => {
    // runs function once onLoad and adds it as callback to onScroll
    updateNavBottomPositionValue();
    window.addEventListener('scroll', updateNavBottomPositionValue);
    return () => {
      window.removeEventListener('scroll', updateNavBottomPositionValue);
    };
  });
  return navBottomPosition;
}