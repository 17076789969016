'use es6';

import { setModuleHighlightedField, selectModuleForXray, openConfirmEditGlobalForXray, openSettingsForXRay } from 'ContentEditorUI/redux/actions/xrayActions';
import { getIsGlobalModuleForXray, getIsDefaultModuleForXray, getIsCustomModuleForXray } from 'ContentEditorUI/redux/selectors/xraySelectors';
export const addImageAltText = moduleId => {
  return (dispatch, getState) => {
    const state = getState();
    const isGlobalModule = getIsGlobalModuleForXray(state, moduleId);
    const isDefaultModule = getIsDefaultModuleForXray(state, moduleId);
    const isCustomModule = getIsCustomModuleForXray(state, moduleId);

    if (isDefaultModule) {
      dispatch(setModuleHighlightedField(['img', 'alt-text']));
      dispatch(selectModuleForXray(moduleId));
    } else if (isGlobalModule) {
      dispatch(openConfirmEditGlobalForXray(moduleId));
    } else if (isCustomModule) {
      dispatch(selectModuleForXray(moduleId));
    }
  };
};
export const addPageTitle = () => {
  return dispatch => dispatch(openSettingsForXRay('name'));
};