'use es6';

import { pick } from 'underscore';
import { CUSTOM_MODULE_PROPS } from 'ContentEditorUI/lib/widgetEdit/constants';
import { CategoryToContentType, CategoryToHostTemplateType } from 'ContentEditorUI/lib/categories/constants';
import ContentRoutes from 'ContentUtils/Routes';

const moduleMatchesCategory = (module, categoryName) => {
  const {
    contentTypes = [],
    hostTemplateTypes = []
  } = module;

  if (ContentRoutes.isUngated('CMv2:UseContentTypes')) {
    return contentTypes.indexOf('ANY') !== -1 || contentTypes.indexOf(CategoryToContentType[categoryName]) !== -1;
  }

  return hostTemplateTypes.indexOf('ANY') !== -1 || hostTemplateTypes.indexOf(CategoryToHostTemplateType[categoryName]) !== -1;
};

export const rehydrateModuleSchemasWithTranslations = (moduleSchemasWithTranslations, state) => {
  let newState = state;
  const relevantGroups = ['all', 'allAddable', 'builtInModules', 'customModules', 'globalModules'];
  Object.keys(moduleSchemasWithTranslations).forEach(id => {
    relevantGroups.forEach(key => {
      if (state.hasOwnProperty(key) && moduleSchemasWithTranslations[id].messages) {
        const moduleList = state[key];
        const relevantModuleIdx = Array.isArray(moduleList) ? moduleList.findIndex(v => `${v.id}` === `${id}`) : id;
        const constainsId = Array.isArray(moduleList) ? relevantModuleIdx > -1 : moduleList.hasOwnProperty(id);

        if (constainsId) {
          if (state[key][relevantModuleIdx] && state[key][relevantModuleIdx].messages) {
            let currentMessages = state[key][relevantModuleIdx].messages;
            currentMessages = Object.assign({}, currentMessages, {}, moduleSchemasWithTranslations[id].messages);
            newState = Object.assign({}, newState, {
              [key]: Object.assign({}, newState[key], {
                [relevantModuleIdx]: Object.assign({}, newState[key][relevantModuleIdx], {
                  messages: currentMessages
                })
              })
            });
          }
        }
      }
    });
  });
  return newState;
};
export const combineModuleSchemas = ({
  categoryName,
  globalModules = [],
  customModules = [],
  usedModuleSchemas = {},
  existingBuiltInModulesMap = {},
  existingGlobalModulesMap = {},
  existingCustomModulesMap = {},
  existingAllModulesMap = {},
  existingAllAddableMap = {},
  useUpdated = false
}, state) => {
  if (Object.keys(usedModuleSchemas).length) {
    customModules = customModules.concat(Object.keys(usedModuleSchemas).map(k => usedModuleSchemas[k]));
  }

  let anythingUpdated = false; // Clone all of these objects once at the start (instead of doing it again and again in a loop)

  existingBuiltInModulesMap = Object.assign({}, existingBuiltInModulesMap);
  existingGlobalModulesMap = Object.assign({}, existingGlobalModulesMap);
  existingCustomModulesMap = Object.assign({}, existingCustomModulesMap);
  existingAllModulesMap = Object.assign({}, existingAllModulesMap);
  existingAllAddableMap = Object.assign({}, existingAllAddableMap);
  customModules.forEach(module => {
    const moduleId = `${module.id}`; // First check if we already have the newest version of the module in our maps in Redux

    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true; // If the module is the right type (i.e "PAGE" or "ANY" for the Page Editor),
      // then we want to check whether or not we should add it to our custom modules, builtin,
      // global, and/or addable

      if (moduleMatchesCategory(module, categoryName)) {
        if (module.default) {
          // Default (built-in) modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'preset_widget',
            displayName: module.displayName || module.label
          });
          existingBuiltInModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;

          if (module.isAvailableForNewContent !== false) {
            existingAllAddableMap[moduleId] = m;
          }
        } else if (module.global) {
          // Global modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'global_widget',
            displayName: module.displayName || module.name
          });
          existingGlobalModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;

          if (module.isAvailableForNewContent !== false) {
            existingAllAddableMap[moduleId] = m;
          }
        } else {
          // Custom modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'custom_widget',
            displayName: module.displayName || module.name
          });
          existingCustomModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;

          if (module.isAvailableForNewContent !== false) {
            existingAllAddableMap[moduleId] = m;
          }
        }
      } else {
        // Otherwise we still want to add it to the allModulesMap so that we still have the schema for legacy
        // modules that have hostTemplateTypes of NONE
        const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
          content_widget_type: 'custom_widget',
          displayName: module.displayName || module.name
        });
        existingAllModulesMap[moduleId] = m;
      }
    }
  });
  globalModules.forEach(module => {
    const moduleId = `${module.id}`;

    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true;
      const m = Object.assign({}, module, {
        content_widget_type: 'global_widget',
        displayName: module.displayName || module.label
      });
      existingGlobalModulesMap[moduleId] = m;
      existingAllModulesMap[moduleId] = m;

      if (module.isAvailableForNewContent !== false) {
        existingAllAddableMap[moduleId] = m;
      }
    }
  });

  if (anythingUpdated) {
    return Object.assign({}, state, {
      all: existingAllModulesMap,
      builtInModules: existingBuiltInModulesMap,
      globalModules: existingGlobalModulesMap,
      customModules: existingCustomModulesMap,
      allAddable: existingAllAddableMap
    });
  }

  return state;
};
export const combineModuleSchemasWithDynamicFilter = ({
  categoryName,
  globalModules = [],
  customModules = [],
  usedModuleSchemas = {},
  existingBuiltInModulesMap = {},
  existingGlobalModulesMap = {},
  existingCustomModulesMap = {},
  existingAllModulesMap = {},
  existingAllAddableMap = {},
  useUpdated = false
}, state, filterFunction) => {
  if (Object.keys(usedModuleSchemas).length) {
    customModules = customModules.concat(Object.keys(usedModuleSchemas).map(k => usedModuleSchemas[k]));
  }

  let anythingUpdated = false; // Clone all of these objects once at the start (instead of doing it again and again in a loop)

  existingBuiltInModulesMap = Object.assign({}, existingBuiltInModulesMap);
  existingGlobalModulesMap = Object.assign({}, existingGlobalModulesMap);
  existingCustomModulesMap = Object.assign({}, existingCustomModulesMap);
  existingAllModulesMap = Object.assign({}, existingAllModulesMap);
  existingAllAddableMap = Object.assign({}, existingAllAddableMap);
  customModules.forEach(module => {
    const moduleId = `${module.id}`; // First check if we already have the newest version of the module in our maps in Redux

    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true; // If the module is the right type (i.e "PAGE" or "ANY" for the Page Editor),
      // then we want to check whether or not we should add it to our custom modules, builtin,
      // global, and/or addable

      if (filterFunction(module, categoryName)) {
        if (module.default) {
          // Default (built-in) modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'preset_widget',
            displayName: module.displayName || module.label
          });
          existingBuiltInModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;

          if (module.isAvailableForNewContent !== false) {
            existingAllAddableMap[moduleId] = m;
          }
        } else if (module.global) {
          // Global modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'global_widget',
            displayName: module.displayName || module.name
          });
          existingGlobalModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;

          if (module.isAvailableForNewContent !== false) {
            existingAllAddableMap[moduleId] = m;
          }
        } else {
          // Custom modules
          const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
            content_widget_type: 'custom_widget',
            displayName: module.displayName || module.name
          });
          existingCustomModulesMap[moduleId] = m;
          existingAllModulesMap[moduleId] = m;

          if (module.isAvailableForNewContent !== false) {
            existingAllAddableMap[moduleId] = m;
          }
        }
      } else {
        // Otherwise we still want to add it to the allModulesMap so that we still have the schema for legacy
        // modules that have hostTemplateTypes of NONE
        const m = Object.assign({}, pick(module, CUSTOM_MODULE_PROPS), {
          content_widget_type: 'custom_widget',
          displayName: module.displayName || module.name
        });
        existingAllModulesMap[moduleId] = m;
      }
    }
  });
  globalModules.forEach(module => {
    const moduleId = `${module.id}`;

    if (!useUpdated || !existingAllModulesMap.hasOwnProperty(moduleId) || existingAllModulesMap[moduleId].updated < module.updated) {
      anythingUpdated = true;
      const m = Object.assign({}, module, {
        content_widget_type: 'global_widget',
        displayName: module.displayName || module.label
      });
      existingGlobalModulesMap[moduleId] = m;
      existingAllModulesMap[moduleId] = m;

      if (module.isAvailableForNewContent !== false) {
        existingAllAddableMap[moduleId] = m;
      }
    }
  });

  if (anythingUpdated) {
    return Object.assign({}, state, {
      all: existingAllModulesMap,
      builtInModules: existingBuiltInModulesMap,
      globalModules: existingGlobalModulesMap,
      customModules: existingCustomModulesMap,
      allAddable: existingAllAddableMap
    });
  }

  return state;
};