import Url from 'urlinator/Url';
import getApiHostForEnvironment from './getApiHostForEnvironment';
import PortalIdParser from 'PortalIdParser';
export default function getApiUrl(path, params = {}) {
  if (!path || typeof path !== 'string' || typeof params !== 'object' || Array.isArray(params)) {
    return null;
  }

  const pathWithLeadingSlash = path[0] === '/' ? path : `/${path}`;
  const url = new Url(pathWithLeadingSlash, {
    hostname: getApiHostForEnvironment(),
    params: Object.assign({
      portalId: PortalIdParser.get()
    }, params)
  });
  return url ? url.href : null;
}