'use es6';

import { createSelector } from 'reselect';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getHasCrmScope, getHasWorkflowsDealsAccessScope, getHasWorkflowsTicketsAccessScope, getHasWorkflowsQuotesAccessScope, getIsUngatedForSubscriptionPersonalizationToken, getIsUngatedForAIContentGeneration } from './authSelectors';
import { COMPANY_FQN, CONTACT_FQN, DEAL_FQN, QUOTE_FQN, TICKET_FQN, SUBSCRIPTION_FQN } from 'rich-text-lib/constants/properties';
import { COMMERCE_PAYMENT_FQN } from 'ContentEditorUI/redux/constants';
import { getPaymentsEligibility } from 'ContentEditorUI/redux/selectors/paymentsEligibilitySelectors';
import { FEATURE_FLAGS } from '../constants';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
export const getCanInsertDealTokens = createSelector([getSubcategory, getHasWorkflowsDealsAccessScope], (subcategory, scoped) => {
  return subcategory === 'automated_for_deal' || ['automated', 'automated_ab_master', 'automated_ab_variant'].includes(subcategory) && scoped;
});
export const getCanInsertTicketTokens = createSelector([getSubcategory, getHasWorkflowsTicketsAccessScope], (subcategory, scoped) => {
  return ['automated', 'automated_ab_master', 'automated_ab_variant', 'ticket_opened_kickback_email', 'ticket_closed_kickback_email', 'automated_for_ticket'].includes(subcategory) && scoped;
});
export const getCanInsertQuoteTokens = createSelector([getSubcategory, getHasWorkflowsQuotesAccessScope], (subcategory, scoped) => {
  return subcategory.indexOf('automated') > -1 && scoped;
});
export const getCanInsertSubscriptionTokens = createSelector([getSubcategory, getIsUngatedForSubscriptionPersonalizationToken], (subcategory, ungated) => {
  return subcategory.indexOf('automated') > -1 && ungated;
});
export const getCanInsertPaymentsToken = createSelector([getSubcategory, getPaymentsEligibility], (subcategory, paymentsEligibility) => {
  return paymentsEligibility && paymentsEligibility.get('chargesEnabled') && subcategory.indexOf('automated') > -1;
});
export const getCanInsertInternalContactRecord = createSelector(getSubcategory, subcategory => subcategory.indexOf('automated') > -1);
export const getSupportedCrmObjectFQNs = createSelector([getHasCrmScope, getCanInsertDealTokens, getCanInsertTicketTokens, getCanInsertQuoteTokens, getCanInsertSubscriptionTokens, getCanInsertPaymentsToken], (canInsertCompanyTokens, canInsertDealTokens, canInsertTicketTokens, canInsertQuoteTokens, canInsertSubscriptionTokens, canInsertPaymentsToken) => {
  const supportedFQNs = [CONTACT_FQN];

  if (canInsertCompanyTokens) {
    supportedFQNs.push(COMPANY_FQN);
  }

  if (canInsertDealTokens) {
    supportedFQNs.push(DEAL_FQN);
  }

  if (canInsertTicketTokens) {
    supportedFQNs.push(TICKET_FQN);
  }

  if (canInsertQuoteTokens) {
    supportedFQNs.push(QUOTE_FQN);
  }

  if (canInsertSubscriptionTokens) {
    supportedFQNs.push(SUBSCRIPTION_FQN);
  }

  if (canInsertPaymentsToken) {
    supportedFQNs.push(COMMERCE_PAYMENT_FQN);
  }

  return supportedFQNs;
});
export const getCanUseAIGeneratedContent = createSelector(getIsUngatedForAIContentGeneration, isUngatedForAIContentGeneration => {
  const aiGeneratedContentFlag = EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.aiGeneratedContent, true);
  return aiGeneratedContentFlag && isUngatedForAIContentGeneration;
});