import useCurrentUserType from './useCurrentUserType';
import { useIsSuperAdmin } from './useIsSuperAdmin';
import useApprovalStepsInfo from './useApprovalStepsInfo';
import { useState } from 'react';
import { useCurrentUser } from './useCurrentUser';

const useApprovalMeta = props => {
  const {
    approvalType,
    approvalAction,
    approvableObjectId,
    assetName,
    approvalData,
    approvalConfig,
    userSelector
  } = props;
  const [showModal, setShowModal] = useState(false);
  const currentUser = useCurrentUser(userSelector);
  const isSuperAdmin = useIsSuperAdmin(currentUser);
  const currentUserType = useCurrentUserType(approvalData.data, currentUser);
  const {
    stepCount,
    approvedCount,
    pendingCount,
    stepStatusForCurrentUser
  } = useApprovalStepsInfo(approvalData.data ? approvalData.data.approvalSteps : [], currentUser.user_id);
  return {
    approvableObjectId,
    approvalAction,
    approvalType,
    assetName,
    isLoading: approvalData.isLoading || approvalConfig.isLoading,
    // TODO remove approvalStatus from meta. Should only be accessed through data object
    approvalStatus: approvalData.data ? approvalData.data.approvalStatus : undefined,
    currentUser,
    currentUserType,
    isSuperAdmin,
    stepCount,
    approvedCount,
    pendingCount,
    stepStatusForCurrentUser,
    showModal,
    setShowModal
  };
};

export default useApprovalMeta;