module.exports = {
  "pageLoad": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": [
        "site-page-editor",
        "landing-page-editor",
        "blog-listing-page-editor"
      ],
      "subscreen": "string",
      "themePath": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "publishOnConnectedCustomDomainActivation": {
    "name": "publish-on-connected-custom-domain",
    "class": "activation",
    "properties": {
      "skip_publish_redirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source": {
        "type": "string",
        "isOptional": true
      },
      "is_drag_and_drop_page": {
        "type": "string",
        "isOptional": true
      },
      "is_successful": {
        "type": "string",
        "isOptional": true
      },
      "publish_type": {
        "type": "string",
        "isOptional": true
      },
      "is_scheduling_update": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source_value": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "publishOnConnectedCustomDomainUsage": {
    "name": "publish-on-connected-custom-domain",
    "class": "usage",
    "properties": {
      "skip_publish_redirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source": {
        "type": "string",
        "isOptional": true
      },
      "is_drag_and_drop_page": {
        "type": "string",
        "isOptional": true
      },
      "is_successful": {
        "type": "string",
        "isOptional": true
      },
      "publish_type": {
        "type": "string",
        "isOptional": true
      },
      "is_scheduling_update": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source_value": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "cosModuleInteraction": {
    "name": "cos module interaction",
    "class": "interaction",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "cosModuleActivation": {
    "name": "cos module interaction",
    "class": "activation",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "cosModuleUsage": {
    "name": "cos module interaction",
    "class": "usage",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "partnerMatchingApp": {
    "name": "Partner Matching",
    "class": "interaction",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "publishActivation": {
    "name": "publish",
    "class": "activation",
    "properties": {
      "skip_publish_redirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source": {
        "type": "string",
        "isOptional": true
      },
      "is_drag_and_drop_page": {
        "type": "string",
        "isOptional": true
      },
      "is_successful": {
        "type": "string",
        "isOptional": true
      },
      "publish_type": {
        "type": "string",
        "isOptional": true
      },
      "is_scheduling_update": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source_value": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "publishUsage": {
    "name": "publish",
    "class": "usage",
    "properties": {
      "skip_publish_redirect": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source": {
        "type": "string",
        "isOptional": true
      },
      "is_drag_and_drop_page": {
        "type": "string",
        "isOptional": true
      },
      "is_successful": {
        "type": "string",
        "isOptional": true
      },
      "publish_type": {
        "type": "string",
        "isOptional": true
      },
      "is_scheduling_update": {
        "type": "string",
        "isOptional": true
      },
      "dynamic_pages_source_value": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "page-publishing"
  },
  "scheduleUpdateActivation": {
    "name": "schedule update",
    "class": "activation",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "scheduleUpdateUsage": {
    "name": "schedule update",
    "class": "usage",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "cancelPublish": {
    "name": "cancel scheduled publish",
    "class": "usage",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "cancelUpdate": {
    "name": "cancel scheduled update",
    "class": "usage",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "scheduleUpdateOnboardingAcknowledged": {
    "name": "scheduled update onboarding acknowledged",
    "class": "interaction",
    "namespace": "page-publishing",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "editorViewInteraction": {
    "name": "editor interaction",
    "class": "view",
    "properties": {
      "subscreen": "string"
    },
    "namespace": "content-editor-ui"
  },
  "loadError": {
    "name": "load error",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string"
      },
      "error": {
        "type": "string"
      }
    },
    "namespace": "content-editor-ui"
  },
  "editorInteraction": {
    "name": "editor interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "event": {
        "type": "string"
      }
    },
    "namespace": "content-editor-ui"
  },
  "audienceAccessInteraction": {
    "name": "audience access interaction",
    "class": "interaction",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": [
          "click-radio-all",
          "click-radio-password",
          "click-radio-sso",
          "click-radio-specificContacts",
          "click-radio-ssoWithSpecificContacts",
          "show-password-help",
          "show-sso-help",
          "show-specificContacts-help",
          "show-ssoWithSpecificContacts-help",
          "change-lists",
          "click-kb-article",
          "show-sso-disabled-tooltip",
          "show-registration-disabled-tooltip"
        ]
      }
    },
    "namespace": "content-components"
  },
  "marketingContactModalInteraction": {
    "name": "marketing contact modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view",
          "confirm"
        ]
      },
      "source": {
        "type": "string"
      }
    },
    "namespace": "content-components"
  },
  "partnerMatchingAppInteraction": {
    "name": "partner matching app interaction",
    "namespace": "partner-matching-app",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "app": {
        "type": "string"
      }
    }
  },
  "colorImportInteraction": {
    "name": "color import modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "submit-url",
          "website-colors-retrieved",
          "toggle-color-selection",
          "import-colors"
        ]
      }
    },
    "namespace": "content-components"
  },
  "componentInteraction": {
    "name": "component interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "app": "string"
    },
    "namespace": "editor-platform"
  },
  "tinymceEdit": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCommand": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "value": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymcePaste": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "type": [
        "html",
        "plaintext",
        "unknown"
      ],
      "pasteSource": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEvent": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditSourceCode": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceChangeColor": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceColorPopoverMode": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "mode": {
        "type": "string"
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceChangeColorPopover": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "mode": {
        "type": "string",
        "isOptional": true
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "format": {
        "type": "string",
        "isOptional": true
      },
      "had-existing-color": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCta": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "context": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceFormatting": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "isCustomFont": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceLink": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "nofollow": {
        "type": "boolean",
        "isOptional": true
      },
      "sponsored": {
        "type": "boolean",
        "isOptional": true
      },
      "ugc": {
        "type": "boolean",
        "isOptional": true
      },
      "externalLink": {
        "type": "boolean",
        "isOptional": true
      },
      "isImage": {
        "type": "boolean",
        "isOptional": true
      },
      "isIcon": {
        "type": "boolean",
        "isOptional": true
      },
      "linkType": {
        "type": "string",
        "isOptional": true
      },
      "isValidLink": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceImage": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceContentGeneration": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditImage": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEmbed": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertEmbed": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "service": {
        "type": "string",
        "isOptional": true
      },
      "tab": [
        "general",
        "embed",
        "paste"
      ],
      "hasDimensions": {
        "type": "boolean",
        "isOptional": true
      },
      "useIframeEmbeds": {
        "type": "boolean",
        "isOptional": true
      },
      "hasScripts": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditEmbed": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymcePersonalize": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "category": "string",
      "fqn": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertPersonalizationToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "tokenName": "string",
      "tokenCategory": {
        "type": "string",
        "isOptional": true
      },
      "isHubspotDefined": {
        "type": "boolean"
      },
      "defaultValueType": [
        "global",
        "local",
        "empty"
      ],
      "fieldType": {
        "type": "string"
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditPersonalizationToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "tokenName": "string",
      "tokenCategory": {
        "type": "string",
        "isOptional": true
      },
      "defaultValueType": [
        "global",
        "local",
        "empty"
      ],
      "fieldType": {
        "type": "string"
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertRssToken": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "tokenName": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "type": "string",
      "method": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "type": "string",
      "replaced": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditVideo": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertEmoji": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "insertCount": {
        "type": "number",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEmoji": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertSnippet": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceSnippet": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertTable": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "columns": {
        "type": "number",
        "isOptional": true
      },
      "rows": {
        "type": "number",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceOpenTable": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "type": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditTable": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "command": {
        "type": "string",
        "isOptional": true
      },
      "editType": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceKeyboardEscape": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "format": "string",
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceAnchor": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "insertLocation": {
        "type": "string",
        "isOptional": true
      },
      "dragStage": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceVisualBlocks": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "active": "boolean",
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertCallout": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "type": {
        "type": "string"
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditCallout": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "editType": {
        "type": "string"
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCharmap": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceFindReplace": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceToolbar": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "startWidth": {
        "type": "number",
        "isOptional": true
      },
      "endWidth": {
        "type": "number",
        "isOptional": true
      },
      "didResize": [
        "larger",
        "smaller",
        "no"
      ],
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertGoogleDoc": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "useUrlInput": {
        "type": "boolean",
        "isOptional": true
      },
      "isPostBody": {
        "type": "boolean",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceAddNewGoogleAccount": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceCancelGoogleDoc": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "uiSource": {
        "type": "string",
        "isOptional": true
      },
      "errorMessage": {
        "type": "string",
        "isOptional": true
      },
      "useUrlInput": {
        "type": "boolean",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceEditIcon": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "version": {
        "type": "string",
        "isOptional": true
      },
      "aspectChanged": {
        "type": "string",
        "isOptional": true
      },
      "newValue": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "tinymceInsertIcon": {
    "name": "Edit",
    "class": "interaction",
    "properties": {
      "action": "string",
      "inline": [
        "yes",
        "no"
      ],
      "setName": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "version": {
        "type": "string",
        "isOptional": true
      },
      "inApp": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "rich-text"
  },
  "smartRulesSelectInteraction": {
    "name": "Smart Rule Selected",
    "class": "interaction",
    "version": "v2",
    "properties": {
      "page_type": {
        "type": "string",
        "isOptional": true
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "smartType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "smart-content"
  },
  "smartContentCriterionInteraction": {
    "name": "Criterion Interaction",
    "class": "interaction",
    "version": "v2",
    "properties": {
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "is_read_only_user": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "target_type": {
        "type": "string",
        "isOptional": true
      },
      "is_drag_drop": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "app": {
        "isOptional": true,
        "type": "string"
      },
      "smartType": {
        "isOptional": true,
        "type": "string"
      }
    },
    "namespace": "smart-content"
  },
  "smartContentDefinitionActivation": {
    "name": "activation",
    "class": "activation",
    "properties": {
      "action": "string",
      "app": {
        "isOptional": true,
        "type": "string"
      },
      "smartType": {
        "isOptional": true,
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "smart-content"
  },
  "smartContentDefinitionInteraction": {
    "name": "Definition Interaction",
    "class": "interaction",
    "version": "v2",
    "properties": {
      "wordpress_plugin": {
        "type": "string",
        "isOptional": true
      },
      "is_read_only_user": {
        "type": "string",
        "isOptional": true
      },
      "page_type": {
        "type": "string",
        "isOptional": true
      },
      "type": {
        "type": "string",
        "isOptional": true
      },
      "content_type": {
        "type": "string",
        "isOptional": true
      },
      "target_type": {
        "type": "string",
        "isOptional": true
      },
      "is_drag_drop": {
        "type": "string",
        "isOptional": true
      },
      "name": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "app": {
        "isOptional": true,
        "type": "string"
      },
      "smartType": {
        "isOptional": true,
        "type": "string"
      }
    },
    "namespace": "smart-content"
  },
  "fileManagerLib": {
    "name": "File Manager Lib",
    "class": "usage",
    "properties": {
      "componentName": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "pickerType": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerSetPanelExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "properties": {
      "panel": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerExploreFiles": {
    "name": "Explore Files",
    "class": "interaction",
    "properties": {
      "fileType": {
        "isOptional": true,
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ]
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerSearchEfficiency": {
    "name": "Search efficiency",
    "class": "interaction",
    "properties": {
      "selectedObjectName": {
        "type": "string",
        "isOptional": true
      },
      "searchQueryWithoutExtension": {
        "type": "string",
        "isOptional": true
      },
      "extension": {
        "isOptional": true,
        "type": "string"
      },
      "objectType": {
        "isOptional": true,
        "type": [
          "file",
          "folder"
        ]
      },
      "searchType": {
        "isOptional": true,
        "type": [
          "contains",
          "equals",
          "startsWith",
          "endsWith",
          "other"
        ]
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerImageOptimization": {
    "name": "Change image optimization setting",
    "class": "interaction",
    "properties": {
      "setting": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFiles": {
    "name": "Manage Files",
    "class": "interaction",
    "properties": {
      "action": "string",
      "filetype": {
        "isOptional": true,
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ]
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerManageFolders": {
    "name": "Manage Folders",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "fileManagerBrowseShutterstock": {
    "name": "Browse Shutterstock",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "fileManagerAcceptVideoTOS": {
    "name": "Accept Video TOS",
    "class": "interaction",
    "properties": {
      "action": "boolean",
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerAlert": {
    "name": "Alert interaction",
    "class": "interaction",
    "namespace": "file-picker",
    "version": "v2",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "fileManagerBulkImport": {
    "name": "Bulk Import Images",
    "class": "interaction",
    "properties": {
      "action": "string",
      "imageCount": {
        "type": "number",
        "isOptional": true
      },
      "isImageFile": {
        "type": "boolean",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerLibErrorDisplay": {
    "name": "Error display",
    "class": "view",
    "properties": {
      "type": "string",
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerVideoMigrationAlert": {
    "name": "Video two",
    "class": "interaction",
    "properties": {
      "isUserSuperAdmin": {
        "type": "boolean",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      },
      "withPanelNavigator": {
        "type": "boolean",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "drawerType": {
        "type": "string",
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-picker",
    "version": "v2"
  },
  "fileManagerVideoOptIn": {
    "name": "video optin",
    "class": "interaction",
    "properties": {
      "hasOptedBefore": {
        "type": "boolean",
        "isOptional": true
      },
      "optInStatus": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoInteraction": {
    "name": "video interaction",
    "class": "interaction",
    "namespace": "file-manager",
    "version": "v2",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "fileManagerUpload": {
    "name": "upload file",
    "class": "interaction",
    "properties": {
      "size": {
        "type": "number",
        "isOptional": true
      },
      "duration": {
        "type": "number",
        "isOptional": true
      },
      "videoOptInStatus": {
        "type": "string",
        "isOptional": true
      },
      "videoDuration": {
        "type": "number",
        "isOptional": true
      },
      "videoHostingProvider": {
        "type": [
          "mux"
        ],
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  },
  "fileManagerVideoPQL": {
    "name": "Video PQL",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": false
      },
      "alert": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "number",
        "isOptional": true
      },
      "quantityRemaining": {
        "type": "number",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "fileType": {
        "type": [
          "IMG",
          "MOVIE",
          "DOCUMENT",
          "AUDIO",
          "OTHER"
        ],
        "isOptional": true
      },
      "fileExtension": {
        "type": "string",
        "isOptional": true
      },
      "target": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "file-manager",
    "version": "v2"
  }
};