'use es6';

import Loadable from 'UIComponents/decorators/Loadable';
const AsyncOnboardingTour = Loadable({
  loader: () => import(
  /* webpackChunkName: "onboarding-tour" */
  'onboarding-tours/components/OnboardingTour').then(mod => mod.default),
  LoadingComponent: () => null,
  ErrorComponent: () => null
});
export default AsyncOnboardingTour;