import http from 'hub-http/clients/apiClient';
const BASE_URL = 'product-approvals/v1';
export default {
  fetchProductApprovalsConfig: ({
    approvalType,
    approvalAction
  }) => http.get(`${BASE_URL}/approval-configurations/configuration/${approvalType}/${approvalAction}`),
  fetchApprovers: () => http.get(`${BASE_URL}/approval-configurations/approvers`),
  fetchLatestApproval: query => http.get(`${BASE_URL}/approvals/latest`, {
    query
  }),
  createApproval: data => http.post(`${BASE_URL}/approvals`, {
    data
  }),
  approveApproval: (approval, note) => http.put(`${BASE_URL}/approvals/${approval.id}/approve`, {
    data: {
      note
    }
  }),
  cancelApproval: (approval, note) => http.put(`${BASE_URL}/approvals/${approval.id}/cancel`, {
    data: {
      note
    }
  })
};