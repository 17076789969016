'use es6'; // Helper to retain plain object "immutablility" while mapping over all values

export const mapOverObject = (object, func) => {
  let result;
  Object.keys(object).forEach(key => {
    const newValue = func(object[key], key);

    if (newValue !== undefined) {
      if (result === undefined) {
        // On first changed value, clone the original object to return as result
        result = Object.assign({}, object);
      }

      result[key] = newValue;
    }
  });

  if (result) {
    return result;
  } // If there never were any changes return the original object


  return object;
};
export const filterObjectValues = (object, func) => {
  const result = {};
  Object.keys(object).forEach(key => {
    const value = object[key];

    if (func(value, key) === true) {
      result[key] = value;
    }
  });
  return result;
};
export const invertObjectKeysAndValues = obj => {
  const invertedObject = {};

  for (const key of Object.keys(obj)) {
    const value = obj[key];
    invertedObject[value] = key;
  }

  return invertedObject;
};
export const isEmptyObject = obj => {
  return obj != null && Object.keys(obj).length === 0 && obj.constructor === Object;
}; // Flattens arrays (deeply) nested inside objects. Note, does not flatten nested arrays

export const deepFlattenArraysInObject = (val, result = []) => {
  if (val == null) {
    return result;
  } else if (Array.isArray(val)) {
    return result.concat(val);
  } else if (typeof val === 'object') {
    for (const key of Object.keys(val)) {
      result = deepFlattenArraysInObject(val[key], result);
    }
  } else {
    result = result.concat([val]);
  }

  return result;
};