import Raven from 'Raven';
import { userInfoSync } from 'hub-http/userInfo';
const gatesResultsMap = {};
export function getIsUngated(gate, userInfo) {
  if (!(gate in gatesResultsMap)) {
    try {
      const {
        gates
      } = userInfo || userInfoSync();
      gatesResultsMap[gate] = gates.includes(gate);
    } catch (e) {
      // Should only fire during unit tests (as userInfo not initialized)
      Raven.captureException(e, {
        fingerprint: ['failed to read gates from userInfoSync']
      });
    }
  }

  return !!gatesResultsMap[gate];
}
export const EXPORTED_FOR_TESTING = {
  gatesResultsMap
};