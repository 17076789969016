'use es6';

import http from 'hub-http/clients/apiClient';
import { createGenericFetchAction } from 'ContentData/helpers/reduxHelpers';
import * as crmObjectsClient from 'ContentData/api/CrmObjects';
import { getCrmObjectCache, getCrmObjectSchema } from 'ContentData/selectors/crmObjects';
import { CRM_OBJECTS_CLEAR_CACHE, CRM_OBJECTS_FETCH_OBJECT_SCHEMA, CRM_OBJECTS_FETCH_OBJECT_TYPES, CRM_OBJECTS_FETCH_OBJECT, CRM_OBJECTS_FETCH_OBJECTS, CRM_OBJECTS_SAVE_OBJECT_CACHE, CRM_OBJECTS_SAVE_PROPERTY_CACHE } from 'ContentData/actions/ActionTypes';
import { getLabellingProperties } from 'ContentUtils/helpers/CrmObjectsHelpers';
export const fetchCrmObjectAction = createGenericFetchAction(CRM_OBJECTS_FETCH_OBJECT, crmObjectsClient.fetchCrmObject);
export const fetchCrmObjectsAction = createGenericFetchAction(CRM_OBJECTS_FETCH_OBJECTS, crmObjectsClient.fetchCrmObjects);
const fetchCrmObjectTypesAction = createGenericFetchAction(CRM_OBJECTS_FETCH_OBJECT_TYPES, crmObjectsClient.fetchCrmObjectTypes);
export function getQueryProperties(crmObjectSchema, displayProperties) {
  return [...getLabellingProperties(crmObjectSchema, displayProperties), 'hs_object_id'];
}
export function fetchCrmObject(objectType, displayProperties, id) {
  return (dispatch, getState) => {
    const crmObjectSchema = getCrmObjectSchema(getState(), {
      objectType
    }) || {};
    const query = {
      properties: getQueryProperties(crmObjectSchema, displayProperties)
    };
    return dispatch(fetchCrmObjectAction({
      objectType,
      objectTypeId: crmObjectSchema.objectTypeId,
      id,
      query
    }));
  };
}
export function fetchCrmObjects(objectType, displayProperties, query) {
  return (dispatch, getState) => {
    const crmObjectSchema = getCrmObjectSchema(getState(), {
      objectType
    }) || {};
    query.sorts = [{
      property: 'hs_object_id',
      order: 'ASC'
    }];
    query.requestOptions = {
      properties: getQueryProperties(crmObjectSchema, displayProperties)
    };
    return dispatch(fetchCrmObjectsAction({
      objectType,
      objectTypeId: crmObjectSchema.objectTypeId,
      query
    }));
  };
}
export const fetchCrmObjectSchema = objectType => {
  const {
    request,
    receive,
    error
  } = fetchCrmObjectSchema;
  return (dispatch, getState, {
    httpClient = http
  } = {}) => {
    dispatch(request(objectType));
    return crmObjectsClient.fetchCrmObjectSchema(objectType, httpClient).then(response => {
      dispatch(receive(objectType, response));
      return response;
    }).catch(__error => {
      // if simple name contains dash or underscore and request fails, append p_ and try again for CRM API support
      const dashOrUnderscoreRegex = /-|_/;

      if (dashOrUnderscoreRegex.test(objectType)) {
        return crmObjectsClient.fetchCrmObjectSchema(`p_${objectType}`, httpClient).then(response => {
          dispatch(receive(objectType, response));
          return response;
        }).catch(__secondError => {
          dispatch(error(objectType, __secondError));
          return __secondError;
        });
      }

      dispatch(error(objectType, __error));
      return __error;
    });
  };
};

fetchCrmObjectSchema.request = options => {
  return {
    type: CRM_OBJECTS_FETCH_OBJECT_SCHEMA,
    options
  };
};

fetchCrmObjectSchema.receive = (options, response) => {
  return {
    type: CRM_OBJECTS_FETCH_OBJECT_SCHEMA,
    response,
    options
  };
};

fetchCrmObjectSchema.error = (options, error) => {
  return {
    type: CRM_OBJECTS_FETCH_OBJECT_SCHEMA,
    error,
    options
  };
};

export const fetchCrmObjectTypes = () => fetchCrmObjectTypesAction();
export function saveCache(objectType, id) {
  return (dispatch, getState) => {
    const crmObject = getCrmObjectCache(getState(), {
      id,
      objectType
    });
    dispatch({
      type: CRM_OBJECTS_SAVE_OBJECT_CACHE,
      crmObject,
      objectType
    });
  };
}
export function clearCache(objectType) {
  return {
    type: CRM_OBJECTS_CLEAR_CACHE,
    objectType
  };
}
export function savePropertyCache(objectType, options, property) {
  return dispatch => {
    dispatch({
      type: CRM_OBJECTS_SAVE_PROPERTY_CACHE,
      property,
      objectType,
      options
    });
  };
}