'use es6';

import useMount from 'ContentEditorUI/hooks/useMount';
import { removeBaymaxSkeleton } from 'ContentEditorUI/lib/layoutDom';

const CleanupBaymaxSkeleton = () => {
  useMount(removeBaymaxSkeleton);
  return null;
};

export default CleanupBaymaxSkeleton;