export const getStepId = stepIndex => {
  return `step ${stepIndex}`;
};

const transformTourSteps = tourSteps => {
  return tourSteps.map((step, index) => {
    return Object.assign({}, step, {
      id: getStepId(index + 1)
    });
  });
};

export const transformTour = tourSteps => {
  return transformTourSteps(tourSteps);
};