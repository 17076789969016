'use es6';

import Categories from 'ContentUtils/js/constants/Categories'; // Content model helper functions

export const contentIsPublished = content => {
  return content.get('isPublished');
};
const CONTENT_AB_STATES = ['DRAFT_AB', 'DRAFT_AB_VARIANT', 'SCHEDULED_AB', 'PUBLISHED_AB', 'PUBLISHED_AB_VARIANT', 'LOSER_AB_VARIANT'];
export const contentIsAb = content => {
  return CONTENT_AB_STATES.includes(content.get('state'));
};
export const getCategory = (category, subcategory) => {
  return typeof subcategory === 'string' && subcategory.toLowerCase() === 'site_page' ? Categories['site-page'] : Categories[category];
};