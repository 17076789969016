'use es6';

import { createSelector } from 'reselect';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import { getFullCategory } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { STATE_KEYS } from 'ContentEditorUI/lib/publishing/constants';
import I18n from 'I18n';
import { createUnmemoizedSelector } from 'ContentEditorUI/redux/selectors/unmemoizedSelectorCreator';
import { getIsFormValid } from 'ContentEditorUI/redux/selectors/formStatusSelectors';
import { getIsSaveInProgress, getIsSavePermanentlyDisabled } from 'ContentEditorUI/redux/selectors/saveDataSelectors';
import { getHasAnyModulesWithErrors } from 'ContentEditorUI/redux/selectors/moduleErrorSelectors';
import * as SimpleDate from 'UIComponents/core/SimpleDate';
import { getContentState, getSubcategory, getPublishDate, getShouldPublishImmediately } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { isScheduledState, isPublishedState } from 'ContentEditorUI/utils/publishUtils';
import { getIsUngatedForPatagonia } from './authSelectors';
export const getIsPublished = createSelector([getContentState], state => isPublishedState(state));
export const getIsScheduled = createSelector([getContentState], state => isScheduledState(state));
export const getIsDraft = createSelector([getContentState], state => StateStatusGroups.DRAFT.includes(state));
export const getIsAutomated = createSelector([getContentState], state => state === 'AUTOMATED' || state.startsWith('AUTOMATED_'));
export const getPublishDateDay = createSelector([getPublishDate], publishDate => {
  return publishDate ? SimpleDate.fromMoment(I18n.moment(publishDate).portalTz()) : null;
});
export const getPublishDateTime = createSelector([getPublishDate], publishDate => {
  const portalTz = I18n.moment(publishDate).portalTz();
  return publishDate ? portalTz.hours() * 60 + portalTz.minutes() : null;
});
export const getIsAfterScheduledPublishDate = createUnmemoizedSelector([getPublishDate], publishDate => publishDate < I18n.moment.portalTz());
export const getPublishType = createSelector([getIsPublished, getShouldPublishImmediately, getIsAfterScheduledPublishDate], (isPublished, shouldPublishImmediately, isAfterScheduledPublishDate) => {
  if (isPublished && (isAfterScheduledPublishDate || shouldPublishImmediately)) {
    return 'update';
  } else if (shouldPublishImmediately) {
    return 'publish';
  }

  return 'schedule';
});
export const getPatagoniaPublishType = createSelector([getIsPublished, getIsUngatedForPatagonia], (isPublished, isUngatedForPatagonia) => {
  if (!isUngatedForPatagonia) return null;

  if (isPublished) {
    return 'update';
  }

  return 'publish';
});
export const getIsPublishingNotUpdating = createSelector([getIsPublished, getShouldPublishImmediately], (isPublished, shouldPublishImmediately) => !isPublished && shouldPublishImmediately);
export const getIsSchedulingNotUpdating = createSelector([getIsPublished, getShouldPublishImmediately, getIsScheduled], (isPublished, shouldPublishImmediately, isScheduled) => !isPublished && !shouldPublishImmediately && !isScheduled);
export const getIsSchedulingAlreadyPublished = createSelector([getIsPublished, getIsDraft, getShouldPublishImmediately], (isPublished, isDraft, shouldPublishImmediately) => {
  return !shouldPublishImmediately && !isDraft && isPublished;
});
export const getPublishStateKey = createSelector([getIsPublished, getIsScheduled], (isPublished, isScheduled) => {
  if (isPublished) {
    return STATE_KEYS.PUBLISHED;
  } else if (isScheduled) {
    return STATE_KEYS.SCHEDULED;
  }

  return STATE_KEYS.DRAFT;
});
export const getPublishNoun = createSelector([getFullCategory, getSubcategory], (fullCategory, subcategory) => {
  let categoryKey = fullCategory.field_name.toLowerCase();

  if (['site_page', 'optin-email'].includes(subcategory)) {
    categoryKey = subcategory;
  }

  return `categories.${categoryKey}`;
}); // AND with app-specific conditions and permission/scope checks

export const getIsPublishEnabledBase = createSelector([getIsFormValid, getIsSaveInProgress, getIsSavePermanentlyDisabled, getHasAnyModulesWithErrors], (isFormValid, isSaveInProgress, isSavePermanentlyDisabled, hasAnyModulesWithErrors) => Boolean(isFormValid && !isSaveInProgress && !isSavePermanentlyDisabled && !hasAnyModulesWithErrors));
export const getUnpublishWarningLangKey = createSelector([getIsDraft], isDraft => {
  return isDraft ? STATE_KEYS.DRAFT : STATE_KEYS.PUBLISHED;
});