'use es6';

import { createSelector } from 'reselect';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { getStateProp } from 'ContentData/utils/stateUtils';
export const getUsers = state => getStateProp(state, 'resources').users.users;
export const getOptions = createSelector([getUsers], users => {
  return Object.values(users).map(user => Object.assign({
    value: user.email,
    text: user.email,
    canNotify: user.canNotify
  }, !user.canNotify && {
    tagUse: 'error'
  }), []);
});
export const getHasFetchedUsers = state => getStateProp(state, 'resources').users.userFetchStatus === RequestStatus.SUCCEEDED;
export const getIsLoading = state => getStateProp(state, 'resources').users.userFetchStatus === RequestStatus.PENDING;
export const getUsersForTeams = state => getStateProp(state, 'resources').users.usersForTeams.data;
export const getUsersForTeamsRequestStatus = state => getStateProp(state, 'resources').users.usersForTeamsFetchStatus;
export const getUsersForTeamsRequestStatusIsLoading = state => getUsersForTeamsRequestStatus(state) === RequestStatus.PENDING;
export const getUsersForTeamsRequestDone = state => getUsersForTeamsRequestStatus(state) === RequestStatus.SUCCEEDED || getUsersForTeamsRequestStatus(state) === RequestStatus.FAILED;