'use es6';

import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import { CLEAR_UPDATED_THEME_SETTINGS, FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_THEME_SUCCEEDED, UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES, FETCH_CUSTOM_FONTS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { getDataVariableKey } from 'theme-previewer-lib/utils/themeSettings';

const themeReducer = (state = ImmutableMap(), {
  type,
  response,
  payload
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          theme_path: themePath,
          parent_theme_path: parentThemePath,
          has_theme_settings: hasThemeSettings
        } = response;
        let newState = state.merge({
          hasTheme: !!themePath,
          hasThemeSettings: !!hasThemeSettings
        });

        if (themePath) {
          newState = newState.setIn(['theme', 'path'], themePath);
        }

        if (parentThemePath) {
          newState = newState.setIn(['theme', 'parentThemePath'], parentThemePath);
        }

        return newState;
      }

    case FETCH_THEME_SUCCEEDED:
      {
        const {
          theme,
          themeSettings
        } = response; // Selectively merging in results from the `theme/by-path` endpoint, as the shape
        // of the response is different than that of `combined-edit-data`, and because we don't
        // have a need for a majority of the data returned from this endpoint.

        const valuesToMerge = {
          name: theme.name,
          fields: theme.fields,
          messages: theme.messages
        };
        state = state.mergeIn(['theme'], valuesToMerge);
        return state.merge({
          themeSettings,
          overriddenThemeSettings: []
        });
      }

    case FETCH_CUSTOM_FONTS_SUCCEEDED:
      {
        const customFonts = response;
        state = state.setIn(['customFonts'], customFonts);
        return state;
      }

    case UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES:
      {
        if (!(payload && payload.fieldKey)) {
          return state.set('overriddenThemeSettings', ImmutableList());
        }

        const currentOverrides = state.get('overriddenThemeSettings') || ImmutableList();
        return state.set('overriddenThemeSettings', currentOverrides.push(getDataVariableKey(payload.fieldKey)));
      }

    case CLEAR_UPDATED_THEME_SETTINGS:
      {
        return state.set('overriddenThemeSettings', ImmutableList());
      }

    default:
      return state;
  }
};

export default themeReducer;