'use es6';

import SEO_RECOMMENDATIONS from 'ContentEditorUI/lib/xray/seoRecommendations';
import { SETTING_REC_TYPE_TO_FIELD_MAP, RECOMMENDATION_TARGET_TYPES } from 'ContentEditorUI/lib/xray/constants'; // editor implementation of how to find modules that match optimizations

export const getTargetsForOptimization = (modules, optType, predicateMap) => {
  const targetType = SEO_RECOMMENDATIONS[optType].targetType;

  switch (targetType) {
    case RECOMMENDATION_TARGET_TYPES.MODULE:
      {
        const predicate = predicateMap[optType];
        const moduleValues = modules.values();
        const matches = [];

        for (const module of moduleValues) {
          if (predicate(module)) {
            matches.push(module);
          }
        }

        return matches;
      }

    case RECOMMENDATION_TARGET_TYPES.SETTING:
      {
        return SETTING_REC_TYPE_TO_FIELD_MAP[optType];
      }

    default:
      {
        throw new Error(`Optimization type ${optType} not recognized`);
      }
  }
};