'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_EDITING_INFO, CONTENT_SAVE_REQUEST_SUCCEEDED, CONTENT_SAVE_REQUEST_FAILED, CONTENT_PUBLISH_REQUEST_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';

const getLastModified = xhr => {
  const last_edit_ticks = xhr.getResponseHeader('x-last-modified-timestamp');
  const lastModifiedDate = last_edit_ticks ? new Date(parseInt(last_edit_ticks, 10)) : new Date();
  return {
    last_edit_ticks,
    lastModifiedDate
  };
};

const editingInfoReducer = (state = {}, payload) => {
  if (!payload) {
    return state;
  }

  const {
    type
  } = payload;

  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          response
        } = payload; // Pull out the last author ID, as the fallback until the current user makes a save

        if (response.content.updatedById) {
          state = Object.assign({}, state, {
            updatedById: response.content.updatedById
          });
        } // Copied out of ContentModulesUI's form.coffee (parse method)


        if (response.context.editing_info) {
          const updatedInfo = response.context.editing_info;
          updatedInfo.hasSaved = false;

          if (updatedInfo.last_edit_ticks) {
            updatedInfo.lastModifiedDate = new Date(updatedInfo.last_edit_ticks);
          } else {
            updatedInfo.lastModifiedDate = new Date();
          }

          updatedInfo.lastModified = updatedInfo.lastModifiedDate.toUTCString();
          return Object.assign({}, state, {}, updatedInfo);
        }

        return state;
      }

    case UPDATE_EDITING_INFO:
      {
        const {
          updatedInfo
        } = payload;
        return Object.assign({}, state, {}, updatedInfo);
      }

    case CONTENT_SAVE_REQUEST_SUCCEEDED:
      {
        const {
          payload: {
            opts: {
              isAutosave,
              buffer
            },
            response: {
              xhr,
              data: {
                updatedById
              }
            }
          }
        } = payload;
        const {
          last_edit_ticks,
          lastModifiedDate
        } = getLastModified(xhr);
        return Object.assign({}, state, {
          has_buffered_changes: buffer,
          hasSaveError: false,
          hasSaved: true,
          last_edit_ticks,
          lastModifiedDate,
          lastModified: lastModifiedDate.toUTCString(),
          lastSaveType: isAutosave ? 'autosave' : 'save',
          updatedById
        });
      }

    case CONTENT_SAVE_REQUEST_FAILED:
      {
        const {
          error: {
            response: {
              xhr
            }
          }
        } = payload;
        const {
          last_edit_ticks,
          lastModifiedDate
        } = getLastModified(xhr);
        return Object.assign({}, state, {
          hasSaveError: true,
          hasSaved: false,
          last_edit_ticks,
          lastModifiedDate,
          lastModified: lastModifiedDate.toUTCString()
        });
      }

    case CONTENT_PUBLISH_REQUEST_SUCCEEDED:
      return Object.assign({}, state, {
        has_buffered_changes: false
      });

    default:
      {
        return state;
      }
  }
};

export default editingInfoReducer;