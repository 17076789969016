import { populateTourWithCopy } from './tourCopy';
import isTourValid from './isTourValid';
import SentryManager from '../manager/SentryManager';

// Generates a lazy-loadable chunk for each tour config file
const TOUR_CONFIGS = require.context('../tours', true, /^.*.ts$/, 'lazy');

export default function getTour(tourId) {
  return new Promise((resolve, reject) => {
    if (!isTourValid(tourId)) {
      reject(new Error(`tourId not valid: ${tourId}`));
      return;
    } // Lazy load tour config file chunk


    resolve(TOUR_CONFIGS(`./${tourId}.ts`));
  }).then(maybeGetTourConfig => {
    const getTourConfig = maybeGetTourConfig.default || maybeGetTourConfig; // Call default export of tour config file, function can be async

    return getTourConfig();
  }).then(maybeTour => {
    const tourConfig = maybeTour.default || maybeTour;
    const tourWithCopy = populateTourWithCopy(tourConfig);
    return tourWithCopy;
  }).catch(error => {
    const [sentryError] = SentryManager.reportLoadTourError(error, tourId);
    throw sentryError;
  });
}