'use es6';

export const TWENTY_FOUR_HOURS = 86400000; // Note, we're sharing these namespaces between user attributes and superstore for convenience
//   - "user" settings apply to user ID no matter the portal (e.g. settings shared between portals)
//   - "per content" settings apply to a specific piece of content (e.g. specific page, blog, or email)

export const USER_SETTINGS_STORE_NAMESPACE = 'ContentEditorUI.userSettings';
export const PER_CONTENT_SETTINGS_STORE_NAMESPACE = 'ContentEditorUI.perContentSettings';
export const PER_PORTAL_SETTINGS_STORE_NAMESPACE = 'ContentEditorUI.perPortalSettings';
export const createUserAttributeKey = key => `${USER_SETTINGS_STORE_NAMESPACE}.${key}`; // -------------------------------------------------------------------------------------------------
// Specific setting keys for components/selectors to rely on. Please add a comment to each for context
// An object of user state to keep track of popups, tooltips, tours, etc that have been shown to users
// Every key should have date value

export const USER_SEEN_STATE_SETTINGS_OBJECT_KEY = createUserAttributeKey('seenStateObj'); // Child keys of ^^^ object (again, all are date values)

export const HAS_SEEN_FREE_LP_ONBOARDING_TOUR_KEY = 'sawFreeLPOnboardingTour';
export const HAS_SEEN_SAVEABLE_SECTIONS_TUTORIAL = 'sawSaveableSectionsTutorial';
export const HAS_SEEN_PAGE_EXPIRY_MOVED_ALERT = 'sawPageExpiryMovedAlert'; // An enum to keep track of the last preview device the user was looking at, so the preview opens to the same one
// value should be one of ['desktop', 'tablet', 'mobile']

export const USER_LAST_PREVIEW_DEVICE = createUserAttributeKey('lastPreviewDevice'); // A boolean that signals when the free landing pages onboarding tour should begin

export const SHOULD_START_FREE_LP_ONBOARDING_TOUR = createUserAttributeKey('shouldStartFreeLPOnboardingTour'); // A boolean to track whether a user has checked "Don't show this message again" on the tooltip
// that appears when styling content on a breakpoint

export const USER_BREAKPOINT_STYLING_TOOLTIP_DISMISSED = createUserAttributeKey('breakpointStylingTooltipDismissed'); // An array of all of the user settings keys we expect to be stored inside user attributes and superstore

export const ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE = [USER_SEEN_STATE_SETTINGS_OBJECT_KEY, SHOULD_START_FREE_LP_ONBOARDING_TOUR]; // An array of the user settings keys we expect to be in superstore, regardless if they're in user attributes

export const ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE = ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE.concat([USER_LAST_PREVIEW_DEVICE, USER_BREAKPOINT_STYLING_TOOLTIP_DISMISSED]); // A temporary example of something per editor where we should embed the editor name in the key

export const SOME_TEMPORARY_EXAMPLE_OF_PAGE_SETTING_KEY = createUserAttributeKey('pages.someSetting'); // -------------------------------------------------------------------------------------------------
// Per content constants

export const MODULE_STYLES_SETTINGS_KEY = 'moduleStyleSettings';
export const MODULE_STYLE_SETTINGS_MAX_SIZE = 50;
export const LAYOUT_STYLE_SETTINGS_KEY = 'layoutStyleSettings';
export const LAYOUT_STYLE_SETTINGS_MAX_SIZE = 50;
export const HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY = 'sawScopesHaveChangedModal';
export const IS_DISTRACTION_FREE_OPEN = 'isDistractionFreeOpen'; // An array of all of the per content settings keys we store in Superstore

export const ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS = [MODULE_STYLES_SETTINGS_KEY, LAYOUT_STYLE_SETTINGS_KEY, HAS_SEEN_SCOPES_HAVE_CHANGED_MODAL_KEY, IS_DISTRACTION_FREE_OPEN];
export const COMBINED_MODULE_DEFINITION_API_KEY = 'combinedModuleDefinitionApiKey';