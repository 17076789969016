'use es6';

import { Map as ImmutableMap } from 'immutable';
import { OPEN_ADD_SECTION_PANEL, CLOSE_ADD_SECTION_PANEL, CUSTOM_LAYOUT_SECTION_ADDED, OPEN_CREATE_NEW_SECTION_PANEL, CLOSE_CREATE_NEW_SECTION_PANEL, SAVE_SECTION_REQUEST, SAVE_SECTION_SUCCESS, SAVE_SECTION_FAILURE, REDIRECT_TO_DELETE_SECTIONS, FETCH_GENERATED_LAYOUT_HUBL_STARTED, SET_ADD_SECTION_MISSING_MODULE_ERRORS, CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS, UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO } from 'ContentEditorUI/redux/actions/actionTypes';

const sectionsReducer = (state = ImmutableMap({
  showAddSectionPanel: false,
  currentLayoutSectionToAddTo: null,
  currentCreateNewSectionInfo: null,
  showCreateNewSectionPanel: false,
  saveNewSectionError: null,
  currentCopyFragmentAsHublInfo: null,
  addSectionMissingModuleSchemaErrors: 0
}), action) => {
  switch (action.type) {
    case OPEN_ADD_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        return state.merge({
          currentLayoutSectionToAddTo: {
            layoutSectionId,
            rowIdToInsertAround: rowId,
            isEmptyLayoutSection,
            targetBeforeOrAfter
          },
          showAddSectionPanel: true
        });
      }

    case CLOSE_ADD_SECTION_PANEL:
      {
        return state.merge({
          showAddSectionPanel: false,
          currentLayoutSectionToAddTo: null
        });
      }

    case CUSTOM_LAYOUT_SECTION_ADDED:
      {
        return state.merge({
          showAddSectionPanel: false,
          currentLayoutSectionToAddTo: null,
          addSectionMissingModuleSchemaErrors: 0
        });
      }

    case UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO:
      {
        const {
          layoutSectionId,
          rowId,
          isEmptyLayoutSection,
          targetBeforeOrAfter
        } = action.payload;
        return state.merge({
          currentLayoutSectionToAddTo: {
            layoutSectionId,
            rowIdToInsertAround: rowId,
            isEmptyLayoutSection,
            targetBeforeOrAfter
          }
        });
      }

    case OPEN_CREATE_NEW_SECTION_PANEL:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        return state.merge({
          showCreateNewSectionPanel: true,
          currentCreateNewSectionInfo: {
            layoutSectionId,
            sectionName
          }
        });
      }

    case SAVE_SECTION_REQUEST:
      return state.merge({
        saveNewSectionError: null
      });

    case SAVE_SECTION_SUCCESS:
    case CLOSE_CREATE_NEW_SECTION_PANEL:
      {
        return state.merge({
          showCreateNewSectionPanel: false,
          currentCreateNewSectionInfo: null
        });
      }

    case SAVE_SECTION_FAILURE:
      return state.merge({
        saveNewSectionError: action.payload
      });

    case REDIRECT_TO_DELETE_SECTIONS:
      return state.merge({
        showCreateNewSectionPanel: false,
        currentCreateNewSectionInfo: null,
        showAddSectionPanel: true
      });

    case FETCH_GENERATED_LAYOUT_HUBL_STARTED:
      {
        const {
          layoutSectionId,
          sectionName
        } = action.payload;
        return state.merge({
          currentCopyFragmentAsHublInfo: {
            layoutSectionId,
            sectionName
          }
        });
      }

    case SET_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        const missingModuleSchemaCount = action.payload.moduleSchemaErrorCount;
        return state.merge({
          addSectionMissingModuleSchemaErrors: missingModuleSchemaCount
        });
      }

    case CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS:
      {
        return state.merge({
          addSectionMissingModuleSchemaErrors: 0
        });
      }

    default:
      return state;
  }
};

export default sectionsReducer;