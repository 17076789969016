'use es6';

import http from 'hub-http/clients/apiClient';

let _cachedPrices;

export const PriceApi = {
  fetchPrice(apiNames, currencyCode) {
    if (_cachedPrices) {
      return _cachedPrices;
    }

    _cachedPrices = http.post('eligibility-engine/v3/price', {
      data: apiNames,
      query: {
        currencyCode
      }
    });
    return _cachedPrices;
  }

};