'use es6';

import { Children } from 'react';
export const isChildActive = (child, value) => {
  const childProps = child && child.props ? child.props : {};

  if (childProps.active === true) {
    return true;
  }

  if (value != null && childProps.value != null) {
    return value === childProps.value;
  }

  return false;
};
export const containsActiveChild = (parent, value) => {
  const children = parent && parent.props && parent.props.children;
  if (children == null) return false;
  let result = false;
  Children.forEach(children, child => {
    if (isChildActive(child, value)) {
      result = true;
    } else if (containsActiveChild(child, value)) {
      result = true;
    }
  });
  return result;
};