'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_CONTENT_MODEL_USE_FEATURED_IMAGE, UPDATE_CONTENT_MODEL_FEATURED_IMAGE, CLEAR_CONTENT_MODEL_FEATURED_IMAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import { Map as ImmutableMap } from 'immutable';

const featuredImageReducer = (state = ImmutableMap(), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        return state.merge({
          featuredImage: content.featuredImage,
          featuredImageAltText: content.featuredImageAltText,
          useFeaturedImage: content.useFeaturedImage
        });
      }

    case UPDATE_CONTENT_MODEL_USE_FEATURED_IMAGE:
      return state.set('useFeaturedImage', payload);

    case UPDATE_CONTENT_MODEL_FEATURED_IMAGE:
      return state.merge({
        featuredImage: payload.featuredImage,
        featuredImageAltText: payload.featuredImageAltText
      });

    case CLEAR_CONTENT_MODEL_FEATURED_IMAGE:
      return state.merge({
        featuredImage: '',
        featuredImageAltText: ''
      });

    default:
      return state;
  }
};

export default featuredImageReducer;