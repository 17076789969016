'use es6';

import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
/**
 * GET hubdb/api/v2/tables/{id}
 */

export function fetchHubDbTable(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`hubdb/api/v2/tables/${id}`);
}
/**
 * GET hubdb/api/v2/tables
 */

export function fetchHubDbTables(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('hubdb/api/v2/tables', {
    query
  });
}
/**
 * GET hubdb/api/v2/tables/{tableId}/rows/{rowId}
 */

export function fetchHubDbRow({
  tableId,
  rowId
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`hubdb/api/v2/tables/${tableId}/rows/${rowId}`);
}
/**
 * GET hubdb/api/v2/tables/{tableId}/rows
 */

export function fetchHubDbRows({
  tableId,
  query
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`hubdb/api/v2/tables/${tableId}/rows`, {
    query
  });
}
/**
 * GET hubdb/api/v2/tables/{tableId}/rows/search
 */

export function searchHubDbRows({
  tableId,
  query
}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`hubdb/api/v2/tables/${tableId}/rows/search`, {
    query
  });
}