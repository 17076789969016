import { useEffect, useRef, useState } from 'react';
import { getAttachedElement, getScrollParent, isScrollableOnXAxis, isScrollableOnYAxis } from '../utils/elementUtils';
import { getPulserPaths } from '../utils/pulserUtils';
export const usePulserContainer = target => {
  const [pulserContainer, setPulserContainer] = useState();
  const popoverId = target && target.getAttribute('data-popover-id');
  useEffect(() => {
    if (!target || !popoverId) {
      return undefined;
    } // Insert pulser dom before tour popover for making popover be above pulser overlay


    const popoverElement = document.getElementById(popoverId);
    const pulserElement = document.createElement('div');
    pulserElement.id = `pulser-for-${popoverId}`;

    if (popoverElement && popoverElement.parentNode) {
      popoverElement.parentNode.insertBefore(pulserElement, popoverElement);
    }

    setPulserContainer(pulserElement);
    return () => {
      if (pulserElement && pulserElement.parentNode) {
        pulserElement.parentNode.removeChild(pulserElement);
      }
    };
  }, [target, popoverId]);
  return pulserContainer;
};
export const usePulserSVGPaths = (attachTo, pulserPathProps) => {
  const pulserRef = useRef({
    rafId: undefined
  });
  const pathsRef = useRef({
    start: {},
    end: {}
  });
  const [pulserPaths, setPulserPaths] = useState({
    start: {},
    end: {}
  });
  useEffect(() => {
    if (!attachTo) {
      return undefined;
    }

    const target = getAttachedElement(attachTo);

    if (!target) {
      return undefined;
    }

    const ref = pulserRef.current;
    const scrollParents = {
      x: getScrollParent(target, isScrollableOnXAxis),
      y: getScrollParent(target, isScrollableOnYAxis)
    }; // Setup recursive function to call requestAnimationFrame to update the modal opening position

    const updatePulserPaths = () => {
      const newPaths = getPulserPaths(target, scrollParents, pulserPathProps);

      if (pathsRef.current.start.overlay !== newPaths.start.overlay || pathsRef.current.end.overlay !== newPaths.end.overlay) {
        pathsRef.current = newPaths;
        setPulserPaths(newPaths);
      }

      ref.timerId = setTimeout(() => {
        ref.rafId = requestAnimationFrame(updatePulserPaths);
      }, 100);
    };

    updatePulserPaths();
    return () => {
      if (ref.timerId) {
        clearTimeout(ref.timerId);
      }

      if (ref.rafId) {
        cancelAnimationFrame(ref.rafId);
      }
    };
  }, [attachTo, pulserPathProps]);
  return pulserPaths;
};