'use es6';

import { combineReducers } from 'redux';
import { CUSTOM_FONTS_FETCH_FONT, CUSTOM_FONTS_FETCH_FONTS, GOOGLE_FONTS_FETCH_FONT, GOOGLE_FONTS_FETCH_FONTS, GOOGLE_FONTS_FETCH_COMMON_FONTS, GOOGLE_FONTS_CLEAR, SELECT_CUSTOM_FONT, SELECT_GOOGLE_FONT } from 'ContentData/actions/ActionTypes';

const fonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case GOOGLE_FONTS_CLEAR:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

const selectedFonts = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case SELECT_GOOGLE_FONT:
      {
        const {
          font
        } = action;
        return Object.assign({}, state, {
          [font.family]: font
        });
      }

    default:
      return state;
  }
};

const commonFonts = (state = [], action) => {
  switch (action.type) {
    case GOOGLE_FONTS_FETCH_COMMON_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.results)) return state;
        return response.results;
      }

    default:
      return state;
  }
};

const customFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONTS:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    default:
      return state;
  }
};

const selectedCustomFonts = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_FONTS_FETCH_FONT:
      {
        const {
          response
        } = action;
        if (!(response && response.length)) return state;
        return response.reduce((hash, font) => {
          hash[font.family] = font;
          return hash;
        }, Object.assign({}, state));
      }

    case SELECT_CUSTOM_FONT:
      {
        const {
          font
        } = action;
        return Object.assign({}, state, {
          [font.family]: font
        });
      }

    default:
      return state;
  }
};

export default combineReducers({
  fonts,
  selectedCustomFonts,
  selectedFonts,
  commonFonts,
  customFonts
});