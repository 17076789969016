'use es6';

import http from 'hub-http/clients/apiClient';
import { getModules } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getPredicateMap } from 'ContentEditorUI/redux/selectors/optimizationSelectors';
import { getTargetsForOptimization } from 'ContentEditorUI/components/xray/xrayUtils';
const OPTIMIZATIONS_V2_BASE = 'optimizations/v2/pages';
export const getFilteredOptimizationsAndTargets = (resp, state) => {
  const modules = getModules(state);
  const predicateMap = getPredicateMap(state);
  const filteredOpts = resp.filter(group => group.optimizationType === 'ADD_IMAGE_ALT_TEXT' || group.optimizationType === 'ADD_TITLE');
  const optToTargetMap = {};
  filteredOpts.forEach(opt => {
    const moduleList = getTargetsForOptimization(modules, opt.optimizationType, predicateMap);
    optToTargetMap[opt.optimizationType] = moduleList;
  });
  return [filteredOpts, optToTargetMap];
};
export const getOptimizations = contentId => {
  return http.get(`${OPTIMIZATIONS_V2_BASE}/${contentId}/recommendations/buffer`);
};