'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FORCE_SHOW_END_TEST_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';

const abTestReducer = (state = ImmutableMap(), {
  type,
  response,
  value
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return state.merge(Object.assign({
        abTestId: response.content.abTestId,
        abStatus: response.content.abStatus,
        abVariation: response.content.abVariation
      }, response.abtest));

    case FORCE_SHOW_END_TEST_MODAL:
      return state.merge({
        forceEndAb: value
      });

    default:
      return state;
  }
};

export default abTestReducer;