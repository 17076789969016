import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';

let _cachedGet;

export const getEligibility = () => {
  if (_cachedGet) {
    return _cachedGet;
  }

  _cachedGet = http.get(`${getFullUrl('app-api')}/hsfx/v1/eligibility`);
  return _cachedGet;
};