'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import { FETCH_USER_ATTRIBUTES_SUCCEEDED, UPDATE_USER_ATTRIBUTE, UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE } from 'ContentEditorUI/redux/actions/actionTypes';

const userAttributesReducer = (state = ImmutableMap(), {
  type,
  attributes,
  key,
  value,
  stringifiedValue
}) => {
  switch (type) {
    case FETCH_USER_ATTRIBUTES_SUCCEEDED:
      return fromJS(attributes);

    case UPDATE_USER_ATTRIBUTE:
      return state.set(key, value);

    case UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE:
      return state.set(key, stringifiedValue);

    default:
      return state;
  }
};

export default userAttributesReducer;