import invariant from 'react-utils/invariant';
import memoizeOne from 'react-utils/memoizeOne';
import performanceNow from './vendor/performanceNow';
export default memoizeOne(history => {
  invariant(!!history.getCurrentLocation, 'Must supply a `history@3` object');
  const {
    pathname: initialPathname
  } = history.getCurrentLocation();
  return {
    pathname: initialPathname,
    listen: cb => history.listen(({
      pathname
    }) => {
      const timestamp = performanceNow();
      cb({
        timestamp,
        pathname
      });
    })
  };
});