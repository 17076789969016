import { ApprovalOperation } from '../types/ApprovalOperation';
export const maxNumberOfApprovers = 10;
export const getModalActionLangKey = approvalOperation => {
  switch (approvalOperation) {
    case ApprovalOperation.REQUEST:
      return 'productApprovals.modal.action.REQUEST';

    case ApprovalOperation.APPROVE:
      return 'productApprovals.modal.action.APPROVE';

    case ApprovalOperation.RECALL:
      return 'productApprovals.modal.action.RECALL';

    default:
      return null;
  }
};
export const shouldPrimaryButtonBeDisabled = (approvalOperation, error, note, approversCount) => {
  if (error !== null) {
    return true;
  }

  switch (approvalOperation) {
    case ApprovalOperation.REQUEST:
      return !approversCount || approversCount > maxNumberOfApprovers;

    case ApprovalOperation.RECALL:
      return !note;

    case ApprovalOperation.APPROVE:
    default:
      return false;
  }
};