/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import { FETCH_PAGE_CSS_SUCCESS, FETCH_PAGE_CSS_FAILED, FETCH_PAGE_CSS_REQUEST, OPTIMISTICALLY_ADD_CSS_ASSET, UPDATE_EDITOR_RENDERED_CSS_ASSET } from 'ContentEditorUI/redux/actions/actionTypes';
import { fetchPageCss } from 'ContentEditorUI/api/ContentEditApi';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
import http from 'hub-http/clients/apiClient';

const fetchPageCssSucceeded = response => ({
  type: FETCH_PAGE_CSS_SUCCESS,
  payload: response
});

const fetchPageCssFailed = error => ({
  type: FETCH_PAGE_CSS_FAILED,
  error
});

export const fetchPageCssAssets = () => (dispatch, getState) => {
  const state = getState();
  const contentId = getContentModelId(state);
  dispatch({
    type: FETCH_PAGE_CSS_REQUEST
  });

  const success = resp => dispatch(fetchPageCssSucceeded(resp));

  const error = resp => dispatch(fetchPageCssFailed(resp));

  if (EditorConfigSingleton.getIsOnScalableEditor()) {
    const generateRequestOptions = EditorConfigSingleton.getIn(['features', 'cssAssets', 'getFetchCssRequestOptions']);
    const {
      url,
      query,
      shouldClearCssInstead
    } = generateRequestOptions(state);

    if (shouldClearCssInstead) {
      // Useful when the preview is outside the context of a single page, like "group only"
      // mode in GCE where we don't have a content id
      dispatch(fetchPageCssSucceeded([]));
    } else {
      http.get(url, {
        query
      }).then(success, error).catch(err => {
        setTimeout(() => {
          throw err;
        });
      });
    }
  } else {
    fetchPageCss(contentId).then(success, error).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  }
};
export const optimisticallyAddCssAsset = assetsToAdd => ({
  type: OPTIMISTICALLY_ADD_CSS_ASSET,
  payload: assetsToAdd
});
export const updateEditorRenderedCssAsset = renderedCssString => ({
  type: UPDATE_EDITOR_RENDERED_CSS_ASSET,
  payload: renderedCssString
});