'use es6';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestFailedOrUninitialized, requestCompleted, requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
import { getTemplatePath } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getTemplateInfoRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { fetchTemplateInfo } from 'ContentEditorUI/redux/actions/templateInfoActions';
import { getIsUngatedForPatagonia } from 'ContentEditorUI/redux/selectors/authSelectors';
import { openModal, togglePublishOptionsModalOpen } from 'ContentEditorUI/redux/actions/appActions';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { updatePatagoniaSettingsModalActiveNavItem } from 'ContentEditorUI/redux/actions/baseSettingsActions';
import { getCurrentTabId } from 'ContentEditorUI/redux/selectors/routingSelectors';
export function useTemplateInfoRequestState() {
  const dispatch = useDispatch();
  const templatePath = useSelector(getTemplatePath);
  const templateRequest = useSelector(getTemplateInfoRequest);
  useEffect(() => {
    if (requestFailedOrUninitialized(templateRequest)) {
      dispatch(fetchTemplateInfo(templatePath));
    }
  }, [templateRequest, templatePath, dispatch]);
  return {
    templateInfoRequestCompleted: requestCompleted(templateRequest),
    templateInfoRequestSucceeded: requestSucceeded(templateRequest)
  };
}
export function useOpenPatagoniaSettingsModalIfNecessary(location, optimizationTypesArray) {
  const {
    optimizationType
  } = location.query;
  const isUngatedForPatagonia = useSelector(getIsUngatedForPatagonia);
  const [optimizeItemIsDeepLinked] = useState(isUngatedForPatagonia && optimizationType && optimizationTypesArray.some(optimizationTypes => Boolean(optimizationTypes[optimizationType])));
  const currentTabId = useSelector(getCurrentTabId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (optimizeItemIsDeepLinked) {
      // For now, every optimization deep link opens to "general"
      // If this changes in the future, we'll likely need the consumer to pass in a lookup map
      dispatch(updatePatagoniaSettingsModalActiveNavItem('general'));
      dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
    }
  }, [optimizeItemIsDeepLinked, dispatch]);
  useEffect(() => {
    if (isUngatedForPatagonia && currentTabId === 'settings') {
      dispatch(updatePatagoniaSettingsModalActiveNavItem('general'));
      dispatch(openModal(MODAL_TYPES.SETTINGS_MODAL));
    }
  }, [currentTabId, dispatch, isUngatedForPatagonia]);
}
export function useOpenPatagoniaPublishingModalIfNecessary() {
  const isUngatedForPatagonia = useSelector(getIsUngatedForPatagonia);
  const currentTabId = useSelector(getCurrentTabId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isUngatedForPatagonia && currentTabId === 'publishing') {
      dispatch(togglePublishOptionsModalOpen(true));
    }
  }, [currentTabId, dispatch, isUngatedForPatagonia]);
}