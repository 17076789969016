export const getStorageItem = key => {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    return null;
  }
};
export const setStorageItem = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {// ignore error
  }
};