'use es6';

import http from 'hub-http/clients/apiClient';
import { getCategory } from 'ContentComponents/utils/contentModelUtils';
import { PAGES_API_BASE_URL } from 'page-editor-ui/Constants';

function getBasePath(categoryId, subcategory) {
  const slug = getCategory(categoryId, subcategory).api_slug;
  return `${PAGES_API_BASE_URL}/${slug}`;
}

function addMABVariants(categoryId, subcategory, experimentId, variantNames) {
  return http.post(`${getBasePath(categoryId, subcategory)}/mab-add-variants/${experimentId}`, {
    data: variantNames
  });
}

function deleteVariants(categoryId, subcategory, variantIds) {
  return http.delete(`${getBasePath(categoryId, subcategory)}/bulk`, {
    query: {
      id: variantIds
    }
  });
}

function deleteExperiment(categoryId, subcategory, experimentId) {
  return http.delete(`${getBasePath(categoryId, subcategory)}/mab-delete-experiment/${experimentId}`);
}

function createMABTest(categoryId, subcategory, contentId, data) {
  return http.post(`${getBasePath(categoryId, subcategory)}/${contentId}/mab-create-experiment`, {
    data
  });
}

function getMABTest(categoryId, subcategory, experimentId) {
  return http.get(`${getBasePath(categoryId, subcategory)}/mab-get-experiment/${experimentId}`);
}

function renameVariant(categoryId, subcategory, variant) {
  return http.put(`${getBasePath(categoryId, subcategory)}/${variant.get('id')}`, {
    data: {
      name: variant.get('name')
    }
  });
}

function startMABTest(categoryId, subcategory, experimentId) {
  return http.post(`${getBasePath(categoryId, subcategory)}/mab-start-experiment/${experimentId}`);
}

export default {
  addMABVariants,
  deleteExperiment,
  deleteVariants,
  createMABTest,
  getMABTest,
  renameVariant,
  startMABTest
};