import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const tracker = createTracker({
  events
});

function getApp() {
  return window.location.pathname.split('/')[1];
}

export const trackInteraction = (interaction, approvalType) => tracker.track('productApprovalsInteraction', {
  app: getApp(),
  interaction,
  type: approvalType
});