'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { isEmpty } from 'underscore';
import { createSelector } from 'reselect';
import { createGenericOptionsSelector, tryParseNumber } from 'ContentData/helpers/reduxHelpers';
import { getColumnsByName, getRowDisplayFormat } from 'ContentUtils/helpers/HubDbHelpers';
import { ColumnTypes } from 'ContentUtils/constants/HubDbColumnTypes';
export const getHubDbTables = state => state.resources.hubdb.tables.byId;
export const getHubDbTable = (state, props) => isNaN(props.id) ? state.resources.hubdb.tables.byName[props.id] : state.resources.hubdb.tables.byId[props.id];
export const getHubDbTableCache = (state, props) => state.resources.hubdb.tablesCache[props.id];

const getHubDbTablesCache = state => state.resources.hubdb.tablesCache;

export const getHubDbTableFetchStatus = (state, props) => state.resources.hubdb.tableRequestStatus[props.id];

const disabledPredicate = table => table && table.publishedAt === 0;

const getTableDisplayText = table => table && (table.label || table.name) || undefined;

export const getIsUnpublished = (state, props) => disabledPredicate(getHubDbTable(state, props));
export const getHubDbTableOptions = createGenericOptionsSelector(getHubDbTablesCache, {
  disabledPredicate,
  getDisplayText: getTableDisplayText
});
export const getDefaultHubDbTableOptions = createGenericOptionsSelector(getHubDbTables, {
  disabledPredicate,
  getDisplayText: getTableDisplayText
});

const getRowDisplayTextArray = (row, columnsByName, displayColumns, displayFormat) => {
  if (!(row && row.values)) return '';
  let hasNoContent = true;
  const columnDelimeter = /(%\d+)/;
  const numberOfDisplayColumns = displayColumns && displayColumns.length;
  const optionArray = getRowDisplayFormat(displayFormat, numberOfDisplayColumns).split(columnDelimeter);
  const columns = numberOfDisplayColumns ? displayColumns : [Object.keys(columnsByName)[0]];
  const optionLabelArray = optionArray.reduce((labelDataArray, frag) => {
    let str = frag ? {
      text: frag
    } : null;

    if (columnDelimeter.test(frag)) {
      const column = columnsByName[columns[frag.substring(1)]]; // substring removes percent symbol

      let content;

      if (column) {
        if (column.id) {
          content = row.values[column.id];
        } else if (column.type === ColumnTypes.URL) {
          content = row.path;
        } else {
          content = row.name;
        }
      }

      str = content !== undefined // content can be the number 0
      ? {
        columnType: column.type,
        text: content
      } : null;
      if (str !== null) hasNoContent = false;
    }

    if (str !== null) labelDataArray.push(str);
    return labelDataArray;
  }, []);
  return hasNoContent ? [] : optionLabelArray;
};

const getHubDbRow = (state, props) => {
  const savedRowsTable = state.resources.hubdb.rows[props.tableId];
  if (!isEmpty(savedRowsTable)) return savedRowsTable[props.rowId];
  return {};
};

export const getHubDbRows = (state, props) => {
  const table_id = 'id';
  return Object.assign({}, state.resources.hubdb.rows[props[table_id]], {
    displayColumns: props.displayColumns,
    displayFormat: props.displayFormat
  }) || {};
};

const getHubDbRowsCache = (state, props) => {
  const table_id = 'id';
  return Object.assign({}, state.resources.hubdb.rowsCache[props[table_id]], {
    displayColumns: props.displayColumns,
    displayFormat: props.displayFormat
  }) || {};
};

export const getHubDbCacheRequestStatus = state => state.resources.hubdb.hubDbCacheRequestStatus;
export const getCachedHubDbRow = (state, props) => {
  const cachedRowsTable = state.resources.hubdb.rowsCache[props.tableId];
  if (!isEmpty(cachedRowsTable)) return cachedRowsTable[props.rowId];
  return {};
};
export const getHubDbRowFetchStatus = (state, props) => state.resources.hubdb.rowRequestStatus[props.id];

const makeGetRowDataByColumnName = (tableSelector, rowSelector) => createSelector([tableSelector, rowSelector], (table, rowData) => {
  const columnsByName = table ? getColumnsByName(table) : {};
  return Object.assign({}, rowData, {
    columnsByName
  });
});

const createRowOptionsSelector = (resourceSelector, options = {}) => {
  const {
    getDisplayTextArray,
    getHelpText
  } = options;
  return createSelector([resourceSelector], resourcesObj => {
    const {
      columnsByName,
      displayColumns,
      displayFormat
    } = resourcesObj,
          resources = _objectWithoutPropertiesLoose(resourcesObj, ["columnsByName", "displayColumns", "displayFormat"]);

    return Object.keys(resources).map(resourceId => {
      const resource = resources[resourceId];
      return {
        value: tryParseNumber(resourceId),
        textArray: getDisplayTextArray ? getDisplayTextArray(resource, columnsByName, displayColumns, displayFormat) : resource.name,
        help: getHelpText && getHelpText(resource)
      };
    });
  });
};

export const getHubDbRowOptions = createRowOptionsSelector(makeGetRowDataByColumnName(getHubDbTable, getHubDbRowsCache), {
  getDisplayTextArray: getRowDisplayTextArray
});
export const getDefaultHubDbRowOptions = createRowOptionsSelector(makeGetRowDataByColumnName(getHubDbTable, getHubDbRows), {
  getDisplayTextArray: getRowDisplayTextArray
});
export const getHubDbRowLabel = (state, props) => {
  const hubDbTable = getHubDbTable(state, props);
  const rowData = makeGetRowDataByColumnName(() => hubDbTable, getHubDbRow)(state, Object.assign({
    tableId: hubDbTable && hubDbTable.id
  }, props));

  const {
    columnsByName
  } = rowData,
        rowProperties = _objectWithoutPropertiesLoose(rowData, ["columnsByName"]);

  const labelArray = getRowDisplayTextArray(rowProperties, columnsByName, props.displayColumns, props.displayFormat);
  return labelArray && labelArray.length ? labelArray : rowProperties.id && rowProperties.id.toString();
};