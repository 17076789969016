'use es6';

import { UPDATE_CONTENT_MODEL_DOMAIN, UPDATE_CONTENT_MODEL_CAMPAIGN, UPDATE_CONTENT_MODEL_EXPIRY_DATE, UPDATE_CONTENT_MODEL_EXPIRY_ENABLED, UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL, UPDATE_CONTENT_MODEL_META_DESCRIPTION, UPDATE_CONTENT_MODEL_HTML_TITLE, UPDATE_CONTENT_MODEL_NAME, UPDATE_CONTENT_MODEL_SLUG, UPDATE_CONTENT_MODEL_CANONICAL_URL, UPDATE_PAGE_CONTENT_MODEL_CSS, UPDATE_CONTENT_MODEL_TEMPLATE_PATH } from 'ContentEditorUI/redux/actions/actionTypes'; // UPDATES ------------------------------->

export const updateCampaign = campaign => dispatch => {
  dispatch({
    type: UPDATE_CONTENT_MODEL_CAMPAIGN,
    payload: campaign
  });
};
export const updateDomain = (domain, bodyOverrideByDomain, language, slugRaw) => {
  return {
    type: UPDATE_CONTENT_MODEL_DOMAIN,
    payload: {
      domain,
      bodyOverrideByDomain,
      language,
      slugRaw
    }
  };
};
export const updateExpiryDate = unixTimestamp => {
  return {
    type: UPDATE_CONTENT_MODEL_EXPIRY_DATE,
    payload: unixTimestamp
  };
};
export const updateExpiryEnabled = enabled => {
  return {
    type: UPDATE_CONTENT_MODEL_EXPIRY_ENABLED,
    payload: enabled
  };
};
export const updateExpiryRedirectUrl = (url, pages) => {
  return {
    type: UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL,
    payload: {
      pages,
      url
    }
  };
};
export const updateMetaDescription = metaDesc => {
  return {
    type: UPDATE_CONTENT_MODEL_META_DESCRIPTION,
    payload: metaDesc
  };
};
export const updateHtmlTitle = htmlTitle => {
  return {
    type: UPDATE_CONTENT_MODEL_HTML_TITLE,
    payload: htmlTitle
  };
};
export const updateName = name => {
  return {
    type: UPDATE_CONTENT_MODEL_NAME,
    payload: name
  };
};
export const updateSlug = slug => {
  return {
    type: UPDATE_CONTENT_MODEL_SLUG,
    payload: slug
  };
};
export const updateCanonicalUrl = url => {
  return {
    type: UPDATE_CONTENT_MODEL_CANONICAL_URL,
    payload: url
  };
};
export const updateCss = value => {
  return {
    type: UPDATE_PAGE_CONTENT_MODEL_CSS,
    payload: value
  };
};
export const updateTemplatePath = value => {
  return {
    type: UPDATE_CONTENT_MODEL_TEMPLATE_PATH,
    payload: value
  };
};