'use es6';

import http from 'hub-http/clients/apiClient';
import { getUserAttributesArray } from '../constants/userAttributes';
const BASE_URL = 'users/v1/app/attributes';
export function fetchUserAttributes(objectType, objectId) {
  return http.get(BASE_URL, {
    query: {
      keys: getUserAttributesArray(objectType, objectId)
    }
  });
}
export function setUserAttribute(attribute, value) {
  return http.post(BASE_URL, {
    data: {
      key: attribute,
      value
    }
  });
}