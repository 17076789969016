'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';

const contentSchemaReducer = (state = {}, {
  type,
  response
}) => {
  const moduleMissingErrors = [];

  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      if (response.schema.template_errors) {
        response.schema.template_errors.forEach(err => {
          if (err.category === 'CUSTOM_WIDGET_NOT_FOUND') {
            moduleMissingErrors.push(err.categoryErrors.widget_name);
          }

          if (err.category === 'MODULE_NOT_FOUND') {
            moduleMissingErrors.push(err.categoryErrors.module_id);
          }
        });
      }

      response.schema.module_missing_errors = moduleMissingErrors;
      return response;

    default:
      return state;
  }
};

export default contentSchemaReducer;