'use es6';

import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getTheme = basicSelector(state => state.theme || Immutable.Map({}));
export const getHasTheme = createSelector([getTheme], theme => theme.get('hasTheme') || false);
export const getThemeObject = createSelector([getTheme], theme => theme.get('theme') || Immutable.Map({}));
export const getThemeSettings = createSelector([getTheme], theme => theme.get('themeSettings') || Immutable.Map({}));
export const getHasThemeSettings = createSelector([getTheme, getHasTheme], (theme, hasTheme) => hasTheme && theme.get('hasThemeSettings'));
export const getOverriddenThemeSettings = createSelector([getTheme], theme => theme.get('overriddenThemeSettings') || Immutable.List());
export const getThemePath = createSelector([getThemeObject], theme => theme.get('path'));
export const getParentThemePath = createSelector([getThemeObject], theme => theme.get('parentThemePath'));
export const getThemeFormSpec = createSelector([getThemeObject], theme => {
  return Immutable.Map({
    fields: theme.get('fields') || [],
    messages: theme.get('messages') || {}
  });
});
export const getThemeName = createSelector([getThemeObject], theme => theme.get('name'));
export const getThemeGlobalSettings = createSelector([getThemeSettings], themeSettings => {
  return themeSettings.get('values') || Immutable.Map({});
});
export const getThemesCustomFontsList = createSelector([getTheme], theme => theme.get('customFonts'));