import { DISPLAY_COLUMN_EXCLUSIONS } from 'ContentUtils/constants/HubDbColumnTypes';
import { ColumnTypes } from 'ContentUtils/constants/HubDbColumnTypes';
export const getRowDisplayFormat = (displayFormat, numberOfDisplayColumns) => {
  if (displayFormat) return displayFormat;
  let defaultDisplayFormat = '%0';

  for (let i = 1; i <= (numberOfDisplayColumns - 1 || 0); i++) {
    defaultDisplayFormat = `${defaultDisplayFormat} %${i}`;
  }

  return defaultDisplayFormat;
};
export const getColumnsByName = table => {
  const builtInColumns = table.useForPages ? {
    hs_name: {
      id: null,
      type: ColumnTypes.TEXT
    },
    hs_path: {
      id: null,
      type: ColumnTypes.URL
    }
  } : {};
  return table.columns.reduce((acc, col) => {
    const {
      id,
      name,
      type
    } = col;

    if (!DISPLAY_COLUMN_EXCLUSIONS[type]) {
      acc[name] = {
        id,
        type
      };
    }

    return acc;
  }, builtInColumns);
};