'use es6';

import { FETCH_CONTENT_SCHEMA_SUCCEEDED, FETCH_PAGE_CSS_SUCCESS, OPTIMISTICALLY_ADD_CSS_ASSET, UPDATE_EDITOR_RENDERED_CSS_ASSET, MODULE_REDUCER_CHANGE_UNDO, MODULE_REDUCER_CHANGE_REDO, MOVED_MODULE, ADDED_MODULE, ADDED_MODULE_TO_POST_BODY, LAYOUT_SECTION_MODULE_DELETED, LAYOUT_SECTION_COLUMN_DELETED, LAYOUT_SECTION_ROW_DELETED, LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW, LAYOUT_SECTION_COLUMN_INSERTED_AFTER, LAYOUT_SECTION_COLUMN_INSERTED_BEFORE, LAYOUT_SECTION_ROW_INSERTED_AFTER, LAYOUT_SECTION_ROW_INSERTED_BEFORE, LAYOUT_SECTION_ROW_CLONED_BELOW, LAYOUT_SECTION_CELL_CLONED_TO_RIGHT, LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN, LAYOUT_SECTION_ROW_APPENDED, LAYOUT_SECTION_ROW_PREPENDED, UPDATED_CONTAINERS_FROM_PREVIEW, UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES } from 'ContentEditorUI/redux/actions/actionTypes';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
import { FEATURE_FLAGS } from 'ContentEditorUI/redux/constants';
import { isEqual } from 'underscore';
import { CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS, convertAssetHtmlAsStringToAssetJSON, assetsAreEqual } from 'ContentEditorUI/utils/contentAssetUtils';

const contentAssetReducer = (state = {
  cssAssets: [],
  shouldFetchNewCssAssetsOnSave: false
}, {
  type,
  response,
  payload
}) => {
  switch (type) {
    // Initial combined-edit-data response seeding this reducer
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          css_assets
        } = response;
        return Object.assign({}, state, {
          cssAssets: css_assets
        });
      }

    case FETCH_PAGE_CSS_SUCCESS:
      {
        const newCssAssets = payload;
        const existingCssAssets = state.cssAssets;
        state = Object.assign({}, state, {
          shouldFetchNewCssAssetsOnSave: false
        });

        if (!isEqual(newCssAssets, existingCssAssets)) {
          return Object.assign({}, state, {
            cssAssets: payload
          });
        } else {
          return state;
        }
      }

    case OPTIMISTICALLY_ADD_CSS_ASSET:
      {
        const newCssAssetsToAddAsHtml = payload;
        const existingCssAssets = state.cssAssets;

        if (!EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.cssAssets)) {
          return state;
        }

        const newAssetsToAddAsJSON = []; // Even with downstream mitigations to prevent flashing, it can still happen
        // Do some simple checking here to see if we can prevent updating the css
        // assets array and skip rendering all together. The most common use this is
        // preventing is mofo updates hitting render-with-meta, which returns some css
        // assets along with the html, and instead of always adding it here, check to see
        // if it already exists (which is probably does if your just updating a mofo of
        // an existing module, as opposed to adding a new module)

        newCssAssetsToAddAsHtml.forEach(newAsset => {
          const newAssetAsJSON = convertAssetHtmlAsStringToAssetJSON(newAsset);
          const assetAlreadyExists = existingCssAssets.some(existingAsset => assetsAreEqual(existingAsset, newAssetAsJSON));

          if (!assetAlreadyExists) {
            newAssetsToAddAsJSON.push(newAssetAsJSON);
          }
        });

        if (newAssetsToAddAsJSON.length) {
          state = Object.assign({}, state, {
            cssAssets: [...existingCssAssets, ...newAssetsToAddAsJSON]
          });
        } // If this action was fired and we even _considered_ optimisitically adding css, we should
        // fetch a new copy. Doing this even if there are no new css assets to fetch
        // handles the use case where a mofo edit _removed_ `required_css`.


        return Object.assign({}, state, {
          shouldFetchNewCssAssetsOnSave: true
        });
      }

    case UPDATE_EDITOR_RENDERED_CSS_ASSET:
      {
        const cssAssets = state.cssAssets;

        if (!EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.cssAssets)) {
          return state;
        }

        const editorRenderedCssAssetIndex = cssAssets.findIndex(asset => asset.type === CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS);

        if (editorRenderedCssAssetIndex > -1) {
          cssAssets[editorRenderedCssAssetIndex].snippet = payload;
        } else {
          cssAssets.push({
            type: CONTENT_ASSET_TYPE_INLINE_EDITOR_CSS,
            snippet: payload
          });
        }

        const newCssAssetsArrayReference = [...cssAssets];
        return Object.assign({}, state, {
          cssAssets: newCssAssetsArrayReference
        });
      }
    // Any of these actions could potentially change the css on the page (mostly just module updates or flex
    // column + dnd area layout changes)

    case MODULE_REDUCER_CHANGE_UNDO:
    case MODULE_REDUCER_CHANGE_REDO:
    case MOVED_MODULE:
    case ADDED_MODULE:
    case ADDED_MODULE_TO_POST_BODY:
    case LAYOUT_SECTION_MODULE_DELETED:
    case LAYOUT_SECTION_COLUMN_DELETED:
    case LAYOUT_SECTION_ROW_DELETED:
    case LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW:
    case LAYOUT_SECTION_COLUMN_INSERTED_AFTER:
    case LAYOUT_SECTION_COLUMN_INSERTED_BEFORE:
    case LAYOUT_SECTION_ROW_INSERTED_AFTER:
    case LAYOUT_SECTION_ROW_INSERTED_BEFORE:
    case LAYOUT_SECTION_ROW_CLONED_BELOW:
    case LAYOUT_SECTION_CELL_CLONED_TO_RIGHT:
    case LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN:
    case LAYOUT_SECTION_ROW_APPENDED:
    case LAYOUT_SECTION_ROW_PREPENDED:
    case UPDATED_CONTAINERS_FROM_PREVIEW:
    case UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES:
      {
        return Object.assign({}, state, {
          shouldFetchNewCssAssetsOnSave: EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.cssAssets)
        });
      }

    default:
      {
        return state;
      }
  }
};

export default contentAssetReducer;