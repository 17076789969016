'use es6';

import { UNIQUE_PROPERTIES_BY_OBJECT_TYPE, UNIQUE_PROPERTIES_FOR_ANY_OBJECT_TYPE } from '../constants/CrmObjectProperties';
const PORTAL_SPECIFIC_CRM_OBJECT_NAME_FORMAT = /^(p_)(.+)/;
export const isCustomCrmObjectDynamicPageSourceId = dynamicPageDataSourceId => PORTAL_SPECIFIC_CRM_OBJECT_NAME_FORMAT.test(dynamicPageDataSourceId);
export const parseCrmObjectDynamicPageDataSourceIdSimpleName = dynamicPageDataSourceId => {
  // We can assume that any id starting with p_ is a custom object
  const matches = PORTAL_SPECIFIC_CRM_OBJECT_NAME_FORMAT.exec(dynamicPageDataSourceId);
  return matches && matches.length === 3 ? matches[2] : dynamicPageDataSourceId;
};
export const formatCrmObjectDynamicPageDataSourceIdForSave = (dynamicPageDataSourceId, isPortalSpecificCrmObject) => isPortalSpecificCrmObject ? `p_${dynamicPageDataSourceId}` : dynamicPageDataSourceId;
export const filterCrmObjectPropertiesUsableAsSlug = (propertyObj, objectSchema) => {
  const isUniqueTextProperty = propertyObj.get('type') === 'string' && propertyObj.get('fieldType') === 'text' && propertyObj.get('hasUniqueValue');
  const propertyName = propertyObj.get('name');
  const allowedPropertiesForObjectType = UNIQUE_PROPERTIES_BY_OBJECT_TYPE[objectSchema.get('name')];
  return isUniqueTextProperty || UNIQUE_PROPERTIES_FOR_ANY_OBJECT_TYPE.includes(propertyName) || allowedPropertiesForObjectType && allowedPropertiesForObjectType.includes(propertyName);
};