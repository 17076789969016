'use es6';

import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';

const createNotificationAction = notificationType => ({
  message,
  sticky = false,
  timeout,
  title
}) => FloatingAlertStore.addAlert({
  message,
  sticky,
  timeout,
  titleText: title,
  type: notificationType
});

export const showErrorNotification = createNotificationAction('danger');
export const showWarningNotification = createNotificationAction('warning');
export const showSuccessNotification = createNotificationAction('success');