'use es6';

import { createSelector } from 'reselect';
import enviro from 'enviro';
import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { determineHubletSubdomain } from 'ContentEditorUI/utils/routeHelper';
import EditorConfigSingleton from '../../EditorConfigSingleton';
export const getHost = basicSelector(() => `https://app${determineHubletSubdomain()}.hubspot${enviro.isQa() ? 'qa' : ''}.com`);
export const getRoutingLocation = basicSelector(state => state.routing && state.routing.locationBeforeTransitions);

const createRoutingSelector = field => {
  return createSelector([getRoutingLocation], routingLocation => {
    if (!routingLocation) {
      return '';
    }

    return routingLocation[field];
  });
};

export const getRoutingBasename = createRoutingSelector('basename');
export const getRoutingPathname = createRoutingSelector('pathname');
export const getRoutingQueryParams = createRoutingSelector('query');
export const getRoutingHash = createSelector([getRoutingLocation], routingLocation => routingLocation.hash);
export const getRoutingKey = createSelector([getRoutingLocation], routingLocation => routingLocation.key);
export const getRoutingCurrentPage = createSelector([getRoutingPathname], getPaneNameFromPath); // TODO branden eventually replace getRoutingCurrentPage?

export const getCurrentTabId = createSelector([getRoutingPathname], getPaneNameFromPath);
export const getRoutingRelativePath = createSelector([getRoutingPathname], pathname => {
  if (pathname.split('/').length > 3 || EditorConfigSingleton.getIsOnScalableEditor() && pathname.split('/').length > 2) {
    return pathname.substring(0, pathname.lastIndexOf('/') + 1);
  }

  return pathname.endsWith('/') ? pathname : `${pathname}/`;
});
export const getIsEmbeddedEmailEditorRoute = createSelector([getRoutingPathname], pathname => pathname.includes('edit-csp'));