import { getChildren, getNumberChildren } from './privateHelpers';
import Cell from './Cell';
import Row from './Row';

const iterateCell = (cell, callback, {
  depth = 0,
  includeStart
}) => {
  if (depth !== 0 || includeStart) {
    const callbackResult = callback({
      cell,
      depth
    }); // Explicit false stops iteration

    if (callbackResult === false) {
      return;
    }
  }

  if (cell.hasRows()) {
    for (const row of cell.getRows()) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      iterateRow(row, callback, {
        depth: depth + 1,
        includeStart
      });
    }
  }
};

const iterateRow = (row, callback, {
  depth = 0,
  includeStart
}) => {
  if (depth !== 0 || includeStart) {
    const callbackResult = callback({
      row,
      depth
    }); // Explicit false stops iteration

    if (callbackResult === false) {
      return;
    }
  }

  if (row.hasColumns()) {
    for (const column of row.getColumns()) {
      iterateCell(column, callback, {
        depth: depth + 1,
        includeStart
      });
    }
  }
};

export const iterateFrom = (cellOrRow, iteratorCallback, {
  includeStart = false
} = {}) => {
  if (cellOrRow instanceof Cell) {
    iterateCell(cellOrRow, iteratorCallback, {
      includeStart
    });
  } else if (cellOrRow instanceof Row) {
    iterateRow(cellOrRow, iteratorCallback, {
      includeStart
    });
  } else {
    throw new Error('Invalid starting point for iteration');
  }
};
export const collectAllDescendentRowsAndCells = cellOrRow => {
  const results = {
    descendentRows: [],
    descendentColumns: []
  };
  iterateFrom(cellOrRow, ({
    row,
    cell
  }) => {
    if (row) {
      results.descendentRows.push(row);
    }

    if (cell) {
      results.descendentColumns.push(cell);
    }
  });
  return results;
};

const pathOfAllUnnecessaryWrappersUpToDescendentToKeepHelper = (cellOrRow, pathToDescendent = []) => {
  const numChildren = getNumberChildren(cellOrRow);
  pathToDescendent.push(cellOrRow.getName()); // Check that the next two wrappers down only have a single child and are eligible for auto-deletion
  // because we can only delete extra _pairs_ of wrappers. E.g. delete the next uncessary column & row
  // pair, not just a single row or column (otherwise where would the leftover left/descendent go?)

  if (cellOrRow.shouldAutoDeleteWhenEmpty() && numChildren === 1) {
    const child = getChildren(cellOrRow)[0];
    const numChildSiblings = getNumberChildren(child);

    if (child.shouldAutoDeleteWhenEmpty() && numChildSiblings === 1) {
      const grandChild = getChildren(child)[0];
      pathToDescendent.push(child.getName());
      return pathOfAllUnnecessaryWrappersUpToDescendentToKeepHelper(grandChild, pathToDescendent);
    }
  }

  return {
    descendent: cellOrRow,
    pathToDescendent
  };
};

export const pathOfAllUnnecessaryWrappersUpToDescendentToKeep = cellOrRow => pathOfAllUnnecessaryWrappersUpToDescendentToKeepHelper(cellOrRow, []);