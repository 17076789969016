'use es6';

import { createSelector } from 'reselect';
import { getCanUseCustomSections } from 'ContentEditorUI/redux/selectors/templateInfoSelectors';
import { getHasAnyLayoutSections } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getHasTheme } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { PATAGONIA_ADD_PANEL_CATEGORIES, PATAGONIA_ADD_PANEL_SUB_CATEGORIES } from 'ContentEditorUI/constants/Sidebar'; // What do we do when there's no place to add modules (i.e a page with all static modules)?

export const getPatagoniaAddSidebarCategories = createSelector([getCanUseCustomSections, getHasAnyLayoutSections], (canUseCustomSections, hasAnyLayoutSections) => {
  const shouldShowSections = canUseCustomSections && hasAnyLayoutSections;
  return Object.keys(PATAGONIA_ADD_PANEL_CATEGORIES).filter(category => {
    switch (category) {
      case PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS:
        return shouldShowSections;

      case PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS:
        return hasAnyLayoutSections;

      default:
        return true;
    }
  });
});
const getSubCategoriesForModulesCategory = createSelector([getHasTheme], hasTheme => {
  const allModulesSubCategories = PATAGONIA_ADD_PANEL_SUB_CATEGORIES.MODULES;

  if (hasTheme) {
    return [allModulesSubCategories.THEME, allModulesSubCategories.COMMON, allModulesSubCategories.ALL];
  }

  return [allModulesSubCategories.COMMON, allModulesSubCategories.ALL];
});
export const getPatagoniaAddSidebarSubCategories = createSelector([getSubCategoriesForModulesCategory], subCategoriesForModulesCategory => {
  const sectionsSubCategories = PATAGONIA_ADD_PANEL_SUB_CATEGORIES.SECTIONS;
  return {
    [PATAGONIA_ADD_PANEL_CATEGORIES.MODULES]: subCategoriesForModulesCategory,
    [PATAGONIA_ADD_PANEL_CATEGORIES.SECTIONS]: [sectionsSubCategories.SAVED, sectionsSubCategories.THEME],
    [PATAGONIA_ADD_PANEL_CATEGORIES.LAYOUTS]: [PATAGONIA_ADD_PANEL_SUB_CATEGORIES.LAYOUTS.DEFAULT]
  };
});