import http from 'hub-http/clients/apiClient';
const ENDPOINT_URL = '/shopping/v3/orders/sku';
const quantityUpgradesApi = {
  post(data) {
    return http.post(ENDPOINT_URL, {
      data
    }).then(purchaseOrder => purchaseOrder.id);
  }

};
export default quantityUpgradesApi;