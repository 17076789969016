import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import PropTypes from 'prop-types';
import emptyFunction from 'react-utils/emptyFunction';
import invariant from 'react-utils/invariant';
import check from './checkParams';
import { uniqueArray } from './customPropTypes';
import ProgressStore from '../stores/ProgressStore';
import { NOT_STARTED, DONE } from '../constants/TourStates';
import handleCallback from './handleCallback';
const paramTypes = {
  key: PropTypes.string.isRequired,
  config: PropTypes.shape({
    steps: uniqueArray,
    beforeExit: PropTypes.func,
    beforeEnter: PropTypes.func,
    beforeStart: PropTypes.func,
    beforeFinish: PropTypes.func
  }),
  progressStore: PropTypes.instanceOf(ProgressStore).isRequired
};
export default class DefaultTourHandler {
  constructor(params) {
    check(params, paramTypes);

    const {
      key,
      config: {
        steps = [],
        beforeExit = emptyFunction,
        beforeEnter = emptyFunction,
        beforeStart = emptyFunction,
        beforeFinish = emptyFunction
      } = {},
      progressStore
    } = params,
          restConfigs = _objectWithoutPropertiesLoose(params.config, ["steps", "beforeExit", "beforeEnter", "beforeStart", "beforeFinish"]);

    this.tour = key;
    this.config = Object.assign({
      steps,
      beforeExit,
      beforeEnter,
      beforeStart,
      beforeFinish
    }, restConfigs);
    this.isActive = false;
    this.progressStore = progressStore;
    this._deactivate = this._deactivate.bind(this);
  }

  _deactivate(callback) {
    return handleCallback(this.config.beforeExit, () => {
      this.isActive = false;

      if (typeof callback === 'function') {
        callback();
      }
    }, {
      tourKey: this.tour,
      stepKey: this.getStepKey()
    });
  }

  deactivate(callback) {
    return handleCallback(this._deactivate, () => {
      this.progressStore.deactivate();

      if (typeof callback === 'function') {
        callback();
      }
    }, undefined);
  }

  _activate() {
    const afterEnter = () => {
      this.isActive = true;

      this._broadcastActiveTour(this.tour);
    };

    return handleCallback(this.config.beforeEnter, afterEnter, {
      tourKey: this.tour,
      stepKey: this.getStepKey()
    });
  }

  _broadcastActiveTour(tourKey) {
    this.progressStore.activateTour(tourKey);
  }

  _tryAction() {
    invariant(this.isActive, `This tour ${this.tour} is not active, so it cannot perform that action`);
  }

  subscribeToUpdates(callback) {
    return this.progressStore.subscribe(callback);
  }

  canGoBack() {
    return this.progressStore.canGoBack();
  }

  goBack() {
    this._tryAction();

    this.progressStore.goBack(this.tour);
  }

  getConfig() {
    return this.config;
  }

  getIndex() {
    const step = this.getStepKey();
    return this.config.steps.indexOf(step);
  }

  getStepKey() {
    return this.progressStore.getStep();
  }

  updateStep(stepKey) {
    this._tryAction();

    this.progressStore.updateProgress(stepKey, this.tour);
  }

  startTour(chainable) {
    this._tryAction();

    const {
      beforeStart,
      beforeEnter,
      steps
    } = this.config;
    const startStep = steps[0];

    const goToStartStep = () => this.updateStep(startStep);

    return handleCallback([beforeStart, beforeEnter], goToStartStep, {
      tourKey: this.tour,
      stepKey: startStep
    }, chainable);
  }

  nextStep(stepKey) {
    this._tryAction();

    if (stepKey) {
      this.updateStep(stepKey);
    } else {
      const tourConfig = this.config;
      const currentStepKey = this.getStepKey();
      invariant(currentStepKey !== NOT_STARTED && currentStepKey !== DONE, `The current active tour is ${this.tour}, tour.start() must be called before advancing the tour index`);
      const index = tourConfig.steps.indexOf(currentStepKey);
      const nextStepKey = tourConfig.steps[index + 1];

      if (nextStepKey) {
        this.updateStep(nextStepKey);
      } else {
        this.finishTour();
      }
    }
  }

  finishTour(chainable) {
    this._tryAction();

    const {
      beforeFinish,
      beforeExit
    } = this.config;

    const finishTour = () => this.updateStep(DONE);

    return handleCallback([beforeFinish, beforeExit], finishTour, {
      tourKey: this.tour,
      stepKey: this.getStepKey()
    }, chainable);
  }

}