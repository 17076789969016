'use es6';

import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
export const getContentSchema = basicSelector(state => state.contentSchema);
export const getContent = createSelector([getContentSchema], contentSchema => contentSchema.content);
export const getContext = createSelector([getContentSchema], contentSchema => contentSchema.context);
export const getConfigValues = createSelector([getContentSchema], contentSchema => contentSchema.config_values);
export const getContentSettings = createSelector([getContentSchema], contentSchema => contentSchema.settings); // Bad to copy this logic out of Tonka/static/coffee/content/domains.coffee ???

export const getHasStagingDomain = createSelector([getContext], context => {
  if (context && context.active_domain) {
    const activeDomain = context.active_domain;
    return activeDomain.fullCategoryKey === '1:staged_page';
  }

  return false;
});
export const getContextActiveDomain = createSelector([getContext], context => context.active_domain); // TODO: Make these domains an Immutable.Map in the actual reducer

export const getContextActiveDomainAsMap = createSelector([getContextActiveDomain], contextActiveDomain => ImmutableMap(contextActiveDomain));
export const getContextPrimaryDomain = createSelector([getContext], context => context.primary_domain);
export const getContextSecondaryToDomain = createSelector([getContext], context => context.secondary_to_domain);
export const getCategoryDomain = createSelector([getContext], context => context.category_domain);
export const getMissingModuleIds = createSelector([getContentSchema], contentSchema => ImmutableSet(contentSchema.schema.module_missing_errors));
export const getContentSchemaWidgets = createSelector(getContentSchema, contentSchema => contentSchema.schema.widgets);
export const getContentSchemaFlexAreas = createSelector(getContentSchema, contentSchema => contentSchema && contentSchema.schema && contentSchema.schema.flex_areas);
export const getContentGroup = createSelector(getContentSchema, contentSchema => contentSchema.content_group);