'use es6';

import { FETCH_SUPPORTED_LANGUAGES_SUCCEEDED, FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_RENDER_LANGUAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
const INITIAL_STATE = Immutable.fromJS({
  supportedLanguages: [],
  renderLanguage: null
});

const languageReducer = (state = INITIAL_STATE, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_SUPPORTED_LANGUAGES_SUCCEEDED:
      return state.merge({
        supportedLanguages: payload
      });

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      return state.merge({
        renderLanguage: response.render_language
      });

    case UPDATE_RENDER_LANGUAGE:
      return state.merge({
        renderLanguage: payload
      });

    default:
      return state;
  }
};

export default languageReducer;