'use es6';

import { Map as ImmutableMap } from 'immutable';
import { TOGGLE_REVIEW_MODAL, SET_CLICKED_VALIDATION_ERROR, NAVIGATION_TO_SAME_PAGE } from 'ContentEditorUI/redux/actions/actionTypes';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getPaneNameFromPath } from 'ContentEditorUI/utils/helpers';
const initialState = ImmutableMap({
  currentPane: ''
});

const updateStateWithNewLocation = (pathname, state) => {
  const pane = getPaneNameFromPath(pathname);

  if (!pane) {
    return state;
  }

  if (state.get('errorClicked')) {
    state = state.set('errorClicked', false).set(pane, true);
  }

  state = state.set(state.get('currentPane'), true);
  return state.set('currentPane', pane);
};

const panesVisitStatusReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case LOCATION_CHANGE:
      return updateStateWithNewLocation(payload.pathname, state);

    case NAVIGATION_TO_SAME_PAGE:
      if (state.get('errorClicked')) {
        return state.set('errorClicked', false).set(payload, true);
      }

      return state;

    case TOGGLE_REVIEW_MODAL:
      if (!state.has('review')) {
        return state.set('review', true);
      }

      return state;

    case SET_CLICKED_VALIDATION_ERROR:
      return state.set('errorClicked', true);

    default:
      return state;
  }
};

export default panesVisitStatusReducer;