'use es6';

import { isTemporarySlug } from 'ContentEditorUI/utils/contentModelURLUtils';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_WEBVERSION_ENABLED, FETCH_PAGE_SUCCESS, UPDATE_SETTINGS_SLUG_RAW, UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL, UNLINK_SLUG_FROM_TITLE, UPDATE_SETTINGS_SHOW_ALL_ERRORS, SET_SETTINGS_SCROLL_TO, FETCH_EDITABLE_SLUG_INFO_SUCCESS, SEEN_SEO_POPOVER } from 'ContentEditorUI/redux/actions/actionTypes';
import { fromJS } from 'immutable';
const initialState = fromJS({
  showAllErrors: false,
  seenSeoPopover: false
}); // NOTE This reducer contains app-settings (UI state) fields
// 'base' means it only holds fields that are shared by at least 2 projects (PEUI/BEUI/EEUI/EDNDUI)
// app-specific UI state fields belong in <app>SettingsReducer

const baseSettingsReducer = (state = initialState, {
  type,
  payload,
  response
}) => {
  switch (type) {
    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        return state.merge({
          linkSlugToTitle: content.slug ? isTemporarySlug(content.slug) : false,
          pageExpiryRedirectId: content.pageExpiryRedirectId
        });
      }

    case UPDATE_WEBVERSION_ENABLED:
      return state.set('linkSlugToTitle', payload.createPage);

    case FETCH_PAGE_SUCCESS:
      {
        let redirectName = null;

        if (state.get('pageExpiryRedirectId') && state.get('pageExpiryRedirectId') === payload.id) {
          redirectName = payload.name;
        }

        return state.set('pageExpiryRedirectName', redirectName);
      }

    case UNLINK_SLUG_FROM_TITLE:
      return state.set('linkSlugToTitle', false);

    case UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL:
      {
        let newId = payload.url ? 0 : null; // ID is 0 for external urls

        let newName = null;
        const availableRedirectPage = payload.pages.find(page => page.get('url') === payload.url);

        if (availableRedirectPage) {
          newId = availableRedirectPage.get('id');
          newName = availableRedirectPage.get('name');
        }

        return state.merge({
          pageExpiryRedirectId: newId,
          pageExpiryRedirectName: newName
        });
      }

    case UPDATE_SETTINGS_SHOW_ALL_ERRORS:
      return state.set('showAllErrors', payload);

    case UPDATE_SETTINGS_SLUG_RAW:
      if (!payload.slugRaw && !payload.htmlTitle) {
        state = state.set('linkSlugToTitle', true);
      }

      return state.set('slugRaw', payload.slugRaw);

    case FETCH_EDITABLE_SLUG_INFO_SUCCESS:
      return state.set('slugRaw', payload.editableSlug);

    case SET_SETTINGS_SCROLL_TO:
      return state.set('scrollToElement', payload);

    case SEEN_SEO_POPOVER:
      return state.set('seenSeoPopover', true);

    default:
      return state;
  }
};

export default baseSettingsReducer;