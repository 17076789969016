'use es6';

import { calculateDiff } from 'editor-iframe-lib/redux/utils/iframeStateSync';
import { syncInpageState } from 'ContentEditorUI/redux/actions/inpageActions';
import { ACTION_TYPE_PREFIX as TINYMCE_ACTION_TYPE_PREFIX } from 'tinymce-plugins/actions/ActionTypes';
import { ACTION_TYPE_PREFIX as SMART_ACTION_TYPE_PREFIX } from 'smart-content-core/actions/ActionTypes';
import { ACTION_TYPE_PREFIX as BASE_INPAGE_TYPE_PREFIX } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import PerformanceStatsGatherer from 'ContentEditorUI/lib/PerformanceStatsGatherer';

function postMessageMiddleware(eventBus, syncConfig) {
  return store => next => action => {
    if (eventBus && eventBus.ready && (action.type.startsWith('@@router') || action.type.indexOf(TINYMCE_ACTION_TYPE_PREFIX) === 0 || action.type.indexOf(SMART_ACTION_TYPE_PREFIX) === 0 || action.type.indexOf(BASE_INPAGE_TYPE_PREFIX) === 0) && action.source !== 'iframe') {
      setTimeout(() => {
        eventBus.postMessage(Object.assign({
          action: 'reduxAction',
          source: 'app'
        }, action));
      }, 0);
    }

    const prevState = store.getState();
    const result = next(action);
    const nextState = store.getState();

    if (eventBus && eventBus.ready && !action.skipSync) {
      const diffStart = PerformanceStatsGatherer.now();
      const diff = calculateDiff(prevState, nextState, {
        config: syncConfig
      });

      if (diff) {
        const delta = PerformanceStatsGatherer.now() - diffStart;
        eventBus.postMessage(Object.assign({
          action: 'reduxAction',
          source: 'app'
        }, syncInpageState(diff), {
          originalActionToPassThrough: action.originalActionToPassThrough,
          timeToCalculateDiff: delta
        }));
      }
    }

    return result;
  };
}

export default postMessageMiddleware;