import Raven from 'Raven';
import http from 'hub-http/clients/apiClient';
import enviro from 'enviro';
import { PROD, QA } from 'self-service-api/constants/Environments';

let _cachedProducts;

export const getProducts = () => {
  if (_cachedProducts) {
    return _cachedProducts;
  }

  _cachedProducts = http.get('monetization/v3/product').catch(error => {
    _cachedProducts = null;

    if (error && error.status === 0) {
      return;
    }

    Raven.captureException(new Error('Failed to fetch products'), {
      extra: {
        error
      }
    });
  });
  return _cachedProducts;
};

let _cachedProductsOwned;

export const getOwnedProducts = () => {
  if (_cachedProductsOwned) {
    return _cachedProductsOwned;
  }

  _cachedProductsOwned = http.get('monetization/v3/product/owned').catch(error => {
    _cachedProductsOwned = null;

    if (error && error.status === 0) {
      return;
    }

    const env = (enviro.isProd() ? PROD : QA).toUpperCase();
    Raven.captureException(new Error(`[${env}] Failed to fetch products owned`), {
      extra: {
        error
      }
    });
  });
  return _cachedProductsOwned;
};