import { waitForElementToAppear } from './mutationUtil';

const convertNodeListToArray = nodeList => {
  return [].slice.call(nodeList);
};
/**
 * Gets an element from the dom given an elementQuery and an optional contains string
 */


export const getElement = options => {
  const {
    contains,
    elementQuery,
    elementGetter,
    targetDocument = document
  } = options;

  if (typeof elementGetter === 'function') {
    return elementGetter() || undefined;
  }

  if (!elementQuery) {
    return undefined;
  }

  if (!contains) {
    return targetDocument.querySelector(elementQuery);
  }

  const elements = convertNodeListToArray(targetDocument.querySelectorAll(elementQuery));
  return elements.find(element => {
    return element.innerText.indexOf(contains) >= 0;
  });
};
export const doesElementExist = elementQuery => {
  return document.querySelectorAll(elementQuery).length > 0;
};
/**
 * Adds a listener to an element if the element currently exists, otherwise sets up a mutation observer which will wait for the element and set up the listener (& unlistener) if the element appears
 * @param {string} elementQuery - the query for grabbing the element
 * @param {string} eventType - the event type (eg: click)
 * @param {function} callback - the function to call when the event occurs
 * @param {function} registerUnlistener - the function to register the unlistener to clean up
 * @param {('escapeHatch'|'tourStep')} listenerType - the source of the listener
 */

export function listenToEventOnElement(elementQuery, eventType, callback, registerUnlistener, listenerType) {
  return waitForElementToAppear({
    elementQuery
  }).then(appearedElement => {
    if (appearedElement) {
      const htmlElement = appearedElement;
      htmlElement.addEventListener(eventType, callback, {
        once: true
      });

      if (typeof registerUnlistener === 'function' && typeof listenerType !== 'undefined') {
        registerUnlistener({
          element: htmlElement,
          eventType,
          listener: callback,
          listenerType
        });
      }
    }
  }).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
}
/**
 * Get document element of iframe by css selector
 * @param {string} iframeSelector - css selector for iframe
 * @return {HTMLDocument} document - the document element of iframe
 */

export const getIframeDocument = iframeSelector => {
  const iframeElement = document.querySelector(iframeSelector);

  if (!iframeElement || !iframeElement.contentWindow) {
    return undefined;
  }

  return iframeElement.contentWindow.document;
};
/**
 * Adds a listener to the window, optionally after a selector returns a result.
 * @param {string} eventName - name of the window event
 * @param {number} delay - delay in milliseconds to apply after the window event fires
 * @param {string} waitSelector - blocking selector to wait for a result until the listener is added
 * @return {Promise} eventPromise - a Promise resolving when the event fires
 */

export const listenForDelayedWindowEvent = (eventName, delayMs, waitSelector) => {
  let timeout;
  const waitSelectorHasResults = waitForElementToAppear({
    elementQuery: waitSelector
  });
  return new Promise(resolve => waitSelectorHasResults.then(() => {
    window.addEventListener(eventName, () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        resolve(true);
      }, delayMs);
    });
  }));
};