import { useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_SORT_STATE, PreferredSortDirs } from 'ContentEditorUI/constants/contentManagement'; // @ts-expect-error Untyped module

import { fetchContent as fetchContentEntities } from 'ContentEditorUI/api/ContentCrmSearchApi';
import Categories from 'ContentUtils/constants/Categories'; // @ts-expect-error Untyped module

import { getActiveDomainName } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors'; // @ts-expect-error Untyped module

import { getContentTypeId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
const initialState = {
  contentTypeId: null,
  blogPostsData: [],
  blogPostsTotal: 0,
  blogPostsStale: false,
  blogPostsRequestStatus: 'UNINITIALIZED',
  blogPostsFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  },
  landingPagesData: [],
  landingPagesTotal: 0,
  landingPagesStale: false,
  landingPagesRequestStatus: 'UNINITIALIZED',
  landingPagesFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  },
  sitePagesData: [],
  sitePagesTotal: 0,
  sitePagesStale: false,
  sitePagesRequestStatus: 'UNINITIALIZED',
  sitePagesFilters: {
    page: 1,
    query: '',
    sorts: DEFAULT_SORT_STATE
  }
};

const getStateKeyPrefixFromContentType = contentTypeId => {
  switch (contentTypeId) {
    case Categories.blog_post.id:
      return 'blogPosts';

    case Categories.site_page.id:
      return 'sitePages';

    default:
    case Categories.landing_page.id:
      return 'landingPages';
  }
};

export const getOppositeSortDir = sortDir => {
  switch (sortDir) {
    case 'DESC':
      return 'ASC';

    case 'ASC':
      return 'DESC';

    default:
      return 'NONE';
  }
}; // Actions

export const FETCH_CONTENT_ENTITIES_STARTED = 'FETCH_CONTENT_ENTITIES_STARTED';
export const FETCH_CONTENT_ENTITIES_SUCCEEDED = 'FETCH_CONTENT_ENTITIES_SUCCEEDED';
export const FETCH_CONTENT_ENTITIES_FAILED = 'FETCH_CONTENT_ENTITIES_FAILED';
export const UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS = 'UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS';
export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_CONTENT_TYPE_ID = 'UPDATE_CONTENT_TYPE_ID';

const requestReducer = (state, action) => {
  const prefix = getStateKeyPrefixFromContentType(action.payload.contentTypeId || state.contentTypeId);

  switch (action.type) {
    case FETCH_CONTENT_ENTITIES_STARTED:
      {
        return Object.assign({}, state, {
          contentTypeId: action.payload.contentTypeId,
          [`${prefix}RequestStatus`]: 'PENDING',
          [`${prefix}Stale`]: false
        });
      }

    case FETCH_CONTENT_ENTITIES_SUCCEEDED:
      {
        return Object.assign({}, state, {
          [`${prefix}RequestStatus`]: 'SUCCEEDED',
          [`${prefix}Data`]: action.payload.objects,
          [`${prefix}Total`]: action.payload.total
        });
      }

    case FETCH_CONTENT_ENTITIES_FAILED:
      {
        return Object.assign({}, state, {
          [`${prefix}RequestStatus`]: 'FAILED'
        });
      }

    case UPDATE_CONTENT_TYPE_ID:
      {
        return Object.assign({}, state, {
          contentTypeId: action.payload.contentTypeId,
          [`${prefix}Stale`]: true
        });
      }

    case UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS:
      {
        const filtersKey = `${prefix}Filters`;
        return Object.assign({}, state, {
          [filtersKey]: Object.assign({}, state[filtersKey], {}, action.payload),
          [`${prefix}Stale`]: true
        });
      }

    default:
      return state;
  }
};

const useContentForContentManagementPanel = () => {
  const [state, dispatch] = useReducer(requestReducer, initialState);
  const domainName = useSelector(getActiveDomainName);
  const defaultContentTypeId = useSelector(getContentTypeId);
  const currentContentTypeId = state.contentTypeId || defaultContentTypeId;
  const prefix = getStateKeyPrefixFromContentType(currentContentTypeId);
  const filters = state[`${prefix}Filters`];
  const stale = state[`${prefix}Stale`];
  const data = state[`${prefix}Data`];
  const total = state[`${prefix}Total`];
  const requestStatus = state[`${prefix}RequestStatus`];
  const fetchContent = useCallback(() => {
    dispatch({
      type: FETCH_CONTENT_ENTITIES_STARTED,
      payload: {
        contentTypeId: currentContentTypeId
      }
    });
    return fetchContentEntities(Object.assign({}, filters, {
      contentTypeId: currentContentTypeId,
      domainFilter: domainName
    })).then(response => dispatch({
      type: FETCH_CONTENT_ENTITIES_SUCCEEDED,
      payload: response
    }), error => dispatch({
      type: FETCH_CONTENT_ENTITIES_FAILED,
      payload: error
    }));
  }, [currentContentTypeId, domainName, filters]);
  const updateCurrentContentTypeId = useCallback(newContentTypeId => {
    dispatch({
      type: UPDATE_CONTENT_TYPE_ID,
      payload: {
        contentTypeId: newContentTypeId
      }
    });
  }, []);
  const updateSearch = useCallback(search => {
    dispatch({
      type: UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS,
      payload: {
        query: search
      }
    });
  }, []);
  const updateSort = useCallback(columnName => {
    const filtersKey = `${prefix}Filters`;
    const {
      selectedColumn: currentSelectedColumn,
      sortDir: currentSortDir
    } = state[filtersKey].sorts;
    const newSortState = {
      selectedColumn: columnName,
      sortDir: currentSelectedColumn === columnName ? getOppositeSortDir(currentSortDir) : PreferredSortDirs[columnName]
    };
    dispatch({
      type: UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS,
      payload: {
        sorts: newSortState
      }
    });
  }, [prefix, state]);

  const onPaginate = nextPage => {
    dispatch({
      type: UPDATE_CONTENT_MANAGEMENT_PANEL_FILTERS,
      payload: {
        page: nextPage
      }
    });
  };

  return Object.assign({}, filters, {
    fetchContent,
    updateCurrentContentTypeId,
    updateSearch,
    data,
    contentTypeId: state.contentTypeId,
    stale,
    requestStatus,
    updateSort,
    onPaginate,
    total
  });
};

export default useContentForContentManagementPanel;