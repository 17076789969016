'use es6';

import { REHYDRATE_MODULE_SCHEMAS_SUCCEEDED, FETCH_MODULE_SCHEMAS_SUCCEEDED, FETCH_CUSTOM_MODULES_SUCCESS, FETCH_CONTENT_SCHEMA_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import { combineModuleSchemas, rehydrateModuleSchemasWithTranslations } from 'ContentEditorUI/data/ModuleTransforms';

const moduleSchemaReducer = (state = {
  all: {},
  allAddable: {},
  builtinMapping: {},
  builtInModules: {},
  categoryName: null,
  customModules: {},
  globalModules: {},
  globalGroupInfo: {},
  globalPartialInfo: {},
  moduleSchemaIsFromCache: false
}, data = {}) => {
  const {
    type,
    response,
    fromSuperStoreCache
  } = data;

  switch (type) {
    case REHYDRATE_MODULE_SCHEMAS_SUCCEEDED:
      return rehydrateModuleSchemasWithTranslations(response, state);

    case FETCH_MODULE_SCHEMAS_SUCCEEDED:
      if (fromSuperStoreCache) {
        state = Object.assign({}, state, {
          categoryName: response.categoryName,
          moduleSchemaIsFromCache: true
        });
      }

      return combineModuleSchemas(Object.assign({
        existingAllModulesMap: state.all,
        existingAllAddableMap: state.allAddable,
        existingBuiltInModulesMap: state.builtInModules,
        existingGlobalModulesMap: state.globalModules,
        existingCustomModulesMap: state.customModules
      }, response), state);

    case FETCH_CUSTOM_MODULES_SUCCESS:
      {
        if (state.moduleSchemaIsFromCache && !fromSuperStoreCache) {
          // We know that in this case the current schemas must have come from the cache,
          // so we know that the user is ungated for the cache and can thus use the updated field.
          state = combineModuleSchemas({
            categoryName: state.categoryName,
            customModules: response,
            existingAllModulesMap: state.all,
            existingAllAddableMap: state.allAddable,
            existingBuiltInModulesMap: state.builtInModules,
            existingGlobalModulesMap: state.globalModules,
            existingCustomModulesMap: state.customModules,
            useUpdated: true
          }, state);
        }

        return state;
      }

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          used_module_schemas: usedModuleSchemas
        } = response;

        if (usedModuleSchemas && Object.keys(usedModuleSchemas).length) {
          let categoryName = state.categoryName;

          if (!categoryName) {
            categoryName = response.categoryName;
            state = Object.assign({}, state, {
              categoryName
            });
          }

          state = combineModuleSchemas({
            categoryName,
            usedModuleSchemas,
            existingAllModulesMap: state.all,
            existingAllAddableMap: state.allAddable,
            existingBuiltInModulesMap: state.builtInModules,
            existingGlobalModulesMap: state.globalModules,
            existingCustomModulesMap: state.customModules
          }, state);
        }

        return Object.assign({}, state, {
          builtinMapping: response.builtin_mapping,
          globalGroupInfo: response.schema.global_group_info_by_path,
          globalPartialInfo: response.schema.global_partial_info_by_path
        });
      }

    default:
      return state;
  }
};

export default moduleSchemaReducer;