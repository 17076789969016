export const CrmSearchSortDirToTableSortDir = {
  ASC: 'ascending',
  DESC: 'descending',
  NONE: 'none'
};
export const DEFAULT_SORT_STATE = {
  selectedColumn: 'hs_updated_at',
  sortDir: 'DESC'
};
export const PreferredSortDirs = {
  hs_name: 'ASC',
  hs_updated_at: 'DESC',
  hs_createdate: 'DESC'
};
export const CONTENT_ITEMS_PER_PAGE = 15;