import { createTracker } from 'usage-tracker';
import events from 'onboarding-tours/events.yaml';
import { APP } from '../constants/TrackingConstants';
export const UsageTracker = createTracker({
  events,
  properties: {
    namespace: APP
  },
  onError: () => {// TODO: Add error handling
  }
});
export const ExternalLinkUsageTracker = UsageTracker.clone({
  isBeforeUnload: true
});