import { CMS_ENTERPRISE, CMS_PROFESSIONAL, MARKETING_ENTERPRISE, MARKETING_PRO, MARKETING_STARTER_EMAIL, OPERATIONS_PROFESSIONAL, OPERATIONS_ENTERPRISE, OPERATIONS_STARTER, SALES_ENTERPRISE, SALES_PROFESSIONAL, SALES_STARTER, SERVICE_ENTERPRISE, SERVICE_PROFESSIONAL, SERVICE_STARTER } from './UpgradeProducts';
import { SALES_HUB_STARTER, SALES_HUB_PROFESSIONAL, SALES_HUB_ENTERPRISE, MARKETING_HUB_STARTER, MARKETING_HUB_PROFESSIONAL, MARKETING_HUB_ENTERPRISE, SERVICE_HUB_STARTER, SERVICE_HUB_PROFESSIONAL, SERVICE_HUB_ENTERPRISE, CMS_HUB_PROFESSIONAL, CMS_HUB_ENTERPRISE, OPERATIONS_HUB_STARTER, OPERATIONS_HUB_PROFESSIONAL, OPERATIONS_HUB_ENTERPRISE, ADS_LIMIT_INCREASE, REPORTING_LIMIT_INCREASE, DEDICATED_IP, TRANSACTIONAL_EMAIL, BRAND_DOMAIN, WORKFLOWS_LIMIT_INCREASE, LISTS_LIMIT_INCREASE, HUBSPOT_FREE, TECHNICAL_CONSULTING_ONGOING, STARTER_KIT, SALES_HUB_PROFESSIONAL_ONBOARDING, SERVICE_HUB_PROFESSIONAL_ONBOARDING, CUSTOM_SSL } from './ApiNames';
import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';

/**
 * @note keep in sync with UpgradeProductToApiNameMap
 */
const ApiNameToUpgradeProductMap = {
  [SALES_HUB_STARTER]: SALES_STARTER,
  [SALES_HUB_PROFESSIONAL]: SALES_PROFESSIONAL,
  [SALES_HUB_ENTERPRISE]: SALES_ENTERPRISE,
  [MARKETING_HUB_STARTER]: MARKETING_STARTER_EMAIL,
  [MARKETING_HUB_PROFESSIONAL]: MARKETING_PRO,
  [MARKETING_HUB_ENTERPRISE]: MARKETING_ENTERPRISE,
  [SERVICE_HUB_STARTER]: SERVICE_STARTER,
  [SERVICE_HUB_PROFESSIONAL]: SERVICE_PROFESSIONAL,
  [SERVICE_HUB_ENTERPRISE]: SERVICE_ENTERPRISE,
  [CMS_HUB_PROFESSIONAL]: CMS_PROFESSIONAL,
  [CMS_HUB_ENTERPRISE]: CMS_ENTERPRISE,
  [OPERATIONS_HUB_STARTER]: OPERATIONS_STARTER,
  [OPERATIONS_HUB_PROFESSIONAL]: OPERATIONS_PROFESSIONAL,
  [OPERATIONS_HUB_ENTERPRISE]: OPERATIONS_ENTERPRISE,
  [ADS_LIMIT_INCREASE]: UpgradeProducts.ADS,
  [REPORTING_LIMIT_INCREASE]: UpgradeProducts.REPORTING,
  [DEDICATED_IP]: UpgradeProducts.DEDICATED_IP,
  [TRANSACTIONAL_EMAIL]: UpgradeProducts.TRANSACTIONAL_EMAIL,
  [BRAND_DOMAIN]: UpgradeProducts.BRAND_DOMAIN,
  [WORKFLOWS_LIMIT_INCREASE]: UpgradeProducts.WORKFLOWS_ADDON,
  [LISTS_LIMIT_INCREASE]: UpgradeProducts.LISTS_ADDON,
  [HUBSPOT_FREE]: UpgradeProducts.CRM,
  [TECHNICAL_CONSULTING_ONGOING]: UpgradeProducts.DESIGNATED_TECHNICAL_SUPPORT,
  [STARTER_KIT]: UpgradeProducts.STARTER_KIT,
  [SALES_HUB_PROFESSIONAL_ONBOARDING]: UpgradeProducts.SALES_PROFESSIONAL_ONBOARDING,
  [SERVICE_HUB_PROFESSIONAL_ONBOARDING]: UpgradeProducts.SERVICE_PROFESSIONAL_ONBOARDING,
  [CUSTOM_SSL]: UpgradeProducts.CUSTOM_SSL
};
export default ApiNameToUpgradeProductMap;