'use es6';

export default {
  USER_HAS_NO_PUBLISH_ACCESS: 'userHasNoPublishAccess',
  NON_RESOLVING_DOMAIN: 'nonResolvingDomain',
  REDIRECTING_DOMAIN: 'redirectingDomain',
  DOMAIN_NOT_ASSOCIATED_WITH_BRAND: 'domainNotAssociatedWithBrand',
  DOMAIN_NOT_PRIMARY_OR_SECONDARY_FOR_CONTENT_TYPE: 'domainNotPrimaryOrSecondaryForContentType',
  NO_DOMAIN_PERMISSION: 'noDomainPermission',
  ACTIVE_DOWNGRADE: 'activeDowngrade',
  ACTIVE_DOWNGRADE_BLOG: 'activeDowngradeBlog'
};