import PortalIdParser from 'PortalIdParser';
import { getIsFilePrivate } from './fileAccessibility';
import getApiHostForEnvironment from './getApiHostForEnvironment';
import ThumbnailSizes from '../enums/ThumbnailSizes';
import { getCurrentApp, getCurrentAppVersion } from './logging';

function appendStaticAppInfo(url) {
  const staticAppName = getCurrentApp();
  const staticAppVersion = getCurrentAppVersion();

  if (staticAppName) {
    url = `${url}&hs_static_app=${staticAppName}`;

    if (staticAppVersion && staticAppVersion !== 'static') {
      url = `${url}&hs_static_app_version=${staticAppVersion}`;
    }
  }

  return url;
}

export function getPublicThumbnailRedirectUrl(file, thumbSize, options = {}) {
  const {
    isFallbackImageDisabled,
    shouldUpscale,
    fallbackSize,
    noCache
  } = options;
  let url = `//${getApiHostForEnvironment()}/filemanager/api/v3/files/thumbnail-redirect/${file.get('id')}?size=${thumbSize}&portalId=${PortalIdParser.get()}`;
  url = appendStaticAppInfo(url);

  if (isFallbackImageDisabled) {
    url = `${url}&errorOnPlaceholder=true`;
  }

  if (shouldUpscale) {
    url = `${url}&upscale=true`;
  }

  if (fallbackSize) {
    url = `${url}&fallbackSize=${fallbackSize}`;
  }

  if (noCache) {
    return `${url}&t=${Date.now()}`;
  }

  return `${url}&t=${file.get('updated')}`;
}
export function getPrivateThumbnailRedirectUrl(file, thumbSize, options = {}) {
  const {
    isFallbackImageDisabled,
    shouldUpscale,
    fallbackSize,
    noCache
  } = options;
  let url = `//${getApiHostForEnvironment()}/filemanager/api/v2/files/${file.get('id')}/signed-url-redirect?size=${thumbSize}&portalId=${PortalIdParser.get()}`;
  url = appendStaticAppInfo(url);

  if (isFallbackImageDisabled) {
    url = `${url}&errorOnPlaceholder=true`;
  }

  if (shouldUpscale) {
    url = `${url}&upscale=true`;
  }

  if (fallbackSize) {
    url = `${url}&fallbackSize=${fallbackSize}`;
  }

  if (noCache) {
    return `${url}&t=${Date.now()}`;
  }

  return `${url}&t=${file.get('updated')}`;
}
export function getVideoOrImageThumbnailUrl(file, thumbSize = ThumbnailSizes.THUMB, options) {
  if (getIsFilePrivate(file)) {
    return getPrivateThumbnailRedirectUrl(file, thumbSize, options);
  }

  return getPublicThumbnailRedirectUrl(file, thumbSize, options);
}