'use es6'; // Default scope required for all editors.

export const DEFAULT_REQUIRED_SCOPE = 'content-editor-access'; // Fatal error messages

export const FATAL_ERROR_CODES = {
  404: 'notFound',
  403: 'notAllowed',
  500: 'badRequest'
};
export const FATAL_ERROR_TYPES = {
  NOT_PERMISSIONED: 'USER_NOT_AUTHORIZED'
};
export const APP_ERRORS = {
  NOT_FOUND: 'notFound',
  NOT_ALLOWED: 'notAllowed',
  BAD_REQUEST: 'badRequest',
  UNKNOWN: 'unknown'
}; // Used when the content model is not available

export const CATEGORY_FROM_PROJECT = {
  // TODO: Find a way to not have to define these here
  ['page-editor-ui']: 'landing-page',
  ['blog-editor-ui']: 'blog-post',
  EmailDragDropEditorUI: 'email',
  EmailTemplateEditorUI: 'email-template'
};
export const INPAGE_EDITOR_UI_SCRIPT_PATH = 'bundles/app.js';
export const INPAGE_EDITOR_UI_CSS_PATH = 'bundles/app.css';
export const InpageModes = {
  LAYOUT: 'layout',
  ADD: 'add',
  EDIT: 'edit',
  DEFAULT: 'default'
};
export const INPAGE_SIDEBAR_IFRAME_ROOT_DIV_ID = 'hubspot-wrapper';
export const INPAGE_SIDEBAR_IFRAME_WINDOW_NAME = 'contentEditor-sidebar';
export const GALLERY_TABS = [{
  name: 'slides',
  icon: 'image'
}, {
  name: 'settings',
  icon: 'settings'
}];