'use es6';

import { requestStates } from 'redux-request-state';
import createRequestStatesReducer from 'ContentEditorUI/redux/reducers/createRequestStatesReducer';
import { FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST, FETCH_HUBDB_TABLES_REQUEST, FETCH_DATA_QUERIES_REQUEST, FETCH_CONTACT_LISTS_REQUEST, FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST, FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST } from 'ContentEditorUI/utils/requestStatesKeys';
import * as pageRequestKeys from 'page-editor-ui/utils/requestStatesKeys';
import { FETCH_AVAILABLE_PAGE_STYLESHEETS_STARTED, FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED, FETCH_AVAILABLE_PAGE_STYLESHEETS_FAILED, FETCH_HUBDB_TABLES_STARTED, FETCH_HUBDB_TABLES_SUCCEEDED, FETCH_HUBDB_TABLES_FAILED, FETCH_DATA_QUERIES_STARTED, FETCH_DATA_QUERIES_SUCCEEDED, FETCH_DATA_QUERIES_FAILED, FETCH_CONTACT_LISTS_STARTED, FETCH_CONTACT_LISTS_SUCCEEDED, FETCH_CONTACT_LISTS_FAILED, FETCH_CONTACT_LISTS_FOR_CONTENT_STARTED, FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED, FETCH_CONTACT_LISTS_FOR_CONTENT_FAILED, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_STARTED, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_FAILED, FETCH_CONTACT_LIST_REGISTRATIONS_STARTED, FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED, FETCH_CONTACT_LIST_REGISTRATIONS_FAILED, FETCH_PAGE_LIMIT_STARTED, FETCH_PAGE_LIMIT_SUCCEEDED, FETCH_PAGE_LIMIT_FAILED } from 'page-editor-ui/redux/actions/actionTypes';
import { FETCH_CRM_OBJECT_SCHEMA_FAILED, FETCH_CRM_OBJECT_SCHEMA_STARTED, FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED, FETCH_CRM_OBJECT_TYPES_FAILED, FETCH_CRM_OBJECT_TYPES_STARTED, FETCH_CRM_OBJECT_TYPES_SUCCEEDED } from '../actions/actionTypes';
const {
  FAILED,
  STARTED,
  SUCCEEDED
} = requestStates;
const fetchAvailablePageStylesheetsArtifactBuilders = {
  [FETCH_AVAILABLE_PAGE_STYLESHEETS_STARTED]: () => ({
    requestKey: FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST,
    requestState: STARTED
  }),
  [FETCH_AVAILABLE_PAGE_STYLESHEETS_SUCCEEDED]: () => ({
    requestKey: FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_AVAILABLE_PAGE_STYLESHEETS_FAILED]: () => ({
    requestKey: FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST,
    requestState: FAILED
  })
};
const fetchHubDbTablesArtifactBuilders = {
  [FETCH_HUBDB_TABLES_STARTED]: () => ({
    requestKey: FETCH_HUBDB_TABLES_REQUEST,
    requestState: STARTED
  }),
  [FETCH_HUBDB_TABLES_SUCCEEDED]: () => ({
    requestKey: FETCH_HUBDB_TABLES_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_HUBDB_TABLES_FAILED]: () => ({
    requestKey: FETCH_HUBDB_TABLES_REQUEST,
    requestState: FAILED
  })
};
const fetchDataQueriesArtifactBuilders = {
  [FETCH_DATA_QUERIES_STARTED]: () => ({
    requestKey: FETCH_DATA_QUERIES_REQUEST,
    requestState: STARTED
  }),
  [FETCH_DATA_QUERIES_SUCCEEDED]: () => ({
    requestKey: FETCH_DATA_QUERIES_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_DATA_QUERIES_FAILED]: () => ({
    requestKey: FETCH_DATA_QUERIES_REQUEST,
    requestState: FAILED
  })
};
const fetchContactListsArtifactBuilders = {
  [FETCH_CONTACT_LISTS_STARTED]: () => ({
    requestKey: FETCH_CONTACT_LISTS_REQUEST,
    requestState: STARTED
  }),
  [FETCH_CONTACT_LISTS_SUCCEEDED]: () => ({
    requestKey: FETCH_CONTACT_LISTS_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_CONTACT_LISTS_FAILED]: () => ({
    requestKey: FETCH_CONTACT_LISTS_REQUEST,
    requestState: FAILED
  }),
  [FETCH_CONTACT_LISTS_FOR_CONTENT_STARTED]: () => ({
    requestKey: FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST,
    requestState: STARTED
  }),
  [FETCH_CONTACT_LISTS_FOR_CONTENT_SUCCEEDED]: () => ({
    requestKey: FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_CONTACT_LISTS_FOR_CONTENT_FAILED]: () => ({
    requestKey: FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST,
    requestState: FAILED
  })
};
const fetchFeaturedImageThumbnailUrlsArtifactBuilders = {
  [FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_STARTED]: () => ({
    requestKey: FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST,
    requestState: STARTED
  }),
  [FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_SUCCEEDED]: () => ({
    requestKey: FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_FAILED]: () => ({
    requestKey: FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST,
    requestState: FAILED
  })
};
const fetchContactListRegistrationsArtifactBuilders = {
  [FETCH_CONTACT_LIST_REGISTRATIONS_STARTED]: () => ({
    requestKey: FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST,
    requestState: STARTED
  }),
  [FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED]: () => ({
    requestKey: FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_CONTACT_LIST_REGISTRATIONS_FAILED]: () => ({
    requestKey: FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST,
    requestState: FAILED
  })
};
const fetchPageLimitArtifactBuilders = {
  [FETCH_PAGE_LIMIT_STARTED]: () => ({
    requestKey: pageRequestKeys.FETCH_PAGE_LIMIT_REQUEST,
    requestState: STARTED
  }),
  [FETCH_PAGE_LIMIT_SUCCEEDED]: () => ({
    requestKey: pageRequestKeys.FETCH_PAGE_LIMIT_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_PAGE_LIMIT_FAILED]: () => ({
    requestKey: pageRequestKeys.FETCH_PAGE_LIMIT_REQUEST,
    requestState: FAILED
  })
};
const fetchCrmObjectTypesArtifactBuilders = {
  [FETCH_CRM_OBJECT_TYPES_STARTED]: () => ({
    requestKey: pageRequestKeys.FETCH_CRM_OBJECT_TYPES_REQUEST,
    requestState: STARTED
  }),
  [FETCH_CRM_OBJECT_TYPES_SUCCEEDED]: () => ({
    requestKey: pageRequestKeys.FETCH_CRM_OBJECT_TYPES_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_CRM_OBJECT_TYPES_FAILED]: () => ({
    requestKey: pageRequestKeys.FETCH_CRM_OBJECT_TYPES_REQUEST,
    requestState: FAILED
  })
};
const fetchCrmObjectSchemaArtifactBuilders = {
  [FETCH_CRM_OBJECT_SCHEMA_STARTED]: () => ({
    requestKey: pageRequestKeys.FETCH_CRM_OBJECT_SCHEMA_REQUEST,
    requestState: STARTED
  }),
  [FETCH_CRM_OBJECT_SCHEMA_SUCCEEDED]: () => ({
    requestKey: pageRequestKeys.FETCH_CRM_OBJECT_SCHEMA_REQUEST,
    requestState: SUCCEEDED
  }),
  [FETCH_CRM_OBJECT_SCHEMA_FAILED]: () => ({
    requestKey: pageRequestKeys.FETCH_CRM_OBJECT_SCHEMA_REQUEST,
    requestState: FAILED
  })
};
const pageArtifactBuilderLookup = Object.assign({}, fetchAvailablePageStylesheetsArtifactBuilders, {}, fetchHubDbTablesArtifactBuilders, {}, fetchContactListsArtifactBuilders, {}, fetchFeaturedImageThumbnailUrlsArtifactBuilders, {}, fetchContactListRegistrationsArtifactBuilders, {}, fetchPageLimitArtifactBuilders, {}, fetchCrmObjectTypesArtifactBuilders, {}, fetchCrmObjectSchemaArtifactBuilders, {}, fetchDataQueriesArtifactBuilders);
export default createRequestStatesReducer(pageArtifactBuilderLookup);