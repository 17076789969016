'use es6';

import { CMS_STARTER, CMS_ENTERPRISE, CMS_PROFESSIONAL, MARKETING_ENTERPRISE, MARKETING_PRO, MARKETING_STARTER_EMAIL, OPERATIONS_PROFESSIONAL, OPERATIONS_ENTERPRISE, OPERATIONS_STARTER, SALES_ENTERPRISE, SALES_PROFESSIONAL, SALES_STARTER, SERVICE_ENTERPRISE, SERVICE_PROFESSIONAL, SERVICE_STARTER, ADS, ESIGNATURES_LIMIT_INCREASE } from './UpgradeProducts';
import * as ApiNames from './ApiNames';
/**
 * @note keep in sync with ApiNameToUpgradeProductMap
 */

const UpgradeProductToApiNameMap = {
  [SALES_STARTER]: ApiNames.SALES_HUB_STARTER,
  [SALES_PROFESSIONAL]: ApiNames.SALES_HUB_PROFESSIONAL,
  [SALES_ENTERPRISE]: ApiNames.SALES_HUB_ENTERPRISE,
  [MARKETING_STARTER_EMAIL]: ApiNames.MARKETING_HUB_STARTER,
  [MARKETING_PRO]: ApiNames.MARKETING_HUB_PROFESSIONAL,
  [MARKETING_ENTERPRISE]: ApiNames.MARKETING_HUB_ENTERPRISE,
  [SERVICE_STARTER]: ApiNames.SERVICE_HUB_STARTER,
  [SERVICE_PROFESSIONAL]: ApiNames.SERVICE_HUB_PROFESSIONAL,
  [SERVICE_ENTERPRISE]: ApiNames.SERVICE_HUB_ENTERPRISE,
  [CMS_STARTER]: ApiNames.CMS_HUB_STARTER,
  [CMS_PROFESSIONAL]: ApiNames.CMS_HUB_PROFESSIONAL,
  [CMS_ENTERPRISE]: ApiNames.CMS_HUB_ENTERPRISE,
  [OPERATIONS_STARTER]: ApiNames.OPERATIONS_HUB_STARTER,
  [OPERATIONS_PROFESSIONAL]: ApiNames.OPERATIONS_HUB_PROFESSIONAL,
  [OPERATIONS_ENTERPRISE]: ApiNames.OPERATIONS_HUB_ENTERPRISE,
  [ADS]: ApiNames.ADS_LIMIT_INCREASE,
  [ESIGNATURES_LIMIT_INCREASE]: ApiNames.ESIGNATURES_LIMIT_INCREASE
};
export default UpgradeProductToApiNameMap;