import { ApprovalStatus } from '../types/ApprovalStatus';
import { ApprovalUserType } from '../types/ApprovalUserType';
import { ApprovalStepStatus } from '../types';
export const shouldRenderPendingButton = approval => {
  const {
    approvalStatus,
    currentUserType,
    stepStatusForCurrentUser
  } = approval.meta;

  if (approvalStatus !== ApprovalStatus.APPROVAL_PENDING) {
    return false;
  }

  if (currentUserType === ApprovalUserType.APPROVER && stepStatusForCurrentUser !== ApprovalStepStatus.APPROVED) {
    return false;
  }

  return true;
};
export const shouldRenderChildren = approval => {
  const approvalsConfigIsDisabled = approval.config.enabled === false;
  const approvalIsApproved = approval.meta.approvalStatus === ApprovalStatus.APPROVED;
  return approvalsConfigIsDisabled || approvalIsApproved;
};
export const shouldRenderChildrenForAdmin = approval => {
  const isSuperAdmin = approval.meta.isSuperAdmin;
  const isApprovalNotPending = approval.meta.approvalStatus !== ApprovalStatus.APPROVAL_PENDING;
  return isSuperAdmin && isApprovalNotPending;
};