import { ARROW_INSET_LOWER_BOUND, OPPOSITE_DIRECTIONS, PLACEMENTS_HORIZ, PLACEMENTS_SIDES, PLACEMENTS_VERT, PLACEMENT_EDGES } from '../PlacementConstants';
import { clipRectByValues, getCollisionRect, makeRect, makeRectCoords, makeRectFromCoords } from '../../utils/Rects';
import { getIframeAwareClientRect } from '../../utils/Dom';
import { includes } from '../../utils/Arrays';
export function getSide(placement) {
  return placement.split(' ')[0];
}
export function isHoriz(direction) {
  return includes(PLACEMENTS_HORIZ, direction) || direction === 'middle';
}
export function isVert(direction) {
  return includes(PLACEMENTS_VERT, direction) || direction === 'center';
}
export function getEdge(placement) {
  const specifiedEdge = placement.split(' ')[1];

  if (specifiedEdge) {
    return specifiedEdge;
  }

  return isHoriz(getSide(placement)) ? 'middle' : 'center';
}
export function getAttachment(placement) {
  // Convert to 'vert horiz' format and flip both the side and the edge
  const side = OPPOSITE_DIRECTIONS[getSide(placement)];
  const edge = OPPOSITE_DIRECTIONS[getEdge(placement)];
  return isHoriz(side) ? `${edge} ${side}` : `${side} ${edge}`;
}
/**
 * @param {string} side
 * @param {string} edge
 * @return {string}
 */

const getPlacementName = (side, edge) => {
  let placement = side;
  if (edge !== 'middle' && edge !== 'center') placement += ` ${edge}`;
  return placement;
};
/**
 * If the popover target is skinnier than the arrow that points at it, or wider than the popover
 * itself, the arrow might wind up outside of the popover! This function tells the popover how far
 * it has to move to ensure that its arrow remains inside of it.
 *
 * @param {number} arrowWidth
 * @param {number} targetSize The size of the target along the side the popover is attached to
 * @param {number} popoverSize The
 * @return {number}
 */


const getArrowAdjustment = (arrowWidth, targetSize, popoverSize) => {
  if (arrowWidth === 0) return 0; // no arrow, no adjustment

  const skinnyTargetAdjustment = (arrowWidth - targetSize) / 2 + ARROW_INSET_LOWER_BOUND;
  if (skinnyTargetAdjustment > 0) return skinnyTargetAdjustment;
  const largeTargetAdjustment = popoverSize - (targetSize + arrowWidth) / 2 - ARROW_INSET_LOWER_BOUND;
  if (largeTargetAdjustment < 0) return largeTargetAdjustment;
  return 0;
};
/**
 * Where would the popover be if it had a given placement?
 *
 * @param {Placement} placement
 * @param {object} popoverSize
 * @param {object} targetRect
 * @param {number} arrowWidth
 * @param {number} distance
 * @param {number} inset
 * @param {HTMLElement} offsetParent
 * @param {?Object} pinning
 * @return {Object} An object of the form { rect, arrowInset }
 */


export const computePopupPosition = (placement, popoverSize, targetRect, arrowWidth, distance, inset, offsetParent, pinning = false) => {
  const rectCoords = makeRectCoords(0, 0, 0, 0);
  const side = getSide(placement);
  const edge = getEdge(placement);
  const computedArrowWidth = pinning ? 0 : arrowWidth;
  let arrowAdjustment;

  if (isVert(side)) {
    rectCoords.bottom = side === 'top' ? targetRect.top - distance : targetRect.bottom + distance + popoverSize.height;
    rectCoords.top = rectCoords.bottom - popoverSize.height;
    arrowAdjustment = getArrowAdjustment(computedArrowWidth, targetRect.width, popoverSize.width);

    if (edge === 'left') {
      rectCoords.right = targetRect.right + arrowAdjustment + inset;
      rectCoords.left = rectCoords.right - popoverSize.width;
    } else if (edge === 'right') {
      rectCoords.left = targetRect.left - arrowAdjustment - inset;
      rectCoords.right = rectCoords.left + popoverSize.width;
    } else if (edge === 'center') {
      const targetMiddle = targetRect.left + targetRect.width / 2;
      rectCoords.left = targetMiddle - popoverSize.width / 2;
      rectCoords.right = targetMiddle + popoverSize.width / 2;
    }
  } else {
    rectCoords.right = side === 'left' ? targetRect.left - distance : targetRect.right + distance + popoverSize.width;
    rectCoords.left = rectCoords.right - popoverSize.width;
    arrowAdjustment = getArrowAdjustment(computedArrowWidth, targetRect.height, popoverSize.height);

    if (edge === 'top') {
      rectCoords.bottom = targetRect.bottom + arrowAdjustment + inset;
      rectCoords.top = rectCoords.bottom - popoverSize.height;
    } else if (edge === 'bottom') {
      rectCoords.top = targetRect.top - arrowAdjustment - inset;
      rectCoords.bottom = rectCoords.top + popoverSize.height;
    } else if (edge === 'middle') {
      const targetMiddle = targetRect.top + targetRect.height / 2;
      rectCoords.top = targetMiddle - popoverSize.height / 2;
      rectCoords.bottom = targetMiddle + popoverSize.height / 2;
    }
  } // Compute where the arrow should be to point at the target's center.


  const arrowInset = computedArrowWidth === 0 ? 0 : Math.max(ARROW_INSET_LOWER_BOUND, isHoriz(getSide(placement)) ? (targetRect.height - arrowWidth) / 2 + (targetRect.top - rectCoords.top) : (targetRect.width - arrowWidth) / 2 + (targetRect.left - rectCoords.left)); // Convert from fixed coordinates to coordinates relative to the `offsetParent`, if one is given.

  if (offsetParent == null) {
    const {
      scrollLeft,
      scrollTop
    } = document.documentElement;
    rectCoords.top = rectCoords.top + scrollTop;
    rectCoords.bottom = rectCoords.bottom + scrollTop;
    rectCoords.left = rectCoords.left + scrollLeft;
    rectCoords.right = rectCoords.right + scrollLeft;
  } else {
    const offsetParentRect = offsetParent.getBoundingClientRect();
    /*
      Offsets the computed position based on scroll position vs. parent offset, to account for margin-top that offsets `body` (#9384).
      If there is no offset on the body from the first element, these would cancel each other and result in an offset of 0px.
    */

    const bodyToPageOffset = offsetParentRect.top + document.documentElement.scrollTop; // Translating the rect by `bodyToPageOffset` in the y-direction means adding to both `top` and `bottom`

    rectCoords.top = rectCoords.top - offsetParentRect.top + bodyToPageOffset;
    rectCoords.bottom = rectCoords.bottom - offsetParentRect.top + bodyToPageOffset;
    rectCoords.left = rectCoords.left - offsetParentRect.left;
    rectCoords.right = rectCoords.right - offsetParentRect.left;
  } // If `pinning` is given, shift as prescribed.


  if (typeof pinning === 'object') {
    rectCoords.top += pinning.top;
    rectCoords.left += pinning.left;
  }

  const rect = makeRectFromCoords(rectCoords);
  return {
    rect,
    arrowInset
  };
};
const COMPUTED_TRANSPARENT = 'rgba(0, 0, 0, 0)';
/**
 * Returns the given element's bounding client rect, minus any transparent border or padding.
 *
 * @param {HTMLElement} element
 * @return {object}
 */

export const getRectWithoutWhitespace = element => {
  const elementRect = getIframeAwareClientRect(element);
  const elementStyles = getComputedStyle(element, null); // Patch for Firefox < 62: https://bugzilla.mozilla.org/show_bug.cgi?id=1467722

  if (!elementStyles) return elementRect;
  const hasVisibleBorder = elementStyles['border-color'] !== COMPUTED_TRANSPARENT && parseInt(elementStyles['border-width'], 10) > 0 && elementStyles['border-style'] !== 'none';

  if (elementStyles['background-color'] === COMPUTED_TRANSPARENT && elementStyles['background-image'] === 'none' && !hasVisibleBorder) {
    return clipRectByValues(elementRect, parseInt(elementStyles['padding-top'], 10), parseInt(elementStyles['padding-right'], 10), parseInt(elementStyles['padding-bottom'], 10), parseInt(elementStyles['padding-left'], 10));
  }

  return elementRect;
};
/**
 * @param {HTMLElement} element
 * @return {object} An object of the form `{ width, height }`
 */

export const getElementDimensions = element => {
  // `getBoundingClientRect()` is inaccurate during popover transitions, due to `transform: scale()`.
  return {
    width: element.clientWidth,
    height: element.clientHeight
  };
};
/**
 * @return {object} A rect-like object corresponding to the visible area in the browser viewport
 */

export const getViewportBounds = () => {
  const {
    scrollLeft,
    scrollTop
  } = document.documentElement;
  return makeRect(scrollTop, innerWidth + scrollLeft, innerHeight + scrollTop, scrollLeft);
};
/**
 * @param {object} collisionRect A rect returned by `getCollisionRect()`
 * @return {boolean}
 */

export const isCollisionFree = collisionRect => {
  return Object.values(collisionRect).every(v => v === 0);
};
/**
 * @param {Array} eligibleSides `['top', 'right', 'bottom', left']` or any subset of that list
 * @param {string} side
 * @param {Object} rect
 * @return {number}
 */

const gatedRectValue = (eligibleSides, side, rect) => {
  return eligibleSides.includes(side) ? rect[side] : 0;
};
/**
 * @param {number} value1
 * @param {number} value2
 * @return {number} Either `value1` or `value2`, whichever has the greatest absolute value
 */


const maxAbsValue = (value1, value2) => {
  return Math.abs(value1) > Math.abs(value2) ? value1 : value2;
};

/**
 * Determine the best placement and pinning for a popover with the given constraints, based on the
 * available space in the viewport.
 *
 * @param {string} currentPlacement
 * @param {true|false|"vert"|"horiz"} autoPlacement
 * @param {true|Array} pinToConstraint
 * @param {object} popoverDimensions The dimensions of the popover element
 * @param {HTMLElement} targetRect The element the popover is pointing at
 * @param {number} arrowWidth
 * @param {number} distance
 * @param {number} inset
 * @return {object} An object of the form `{ bestPlacement: string, pinned: boolean }`
 */
export const findBestPosition = (currentPlacement, autoPlacement, pinToConstraint, popoverDimensions, targetRect, arrowWidth, distance, inset) => {
  const constraintRect = getViewportBounds(); // If the current placement has no collisions with the constraint, return it.

  const currentPlacementRect = computePopupPosition(currentPlacement, popoverDimensions, targetRect, arrowWidth, distance, inset).rect;
  const currentCollisionRect = getCollisionRect(currentPlacementRect, constraintRect);

  if (isCollisionFree(currentCollisionRect)) {
    return {
      placement: currentPlacement
    };
  } // Try all other possible placements allowed by the `autoPlacement` prop.


  const currentPlacementSide = getSide(currentPlacement);
  const currentPlacementEdge = getEdge(currentPlacement);
  let possibleSides;

  if (autoPlacement === true) {
    possibleSides = PLACEMENTS_SIDES;
  } else if (autoPlacement === 'dropdown' || autoPlacement === 'vert' && isVert(currentPlacementSide)) {
    possibleSides = ['top', 'bottom'];
  } else if (autoPlacement === 'horiz' && isHoriz(currentPlacementSide)) {
    possibleSides = ['left', 'right'];
  } else {
    possibleSides = [currentPlacementSide];
  }

  let possibleEdges;

  if (autoPlacement === true || autoPlacement === 'dropdown') {
    possibleEdges = PLACEMENT_EDGES;
  } else if (autoPlacement === 'vert' && isVert(currentPlacementEdge)) {
    possibleEdges = ['top', 'bottom', 'middle'];
  } else if (autoPlacement === 'horiz' && isHoriz(currentPlacementEdge)) {
    possibleEdges = ['left', 'right', 'center'];
  } else {
    possibleEdges = [currentPlacementEdge];
  }

  const possiblePlacements = [];
  possibleSides.forEach(newPlacementSide => {
    possibleEdges.forEach(newPlacementEdge => {
      // Ignore invalid side+edge combinations, e.g. "top top" or "left right"
      if (isHoriz(newPlacementSide) && isHoriz(newPlacementEdge) || isVert(newPlacementSide) && isVert(newPlacementEdge)) {
        return;
      } // Don't allow centered placements ("top", "right", "bottom", "left") for arrowless popovers


      if (arrowWidth === 0 && /middle|center/.test(newPlacementEdge)) {
        return;
      }

      possiblePlacements.push(getPlacementName(newPlacementSide, newPlacementEdge));
    });
  }); // Order matters! Give preference to the placements that only change the edge or the side.

  possiblePlacements.sort((placement1, placement2) => {
    const sameSide1 = getSide(placement1) === currentPlacementSide;
    const sameSide2 = getSide(placement2) === currentPlacementSide;
    if (sameSide1 && !sameSide2) return -1;
    if (sameSide2 && !sameSide1) return 1;
    const sameEdge1 = getEdge(placement1) === currentPlacementEdge;
    const sameEdge2 = getEdge(placement2) === currentPlacementEdge;
    if (sameEdge1 && !sameEdge2) return -1;
    if (sameEdge2 && !sameEdge1) return 1;
    return 0;
  });
  const collisionRectForPlacement = {};

  for (let i = 0; i < possiblePlacements.length; i++) {
    const newPlacement = possiblePlacements[i];

    if (newPlacement === currentPlacement) {
      collisionRectForPlacement[currentPlacement] = currentCollisionRect;
      continue;
    }

    const newPlacementRect = computePopupPosition(newPlacement, popoverDimensions, targetRect, arrowWidth, distance, inset).rect;
    const newCollisionRect = getCollisionRect(newPlacementRect, constraintRect);

    if (isCollisionFree(newCollisionRect)) {
      return {
        placement: newPlacement
      };
    }

    collisionRectForPlacement[newPlacement] = newCollisionRect;
  } // Otherwise, return the least-bad placement (the one that minimizes clipping), with pinning if allowed.


  const pinnableSides = Array.isArray(pinToConstraint) ? pinToConstraint : pinToConstraint && PLACEMENTS_SIDES || [];
  let leastBadPlacement = currentPlacement;
  let leastBadTopPinning = 0;
  let leastBadLeftPinning = 0;
  let leastBadTotalClipping = Infinity;
  possiblePlacements.forEach(placement => {
    const collisionRect = collisionRectForPlacement[placement];

    if (!collisionRect) {
      return;
    }

    const totalClipping = Math.max(collisionRect.top, collisionRect.bottom) + Math.max(collisionRect.left, collisionRect.right);

    if (totalClipping < leastBadTotalClipping) {
      leastBadTotalClipping = totalClipping;
      leastBadPlacement = placement;
      leastBadTopPinning = maxAbsValue(gatedRectValue(pinnableSides, 'top', collisionRect), -gatedRectValue(pinnableSides, 'bottom', collisionRect));
      leastBadLeftPinning = maxAbsValue(gatedRectValue(pinnableSides, 'left', collisionRect), -gatedRectValue(pinnableSides, 'right', collisionRect));
    }
  });

  if (leastBadTopPinning || leastBadLeftPinning) {
    return {
      pinned: true,
      placement: leastBadPlacement,
      topPinning: leastBadTopPinning,
      leftPinning: leastBadLeftPinning
    };
  }

  return {
    placement: leastBadPlacement
  };
};
/**
 * Convert a `getBoundingClientRect`-style rect to a CSS transform string.
 *
 * @param {object} position An object of the form `{ top, left }`
 * @return {string}
 */

export const getTransformForPosition = rect => {
  // On high-DPI ("retina") displays, align popups with physical pixel boundaries to avoid blur.
  const roundPosition = typeof window.devicePixelRatio === 'number' && devicePixelRatio % 1 === 0 ? rawPosition => Math.round(rawPosition * devicePixelRatio) / devicePixelRatio : rawPosition => rawPosition;
  const xPosition = roundPosition(rect.left);
  const yPosition = roundPosition(rect.top); // Making this a "3D" translation hints to the browser that it should enable GPU acceleration.

  return `translate3d(${xPosition}px, ${yPosition}px, 0px)`;
};