export const DEEP_COMMENTING_REDESIGN_TOUR_ID = 'embedded-comments-redesign';
export const DEEP_COMMENTING_TOUR_BACKDOOR = 'deepCommentingOnboarding'; // Used to simulate a click on a commentable area when in the onboarding.
// We simulate as the actual commentable area might be in an iframe that we can't target.

export const COMMENTABLE_AREA_ONBOARDING_CLICKABLE = 'fake-clickable-commentable-area'; // Used to simulate a click on a comment button when in the onboarding.
// We simulate as the actual comment button might be in an iframe that we can't target.

export const ELEMENT_ONBOARDING_CLICKABLE = 'fake-clickable-onboarding-element'; // Used to position the onboarding popover to point at the deep commenting
// toggle. It lives in an iframe so we cannot target it normally.

export const MORE_MENU_ANCHOR = 'more-menu-anchor'; // Used to position the onboarding popover to point at the close comment mode icoon.

export const ONBOARDING_CLOSE_COMMENT_MODE_ANCHOR = 'onboarding-comment-mode-close-anchor'; // The number of times a user must see the sidebar in a single app
// before we will show them the onboarding.

export const REQUIRED_VIEWS_TO_SHOW_ONBOARDING = 10;