'use es6';

import { List, Map as ImmutableMap } from 'immutable';
import AudienceAccessTypes from 'ContentUtils/constants/AudienceAccessTypes';
import * as PublicAccessRuleTypes from 'ContentUtils/constants/PublicAccessRuleTypes'; // Returns content model redux state fields to update when updating the given audience access level

export const updateStateWithAudienceAccess = audienceAccess => {
  const state = {};

  if (audienceAccess === AudienceAccessTypes.PASSWORD) {
    state.publicAccessRules = new List();
    state.publicAccessRulesEnabled = false; // TODO: @cvuong This is so that we force a form validation when password/public is selected
    // Shouldn't affect anything since they can't publish, but in future w/o backbone, we want this to be null

    state.password = '';
  } else if (audienceAccess === AudienceAccessTypes.SPECIFIC_CONTACTS) {
    state.password = null;
    state.publicAccessRulesEnabled = true;
  } else if (audienceAccess === AudienceAccessTypes.SSO) {
    state.publicAccessRules = List.of(ImmutableMap({
      type: PublicAccessRuleTypes.SSO_LOGIN
    }));
    state.publicAccessRulesEnabled = true;
    state.password = null;
  } else {
    state.publicAccessRules = new List();
    state.publicAccessRulesEnabled = false;
    state.password = null;
  }

  return state;
};
export const updatePublicAccessRulesWithLists = (publicAccessRules, listIds) => {
  const updatedRule = new ImmutableMap({
    type: PublicAccessRuleTypes.LIST_MEMBERSHIP,
    ids: listIds.toList()
  });
  const listRuleIndex = publicAccessRules.findIndex(rule => [PublicAccessRuleTypes.LIST_MEMBERSHIP, PublicAccessRuleTypes.SSO_LOGIN].includes(rule.get('type')));

  if (listRuleIndex > -1) {
    return publicAccessRules.set(listRuleIndex, updatedRule);
  }

  return publicAccessRules.push(updatedRule);
};