'use es6';

const DEEP_LINK_QUERIES = {
  GENERAL: {
    NAME: 'general.name',
    TITLE: 'general.htmlTitle',
    FEATURED_IMAGE: 'general.featuredImage',
    SLUG: 'general.slug'
  },
  TEMPLATE: {},
  LANG_AND_REGION: {
    LANGUAGE: 'langAndRegion.language'
  },
  ROLE_AND_ACCESS: {
    PASSWORD: 'roleAndAccess.password'
  },
  ADVANCED: {
    DYNAMIC_PAGES: 'advanced.dynamicPages'
  }
};
export default DEEP_LINK_QUERIES;