'use es6';

import { Map as ImmutableMap, fromJS } from 'immutable';
import { FETCH_BRAND_COLORS_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
const INITIAL_STATE = ImmutableMap({
  colors: undefined
});

const brandSettingsReducer = (state = INITIAL_STATE, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_BRAND_COLORS_SUCCEEDED:
      return state.set('colors', fromJS(payload));

    default:
      return state;
  }
};

export default brandSettingsReducer;