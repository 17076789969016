'use es6'; // Basically the same functionality as the experimental Promise.any()
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/any

export const raceToFirstSucceeded = promises => {
  return Promise.all(promises.map(promise => {
    return promise.then( // We want to resolve errors so that Promise.all still attempts to resolve the other promises
    // But, we want to reject successes because Promise.all() short-circuits on errors
    // which will give us that "race" that we're looking for.
    // Also, the rest of the promises will still be called, even if one has already succeeded,
    // like with Promise.race()
    success => {
      return Promise.reject(success);
    }, error => {
      return Promise.resolve(error);
    });
  })).then( // Because of the way we set up our returns above, our successes are actually errors that we want to reject, while our
  // errors are actually successes that we want to resolve
  errors => {
    return Promise.reject(errors);
  }, success => {
    return Promise.resolve(success);
  });
};