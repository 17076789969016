'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FETCH_OPTIMIZATIONS_SUCCEEDED, SET_HIGHLIGHTED_FIELD } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = ImmutableMap({
  optimizations: [],
  optToTargetMap: {},
  highlightedField: []
});

const optimizationsReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_OPTIMIZATIONS_SUCCEEDED:
      {
        const {
          optimizations,
          optToTargetMap
        } = payload;
        return state.merge({
          optimizations,
          optToTargetMap
        });
      }

    case SET_HIGHLIGHTED_FIELD:
      {
        const {
          field
        } = payload;
        return state.merge({
          highlightedField: field
        });
      }

    default:
      return state;
  }
};

export default optimizationsReducer;