// A way to wrap non-memoized selectors so they give stats too (when SELECTOR_STATS is true)
export const basicSelectorWithStats = basicUnmemoizedSelector => {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'SELECTOR_STATS'.
  // eslint-disable-next-line no-undef
  if (!(typeof SELECTOR_STATS !== 'undefined' && SELECTOR_STATS)) {
    return basicUnmemoizedSelector;
  }

  let recomputations = 0;
  let durations = [];

  const wrappedSelector = function wrappedSelector() {
    recomputations += 1;
    const start = performance.now(); // apply arguments instead of spreading for performance.
    // eslint-disable-next-line

    const result = basicUnmemoizedSelector.apply(null, arguments);
    const end = performance.now();
    durations.push(end - start);
    return result;
  };

  wrappedSelector.resultFunc = basicUnmemoizedSelector; // @ts-expect-error ts-migrate(7008) FIXME: Member 'dependencies' implicitly has an 'any[]' ty... Remove this comment to see the full error message

  wrappedSelector.dependencies = [];

  wrappedSelector.recomputations = () => recomputations;

  wrappedSelector.resetRecomputations = () => recomputations = 0;

  wrappedSelector.durations = () => durations;

  wrappedSelector.resetDurations = () => durations = [];

  wrappedSelector.notMemoized = true;
  return wrappedSelector;
}; // A away to wrap non-memoized selectors that we don't care to collect stats on. Such as selectors
// that are trivial state getters

export const basicSelector = basicUnmemoizedSelector => {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'SELECTOR_STATS'.
  // eslint-disable-next-line no-undef
  if (typeof SELECTOR_STATS !== 'undefined' && SELECTOR_STATS) {
    // @ts-expect-error this is weird
    basicUnmemoizedSelector.ignoredForStats = true;
  }

  return basicUnmemoizedSelector;
};