import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { CREATE_DEFAULTS } from 'ContentData/constants/apiDefaults';

function getQuery(query) {
  return Object.assign({
    state__in: ['automated_for_form', 'automated_for_form_draft']
  }, query);
}
/**
 * GET cosemail/v1/emails/{id}
 * @param {number} id Email id
 * @returns {Promise} fetch email by id
 */


export function fetchEmail(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`cosemail/v1/emails/${id}`);
}
/**
 * GET cosemail/v1/emails
 * @param {Object} query Query params
 * @returns {Promise} fetch paginated emails
 */

export function fetchEmails(query = {}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('cosemail/v1/emails', {
    query: getQuery(query)
  });
}
/**
 * GET cosemail/v1/emails/search/automated-with-ticket-and-deal
 * @param {Object} query Query params
 * @returns {Promise} fetch paginated automated emails
 */

export function fetchAutomatedEmails(query = {}, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('cosemail/v1/emails/search/automated-with-ticket-and-deal', {
    query: Object.assign({}, query, {
      emailTypes: ['AUTOMATED_EMAIL', 'AUTOMATED_AB_EMAIL'],
      emailSortField: 'UPDATED',
      sortOrder: 'DESC'
    })
  });
}
/**
 * POST cosemail/v1/emails
 * @param {string} name Email name
 * @returns {Promise} create email
 */

export function createEmail(name, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post('cosemail/v1/emails', {
    query: CREATE_DEFAULTS.emails.query,
    data: Object.assign({
      name
    }, CREATE_DEFAULTS.emails.data)
  });
}