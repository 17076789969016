'use es6';

import { createSelector } from 'reselect';
import { requestStarted } from 'redux-request-state/util/requestStateCheckers';
import { getContentSaveRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { getIsReadOnlyMode } from 'ContentEditorUI/redux/selectors/appStatusSelectors';
import { SaveStates } from 'editor-components/EPConstants';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getSaveData = basicSelector(state => state.saveData);
export const getIsSaveInProgress = createSelector([getContentSaveRequest], contentSaveRequest => requestStarted(contentSaveRequest));
export const getIsSavePermanentlyDisabled = createSelector([getSaveData, getIsReadOnlyMode], (saveData, isReadOnlyMode) => saveData.get('permanentlyDisabled') || isReadOnlyMode);
export const getWasLastSaveSuccessful = createSelector([getSaveData], saveData => saveData.get('lastSaveSuccessful'));
export const getHasUnsavedChanges = createSelector(getSaveData, saveData => saveData.get('isDirty'));
export const getSaveRequestId = createSelector(getSaveData, saveData => saveData.get('saveRequestId'));
export const getIsHardSavePending = createSelector(getSaveData, saveData => saveData.get('isHardSavePending'));
export const getLastHardSaveTs = createSelector(getSaveData, saveData => saveData.get('lastHardSaveTs'));
export const getPreventUnsavedChangesWarning = createSelector(getSaveData, saveData => saveData.get('preventUnsavedChangesWarning'));
export const getSaveRequested = createSelector(getSaveData, saveData => saveData.get('saveRequested'));
export const getLastSaveWasAutoSave = createSelector(getSaveData, saveData => saveData.get('lastSaveWasAutoSave'));
export const getSaveState = createSelector([getIsSaveInProgress, getSaveRequested, getHasUnsavedChanges, getWasLastSaveSuccessful, getLastSaveWasAutoSave], (isSaveInProgress, saveRequested, hasUnsavedChanges, wasLastSaveSuccessful, lastSaveWasAutoSave) => {
  if (isSaveInProgress) {
    return SaveStates.SAVING_CHANGES;
  } else if (hasUnsavedChanges) {
    return SaveStates.UNSAVED_CHANGES;
  } else if (!saveRequested) {
    return SaveStates.NO_UNSAVED_CHANGES;
  } else if (wasLastSaveSuccessful) {
    if (lastSaveWasAutoSave) {
      return SaveStates.AUTO_SAVED_CHANGES;
    } else {
      return SaveStates.SAVED_CHANGES;
    }
  }

  return SaveStates.SAVE_FAILED;
});
export const getShouldSaveImmediately = createSelector(getSaveData, saveData => saveData.get('shouldSaveImmediately'));
export const getErrorStatus = createSelector(getSaveData, saveData => saveData.get('errorStatus'));
export const getErrorType = createSelector(getSaveData, saveData => saveData.get('errorType'));
export const getErrorMessage = createSelector(getSaveData, saveData => saveData.get('errorMessage'));