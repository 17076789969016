'use es6';

import { useEffect, useMemo } from 'react';
import { FEATURES } from 'collaboration-sidebar/constants/features';
import { hasFeature } from '../utils/features';
import { mergeCollaborationSidebarState } from '../utils';
/**
 * Today, several prop values (such as objectId ,objectType, ctaUrl)
 * are duplicated in CollaborationSidebarState, where they can be read
 * by the mini commenter. To ensure these values are up to date, we
 * sync into the state from props.
 */

export default function usePersistPropsToCollaborationSidebarState({
  collaborationSidebarState,
  onUpdateCollaborationSidebarState,
  collaborationSidebarProps: {
    objectId,
    objectType,
    appName,
    enabledFeatures,
    ctaUrl = window.location.href
  }
}) {
  const isEmbeddedCommentingEnabled = hasFeature(enabledFeatures, FEATURES.DEEP_COMMENTING);
  const updatesToPersist = useMemo(() => ({
    isEmbeddedCommentingEnabled,
    appName,
    objectId,
    objectType,
    ctaUrl
  }), [objectId, objectType, appName, isEmbeddedCommentingEnabled, ctaUrl]);
  /**
   * Persist prop updates to state
   */

  useEffect(() => {
    onUpdateCollaborationSidebarState(updatesToPersist); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatesToPersist]);
  /**
   * As an optimization, return updated version (avoid unnecessary prop updates in iframe)
   */

  return useMemo(() => mergeCollaborationSidebarState(collaborationSidebarState, updatesToPersist), [collaborationSidebarState, updatesToPersist]);
}