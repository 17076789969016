'use es6';

import { getRoutingLocation, getRoutingCurrentPage } from 'ContentEditorUI/redux/selectors/routingSelectors';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { MODULE_REDUCER_CHANGE_REDO, MODULE_REDUCER_CHANGE_UNDO } from 'ContentEditorUI/redux/actions/actionTypes';
import { getLastUndoneActionType, getLastUndoableActionType } from '../selectors/moduleSelectors';
import { LOCATION_CHANGE } from 'react-router-redux';

const usageTrackerMiddleware = store => next => action => {
  switch (action.type) {
    case LOCATION_CHANGE:
      {
        const prevState = store.getState();
        const result = next(action);
        const currentState = store.getState(); // Do not track this screen change if it is the initial page load

        if (getRoutingLocation(prevState) && UsageTracker.isInitialized()) {
          let subscreen = getRoutingCurrentPage(currentState) || 'content';

          if (['publishing', 'send', 'rssSchedule'].includes(subscreen)) {
            subscreen = 'publish-send';
          }

          UsageTracker.track('editorViewInteraction', {
            subscreen
          });
        }

        return result;
      }

    case MODULE_REDUCER_CHANGE_REDO:
      UsageTracker.trackEditorInteraction({
        action: 'Undo or Redo Changes',
        event: 'redo',
        actionType: getLastUndoneActionType(store.getState())
      });
      return next(action);

    case MODULE_REDUCER_CHANGE_UNDO:
      UsageTracker.trackEditorInteraction({
        action: 'Undo or Redo Changes',
        event: 'undo',
        actionType: getLastUndoableActionType(store.getState())
      });
      return next(action);

    default:
      return next(action);
  }
};

export default usageTrackerMiddleware;