'use es6';

import quickFetch from 'quick-fetch';
import http from 'hub-http/clients/apiClient';
import memoize from '../utilities/memoize';
export const fetchProductPurchaseMotionData = memoize(({
  apiNames = [],
  locations,
  apiGetter
}) => {
  const url = '/monetization/v3/purchase-motions';
  const query = locations ? {
    apiName: apiNames,
    location: locations
  } : {
    apiNames
  };

  if (!apiGetter) {
    return http.get(url, {
      query
    });
  }

  return new Promise((resolve, reject) => {
    apiGetter.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest('purchase-motions');
    });
    apiGetter.onError(() => {
      quickFetch.removeEarlyRequest('purchase-motions');
      return http.get(url, {
        query
      }).then(resolve).catch(httpError => {
        reject(httpError);
      });
    });
  });
});