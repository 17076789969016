// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cont... Remove this comment to see the full error message
import { SLUG_EXISTS, PAGE_EXISTS } from 'ContentEditorUI/lib/modals/constants';
export const shouldIgnoreSentry = error => {
  const errorType = error && (error.type || error.responseJSON && error.responseJSON.errorType);
  const errorStatus = error && error.status;
  const IGNORE_ERROR_TYPES = [SLUG_EXISTS, PAGE_EXISTS];
  const IGNORE_STATUS_CODES = [401, 403];

  if (!errorType) {
    return false;
  }

  return IGNORE_ERROR_TYPES.includes(errorType) || IGNORE_STATUS_CODES.includes(errorStatus);
};