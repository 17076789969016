'use es6';

import { Map as ImmutableMap } from 'immutable';
import { REQUESTED_MODULE_DELETE, DELETED_MODULE, CLOSE_DELETE_REQUEST_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
const INITIAL_STATE = new ImmutableMap({
  moduleIdToDelete: null
});

const deleteRequestReducer = (state = INITIAL_STATE, {
  type,
  payload = {}
}) => {
  if (!payload) {
    return state;
  }

  const {
    moduleId
  } = payload;

  switch (type) {
    case REQUESTED_MODULE_DELETE:
      return state.set('moduleIdToDelete', moduleId);

    case DELETED_MODULE:
      return state.set('moduleIdToDelete', null);

    case CLOSE_DELETE_REQUEST_MODAL:
      return state.merge({
        moduleIdToDelete: null
      });

    default:
      {
        return state;
      }
  }
};

export default deleteRequestReducer;