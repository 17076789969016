'use es6';

import { buildDndAreaCellSelector, buildDndAreaUniqueRowSelector } from '../../dom/dndAreaDomSelectors';
import { isValidRGBA } from 'ContentUtils/helpers/ColorsHelpers';
export const calculateBackgroundColorClasses = (treeNode, styleValue) => {
  let domNodeSelector;
  let rendererName;

  if (!styleValue || !isValidRGBA(styleValue)) {
    console.warn('Background color value must be an object in the format { r: (0-255), g: (0-255), b: (0-255), a: (0-1)}');
    return [];
  }

  if (treeNode.isRow()) {
    rendererName = treeNode.getRendererCompatibleRowName();
    domNodeSelector = buildDndAreaUniqueRowSelector(treeNode.getParent().getName(), treeNode.getName());
  } else {
    rendererName = treeNode.getName();
    domNodeSelector = buildDndAreaCellSelector(rendererName);
  }

  const cssProperties = {
    'background-color': `rgba(${styleValue.r}, ${styleValue.g}, ${styleValue.b}, ${styleValue.a}) !important`
  };
  return [{
    nodeName: treeNode.getName(),
    domNodeSelector,
    className: `${rendererName}-background-color`,
    cssSelector: `.${rendererName}-background-color`,
    cssProperties
  }];
};