import { getSkuMap } from './productUtils';
export const createQuantityUpgradesPayload = (productConfigurations, products) => {
  const skuMap = getSkuMap(products);
  return productConfigurations.map(productConfiguration => {
    const skuInfo = skuMap[productConfiguration.skuId];
    const includedWithQuantityPack = 'quantityUnit' in skuInfo ? skuInfo.quantityUnit.includedWithQuantityPack : 1; // Special case for sales starter and sales pro legacy additional seat purchase

    const calculatedQuantity = Math.ceil(productConfiguration.quantity / includedWithQuantityPack);
    return Object.assign({}, productConfiguration, {
      quantity: calculatedQuantity
    });
  });
};