/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import { SET_SETTINGS_SCROLL_TO, UNLINK_SLUG_FROM_TITLE, UPDATE_SETTINGS_SHOW_ALL_ERRORS, UPDATE_SETTINGS_SLUG_RAW, FETCH_SETTINGS_SLUG_FAIL, FETCH_SETTINGS_SLUG_REQUEST, SEEN_SEO_POPOVER, UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM } from 'ContentEditorUI/redux/actions/actionTypes';
import http from 'hub-http/clients/apiClient';
import { SLUG_FOR_TITLE_URL } from 'ContentEditorUI/lib/pageSettings/constants';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors'; // UPDATES ---------------------->

export const setScrollTo = element => {
  return {
    type: SET_SETTINGS_SCROLL_TO,
    payload: element
  };
};
export const unlinkSlugFromTitle = () => {
  return {
    type: UNLINK_SLUG_FROM_TITLE
  };
};
export const updateShowAllErrors = showErrors => {
  return {
    type: UPDATE_SETTINGS_SHOW_ALL_ERRORS,
    payload: showErrors
  };
};
export const updateSlugRaw = (slugRaw, languageString, htmlTitle) => {
  return {
    type: UPDATE_SETTINGS_SLUG_RAW,
    payload: {
      slugRaw,
      languageString,
      htmlTitle
    }
  };
}; // REQUEST FAILURES ---------------------->

const fetchSlugFailure = error => {
  return {
    type: FETCH_SETTINGS_SLUG_FAIL,
    payload: error
  };
}; // REQUESTS ------------------------------>


export const fetchSlugForTitle = (htmlTitle, languageStringForSlug) => (dispatch, getState) => {
  const contentId = getContentModelId(getState());
  dispatch({
    type: FETCH_SETTINGS_SLUG_REQUEST
  });

  const success = resp => {
    if (resp && resp.slug) {
      dispatch(updateSlugRaw(resp.slug, languageStringForSlug, htmlTitle));
    } else {
      dispatch(fetchSlugFailure(resp));
    }
  };

  const error = err => dispatch(fetchSlugFailure(err));

  http.get(SLUG_FOR_TITLE_URL, {
    query: {
      title: htmlTitle,
      contentId
    }
  }).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};
export const setSeenSeoPopover = (contentId, field) => {
  return {
    type: SEEN_SEO_POPOVER,
    payload: {
      contentId,
      field
    }
  };
};
export const updatePatagoniaSettingsModalActiveNavItem = navItem => {
  return {
    type: UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM,
    payload: navItem
  };
};