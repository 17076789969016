'use es6';

import { calculateBackgroundColorClasses } from './implementations/backgroundColor';
import { calculateBackgroundImageClasses } from './implementations/backgroundImage';
import { calculateVerticalAlignmentClasses } from './implementations/verticalAlignment';
import { calculateMaxWidthSectionCenteringClasses } from './implementations/maxWidthSectionCentering';
import { calculateForceFullWidthSectionCenteringClasses } from './implementations/forceFullWidthSection';
import { calculateMarginClasses, calculateBreakpointMarginClassesForTreeNode, calculateBreakpointMarginClassesForModule } from './implementations/margin';
import { calculatePaddingClasses, calculateBreakpointPaddingClassesForTreeNode, calculateBreakpointPaddingClassesForModule } from './implementations/padding';
import { calculateFlexboxPositioningClasses } from './implementations/flexboxPositioning';
import { calculateBackgroundGradientClasses } from './implementations/backgroundLinearGradient';
import { calculateBreakpointHiddenClassesForTreeNode } from './implementations/hiddenOnDevice';
const DEFAULT_BREAKPOINT = 'default';
const breakpointStyleImplementationsForTreeNode = {
  margin: calculateBreakpointMarginClassesForTreeNode,
  padding: calculateBreakpointPaddingClassesForTreeNode,
  hidden: calculateBreakpointHiddenClassesForTreeNode
};
const breakpointStyleImplementationsForStaticModules = {
  margin: calculateBreakpointMarginClassesForModule,
  padding: calculateBreakpointPaddingClassesForModule,
  // These aren't supported by static modules.
  hidden: null
};
const layoutStyleImplementations = {
  verticalAlignment: calculateVerticalAlignmentClasses,
  backgroundColor: calculateBackgroundColorClasses,
  backgroundImage: calculateBackgroundImageClasses,
  backgroundGradient: calculateBackgroundGradientClasses,
  maxWidthSectionCentering: calculateMaxWidthSectionCenteringClasses,
  forceFullWidthSection: calculateForceFullWidthSectionCenteringClasses,
  flexboxPositioning: calculateFlexboxPositioningClasses,
  // For backwards compatibility until new margin and padding data structure is ungated: `ContentEditor:NormalizeLayoutStyles`
  margin: calculateMarginClasses,
  padding: calculatePaddingClasses
}; // Need to calculate the resulting class strings of each dom element every layout style implementation makes, than concat those changes together with existing design manager styles and apply the update to the element all at once
// Style data on a layout tree node should look something like this:
// {
//  "backgroundColor": { // backgroundColorData }
//  "verticalAlignment": { // verticalAlignmentData }
//  "breakpointStyles": { <-- Need to treat these special since they can be set across user defined breakpoints
//    "default": {
//      "padding": { // paddingData }
//      "margin": { // marginData }
//    }
//    "mobile": {
//      "padding": { // paddingData }
//      "margin": { // marginData }
//    }
//  }
// }

const computeNodeOrModuleDataToDesiredDomState = (breakpointConfigByName, nodeOrModule, styles, styleImplementations, result) => {
  // "Breakpoint styles" that work on multiple breakpoints
  if (styles.breakpointStyles) {
    // Iterate over each breakpoint key
    Object.keys(styles.breakpointStyles).forEach(breakpointName => {
      // If this breakpoint is not the default one, and doesn't have a matching entry
      // in the breakpoint config, bail now. This could happen if the style was previously
      // set for a breakpoint, but then the breakpoint was removed from the config in the theme.
      if (breakpointName !== DEFAULT_BREAKPOINT && !breakpointConfigByName[breakpointName]) {
        return;
      }

      const thisBreakPointConfig = breakpointConfigByName[breakpointName] || {}; // Iterate over each style key inside that breakpoint object

      Object.keys(styles.breakpointStyles[breakpointName]).forEach(styleType => {
        let desiredDomClassState = [];

        if (styleImplementations[styleType]) {
          desiredDomClassState = styleImplementations[styleType](nodeOrModule, styles.breakpointStyles[breakpointName][styleType], thisBreakPointConfig, {
            styles
          });
        } // For each mutation returned from the layout style implementation, sort them into a map by dom
        // element and dom selector the change needs to happen to


        desiredDomClassState.forEach(styleDeclaration => {
          const {
            nodeName,
            domNodeSelector
          } = styleDeclaration; // If there is a defined breakpoint order, make sure to pass it along to the style declaration
          // so it can be more easily sorted later

          styleDeclaration.breakpointOrder = thisBreakPointConfig.order;
          result[nodeName] = result[nodeName] || {};
          result[nodeName][domNodeSelector] = result[nodeName][domNodeSelector] || [];
          result[nodeName][domNodeSelector].push(styleDeclaration);
        });
      });
    });
  } // "Fancy styles" that don't work with customer defined breakpoints (but can still define media query css)


  Object.keys(styles).forEach(styleType => {
    // If the style value was nulled out, make sure it doesn't show up as an key with an undefined value
    // Also handle breakpoint styles in another loop
    if (styles[styleType] === null || styleType === 'breakpointStyles') {
      return;
    }

    let desiredDomClassState; // Get a list of dom mutations requested by the layout style implementation

    if (layoutStyleImplementations[styleType]) {
      desiredDomClassState = layoutStyleImplementations[styleType](nodeOrModule, styles[styleType], styles);
    } else {
      console.warn(`Unsupported layout style type "${styleType}", valid styles are ${Object.keys(layoutStyleImplementations)}`);
      return;
    } // For each mutation returned from the layout style implementation, sort them into a map by dom
    // element and dom selector the change needs to happen to


    desiredDomClassState.forEach(styleDeclaration => {
      const {
        nodeName,
        domNodeSelector
      } = styleDeclaration;
      result[nodeName] = result[nodeName] || {};
      result[nodeName][domNodeSelector] = result[nodeName][domNodeSelector] || [];
      result[nodeName][domNodeSelector].push(styleDeclaration);
    });
  });
  return result;
};

export const computeTreeNodeDataToDesiredDomStateData = (nodesWithStyles, breakpointConfigByName = {}) => nodesWithStyles.reduce((result, node) => {
  const nodeStyles = node.getLayoutStyleData();
  computeNodeOrModuleDataToDesiredDomState(breakpointConfigByName, node, nodeStyles, breakpointStyleImplementationsForTreeNode, result);
  return result;
}, {});
export const computeModuleDataToDesiredDomStateData = (modulesWithStyles, breakpointConfigByName = {}) => modulesWithStyles.reduce((result, module) => {
  const moduleStyles = module.get('styles').toJS();
  computeNodeOrModuleDataToDesiredDomState(breakpointConfigByName, module, moduleStyles, breakpointStyleImplementationsForStaticModules, result);
  return result;
}, {});

const groupLayoutStyleDataByMediaQuery = layoutStyleData => {
  const result = {
    stylesWithNoMediaQuery: {},
    mediaQueryWithNoOrder: {},
    mediaQueryWithSpecificOrder: []
  }; // ^^ The output of this method should look something like:
  // {
  //  stylesWithNoMediaQuery: {  < Object with domNodeSelector --> array of rules to that should be applied to that node. These are rendered to CSS first. Most fancy styles fall into here.
  //    '[data-hs-cell-id="123123"]': [
  //      { // cssProperties },
  //      { // cssProperties },
  //    ]
  //    '[data-hs-row-id="456456"]': [
  //      { // cssProperties },
  //      { // cssProperties },
  //    ]
  //  },
  //  mediaQueryWithNoOrder: {  < Object with "unordered media query" (a rule that defines a media query, like vertical alignment, that isn't associated with a specific breakpoint and has no defined order amongst other breakpoint media queries). These are rendered to CSS second. Special fancy styles, like vertical alignment or max width that define media queries that "shut off" their effect on smaller stacked screensizes would fall into here.
  //    '@media(max-width: 768)': {
  //      '[data-hs-cell-id="123123"]': [
  //        { // cssProperties },
  //        { // cssProperties },
  //      ]
  //     '[data-hs-row-id="456456"]': [
  //        { // cssProperties },
  //        { // cssProperties },
  //      ]
  //    }
  //  },
  //  mediaQueryWithSpecificOrder: [ < Array of objects that contain a mediaQuery associated with a breakpoint and a defined order to render and a map of domNodeSelector to style rule. These are rendered, in array order, third, after the above two groups.
  //    {
  //      mediaQuery: '@media(max-width: 560)',
  //      styleRules: {
  //        '[data-hs-cell-id="83838"]': [
  //          { // cssProperties }
  //          { // cssProperties }
  //        ]
  //        '[data-hs-row-id="158789"]': [
  //          { // cssProperties }
  //          { // cssProperties }
  //        ]
  //      }
  //    },
  //    {
  //      mediaQuery: '@media(max-width: 700)',
  //      styleRules: {
  //        '[data-hs-cell-id="847467438"]': [
  //          { // cssProperties }
  //          { // cssProperties }
  //        ]
  //        '[data-hs-row-id="781237123"]': [
  //          { // cssProperties }
  //          { // cssProperties }
  //        ]
  //      }
  //    },
  //  ],
  //
  // }

  Object.keys(layoutStyleData).forEach(nodeName => {
    Object.keys(layoutStyleData[nodeName]).forEach(domNodeSelector => {
      const layoutStylesForThisTreeNode = layoutStyleData[nodeName][domNodeSelector];
      layoutStylesForThisTreeNode.forEach(styleDeclaration => {
        const mediaQuery = styleDeclaration.mediaQuery;
        const mediaQueryOrder = styleDeclaration.breakpointOrder;
        const mediaQueryName = styleDeclaration.breakpointName; // have to do this because 0 is falsey (assuming we force the 'default' breakpoint to have an order of 0)

        if (!mediaQuery && mediaQueryOrder === undefined) {
          result.stylesWithNoMediaQuery[domNodeSelector] = result.stylesWithNoMediaQuery[domNodeSelector] || [];
          result.stylesWithNoMediaQuery[domNodeSelector].push(styleDeclaration);
        } else if (mediaQuery && !mediaQueryOrder) {
          result.mediaQueryWithNoOrder[mediaQuery] = result.mediaQueryWithNoOrder[mediaQuery] || {};
          result.mediaQueryWithNoOrder[mediaQuery][domNodeSelector] = result.mediaQueryWithNoOrder[mediaQuery][domNodeSelector] || [];
          result.mediaQueryWithNoOrder[mediaQuery][domNodeSelector].push(styleDeclaration);
        } else {
          // Setting an index in an array here
          result.mediaQueryWithSpecificOrder[mediaQueryOrder] = result.mediaQueryWithSpecificOrder[mediaQueryOrder] || {
            mediaQuery,
            mediaQueryName,
            styleRules: {}
          };
          result.mediaQueryWithSpecificOrder[mediaQueryOrder].styleRules[domNodeSelector] = result.mediaQueryWithSpecificOrder[mediaQueryOrder].styleRules[domNodeSelector] || [];
          result.mediaQueryWithSpecificOrder[mediaQueryOrder].styleRules[domNodeSelector].push(styleDeclaration);
        }
      });
    });
  });
  return result;
};

const generateCssStringForNode = arrayOfStyleDeclarations => {
  const setOfCssSubStrings = new Set();
  let cssStringForThisNode = '';
  arrayOfStyleDeclarations.forEach(styleDeclaration => {
    let cssSubString = '\n';
    cssSubString += `\n${styleDeclaration.cssSelector} {`;
    Object.keys(styleDeclaration.cssProperties).forEach(styleProperty => {
      const styleValue = styleDeclaration.cssProperties[styleProperty];
      cssSubString += `\n\t${styleProperty}: ${styleValue};`;
    });
    cssSubString += '\n}'; // Don't repeat the same exact CSS substring twice

    if (!setOfCssSubStrings.has(cssSubString)) {
      setOfCssSubStrings.add(cssSubString);
      cssStringForThisNode += cssSubString;
    }
  });
  return cssStringForThisNode;
};

export function getLayoutStyleCssString(styleData) {
  const dataByMediaQuery = groupLayoutStyleDataByMediaQuery(styleData);
  const cssStringsForLayoutStylesWithNoMediaQuery = [];
  const cssStringsForMediaQueriesWithNoOrder = [];
  const cssStringsForMediaQueriesWithOrder = []; // Generate strings for fancy styles with no media queries

  Object.keys(dataByMediaQuery.stylesWithNoMediaQuery).forEach(domNodeSelector => {
    const styleDeclarationsForThisNodeSelector = dataByMediaQuery.stylesWithNoMediaQuery[domNodeSelector];
    const cssStringForThisNode = generateCssStringForNode(styleDeclarationsForThisNodeSelector);
    cssStringsForLayoutStylesWithNoMediaQuery.push(cssStringForThisNode);
  }); // Generate strings for fancy styles with media queries that are not associated with a breakpoint

  Object.keys(dataByMediaQuery.mediaQueryWithNoOrder).forEach(mediaQuery => {
    let cssStringForThisMediaQuery = `\n${mediaQuery} {\n`;
    let hasStylesForThisMediaQuery = false;
    Object.keys(dataByMediaQuery.mediaQueryWithNoOrder[mediaQuery]).forEach(domNodeSelector => {
      const styleDeclarationsForThisNodeSelector = dataByMediaQuery.mediaQueryWithNoOrder[mediaQuery][domNodeSelector];
      const cssStringForThisNode = generateCssStringForNode(styleDeclarationsForThisNodeSelector);
      cssStringForThisMediaQuery += cssStringForThisNode;
      hasStylesForThisMediaQuery = true;
    });
    cssStringForThisMediaQuery += '\n}\n';

    if (hasStylesForThisMediaQuery) {
      cssStringsForMediaQueriesWithNoOrder.push(cssStringForThisMediaQuery);
    }
  }); // Generate strings for fancy styles associated with breakpoints, these have a specific theme defined order we need to output htem in

  dataByMediaQuery.mediaQueryWithSpecificOrder.forEach(mediaQuery => {
    let cssStringForThisMediaQuery = `\n/* Media Query Styles (${mediaQuery.mediaQueryName}) */`;
    cssStringForThisMediaQuery += mediaQuery.mediaQuery ? `\n${mediaQuery.mediaQuery} {\n` : '';
    let hasStylesForThisMediaQuery = false;
    Object.keys(mediaQuery.styleRules).forEach(domNodeSelector => {
      const styleDeclarationsForThisNodeSelector = mediaQuery.styleRules[domNodeSelector];
      const cssStringForThisNode = generateCssStringForNode(styleDeclarationsForThisNodeSelector);
      cssStringForThisMediaQuery += cssStringForThisNode;
      hasStylesForThisMediaQuery = true;
    });
    cssStringForThisMediaQuery += mediaQuery.mediaQuery ? '\n}\n' : '\n';

    if (hasStylesForThisMediaQuery) {
      cssStringsForMediaQueriesWithOrder.push(cssStringForThisMediaQuery);
    }
  });
  let finalLayoutStyleCssString = '';
  cssStringsForLayoutStylesWithNoMediaQuery.forEach(cssStringForNode => {
    finalLayoutStyleCssString += cssStringForNode;
  });
  cssStringsForMediaQueriesWithNoOrder.forEach(cssStringForMediaQuery => {
    finalLayoutStyleCssString += cssStringForMediaQuery;
  });
  cssStringsForMediaQueriesWithOrder.forEach(cssStringForMediaQuery => {
    finalLayoutStyleCssString += cssStringForMediaQuery;
  });
  return finalLayoutStyleCssString;
}