'use es6';

import { generateLayoutDataForDefaultSection } from 'ContentEditorUI/utils/layoutSectionTreeUtils';
export const LAYOUTS = {
  ONE_COLUMN: 'ONE_COLUMN',
  TWO_COLUMN: 'TWO_COLUMN',
  THREE_COLUMN: 'THREE_COLUMN',
  ONE_TWO_THIRDS_COLUMN: 'ONE_TWO_THIRDS_COLUMN',
  TWO_ONE_THIRDS_COLUMN: 'TWO_ONE_THIRDS_COLUMN',
  FOUR_COLUMN: 'FOUR_COLUMN'
};
export const LAYOUT_COLUMNS = {
  [LAYOUTS.ONE_COLUMN]: {
    widths: [12]
  },
  [LAYOUTS.TWO_COLUMN]: {
    widths: [6, 6]
  },
  [LAYOUTS.THREE_COLUMN]: {
    widths: [4, 4, 4]
  },
  [LAYOUTS.ONE_TWO_THIRDS_COLUMN]: {
    widths: [4, 8]
  },
  [LAYOUTS.TWO_ONE_THIRDS_COLUMN]: {
    widths: [8, 4]
  },
  [LAYOUTS.FOUR_COLUMN]: {
    widths: [3, 3, 3, 3]
  }
};
export const generateLayoutDataForAllDefaultColumns = () => {
  return {
    [LAYOUTS.ONE_COLUMN]: generateLayoutDataForDefaultSection(LAYOUT_COLUMNS[LAYOUTS.ONE_COLUMN].widths),
    [LAYOUTS.TWO_COLUMN]: generateLayoutDataForDefaultSection(LAYOUT_COLUMNS[LAYOUTS.TWO_COLUMN].widths),
    [LAYOUTS.THREE_COLUMN]: generateLayoutDataForDefaultSection(LAYOUT_COLUMNS[LAYOUTS.THREE_COLUMN].widths),
    [LAYOUTS.ONE_TWO_THIRDS_COLUMN]: generateLayoutDataForDefaultSection(LAYOUT_COLUMNS[LAYOUTS.ONE_TWO_THIRDS_COLUMN].widths),
    [LAYOUTS.TWO_ONE_THIRDS_COLUMN]: generateLayoutDataForDefaultSection(LAYOUT_COLUMNS[LAYOUTS.TWO_ONE_THIRDS_COLUMN].widths),
    [LAYOUTS.FOUR_COLUMN]: generateLayoutDataForDefaultSection(LAYOUT_COLUMNS[LAYOUTS.FOUR_COLUMN].widths)
  };
};