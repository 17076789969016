'use es6';

import { fromJS } from 'immutable';
import { getAllValuesFromSuperstoreAsMap } from 'ContentEditorUI/lib/superstore';
import { USER_SEEN_STATE_SETTINGS_OBJECT_KEY, ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE, SHOULD_START_FREE_LP_ONBOARDING_TOUR } from './constants';
export const ensureEarlyRequestedAttributesMatch = attributesFromRegularSource => {
  const earlySourceString = window.hubspot.SHARED_EDITOR_USER_ATTRIBUTES_QUICK_FETCHED.keys.join(',');
  const regularSourceString = attributesFromRegularSource.join(',');

  if (earlySourceString !== regularSourceString) {
    throw new Error('SHARED_EDITOR_USER_ATTRIBUTES_QUICK_FETCHED does not match ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE');
  }
};
export const prepareIntitialSettingsStateFromSuperstores = (userSettingsSuperstorePromise, perContentSettingsSuperstorePromise) => {
  // First resolve store promises to stores
  return Promise.all([userSettingsSuperstorePromise, perContentSettingsSuperstorePromise]).then(([userSettingsSuperstore, perContentSettingsSuperstore]) => {
    // Then get all of the key-value pairs we want from each store
    return Promise.all([getAllValuesFromSuperstoreAsMap(userSettingsSuperstore, ALL_USER_ATTRIBUTE_KEYS_IN_SUPERSTORE), getAllValuesFromSuperstoreAsMap(perContentSettingsSuperstore, ALL_LOCALLY_CACHED_PER_CONTENT_ATTRIBUTE_KEYS)]).then(([mapOfUserSettings, mapOfPerContentSettings]) => {
      // And then finally convert those to maps in the shape the locallyCachedSettingsReducer expects
      return fromJS({
        perUser: mapOfUserSettings,
        perContent: mapOfPerContentSettings
      });
    }).catch(e => {
      console.error('Error in preparing initial state from superstore: ', e);
    });
  }).catch(error => {
    console.error('Error loading initial setting from superstore (but continuing to load app without them)', error); // Deal with errors here instead of making Application.js need to worry about this promise failing
    // and blocking the app from loading

    return fromJS({
      errorLoadingSettings: error.message,
      perUser: {},
      perContent: {}
    });
  });
};
export const parseUserAttributes = attributes => {
  const parsedAttributes = {};
  ALL_USER_ATTRIBUTE_KEYS_IN_DATABASE_AND_SUPERSTORE.forEach(key => {
    if (attributes.hasOwnProperty(key)) {
      let value = attributes[key];

      if (key === USER_SEEN_STATE_SETTINGS_OBJECT_KEY) {
        value = JSON.parse(value); // We assume all child keys are date values

        Object.keys(value).forEach(nestedKey => {
          value[nestedKey] = new Date(value[nestedKey]);
        });
      } else if (key === SHOULD_START_FREE_LP_ONBOARDING_TOUR) {
        value = value === 'true';
      } else {
        throw new Error(`Did not know how to parse ${key} in parseUserAttributes`);
      }

      parsedAttributes[key] = value;
    }
  });
  return parsedAttributes;
};