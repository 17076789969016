import { createSelector } from 'reselect';
import { createGenericOptionsSelector, tryParseNumber } from 'ContentData/helpers/reduxHelpers';
export const getEmails = state => state.resources.emails.emails;
export const getEmail = (state, props) => state.resources.emails.emails[props.id];
export const getEmailCache = (state, props) => state.resources.emails.emailsCache[props.id];
export const getEmailsCache = state => state.resources.emails.emailsCache;
export const getEmailsCacheRequestStatus = state => state.resources.emails.emailsCacheRequestStatus;
export const getEmailFetchStatus = (state, props) => state.resources.emails.requestStatus[props.id];
export const getEmailOptions = createGenericOptionsSelector(getEmailsCache);
export const getDefaultEmailOptions = createGenericOptionsSelector(getEmails);
export const getAutomatedEmailOptions = createSelector([getEmailsCache], emailsCache => {
  return Object.keys(emailsCache).reduce((emailOptions, emailId) => {
    const email = emailsCache[emailId];
    const {
      emailType,
      name = '',
      isPublished
    } = email || {};

    if (emailType === 'AUTOMATED_EMAIL' || emailType === 'AUTOMATED_AB_EMAIL') {
      emailOptions.push({
        value: tryParseNumber(emailId),
        text: name,
        isPublished
      });
    }

    return emailOptions;
  }, []);
});
export const makeGetIsEmailValid = () => {
  return createSelector([getEmail], email => email && email.isPublished);
};
export const makeGetIsEmailClassic = () => {
  return createSelector([getEmail], email => email && email.emailTemplateMode === 'DESIGN_MANAGER');
};