import { doesElementExist } from './elementUtil';
/**
 * Get an element query for the attachTo config object.
 *
 */

export const getAttachToElementQuery = attachTo => {
  if (!attachTo || typeof attachTo.elementQuery !== 'string') {
    return undefined;
  }

  const {
    elementQuery,
    fallbackElementQuery
  } = attachTo; // Use the fallback element option if it is available and elementQuery doesn't exist on the dom

  if (!doesElementExist(elementQuery) && fallbackElementQuery) {
    return fallbackElementQuery;
  }

  return elementQuery;
};