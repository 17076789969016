'use es6';

import { createSelector } from 'reselect';
import { getIsEmail } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { ContentTypes as SmartContentTypes } from 'smart-content-lib/constants';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getSmartView = basicSelector(state => state.smartView);

const createSmartViewSelector = field => {
  return createSelector([getSmartView], smartView => smartView.get(field));
};

export const getSmartViewCriterionId = createSmartViewSelector('criterionId');
export const getSmartViewContentType = createSelector([getIsEmail], isEmail => {
  if (isEmail) {
    return SmartContentTypes.EMAIL;
  }

  return SmartContentTypes.PAGE;
});
export const getSmartViewModuleId = createSmartViewSelector('moduleId');
export const getSmartViewOrder = createSmartViewSelector('order');
export const getSmartViewTargetType = createSmartViewSelector('targetType');
export const getShouldShowSmartRulePreviewToolbar = createSelector(getSmartViewCriterionId, smartViewCriterionId => !!smartViewCriterionId);
export const getSmartViewUpdatedAt = createSmartViewSelector('smartViewUpdatedAt');