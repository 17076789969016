import { useEffect, useState } from 'react';
import Raven from 'Raven';
import { SUITE_STARTER, SUITE_PROFESSIONAL, SUITE_ENTERPRISE } from 'self-service-api/constants/UpgradeProducts';
import { BUY_TOUCHLESS } from 'ui-addon-upgrades/_core/purchaseMotions/PurchaseMotionTypes';
import { getIsPartnerHub } from '../eligibility/getIsPartner';
import { getIsHsfxEligible } from '../eligibility/getIsHsfxEligible'; // @ts-expect-error dependency missing types

import { getFullCheckoutQaOverride } from 'ui-addon-upgrades/_core/utils/getFullCheckoutQaOverride';
import { getParsedProductPurchaseMotions } from 'ui-addon-upgrades/_core/common/data/purchaseMotionData/getParsedProductPurchaseMotions'; // @ts-expect-error dependency missing types

import UpgradeProductToApiNameMap from 'self-service-api/constants/UpgradeProductToApiNameMap';
const CHECKOUT_INELIGIBLE_APPS = ['account-and-billing']; // TODO remove suite upgrade products from ineligible list when true bundling complete.

const CHECKOUT_INELIGIBLE_UPGRADE_PRODUCTS = [SUITE_STARTER, SUITE_PROFESSIONAL, SUITE_ENTERPRISE];

const isCheckoutIneligibleUpgradeProduct = upgradeProduct => {
  return CHECKOUT_INELIGIBLE_UPGRADE_PRODUCTS.includes(upgradeProduct);
};

const isCheckoutIneligibleApp = app => {
  return CHECKOUT_INELIGIBLE_APPS.includes(app);
};

const shouldExcludeFromFullCheckout = (app, upgradeProduct) => {
  return isCheckoutIneligibleUpgradeProduct(upgradeProduct) || isCheckoutIneligibleApp(app);
};

export const useFullCheckoutEligibility = ({
  upgradeProduct,
  location,
  app = ''
}) => {
  const [isEligibleForFullCheckout, setIsEligibleForFullCheckout] = useState(null);
  useEffect(() => {
    if (getFullCheckoutQaOverride()) {
      setIsEligibleForFullCheckout(true);
      return;
    }

    if (shouldExcludeFromFullCheckout(app, upgradeProduct)) {
      setIsEligibleForFullCheckout(false);
      return;
    }

    Promise.all([getIsPartnerHub(), getIsHsfxEligible(), getParsedProductPurchaseMotions({
      apiName: UpgradeProductToApiNameMap[upgradeProduct],
      location
    })]).then(([isPartnerHub, isHsfxEligible, purchaseMotions]) => {
      const canBuyTouchless = purchaseMotions.includes(BUY_TOUCHLESS);
      const isFullCheckoutEligible = !isPartnerHub && !isHsfxEligible && canBuyTouchless;
      setIsEligibleForFullCheckout(isFullCheckoutEligible);
    }).catch(error => {
      Raven.captureException(error, {
        extra: {
          message: 'Unable to fetch full checkout eligibility'
        }
      });
      setIsEligibleForFullCheckout(false);
    });
  }, [app, upgradeProduct, location]);
  return isEligibleForFullCheckout;
};