'use es6';

import Immutable from 'immutable';
import { createSelector } from 'reselect';
import { getThemeObject, getThemeGlobalSettings } from 'ContentEditorUI/redux/selectors/themeSelectors';
import { getModuleLists } from 'ContentEditorUI/redux/selectors/moduleSelectorHelpers';
import { getGoogleFontsMap } from 'theme-previewer-lib/utils/fonts'; // Splitting these theme selectors off to prevent some circular selector dependencies

export const getPlainThemePageOverrides = createSelector([getModuleLists], moduleLists => {
  return moduleLists.get('themePageOverrides');
});
export const getThemePageOverrides = createSelector([getPlainThemePageOverrides], plainThemePageOverrides => {
  return Immutable.fromJS(plainThemePageOverrides);
});
export const getComputedThemeSettings = createSelector([getThemeGlobalSettings, getThemePageOverrides], (themeGlobalSettings, themePageOverrides) => {
  return themeGlobalSettings.mergeDeep(themePageOverrides);
});
export const getThemeGoogleFontsMap = createSelector([getThemeObject, getComputedThemeSettings], (theme, computedThemeSettings) => {
  const fields = theme.get('fields') ? theme.get('fields').toJS() : [];
  return getGoogleFontsMap(fields, computedThemeSettings.toJS());
});