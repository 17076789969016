import { createGenericFetchAction, createGenericCreateAction } from 'ContentData/helpers/reduxHelpers';
import * as emailsClient from 'ContentData/api/Emails';
import { getEmailCache } from 'ContentData/selectors/emails';
import { EMAILS_FETCH_EMAIL, EMAILS_FETCH_EMAILS, EMAILS_FETCH_AUTOMATED_EMAILS, EMAILS_SAVE_EMAIL_CACHE, EMAILS_CLEAR_CACHE, EMAILS_CREATE_EMAIL } from 'ContentData/actions/ActionTypes';
const fetchEmailAction = createGenericFetchAction(EMAILS_FETCH_EMAIL, emailsClient.fetchEmail);
const fetchEmailsAction = createGenericFetchAction(EMAILS_FETCH_EMAILS, emailsClient.fetchEmails);
const fetchAutomatedEmailsAction = createGenericFetchAction(EMAILS_FETCH_AUTOMATED_EMAILS, emailsClient.fetchAutomatedEmails);
const createEmailAction = createGenericCreateAction(EMAILS_CREATE_EMAIL, emailsClient.createEmail);
export function fetchEmail(id) {
  return fetchEmailAction(id);
}
export function fetchEmails(query) {
  return fetchEmailsAction(query);
}
export function fetchAutomatedEmails(query) {
  return fetchAutomatedEmailsAction(query);
}
export function createEmail(name) {
  return createEmailAction(name);
}
export function saveCache(id) {
  return (dispatch, getState) => {
    const email = getEmailCache(getState(), {
      id
    });
    dispatch({
      type: EMAILS_SAVE_EMAIL_CACHE,
      email
    });
  };
}
export function clearCache() {
  return {
    type: EMAILS_CLEAR_CACHE
  };
}
export const fetchWorkflowFollowupEmails = followupEmailWorkflows => {
  const {
    request,
    receive,
    error
  } = fetchWorkflowFollowupEmails;
  return dispatch => {
    const emailIds = followupEmailWorkflows.reduce((_emailIds, workflow) => {
      const emailId = workflow.emailId;
      if (emailId) _emailIds.push(emailId);
      return _emailIds;
    }, []);

    if (!emailIds.length) {
      return;
    }

    dispatch(request());
    emailsClient.fetchEmails({
      id__in: emailIds,
      property: ['id', 'name', 'emailType', 'isPublished'],
      state__in: undefined
    }).then(response => {
      dispatch(receive(response));
      return response;
    }).catch(e => {
      dispatch(error(e));
      return e;
    });
  };
};

fetchWorkflowFollowupEmails.request = () => {
  return {
    type: EMAILS_FETCH_EMAILS
  };
};

fetchWorkflowFollowupEmails.receive = response => {
  return {
    type: EMAILS_FETCH_EMAILS,
    response
  };
};

fetchWorkflowFollowupEmails.error = error => {
  return {
    type: EMAILS_FETCH_EMAILS,
    error
  };
};