const apiProxyList = [{
  regex: new RegExp('^blog-settings/v1/blogs(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^blog-settings/v1/blogs$'),
  methods: ['GET']
}, {
  regex: new RegExp('^ctas/v3/placements(/[0-9]+)$'),
  methods: ['GET']
}, {
  regex: new RegExp('^ctas/v3/placements$'),
  methods: ['GET']
}, {
  regex: new RegExp('^cosemail/v1/emails(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^cosemail/v1/emails$'),
  methods: ['GET', 'POST']
}, {
  regex: new RegExp('^forms/v2/forms(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^forms/v2/forms$'),
  methods: ['GET', 'POST']
}, {
  regex: new RegExp('^forms/v2/forms/count$'),
  methods: ['GET']
}, {
  regex: new RegExp('^content/api/v4/contents/linkable-pages(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^meetings/v1/link/slug(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^meetings/v2/link/slug(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^meetings/v1/link/portal$'),
  methods: ['GET']
}, {
  regex: new RegExp('^meetings/v2/link/portal$'),
  methods: ['GET']
}, {
  regex: new RegExp('^gotowebinar/v1/settings$'),
  methods: ['GET']
}, {
  regex: new RegExp('^forms/v2/forms/gotowebinar-eligible$'),
  methods: ['GET']
}, {
  regex: new RegExp('^gotowebinar/v1/webinars/future?includeOnDemand=true$'),
  methods: ['GET']
}, {
  regex: new RegExp('^hubdb/api/v2/tables(/[0-9]+)?'),
  methods: ['GET']
}, {
  regex: new RegExp('^hubdb/api/v2/tables$'),
  methods: ['GET']
}, {
  regex: new RegExp('^content-icons/v1$'),
  methods: ['GET']
}, {
  regex: new RegExp('^designmanager/v1/menus(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^designmanager/v1/menus'),
  methods: ['GET', 'POST']
}, {
  regex: new RegExp('^sfdc/v1/integration-status$'),
  methods: ['GET']
}, {
  regex: new RegExp('^sfdc/v1/campaigns(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^content-settings/v1/site-settings/by-portal-id(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^app-users/v1/teams/hierarchy$'),
  methods: ['GET']
}, {
  regex: new RegExp('^users/v2/app/hub-users$'),
  methods: ['GET']
}, {
  regex: new RegExp('^automation/v3/workflows$'),
  methods: ['GET']
}, {
  regex: new RegExp('^blogs/v3/tags(/[0-9]+)?$'),
  methods: ['GET']
}, {
  regex: new RegExp('^blogs/v3/tags/search$'),
  methods: ['GET']
}, {
  regex: new RegExp('^filemanager/api/v3/players/(/[0-9]+)/videos/file-metadata$'),
  methods: ['GET']
}, {
  regex: new RegExp('^cosemail/v1/rssfeeds/validate'),
  methods: ['GET']
}, {
  regex: new RegExp('^feedback/v1/survey/page'),
  methods: ['GET']
}, {
  regex: new RegExp('^feedback/v1/(nps|ces|csat)/([0-9]+)'),
  methods: ['GET']
}, {
  regex: new RegExp('^feedback/v1/(nps|ces|csat)/(enable|disable)/([0-9]+)'),
  methods: ['PUT']
}, {
  regex: new RegExp('^inbounddb-objects/v1/crm-objects'),
  methods: ['GET']
}, {
  regex: new RegExp('^crm-object-schemas/v3/schemas'),
  methods: ['GET']
}, {
  regex: new RegExp('^crm-search/search'),
  methods: ['POST']
}, {
  regex: new RegExp('^inbounddb-meta/v1/object-types/for-portal'),
  methods: ['GET']
}, {
  regex: new RegExp('^extensions/ecomm/v2/ui/stores'),
  methods: ['GET']
}, {
  regex: new RegExp('^folders/v1/search/external-sync-sources-with-products'),
  methods: ['GET']
}, {
  regex: new RegExp('^oembedproxy/v1/oembed'),
  methods: ['GET']
}, {
  regex: new RegExp('^oembedproxy/v1/app-oembed'),
  methods: ['GET']
}, {
  regex: new RegExp('^media-bridge/v1/providers'),
  methods: ['GET', 'POST']
}];
export default apiProxyList;