import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';
// This is used to submit product_upgrade_interest to 53
// If a new value is added here, let #marketing-ops know so they can add it in 53
export const productUpgradeInterest = {
  [UpgradeProducts.GENERAL]: 'General',
  [UpgradeProducts.SALES_PRO]: 'Sales-Pro',
  [UpgradeProducts.SALES_STARTER]: 'Sales-Starter',
  [UpgradeProducts.SALES_PROFESSIONAL]: 'Sales-Professional',
  [UpgradeProducts.SALES_ENTERPRISE]: 'Sales-Enterprise',
  [UpgradeProducts.SALES_FREE]: 'Sales-Free',
  [UpgradeProducts.MARKETING_FREE]: 'Marketing-Free',
  [UpgradeProducts.MARKETING_STARTER]: 'Marketing-Starter',
  [UpgradeProducts.MARKETING_STARTER_EMAIL]: 'Marketing-Starter',
  [UpgradeProducts.MARKETING_BASIC]: 'Marketing-Basic',
  [UpgradeProducts.MARKETING_PRO]: 'Marketing-Pro',
  [UpgradeProducts.MARKETING_ENTERPRISE]: 'Marketing-Ent',
  [UpgradeProducts.SERVICE_FREE]: 'Service-Free',
  [UpgradeProducts.SERVICE_STARTER]: 'Service-Starter',
  [UpgradeProducts.SERVICE_PROFESSIONAL]: 'Service-Professional',
  [UpgradeProducts.SERVICE_ENTERPRISE]: 'Service-Enterprise',
  [UpgradeProducts.STARTER_CONTACTS]: 'Starter-Contacts',
  [UpgradeProducts.CMS_PROFESSIONAL]: 'CMS-Professional',
  [UpgradeProducts.CMS_ENTERPRISE]: 'CMS-Enterprise',
  [UpgradeProducts.ADS]: 'Marketing-Ads',
  [UpgradeProducts.ADS_CAPACITY]: 'Ads-Capacity',
  [UpgradeProducts.WEBSITE]: 'Marketing-Website',
  [UpgradeProducts.REPORTING]: 'reporting',
  [UpgradeProducts.DEDICATED_IP]: 'Marketing-Dedicated IP',
  [UpgradeProducts.TRANSACTIONAL_EMAIL]: 'Marketing-Transactional Email',
  [UpgradeProducts.ADDITIONAL_ACCOUNT]: 'Marketing-Additional Portal',
  [UpgradeProducts.BRAND_DOMAIN]: 'Brand-Domain',
  [UpgradeProducts.DESIGNATED_TECHNICAL_SUPPORT]: 'Designated-Technical-Support',
  [UpgradeProducts.IN_PERSON_TRAINING]: 'Training-In-Person',
  [UpgradeProducts.STARTER_KIT]: 'Starter Kit',
  [UpgradeProducts.SALES_PROFESSIONAL_ONBOARDING]: 'Sales Professional Onboarding',
  [UpgradeProducts.SERVICE_PROFESSIONAL_ONBOARDING]: 'Service Professional Onboarding',
  [UpgradeProducts.INBOUND_CONSULTING_BLOCK]: 'Inbound Consulting Block',
  [UpgradeProducts.TECHNICAL_CONSULTING_BLOCK]: 'Technical Consulting Block',
  [UpgradeProducts.ENTERPRISE]: 'Enterprise',
  [UpgradeProducts.PARTNER_PROGRAM]: 'Partner-Program',
  [UpgradeProducts.SUITE_STARTER]: 'Starter Suite',
  [UpgradeProducts.SUITE_PROFESSIONAL]: 'Professional Suite',
  [UpgradeProducts.SUITE_ENTERPRISE]: 'Enterprise Suite',
  [UpgradeProducts.BUNDLE]: 'Bundle'
};