'use es6';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getHasInpagePreviewUnexpectedlyUnloaded } from 'ContentEditorUI/redux/selectors/inpageSelectors';

const mapStateToProps = state => ({
  contentModelId: getContentModelId(state),
  hasInpagePreviewUnexpectedlyUnloaded: getHasInpagePreviewUnexpectedlyUnloaded(state)
});

class ShowUnexpectedPreviewUnloadWarning extends Component {
  componentDidMount() {
    const {
      hasInpagePreviewUnexpectedlyUnloaded
    } = this.props;
    this.mounted = true;

    if (hasInpagePreviewUnexpectedlyUnloaded) {
      this.showWarningAndTrackInNewRelicOnDelay();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      hasInpagePreviewUnexpectedlyUnloaded
    } = this.props;

    if (!prevProps.hasInpagePreviewUnexpectedlyUnloaded && hasInpagePreviewUnexpectedlyUnloaded) {
      this.showWarningAndTrackInNewRelicOnDelay();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  showWarningAndTrackInNewRelicOnDelay() {
    // Set a timeout so that if the customer is navigating away from the _parent app_ (which will
    // cause the iframe to unload), we never end up showing the warning or tracking (because then
    // the unload is expected)
    setTimeout(() => {
      if (this.mounted) {
        this.showWarning();
        this.trackInNewRelic();
      }
    }, 1000);
  }

  showWarning() {
    console.error('It looks like the preview iframe unexpected redirected to another URL');
  }

  trackInNewRelic() {
    const {
      contentModelId
    } = this.props; // Send a new browser interaction to new relic for failed inpage assets (once per editor session)
    // https://insights.newrelic.com/accounts/1393184/query?query=SELECT%20browserInteractionName,%20contentId%20%20%20%20from%20BrowserInteraction%20where%20browserInteractionName%20%3D%20%27inpage-preview-unexpectedly-redirected%27%20since%207%20days%20ago&hello=overview

    if (this.alreadyLoggedToNewRelicOnce) {
      return;
    }

    this.alreadyLoggedToNewRelicOnce = true;

    if (window.newrelic && window.newrelic.interaction) {
      const nrInteraction = window.newrelic.interaction(); // Our NR wrapper will make interaction() return undefined when not deployed

      if (nrInteraction) {
        nrInteraction.setName('inpage-preview-unexpectedly-redirected').setAttribute('contentId', contentModelId).save();
      }
    }
  }

  render() {
    return null;
  }

}

ShowUnexpectedPreviewUnloadWarning.propTypes = {
  contentModelId: PropTypes.number.isRequired,
  hasInpagePreviewUnexpectedlyUnloaded: PropTypes.bool.isRequired
};
export default connect(mapStateToProps)(ShowUnexpectedPreviewUnloadWarning);