/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
import * as Scheduler from 'scheduler';
export const requestIdleCallback = window.requestIdleCallback || setTimeout;
export const cancelIdleCallback = window.cancelIdleCallback || clearTimeout;
export const requestSchedulerCallback = callback => Scheduler.unstable_scheduleCallback(Scheduler.unstable_NormalPriority, callback);
export const cancelSchedulerCallback = id => {
  if (id) {
    Scheduler.unstable_cancelCallback(id);
  }
};
/**
 * Debounce function that uses Promise resolution for timing, resulting in a shorter delay than
 * `setTimeout()` in modern browsers.
 *
 * Borrowed from Popper.js:
 * https://github.com/popperjs/popper-core/blob/be548db65912e1126581a44faa7f758ff004680a/src/utils/debounce.js
 *
 * @param {Function} callback
 */

export const debounceByTick = callback => {
  let pending;
  return () => {
    if (!pending) {
      pending = new Promise(resolve => {
        Promise.resolve().then(() => {
          pending = undefined;
          resolve(callback());
        });
      });
    }

    return pending;
  };
};