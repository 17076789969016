import I18n from 'I18n';
export const getTranslatedApprovalType = approvalType => {
  return I18n.text(`productApprovals.approvalTypes.${approvalType}`);
};
export const getTranslationsForTypeAndAction = (approvalType, approvalAction) => {
  const typeActionString = `${approvalType}-${approvalAction}`;
  return {
    ApprovalModalMessage: {
      REQUEST: 'productApprovals.ApprovalModalMessage.REQUEST',
      RECALL: 'productApprovals.ApprovalModalMessage.RECALL',
      APPROVE: `productApprovals.ApprovalModalMessage.APPROVE.${typeActionString}`
    },
    ApprovalEmptyState: {
      title: `productApprovals.ApprovalEmptyState.${typeActionString}.title`,
      firstSection: `productApprovals.ApprovalEmptyState.${typeActionString}.firstSection`,
      secondSection: `productApprovals.ApprovalEmptyState.${typeActionString}.secondSection`
    },
    PendingApprovalTooltipHeadingText: {
      REQUESTER: 'productApprovals.PendingApprovalTooltipHeadingText.REQUESTER',
      APPROVER: 'productApprovals.PendingApprovalTooltipHeadingText.APPROVER',
      NON_PARTICIPANT: `productApprovals.PendingApprovalTooltipHeadingText.NON_PARTICIPANT.${typeActionString}`
    },
    PendingApprovalTooltipTitle: {
      approversList: 'productApprovals.PendingApprovalTooltipTitle.approversList',
      body: {
        REQUESTER: `productApprovals.PendingApprovalTooltipTitle.body.${typeActionString}.REQUESTER`,
        APPROVER: `productApprovals.PendingApprovalTooltipTitle.body.${typeActionString}.APPROVER`,
        NON_PARTICIPANT: `productApprovals.PendingApprovalTooltipTitle.body.${typeActionString}.NON_PARTICIPANT`
      }
    },
    ApprovalNotRequested: {
      header: 'productApprovals.ApprovalNotRequested.header',
      message: `productApprovals.ApprovalNotRequested.${typeActionString}.message`
    },
    ApprovalStepsList: {
      title: `productApprovals.ApprovalStepsList.${typeActionString}.title`
    }
  };
};