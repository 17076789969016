'use es6';

import { Map as ImmutableMap } from 'immutable';
import { SHOULD_CLEAR_HEADER_ACTIVE_ITEM, UPDATE_HEADER_ACTIVE_ITEM } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = ImmutableMap({
  activeItem: '',
  shouldClear: false
});

const headerReducer = (state = initialState, {
  payload,
  type
}) => {
  switch (type) {
    case UPDATE_HEADER_ACTIVE_ITEM:
      {
        return state.set('activeItem', payload);
      }

    case SHOULD_CLEAR_HEADER_ACTIVE_ITEM:
      return state.set('shouldClear', payload);

    default:
      return state;
  }
};

export default headerReducer;