import { EVENTS } from '../constants/TrackingConstants';
import { UsageTracker, ExternalLinkUsageTracker } from './usageTracker';
const DONE_STEP = 'done';
let currentStepId;
let currentTourId; // Fires when a tour step changes

const stepChanged = (stepId, tourId) => {
  if (stepId === DONE_STEP) {
    // Track the last step the user finished the tour on
    ExternalLinkUsageTracker.track(EVENTS.TOUR_FINISH, {
      stepId: currentStepId,
      tourId
    });
  } else if (stepId === null && tourId === null) {
    UsageTracker.track(EVENTS.TOUR_CLOSE, {
      stepId: currentStepId,
      tourId: currentTourId
    });
  } else {
    currentStepId = stepId;
    currentTourId = tourId;
  }
};

export const handleCompletionTracking = tour => {
  tour.subscribe(stepChanged);
};
export const externalLinkClicked = (stepId, tourId) => {
  ExternalLinkUsageTracker.track(EVENTS.TOUR_CLICK_EXTERNAL_STEP_LINK, {
    stepId,
    tourId
  });
};