'use es6';

import * as actionTypes from 'page-editor-ui/redux/actions/actionTypes';
import { FETCH_CONTENT_SCHEMA_SUCCEEDED, UPDATE_SETTINGS_SLUG_RAW, UPDATE_CONTENT_MODEL_DOMAIN, FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED } from 'ContentEditorUI/redux/actions/actionTypes';
import Immutable from 'immutable';
import { getBooleanOrNull } from 'ContentEditorUI/utils/helpers';
import { isTemporarySlug, buildSlugWithLang, buildLanguageString } from 'ContentEditorUI/utils/contentModelURLUtils';
import withBaseContentModel from 'ContentEditorUI/redux/reducers/contentModel/withBaseContentModel';
import featuredImageReducer from 'ContentEditorUI/redux/reducers/contentModel/featuredImageReducer';
import pagePublishReducer from 'page-editor-ui/redux/reducers/pagePublishReducer';
import { updatePublicAccessRulesWithLists, updateStateWithAudienceAccess } from 'ContentEditorUI/utils/audienceAccessUtils';
import { parseCrmObjectDynamicPageDataSourceIdSimpleName } from '../../utils/dynamicPagesUtils';
import { CRM_SOURCE_TYPE } from '../../constants/DynamicPagesDataSource';

const pageContentModelReducer = (state = Immutable.Map(), {
  type,
  payload,
  response
}) => {
  switch (type) {
    case actionTypes.ADD_PAGE_CONTENT_MODEL_PAGE_STYLESHEET:
      return state.set('pageStylesheets', state.get('pageStylesheets').push(payload));

    case FETCH_CONTENT_SCHEMA_SUCCEEDED:
      {
        const {
          content
        } = response;
        return state.merge({
          css: content.css,
          cssText: content.cssText,
          dynamicPageDataSourceId: content.dynamicPageDataSourceType === CRM_SOURCE_TYPE ? parseCrmObjectDynamicPageDataSourceIdSimpleName(content.dynamicPageDataSourceId) : content.dynamicPageDataSourceId,
          dynamicPageDataSourceType: content.dynamicPageDataSourceType,
          enableDomainStylesheets: content.enableDomainStylesheets,
          enableLayoutStylesheets: content.enableLayoutStylesheets,
          footerHtml: content.footerHtml,
          headHtml: content.headHtml,
          includeDefaultCustomCss: content.includeDefaultCustomCss,
          pageStylesheets: content.attachedStylesheets,
          password: content.password,
          propertyForDynamicPageCanonicalUrl: content.propertyForDynamicPageCanonicalUrl,
          propertyForDynamicPageFeaturedImage: content.propertyForDynamicPageFeaturedImage,
          propertyForDynamicPageMetaDescription: content.propertyForDynamicPageMetaDescription,
          propertyForDynamicPageTitle: content.propertyForDynamicPageTitle,
          propertyForDynamicPageSlug: content.propertyForDynamicPageSlug,
          publicAccessRules: Immutable.fromJS(content.publicAccessRules),
          publicAccessRulesEnabled: content.publicAccessRulesEnabled,
          requirePassword: content.password !== null,
          slug: content.htmlTitle && isTemporarySlug(content.slug) ? '' : content.slug,
          translatedFromId: content.translatedFromId,
          subcategory: content.subcategory,
          scheduledUpdateDate: content.scheduledUpdateDate // TODO: temporarily, we get this value from the buffer later to be added in `context.hard_content_info` so that we can get this value from hard saved content model which will be more accurate.

        });
      }

    case FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED:
      return state.set('scheduledUpdateDate', payload.scheduledUpdateDate);
    // TODO: temporarily using hard save requests after publish to get this value stored here in the redux correctly. Same for blog posts.

    case actionTypes.FETCH_PAGE_STYLESHEET_INFO_SUCCEEDED:
      return state.merge({
        defaultCustomCssPath: payload.defaultCustomCssPath
      });

    case actionTypes.REMOVE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET:
      return state.set('pageStylesheets', state.get('pageStylesheets').filter(stylesheet => stylesheet.get('id') !== payload));

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_DEFAULT_CSS_STYLESHEETS:
      return state.set('includeDefaultCustomCss', getBooleanOrNull(payload));

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_DOMAIN_STYLESHEETS:
      return state.set('enableDomainStylesheets', getBooleanOrNull(payload));

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_ENABLE_LAYOUT_STYLESHEETS:
      return state.set('enableLayoutStylesheets', getBooleanOrNull(payload));

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_FOOTER_HTML:
      return state.set('footerHtml', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_HEAD_HTML:
      return state.set('headHtml', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_HUBDB_TABLE:
      return state.set('dynamicPageHubDbTableId', payload ? payload.id : null);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_TYPE:
      return state.set('dynamicPageDataSourceType', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SOURCE_ID:
      return state.set('dynamicPageDataSourceId', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_SLUG:
      return state.set('propertyForDynamicPageSlug', payload.toString());

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_TITLE:
      return state.set('propertyForDynamicPageTitle', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_META:
      return state.set('propertyForDynamicPageMetaDescription', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_URL:
      return state.set('propertyForDynamicPageCanonicalUrl', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_DYNAMIC_PAGE_IMAGE:
      return state.set('propertyForDynamicPageFeaturedImage', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PAGE_STYLESHEET_ORDER:
      return state.set('pageStylesheets', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_REQUIRE_PASSWORD:
      return state.set('requirePassword', payload);

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_PASSWORD:
      return state.set('password', payload);

    case actionTypes.UPDATE_PAGE_SETTINGS_AUDIENCE_ACCESS:
      return state.merge(updateStateWithAudienceAccess(payload));

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_AUDIENCE_ACCESS_LISTS:
      return state.set('publicAccessRules', updatePublicAccessRulesWithLists(state.get('publicAccessRules'), payload));

    case UPDATE_SETTINGS_SLUG_RAW:
      {
        const {
          slugRaw,
          languageString = ''
        } = payload;
        return state.set('slug', buildSlugWithLang(slugRaw, languageString));
      }

    case UPDATE_CONTENT_MODEL_DOMAIN:
      {
        const {
          bodyOverrideByDomain,
          language,
          domain,
          slugRaw
        } = payload;
        const slug = buildSlugWithLang(slugRaw, buildLanguageString(bodyOverrideByDomain, language, domain));
        return state.set('slug', slug);
      }

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_LANGUAGE:
      {
        const {
          language,
          slugRaw,
          bodyOverrideByDomain,
          domain
        } = payload;
        const slug = buildSlugWithLang(slugRaw || '', buildLanguageString(bodyOverrideByDomain, language, domain));
        return state.merge({
          slug,
          language
        });
      }

    case actionTypes.UPDATE_PAGE_CONTENT_MODEL_LANGUAGE_MASTER:
      return state.set('translatedFromId', payload.translatedFromId);

    case actionTypes.FETCH_CONTACT_LIST_REGISTRATIONS_SUCCEEDED:
      return state.merge({
        emailsNotSent: payload.emailsNotSent,
        emailsSent: payload.emailsSent,
        registeredContacts: payload.registered,
        unregisteredContacts: payload.unregistered
      });

    default:
      return state;
  }
};

export default withBaseContentModel(pagePublishReducer, featuredImageReducer, pageContentModelReducer);