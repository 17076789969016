export const SaveStates = {
  NO_UNSAVED_CHANGES: 'NO_UNSAVED_CHANGES',
  UNSAVED_CHANGES: 'UNSAVED_CHANGES',
  SAVING_CHANGES: 'SAVING_CHANGES',
  AUTO_SAVED_CHANGES: 'AUTO_SAVED_CHANGES',
  SAVED_CHANGES: 'SAVED_CHANGES',
  SAVE_FAILED: 'SAVE_FAILED'
};
export const FLOATY = 'floaty';
export const PUSHY = 'pushy';
export const COLLAPSED = 'collapsed';