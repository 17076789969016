export const shouldShowBeta = approvalType => {
  switch (approvalType) {
    // For example:
    // case ApprovalTypes.BLOG_POST: {
    //   return false;
    // }
    default:
      {
        return true;
      }
  }
};