import { QUANTITY_PACK } from 'self-service-api/constants/SkuTypes';

const getQuantityPackFromSku = (products, skuId) => {
  let quantityPackDetails = null;
  Object.values(products).forEach(product => {
    product.productVersions.forEach(productVersion => {
      productVersion.quantityPackDetails.forEach(quantityPack => {
        if (quantityPack.skuId === skuId) {
          quantityPackDetails = quantityPack;
        }
      });
    });
  });
  return quantityPackDetails;
};

const getProductInfoFromSkuId = (products, skuId) => {
  return Object.values(products).find(product => {
    return product.productVersions.find(productVersion => {
      return productVersion.skuId === skuId;
    });
  }) || null;
};

const getProductVersionFromSkuId = (products, skuId) => {
  const productInfo = getProductInfoFromSkuId(products, skuId);
  return productInfo ? productInfo.productVersions.find(productVersion => productVersion.skuId === skuId) : null;
};

export const getProductTrackingName = (products, skuId) => {
  if (!products || !skuId) return null;
  const productVersion = getProductVersionFromSkuId(products, skuId);
  const quantityPack = getQuantityPackFromSku(products, skuId);

  if (productVersion || quantityPack) {
    return productVersion ? productVersion.displayName : quantityPack.displayName;
  }

  return null;
};

const getSkuMap = productMap => {
  const productVersions = productMap.reduce((acc, product) => {
    const keyedVersions = {};
    product.productVersions.forEach(version => keyedVersions[version.skuId] = Object.assign({}, version, {
      quantityPackDetails: version.quantityPackDetails.map(quantityPack => Object.assign({}, quantityPack, {
        productVersionSkuId: version.skuId
      }))
    }));
    return Object.assign({}, acc, {}, keyedVersions);
  }, {});
  return Object.values(productVersions).reduce((acc, productVersion) => {
    const skus = Object.assign({}, acc, {
      [productVersion.skuId]: productVersion
    });
    productVersion.quantityPackDetails.forEach(quantityPack => {
      skus[quantityPack.skuId] = quantityPack;
    });
    return skus;
  }, {});
};

export const getIsQuantityUpgrade = (products, productConfigurations = []) => {
  if (productConfigurations.length !== 1 || !products) return false;
  const skuMap = getSkuMap(products);
  const skuId = productConfigurations[0].skuId;
  return skuMap[skuId].skuType === QUANTITY_PACK;
};
export const getIsMultipleOfferQuantityUpgrade = (products, offerProductConfigurations) => {
  if (!products) return false;
  return offerProductConfigurations.map(({
    productConfigurations
  }) => productConfigurations).flat().every(({
    skuId
  }) => {
    const skuMap = getSkuMap(products);
    return skuMap[skuId].skuType === QUANTITY_PACK;
  });
};
export const getSkuIdFromApiName = (products, name) => {
  const productInfo = products.find(({
    apiName
  }) => apiName === name);
  return productInfo.productVersions.find(product => product.versionType === 'CURRENT').skuId;
};