'use es6';

import { computeTreeNodeDataToDesiredDomStateData, computeModuleDataToDesiredDomStateData, getLayoutStyleCssString } from 'layout-dnd-utils/layoutTreeStyles/calculateLayoutStyleData';
import { createSelector } from 'reselect';
import { getAllLayoutSectionTreesAsMap, getAllStaticAndFlexColumnModules, getFakeBodyModule, getAllNonFakeBodyModules, getDefaultTypeHelper, getCellOrRowById, getModuleById, getIsModuleEditable, getIsDefaultModule, getIsCMv2Module, getIsFakeBodyOrTitleModule, getAllV2ModuleDefinitionIdsWithStylesOnPage, getIsGlobalModuleGroupOrPartial, isFakeBodyModuleName, getIsCustomCmv2Module, getIsModuleInLayoutSection, getModulesByNameFromPostBody } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { getModuleStylesCssString, getNonModuleStyles } from 'ContentEditorUI/lib/widgetEdit/StyleEditorUtils';
import { getBackgroundColorFromLayoutStyles, getBackgroundImageFromLayoutStyles, getBackgroundGradientFromLayoutStyles, getHiddenFromLayoutStyles, getMarginFromLayoutStyles, getPaddingFromLayoutStyles, getMobilePaddingFromLayoutStyles, getSectionMaxWidthFromLayoutStyles, getSectionForceFullWidthFromLayoutStyles, getFlexboxPositioningFromLayoutStyles } from 'layout-dnd-utils/layoutTreeStyles/helpers';
import { getModuleIdsByBuiltinType, getBuiltInTypesByModuleId, getAllModuleSchemasAsArray, getSchemaForModuleHelper } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { calculateExistingVerticalAlignmentForRow } from 'layout-dnd-utils/layoutTreeStyles/implementations/verticalAlignment';
import { getSelectedModuleInTree, getLayoutStylesForSelectedItem } from 'ContentEditorUI/redux/selectors/selectionSelectors';
import { getActiveBreakpointName, getBreakpointsByName, getShouldUseResponsiveBreakpoints } from 'ContentEditorUI/redux/selectors/styleBreakpointsSelectors';
import { getIsBlogPost } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';

const nodeExtractor = (__state, node) => node;

const getLayoutStylesForNode = createSelector([nodeExtractor], node => node !== null ? node.getLayoutStyleData() : null);
export const getAllLayoutTreeNodesWithLayoutStyles = createSelector(getAllLayoutSectionTreesAsMap, layoutSectionTrees => {
  return layoutSectionTrees.valueSeq().flatMap(tree => tree.allCellsAndRows()).filter(node => node.getLayoutStyleData()).toArray();
});
export const getLayoutStylesHiddenForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (styleData, activeBreakpoint) => {
  return getHiddenFromLayoutStyles(styleData, activeBreakpoint);
});
export const makeGetIsLayoutTreeNodeHiddenForId = () => createSelector(getCellOrRowById, getActiveBreakpointName, (node, activeBreakpoint) => {
  if (node && node.getLayoutStyleData()) {
    return getHiddenFromLayoutStyles(node.getLayoutStyleData(), activeBreakpoint);
  } else {
    return null;
  }
});
export const getComputedLayoutStyleDataToDesiredDomStateData = createSelector([getAllLayoutTreeNodesWithLayoutStyles, getBreakpointsByName], (nodesWithLayoutStyles, breakpoints) => {
  return computeTreeNodeDataToDesiredDomStateData(nodesWithLayoutStyles, breakpoints);
});
export const getComputedModuleStyleDataToDesiredDomStateData = createSelector([getAllStaticAndFlexColumnModules, getBreakpointsByName], (modules, breakpoints) => {
  return computeModuleDataToDesiredDomStateData(modules, breakpoints);
});
export const getComputedModuleAndLayoutStyleCssString = createSelector(getComputedModuleStyleDataToDesiredDomStateData, getComputedLayoutStyleDataToDesiredDomStateData, (moduleStateData, layoutStyleData) => {
  return getLayoutStyleCssString(Object.assign({}, layoutStyleData, moduleStateData));
}); // TODO, another good candiate where mulit-level selector memoization will make a significant impact

export const getAllNonFakeBodyModulesAndTheirTypes = createSelector(getAllNonFakeBodyModules, getModuleIdsByBuiltinType, getBuiltInTypesByModuleId, getAllModuleSchemasAsArray, (allNonBodyModules, moduleIdsByBuiltinType, builtInTypesByModuleId, allModulesSchemasArray) => {
  const modulesWithTypes = [];
  allNonBodyModules.forEach(module => {
    const schemaForModule = getSchemaForModuleHelper(module, allModulesSchemasArray, moduleIdsByBuiltinType);
    const moduleType = getDefaultTypeHelper(module, schemaForModule, builtInTypesByModuleId);
    modulesWithTypes.push({
      module,
      moduleType
    });
  });
  return modulesWithTypes;
});
export const getComputedModuleStyleCssString = createSelector(getAllNonFakeBodyModulesAndTheirTypes, getFakeBodyModule, (modulesAndTypes, reduxBodyModule) => {
  return getModuleStylesCssString(modulesAndTypes, reduxBodyModule);
});
export const getComputedNonModuleStyleCssString = createSelector(getAllNonFakeBodyModulesAndTheirTypes, getFakeBodyModule, (modulesAndTypes, reduxBodyModule) => {
  return getNonModuleStyles(modulesAndTypes, reduxBodyModule);
});
export const getComputedCombinedEditorStyles = createSelector(getComputedModuleStyleCssString, getComputedModuleAndLayoutStyleCssString, (moduleCssString, computedModuleAndLayoutCssStrings) => {
  return `${moduleCssString} \n${computedModuleAndLayoutCssStrings}`;
});
export const getSelectModuleHasAnyLayoutStyles = createSelector([getSelectedModuleInTree], cell => {
  if (cell && cell.getLayoutStyleData()) {
    return Object.keys(cell.getLayoutStyleData()).length > 0;
  }

  return false;
});
export const getLayoutStylesBackgroundColorForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getBackgroundColorFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesBackgroundImageForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getBackgroundImageFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesBackgroundGradientForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getBackgroundGradientFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesMarginForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (styleData, activeBreakpoint) => {
  return getMarginFromLayoutStyles(styleData, activeBreakpoint);
});
export const getLayoutStylesPaddingForNode = createSelector([getLayoutStylesForNode, getActiveBreakpointName], (styleData, activeBreakpoint) => {
  return getPaddingFromLayoutStyles(styleData, activeBreakpoint);
});
export const getLayoutStylesMobilePaddingForSelectedItem = createSelector([getLayoutStylesForSelectedItem], styleData => {
  return getMobilePaddingFromLayoutStyles(styleData);
});
export const getLayoutStylesVerticalAlignmentForNode = createSelector([nodeExtractor], node => {
  return calculateExistingVerticalAlignmentForRow(node);
});
export const getLayoutStylesMaxWidthForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getSectionMaxWidthFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesForceFullWidthForNode = createSelector([getLayoutStylesForNode], nodeStyles => {
  return getSectionForceFullWidthFromLayoutStyles(nodeStyles);
});
export const getLayoutStylesFlexboxPositioningForModule = createSelector([getSelectedModuleInTree], cell => cell ? getFlexboxPositioningFromLayoutStyles(cell.getLayoutStyleData()) : undefined);
const getIsStylingModulesSupported = createSelector([getIsBlogPost], isBlogPost => !isBlogPost);
export const getIsModuleStyleable = basicSelectorWithStats((state, id) => {
  const module = getModuleById(state, id);
  const isModuleEditable = getIsModuleEditable(state, id);
  const isNotFakeBodyOrTitleModule = !getIsFakeBodyOrTitleModule(state, id);
  const isDefaultModule = getIsDefaultModule(state, id);
  const isCustomCmv2ModuleInLayoutSection = getIsCustomCmv2Module(state, id) && getIsModuleInLayoutSection(state, id);
  const isCmv2 = getIsCMv2Module(state, id);
  const isCmv2BuiltinModule = isCmv2 && isDefaultModule;
  const isCustomOrBuiltinV1Module = !isCmv2;
  const isFakeBodyModule = isFakeBodyModuleName(id);
  const allV2ModuleDefinitionIdsWithStyles = getAllV2ModuleDefinitionIdsWithStylesOnPage(state);
  const anyOtherV2ModuleOfSameTypeWithStyles = !!module && allV2ModuleDefinitionIdsWithStyles.has(module.get('module_id'));
  const isGlobal = getIsGlobalModuleGroupOrPartial(state, id);
  const isStylingModulesSupported = getIsStylingModulesSupported(state);
  const isBlogPostBodyModule = getModulesByNameFromPostBody(state).has(id);
  const shouldUseResponsiveBreakpoints = getShouldUseResponsiveBreakpoints(state);
  return (isStylingModulesSupported || isBlogPostBodyModule) && isModuleEditable && isNotFakeBodyOrTitleModule && !isGlobal && (isCmv2BuiltinModule || isCustomCmv2ModuleInLayoutSection && shouldUseResponsiveBreakpoints || isCustomOrBuiltinV1Module || isFakeBodyModule || anyOtherV2ModuleOfSameTypeWithStyles);
});