import { getHttpClientAsLastParam } from 'ContentData/helpers/apiHelpers';
import { userInfoSync } from 'hub-http/userInfo';
import PortalIdParser from 'PortalIdParser';

/**
 * GET blog-settings/v1/blogs/{id}
 */
export function fetchBlog(id, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get(`blog-settings/v1/blogs/${id}`);
}
/**
 * GET blog-settings/v1/blogs
 */

export function fetchBlogs(query, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.get('blog-settings/v1/blogs', {
    query
  });
}
/**
 * POST blog-settings/v1/blogs
 * @param {object} data { name, domain, slug, language }
 * @return {Promise} create blog
 */

export function createBlog(data, ...rest) {
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.post(`blog-settings/v1/blogs`, {
    data
  });
}
/**
 * PUT users/v1/app/attributes/bulk-get
 * @param {object} userData auth user info
 */

export function fetchDefaultBlog(...rest) {
  const {
    user
  } = userInfoSync();
  const httpClient = getHttpClientAsLastParam(rest);
  return httpClient.put(`users/v1/app/attributes/bulk-get`, {
    data: {
      userId: user.user_id,
      key: `${PortalIdParser.get()}:cms-control-center-favorite-blog`
    }
  });
}