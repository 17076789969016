import { SELECTED_ASSET, APP_NAME, OBJECT_ID, OBJECT_TYPE, CTA_URL, MODULES_WITH_REPLIES, IS_COMMENT_MODE_ACTIVE, BROADCAST_COMMENT_MESSAGE } from '../constants/CollaborationSidebarStateProperties';
import createCollaborationSidebarState from '../factories/CollaborationSidebarState';
import { getIsCommentingUrl, getSecuredTabQueryParam, getOpenQueryParam, getCommentingThreadQueryParam, getSelectedObjectTypeQueryParam, getSelectedObjectIdQueryParam // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module
} from './urls';
/**
 * Returns a newly created CollaborationSidebarState object. Note, this
 * is a util as it depends on window.location being in scope
 */

export function getInitialCollaborationSidebarState({
  isEmbeddedCommentingEnabled = false,
  appName = null,
  objectId = null,
  objectType = null,
  ctaUrl = window.location.href,
  enabledFeatures = null
} = {}) {
  const isOpen = getIsCommentingUrl() || getOpenQueryParam();
  const commentThread = getCommentingThreadQueryParam();
  const tab = getSecuredTabQueryParam({
    enabledFeatures
  });
  const selectedObjectType = getSelectedObjectTypeQueryParam();
  const selectedObjectId = getSelectedObjectIdQueryParam();
  return createCollaborationSidebarState({
    isOpen,
    commentThread,
    isEmbeddedCommentingEnabled,
    hideEmbeddedComments: false,
    tab,
    [APP_NAME]: appName,
    [OBJECT_ID]: objectId,
    [OBJECT_TYPE]: objectType,
    [CTA_URL]: ctaUrl,
    [SELECTED_ASSET]: selectedObjectType ? {
      objectType: selectedObjectType,
      objectId: selectedObjectId
    } : null,
    [IS_COMMENT_MODE_ACTIVE]: false,
    [MODULES_WITH_REPLIES]: {},
    [BROADCAST_COMMENT_MESSAGE]: null
  });
}
export function mergeCollaborationSidebarState(currentState, updates) {
  return Object.assign({}, currentState, {}, updates);
}