import keyMirror from 'react-utils/keyMirror';
import Immutable from 'immutable';
export const MINIMUM_FETCH_FILES_LIMIT = 40;
export const SEARCH_QUERY_WARN_LENGTH = 45;
export const MAX_SEARCH_QUERY_LENGTH = 50;
export const TRACK_EVENT = '@@usage';
export const NOTIFICATION_EVENT = 'notification';
export const VIDEO_EXPERIMENTS_GATE = 'video-experiments';
export const BYPASS_ELASTIC_SEARCH_GATE = 'FileManagerUI:BypassElasticSearch';
export const FREE_FILE_HOSTING_DOMAIN_GATE = 'free-file-hosting-domain';
export const SHUTTERSTOCK_MAINTENANCE_GATE = 'FileManagerUI:ShutterstockMaintenance';
export const PICKER_FIREALARM_GATE = 'FileManagerUI:PickerFireAlarm';
export const SEARCHABLE_MOVE_MODAL_GATE = 'FileManagerUI:SearchableMoveModal';
export const RECYCLE_BIN_GATE = 'restore-files-ui:app';
export const PARTITIONING_GATE = 'FileManager:EnablePartitioning';
export const SUPPRESS_SVG_DIMENSIONS_GATE_NAME = 'FileManagerUI:SuppressSvgDimensions';
export const CDN_SWAP_GATE = 'FileManager:SwapCdnSubdomains';
export const PORTAL_ID_DOMAIN_GATE = 'FileManager:UsePortalIdBasedDomain';
export const CRM_SEARCH_GATE = 'FileManagerSearch:UserCrmSearch';
export const VIDEO_CAPTIONS_GATE = 'DAM:Video2Captions';
export const BULK_PARTITIONING_GATE = 'FileManagerUI:BulkPartitioning';
export const UNICODE_NORMALIZATION_GATE = 'FileManagerUI:NormalizeUnicode';
export const FILE_HISTORY_APP_GATE = 'file-history-ui:app';
export const UPLOAD_DEDUPLICATION_GATE = 'DAM:UploadDeduplication';
export const CUSTOM_ALT_TEXT_GATE = 'DAM:CustomAltText';
export const CANVA_INTEGRATION_SCOPE = 'canva-integration';
export const MARKETING_VIDEO_SCOPE = 'marketing-video';
export const FILE_MANAGER_ACCESS = 'file-manager-access';
export const FILE_MANAGER_WRITE_SCOPE = 'file-manager-write';
export const TEAMS_ACCESS_SCOPE = 'teams-access';
export const FILE_MANAGER_ACCOUNT_VERIFICATION_SOURCE = 'FILE_MANAGER';
export const FILE_HOSTING_PAID_DOMAINS_SCOPE = 'file-hosting-paid-domains-access';
export const SUPER_ADMIN_SCOPE = 'super-admin';
export let RequestStatus;

(function (RequestStatus) {
  RequestStatus["UNINITIALIZED"] = "UNINITIALIZED";
  RequestStatus["PENDING"] = "PENDING";
  RequestStatus["SUCCEEDED"] = "SUCCEEDED";
  RequestStatus["FAILED"] = "FAILED";
  RequestStatus["PRECONDITION_FAILED"] = "PRECONDITION_FAILED";
  RequestStatus["NOTFOUND"] = "NOTFOUND";
})(RequestStatus || (RequestStatus = {}));

export const COMPLETED_REQUEST_STATUSES = [RequestStatus.SUCCEEDED, RequestStatus.FAILED, RequestStatus.NOTFOUND];
export const UrlSchemes = keyMirror({
  SIMPLIFIED: null,
  LEGACY: null
});
export let FileTypes;

(function (FileTypes) {
  FileTypes["IMG"] = "IMG";
  FileTypes["MOVIE"] = "MOVIE";
  FileTypes["DOCUMENT"] = "DOCUMENT";
  FileTypes["AUDIO"] = "AUDIO";
  FileTypes["OTHER"] = "OTHER";
})(FileTypes || (FileTypes = {}));

export let ObjectCategory;

(function (ObjectCategory) {
  ObjectCategory["FILE"] = "FILE";
  ObjectCategory["FOLDER"] = "FOLDER";
})(ObjectCategory || (ObjectCategory = {}));

const IMG_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'ico', 'bmp', 'webp', 'svg'];
const MOVIE_TYPES = ['mov', 'avi', 'flv', 'wmv', 'rm', '3gp', '3g2', 'asf', 'asx', 'mpg', 'mp4', 'mpeg', 'swf', 'm4v', 'webm'];
const DOCUMENT_TYPES = ['csv', 'doc', 'docx', 'dot', 'dotx', 'key', 'pdf', 'pot', 'potx', 'pps', 'ppsx', 'ppt', 'pptx', 'txt', 'wpd', 'wps', 'wri', 'xls', 'xlsb', 'xlsx', 'xlt', 'xlx', 'xml'];
const AUDIO_TYPES = ['aif', 'm4a', 'mp3', 'mpa', 'ra', 'wav', 'wma'];
export const TypeToExtensions = Immutable.Map({
  [FileTypes.IMG]: Immutable.Set(IMG_TYPES),
  [FileTypes.MOVIE]: Immutable.Set(MOVIE_TYPES),
  [FileTypes.DOCUMENT]: Immutable.Set(DOCUMENT_TYPES),
  [FileTypes.AUDIO]: Immutable.Set(AUDIO_TYPES)
});
export const SystemRecognizedFileExtensionList = TypeToExtensions.flatten().toList();
export const ContentCategories = Immutable.Map({
  UNMAPPED: 0,
  LANDING_PAGE: 1,
  EMAIL: 2,
  BLOG_POST: 3,
  SITE_PAGE: 4,
  LEGACY_PAGE: 5,
  KNOWLEDGE_ARTICLE: 6
});
export const ROOT_FOLDER_ID = 'root';
export const ImportImageCopyrightNoticeValues = {
  ACCEPTED: 'ACCEPTED'
};
export const ShutterstockTosValues = {
  ACCEPTED: 'ACCEPTED'
};
export const DiscoverabilityPopupDismissed = 'DISMISSED';
export const MAX_IMG_SIZE_FOR_THUMBNAIL = 999999;
export const VideoProviders = keyMirror({
  HUBSPOT_VIDEO_2: null
});
export let Limits;

(function (Limits) {
  Limits["EMBEDDABLE_VIDEO"] = "EMBEDDABLE_VIDEO";
})(Limits || (Limits = {}));

export const BASE_VIDEO_LIMIT = 250;
export const VIDEO_CAPACITY_PACKS_UPGRADE_THRESHOLD = 5;
export const IMG_WIDTH_IN_DETAIL_PANEL = 430;
export const PATH_TO_VIDEO_HOSTING_INFO = ['meta', 'video_data', 'hosting_infos'];
export const ImageEditorLocations = keyMirror({
  DRAWER: null,
  EDITOR_MODAL: null
});
export const FILE_DETAILS_QUERY_PARAM_NAME = 'showDetails';
export const TRY_VIDEO_PATH = 'tryVideo';
export const HUSBPOT_VIDEO_PATH = 'hubspot-video';
export const ShutterstockFolderName = 'Stock images';
export const ShutterstockFolderPath = `/${ShutterstockFolderName}`;
export const PathToFileAccessibility = ['meta', 'allows_anonymous_access'];
export const PICKER_CHANGE_VISIBILITY_SOURCE = 'picker-change-visibility';
export const MODAL_WIDTH = 600;
export const VIDEO_MIGRATION_REMINDER_DUE_DATE = Date.parse('12/26/2021');
export const VIDEO_CUT_OFF_DATE = Date.parse('02/21/2022');
export const LEARN_ABOUT_MANUAL_MIGRATION = 'https://knowledge.hubspot.com/website-pages/host-videos-in-hubspot';
export const VIYDARD_UPGRADE_LINK = 'https://www.vidyard.com/hubspot-video-upgrade';
export const USE_HS_WITH_VY_LINK = 'https://knowledge.vidyard.com/hc/en-us/articles/360009997013-How-to-add-videos-to-your-HubSpot-landing-pages-website-and-blog';
export const LEARN_ABOUT_HS_VIDEO_LINK = 'https://knowledge.hubspot.com/website-pages/add-videos-to-your-hubspot-content';
export const VIDEO_THUMB_ACCEPTED_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];