import { EVENTS } from '../constants/TrackingConstants';
import { debug } from './debugUtil';
import { UsageTracker } from './usageTracker';
import bindArg from './bindArg';
import { markTaskComplete } from './taskUtil';
import { openTaskCompletionModal } from './banner';
export const buildTourConfig = (tourId, tourData, prevTourId) => {
  const {
    beforeEnter,
    beforeExit,
    beforeStart,
    beforeFinish,
    completeActionKey,
    escapeHatches,
    nextTourAlias
  } = tourData;
  return {
    steps: tourData.steps.map(step => step.id),
    nextTourAlias,
    escapeHatches: escapeHatches || [],
    beforeEnter: bindArg(beforeEnter, tourData),
    beforeExit: bindArg(beforeExit, tourData),
    beforeFinish: bindArg(beforeFinish, tourData),
    beforeStart: () => {
      UsageTracker.track(EVENTS.TOUR_START, {
        tourId,
        prevTourId
      });

      if (beforeStart) {
        beforeStart(tourData);
      }
    },
    markTaskComplete: options => {
      if (completeActionKey) {
        debug(`Marking task with actionName ${completeActionKey} as complete`);
        markTaskComplete(completeActionKey, options);
      } else {
        debug(`No taskCompleteActionKey for tour with id ${tourId}`);
      }
    },
    openTaskCompletionModal: () => {
      if (completeActionKey) {
        openTaskCompletionModal(completeActionKey);
      }
    }
  };
};