'use es6';

import PropTypes from 'prop-types';
export const BuyAdditionalContactsPropsInterface = {
  additionalUnitQuantity: PropTypes.number,
  activeTerm: PropTypes.string,
  ownsProduct: PropTypes.bool,
  trackingSource: PropTypes.string,
  onClick: PropTypes.func,
  offerId: PropTypes.number
};