import { useContext } from 'react';
import { IS_OPEN, TAB, HIDE_EMBEDDED_COMMENTS, APP_NAME, OBJECT_TYPE, OBJECT_ID, IS_EMBEDDED_COMMENTS_ENABLED, MODULES_WITH_REPLIES, IS_COMMENT_MODE_ACTIVE } from '../constants/CollaborationSidebarStateProperties';
import CollaborationSidebarStateContext from '../contexts/CollaborationSidebarStateContext';
import { getSanitizedTab } from '../utils/tab';
export default function useCollaborationSidebarState() {
  const {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState
  } = useContext(CollaborationSidebarStateContext);
  return {
    collaborationSidebarState,
    onUpdateCollaborationSidebarState,
    // These are provided as helpers for the above
    appName: collaborationSidebarState[APP_NAME],
    objectType: collaborationSidebarState[OBJECT_TYPE],
    objectId: collaborationSidebarState[OBJECT_ID],
    isEmbeddedCommentingEnabled: collaborationSidebarState[IS_EMBEDDED_COMMENTS_ENABLED],
    isOpen: collaborationSidebarState[IS_OPEN],
    tab: collaborationSidebarState[TAB],
    modulesWithReplies: collaborationSidebarState[MODULES_WITH_REPLIES],
    isCommentModeActive: collaborationSidebarState[IS_COMMENT_MODE_ACTIVE],
    hideEmbeddedComments: collaborationSidebarState[HIDE_EMBEDDED_COMMENTS],
    openSidebar: tab => onUpdateCollaborationSidebarState({
      [IS_OPEN]: true,
      [TAB]: getSanitizedTab(tab)
    }),
    closeSidebar: () => onUpdateCollaborationSidebarState({
      [IS_OPEN]: false
    }),
    setHideEmbeddedComments: hideEmbeddedComments => onUpdateCollaborationSidebarState({
      [HIDE_EMBEDDED_COMMENTS]: hideEmbeddedComments
    })
  };
}