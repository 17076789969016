import PropTypes from 'prop-types';
import Raven from 'Raven';
import { Component } from 'react';
export default class ChildrenErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    Raven.captureException(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.originalChildren;
    }

    return this.props.children;
  }

}
ChildrenErrorBoundary.propTypes = {
  children: PropTypes.node,
  originalChildren: PropTypes.node
};
ChildrenErrorBoundary.defaultProps = {
  children: null,
  originalChildren: null
};