'use es6';

import { createSelector } from 'reselect';
import { getHasAnyLayoutSections, getIsCustomCmv2Module, getIsModuleInLayoutSection } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { basicSelector, basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
import { FEATURE_FLAGS } from '../constants';
import EditorConfigSingleton from 'ContentEditorUI/EditorConfigSingleton';
export const getStyleBreakpointState = basicSelector(state => state.styleBreakpoints);
export const getActiveBreakpointName = createSelector([getStyleBreakpointState], styleBreakpoints => styleBreakpoints && styleBreakpoints.get('activeBreakpointName'));
export const getActiveBreakpointPreviewWidth = createSelector([getStyleBreakpointState], styleBreakpoints => styleBreakpoints && styleBreakpoints.get('activeBreakpointPreviewWidth'));
export const getResponsiveBreakpoints = createSelector([getStyleBreakpointState], styleBreakpoints => styleBreakpoints && styleBreakpoints.get('breakpoints'));
export const getActiveBreakpoint = createSelector([getActiveBreakpointName, getResponsiveBreakpoints], (activeBreakpointName, breakpoints) => {
  if (activeBreakpointName) {
    return breakpoints.find(breakpoint => breakpoint.name === activeBreakpointName);
  }

  return null;
});
export const getBreakpointsByName = createSelector(getResponsiveBreakpoints, breakpoints => {
  const configByName = {};
  breakpoints.forEach((breakpoint, index) => {
    configByName[breakpoint.name] = breakpoint;
    configByName[breakpoint.name].order = index;
  });
  return configByName;
});
export const getHasBreakpointFeatureFlag = () => EditorConfigSingleton.getFeatureFlagOrDefault(FEATURE_FLAGS.breakpoints); // Only want to show device style controls if both ungated for layout data normalization
// and device style controls. The breakpoint styles implementation is has a dependency
// on the layout data being normalized, but the layout normalization should end up
// being ungated first

export const getShouldUseResponsiveBreakpoints = createSelector([getResponsiveBreakpoints, getHasBreakpointFeatureFlag], (responsiveBreakpoints, hasBreakpointFeatureFlag) => // because we will always have a "default" breakpoint
responsiveBreakpoints.length > 1 && hasBreakpointFeatureFlag);
export const getIsInDefaultBreakpointMode = createSelector([getActiveBreakpointName], activeBreakpointName => activeBreakpointName === 'default');
export const getIsInNonDefaultBreakpointMode = createSelector([getActiveBreakpointName], activeBreakpointName => activeBreakpointName !== 'default');
export const getPreviewFrameMinWidth = createSelector([getIsInDefaultBreakpointMode, getHasAnyLayoutSections], (isInDefaultBreakpointMode, hasAnyLayoutSections) => {
  if (hasAnyLayoutSections && isInDefaultBreakpointMode) {
    return '768px';
  } else {
    return '';
  }
});
export const getShouldShowOnlyVisibilityStyleOptions = basicSelectorWithStats((state, id) => {
  const isCustomModule = getIsCustomCmv2Module(state, id);
  const isModuleInLayoutSection = getIsModuleInLayoutSection(state, id);
  const shouldUseResponsiveBreakpoints = getShouldUseResponsiveBreakpoints(state);
  return shouldUseResponsiveBreakpoints && isCustomModule && isModuleInLayoutSection;
});