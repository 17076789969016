import getLang from 'I18n/utils/getLang';
const FormsLangToLang = Object.freeze({
  esES: 'es',
  esMX: 'es-mx',
  jaJP: 'ja',
  ptBR: 'pt-br',
  svSE: 'sv',
  zhCN: 'zh-cn',
  zhHK: 'zh-hk'
});
export const getLangFromFormsLang = metaDataList => {
  const metaDataLangKey = metaDataList.find(val => val.name === 'lang');
  const formsLangOrDefault = metaDataLangKey ? metaDataLangKey.value : getLang() || 'en';
  return FormsLangToLang[formsLangOrDefault] || formsLangOrDefault;
};