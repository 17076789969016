'use es6';

import enviro from 'enviro';
export default {
  log: (...args) => {
    if (enviro.isQa()) {
      console.log(...args);
    }
  },
  debug: (...args) => {
    if (enviro.isQa()) {
      console.debug(`%c product-approvals: ${args.map(JSON.stringify).join(', ')}`, 'background: #F37658; color: #FFF');
    }
  }
};