export const AssetTypes = {
  BLOG_POST: 'BLOG_POST',
  BROADCAST: 'BROADCAST',
  CTA: 'CTA',
  EMAIL: 'EMAIL',
  LANDING_PAGE: 'LANDING_PAGE',
  LIST: 'LIST',
  SITE_PAGE: 'SITE_PAGE',
  TRACKING_URL: 'TRACKING_URL',
  WORKFLOW: 'WORKFLOW',
  AD_CAMPAIGN: 'AD_CAMPAIGN',
  FORM: 'FORM',
  MARKETING_EVENT: 'MARKETING_EVENT'
};
export const AssociatableObjectTypesMap = {
  BLOG_POST: 'BLOG_POST',
  SOCIAL_BROADCAST: 'SOCIAL_BROADCAST',
  CTA: 'CTA',
  MARKETING_EMAIL: 'MARKETING_EMAIL',
  LANDING_PAGE: 'LANDING_PAGE',
  SITE_PAGE: 'SITE_PAGE',
  AUTOMATION_PLATFORM_FLOW: 'AUTOMATION_PLATFORM_FLOW',
  AD_CAMPAIGN: 'AD_CAMPAIGN',
  FORM: 'FORM',
  MARKETING_EVENT: 'MARKETING_EVENT'
}; // TODO: Figure out what to do with lists as `OBJECT_LIST` fails on
// campaigns/v1/assets/associations/is-limit-reached

export const AssociatableObjectTypesMapWithList = Object.assign({}, AssociatableObjectTypesMap, {
  OBJECT_LIST: 'OBJECT_LIST'
});
export const AssetTypesToObjectTypes = {
  [AssetTypes.BLOG_POST]: AssociatableObjectTypesMap.BLOG_POST,
  [AssetTypes.BROADCAST]: AssociatableObjectTypesMap.SOCIAL_BROADCAST,
  [AssetTypes.CTA]: AssociatableObjectTypesMap.CTA,
  [AssetTypes.EMAIL]: AssociatableObjectTypesMap.MARKETING_EMAIL,
  [AssetTypes.LANDING_PAGE]: AssociatableObjectTypesMap.LANDING_PAGE,
  [AssetTypes.SITE_PAGE]: AssociatableObjectTypesMap.SITE_PAGE,
  [AssetTypes.WORKFLOW]: AssociatableObjectTypesMap.AUTOMATION_PLATFORM_FLOW,
  [AssetTypes.AD_CAMPAIGN]: AssociatableObjectTypesMap.AD_CAMPAIGN,
  [AssetTypes.FORM]: AssociatableObjectTypesMap.FORM,
  [AssetTypes.MARKETING_EVENT]: AssociatableObjectTypesMap.MARKETING_EVENT
}; // TODO: Figure out what to do with lists as `OBJECT_LIST` fails on
// campaigns/v1/assets/associations/is-limit-reached

export const AssetTypesToObjectTypesWithList = Object.assign({}, AssetTypesToObjectTypes, {
  [AssetTypes.LIST]: AssociatableObjectTypesMapWithList.OBJECT_LIST
});
export const ObjectTypesToAssetTypes = {
  [AssociatableObjectTypesMap.BLOG_POST]: AssetTypes.BLOG_POST,
  [AssociatableObjectTypesMap.SOCIAL_BROADCAST]: AssetTypes.BROADCAST,
  [AssociatableObjectTypesMap.CTA]: AssetTypes.CTA,
  [AssociatableObjectTypesMap.MARKETING_EMAIL]: AssetTypes.EMAIL,
  [AssociatableObjectTypesMap.LANDING_PAGE]: AssetTypes.LANDING_PAGE,
  [AssociatableObjectTypesMap.SITE_PAGE]: AssetTypes.SITE_PAGE,
  [AssociatableObjectTypesMap.AUTOMATION_PLATFORM_FLOW]: AssetTypes.WORKFLOW,
  [AssociatableObjectTypesMap.AD_CAMPAIGN]: AssetTypes.AD_CAMPAIGN,
  [AssociatableObjectTypesMap.FORM]: AssetTypes.FORM,
  [AssociatableObjectTypesMap.MARKETING_EVENT]: AssetTypes.MARKETING_EVENT
}; // TODO: Figure out what to do with lists as `OBJECT_LIST` fails on
// campaigns/v1/assets/associations/is-limit-reached

export const ObjectTypesToAssetTypesWithList = Object.assign({}, ObjectTypesToAssetTypes, {
  [AssociatableObjectTypesMapWithList.OBJECT_LIST]: AssetTypes.LIST
});
export const TrackingURLAssetTypesMap = {
  [AssetTypes.TRACKING_URL]: AssetTypes.TRACKING_URL
};
export const AssociatableAssetTypesMap = {
  [AssetTypes.BLOG_POST]: AssetTypes.BLOG_POST,
  [AssetTypes.BROADCAST]: AssetTypes.BROADCAST,
  [AssetTypes.CTA]: AssetTypes.CTA,
  [AssetTypes.EMAIL]: AssetTypes.EMAIL,
  [AssetTypes.LANDING_PAGE]: AssetTypes.LANDING_PAGE,
  [AssetTypes.LIST]: AssetTypes.LIST,
  [AssetTypes.SITE_PAGE]: AssetTypes.SITE_PAGE,
  [AssetTypes.WORKFLOW]: AssetTypes.WORKFLOW,
  [AssetTypes.AD_CAMPAIGN]: AssetTypes.AD_CAMPAIGN,
  [AssetTypes.FORM]: AssetTypes.FORM,
  [AssetTypes.MARKETING_EVENT]: AssetTypes.MARKETING_EVENT
};
export const AssociatableAssetTypesRequiredScopes = {
  [AssetTypes.BLOG_POST]: ['blog-access'],
  [AssetTypes.BROADCAST]: ['social-access'],
  [AssetTypes.CTA]: ['cta-access'],
  [AssetTypes.EMAIL]: ['email-access'],
  [AssetTypes.LANDING_PAGE]: ['landingpages-access'],
  [AssetTypes.LIST]: [],
  [AssetTypes.SITE_PAGE]: ['sitepages-access'],
  [AssetTypes.WORKFLOW]: ['automation-access'],
  [AssetTypes.AD_CAMPAIGN]: ['ads-read'],
  [AssetTypes.FORM]: ['forms-read'],
  [AssetTypes.MARKETING_EVENT]: ['marketing-events-read']
};
export const AssociatableAssetTypesWriteRequiredScopes = {
  [AssetTypes.BLOG_POST]: ['blog-write'],
  [AssetTypes.BROADCAST]: [],
  [AssetTypes.CTA]: ['cta-write'],
  [AssetTypes.EMAIL]: ['email-write'],
  [AssetTypes.LANDING_PAGE]: ['landingpages-write'],
  [AssetTypes.LIST]: [],
  [AssetTypes.SITE_PAGE]: ['pages-write'],
  [AssetTypes.WORKFLOW]: [],
  [AssetTypes.AD_CAMPAIGN]: [],
  [AssetTypes.FORM]: ['forms-write'],
  [AssetTypes.MARKETING_EVENT]: ['marketing-events-write']
};
export const AssociatableAssetTypes = Object.keys(AssociatableAssetTypesMap);
export const TrackingURLAssetTypes = Object.keys(TrackingURLAssetTypesMap);
export const MAX_BROADCASTS_TO_FETCH = 500;