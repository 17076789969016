import { uniq } from 'underscore';
import { combineReducers } from 'redux';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
const {
  MENUS_FETCH_MENU,
  MENUS_FETCH_MENUS,
  MENUS_SAVE_MENU_CACHE,
  MENUS_CLEAR_CACHE
} = ActionTypes;

function menus(state = {}, action) {
  switch (action.type) {
    case MENUS_FETCH_MENU:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }

    case MENUS_SAVE_MENU_CACHE:
      {
        const {
          menu
        } = action;
        if (!menu) return state;
        return Object.assign({}, state, {
          [menu.id]: menu
        });
      }

    default:
      return state;
  }
}

function menusByName(state = {}, action) {
  switch (action.type) {
    case MENUS_FETCH_MENU:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.name]: response
        });
      }

    case MENUS_SAVE_MENU_CACHE:
      {
        const {
          menu
        } = action;
        if (!menu) return state;
        return Object.assign({}, state, {
          [menu.name]: menu
        });
      }

    default:
      return state;
  }
}

function menusCache(state = {}, action) {
  switch (action.type) {
    case MENUS_FETCH_MENUS:
      {
        const {
          response
        } = action;
        if (!(response && response.objects)) return state;
        return response.objects.reduce((hash, menu) => {
          hash[menu.id] = menu;
          return hash;
        }, Object.assign({}, state));
      }

    case MENUS_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
}

function menusCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case MENUS_FETCH_MENUS:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
}

function order(state = [], action) {
  switch (action.type) {
    case MENUS_FETCH_MENUS:
      {
        const {
          response
        } = action;
        if (!(response && response.objects)) return state;
        return uniq(response.objects.reduce((hash, menu) => {
          hash.push(menu.id);
          return hash;
        }, [...state]));
      }

    case MENUS_CLEAR_CACHE:
      {
        if (state.length) return [];
        return state;
      }

    default:
      return state;
  }
}

export default combineReducers({
  menus,
  menusByName,
  menusCache: combineReducers({
    menus: menusCache,
    order
  }),
  menusCacheRequestStatus,
  requestStatus: createGenericRequestStatusReducer(MENUS_FETCH_MENU)
});