import { CORE_SEATS_ENTERPRISE, CORE_SEATS_INCLUDED_STARTER, CORE_SEATS_PROFESSIONAL, CORE_SEATS_STARTER, SALES_HUB_ENTERPRISE, SALES_HUB_PROFESSIONAL, SERVICE_HUB_ENTERPRISE, SERVICE_HUB_PROFESSIONAL } from '../../constants/ApiNames';
import VersionNumbers from '../../constants/VersionNumbers';
import { getProductInfoFromSkuId } from './productUtils';
export const isCoreSeatSku = apiName => {
  return [CORE_SEATS_INCLUDED_STARTER, CORE_SEATS_STARTER, CORE_SEATS_PROFESSIONAL, CORE_SEATS_ENTERPRISE].includes(apiName);
};
export const isQuantifiableSalesOrServiceSeatSku = (products, skuMap, skuId) => {
  const product = getProductInfoFromSkuId(products, skuId);
  const productVersion = skuMap[skuId];

  if (!product) {
    return false;
  }

  const isSalesServiceProOrEnt = [SALES_HUB_PROFESSIONAL, SALES_HUB_ENTERPRISE, SERVICE_HUB_PROFESSIONAL, SERVICE_HUB_ENTERPRISE].includes(product.apiName);
  const isQuantifiable = productVersion.isQuantifiable;

  if (isSalesServiceProOrEnt && isQuantifiable) {
    const apiName = product.apiName;
    return productVersion.versionNumber >= VersionNumbers[apiName].QUANTIFIABLE_SEAT;
  }

  return false;
};