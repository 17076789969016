'use es6';

import { BUTTON_DISABLED_TEXT, CALYPSO, BATTLESHIP, GYPSUM, LINK_HOVER } from 'HubStyleTokens/colors';
export const EXPANDABLE_ACTIONS_SPACING_X = '8px';
export const EXPANDABLE_DARK_BORDER_COLOR = BATTLESHIP;
export const EXPANDABLE_DARK_BACKGROUND = GYPSUM;
export const EXPANDABLE_DARK_PADDING_TOP = '20px';
export const EXPANDABLE_OPENER_COLOR = CALYPSO;
export const EXPANDABLE_OPENER_DISABLED_COLOR = BUTTON_DISABLED_TEXT;
export const EXPANDABLE_OPENER_HOVER_COLOR = LINK_HOVER;
export const EXPANDABLE_PADDING_BOTTOM = '24px';
export const EXPANDABLE_PADDING_LEFT = '44px';
export const EXPANDABLE_PADDING_TOP = '4px';
export const EXPANDABLE_PADDING_X = '20px';