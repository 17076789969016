'use es6';

import { Map as ImmutableMap } from 'immutable';
import { OPEN_MODAL, CLOSE_MODAL, OPEN_POPOUT_EDITOR, CLOSE_POPOUT_EDITOR, EDIT_SIDEBAR_ACTIVE_TAB_UPDATED, OPENED_PREVIOUS_PANEL, INPAGE_UI_STATE_CLEARED, SAVED_MODULE, SELECTED_ITEM_CLEARED, TOGGLE_PUBLISH_MODAL_OPEN, TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN, TOGGLE_APPLY_CHANGES_MODAL_OPEN, CONTENT_SAVE_REQUEST_FAILED, DISMISS_SCOPES_HAVE_CHANGED_MODAL, SHOW_MATCHING_APP_MODAL, HIDE_MATCHING_APP_MODAL, UPDATE_SELECTED_UPGRADABLE_MODULE_PQL, UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM, TOGGLE_RUN_TEST_MODAL } from 'ContentEditorUI/redux/actions/actionTypes';
import { CLOSE_EDIT_TEMPLATE_MODAL, OPEN_EDIT_TEMPLATE_MODAL } from 'ContentEditorUI/redux/actions/inpageReduxActionTypes';
import { LOCATION_CHANGE } from 'react-router-redux';
import { MODAL_TYPES } from 'ContentEditorUI/lib/modalConstants';
import { isLocked } from 'ContentEditorUI/utils/errorUtils';

const modalReducer = (state = ImmutableMap({
  openModal: null,
  modalMetadata: null,
  publishModalOpen: false,
  publishOptionsModalOpen: false,
  applyChangesModalOpen: false,
  openPopOutEditorKey: null,
  editTemplateModalIsOpen: false,
  scopesHaveChangedModalWasDismissed: false,
  showMatchingAppModal: false,
  matchingAppOpenLocation: 'addmodulesidebar',
  selectedUpgradableModule: null,
  patagoniaSettingsModalActiveNavItem: '',
  runTestModalOpen: false
}), action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return state.set('openModal', action.modalName).set('modalMetadata', action.metadata);

    case CLOSE_MODAL:
      return state.set('openModal', null);

    case OPEN_POPOUT_EDITOR:
      return state.set('openPopOutEditorKey', action.fieldKeyArray);
    // Make sure to clear pop out editor state when the sidebar close/back link is clicked

    case CLOSE_POPOUT_EDITOR:
    case EDIT_SIDEBAR_ACTIVE_TAB_UPDATED: // Clicked style sidebar tab

    case OPENED_PREVIOUS_PANEL: // Baymax sidebar back button

    case INPAGE_UI_STATE_CLEARED: // Non-Baymax sidebar 'X' button

    case SAVED_MODULE: // Non-Baymax "Save and go back" sidebar button

    case SELECTED_ITEM_CLEARED: // Apply button

    case LOCATION_CHANGE:
      // Changing to the Baymax preview modal (and more)
      return state.set('openPopOutEditorKey', null);

    case TOGGLE_PUBLISH_MODAL_OPEN:
      return state.set('publishModalOpen', action.open);

    case TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN:
      return state.set('publishOptionsModalOpen', action.open);

    case TOGGLE_APPLY_CHANGES_MODAL_OPEN:
      {
        return state.set('applyChangesModalOpen', action.open);
      }

    case OPEN_EDIT_TEMPLATE_MODAL:
      return state.merge({
        editTemplateModalIsOpen: true
      });

    case CLOSE_EDIT_TEMPLATE_MODAL:
      return state.merge({
        editTemplateModalIsOpen: false
      });

    case CONTENT_SAVE_REQUEST_FAILED:
      {
        const {
          error: {
            response
          }
        } = action;

        if (isLocked(response)) {
          return state.merge({
            openModal: MODAL_TYPES.LOCKED_MODAL
          });
        }

        return state;
      }

    case DISMISS_SCOPES_HAVE_CHANGED_MODAL:
      {
        return state.set('scopesHaveChangedModalWasDismissed', true);
      }

    case SHOW_MATCHING_APP_MODAL:
      {
        const {
          location
        } = action.payload;
        return state.merge({
          showMatchingAppModal: true,
          matchingAppOpenLocation: location
        });
      }

    case HIDE_MATCHING_APP_MODAL:
      {
        return state.merge({
          showMatchingAppModal: false
        });
      }

    case UPDATE_SELECTED_UPGRADABLE_MODULE_PQL:
      {
        const {
          selectedPqlId
        } = action.payload;
        return state.set('selectedUpgradableModule', selectedPqlId);
      }

    case UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM:
      {
        return state.set('patagoniaSettingsModalActiveNavItem', action.payload);
      }

    case TOGGLE_RUN_TEST_MODAL:
      return state.set('runTestModalOpen', action.payload);

    default:
      return state;
  }
};

export default modalReducer;