import { combineReducers } from 'redux';
import { createGenericRequestStatusReducer } from 'ContentData/helpers/reduxHelpers';
import * as RequestStatus from 'ContentUtils/constants/RequestStatus';
import { DEFAULT } from 'ContentUtils/constants/Blogs';
import * as ActionTypes from 'ContentData/actions/ActionTypes';
const {
  BLOGS_FETCH_BLOG,
  BLOGS_FETCH_DEFAULT_BLOG,
  BLOGS_FETCH_BLOGS,
  BLOGS_SAVE_BLOG_CACHE,
  BLOGS_CLEAR_CACHE,
  BLOGS_CREATE_BLOG_SUCCEEDED
} = ActionTypes;

const blogs = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_FETCH_BLOG:
      {
        const {
          response
        } = action;
        if (!response) return state;
        return Object.assign({}, state, {
          [response.id]: response
        });
      }

    case BLOGS_CREATE_BLOG_SUCCEEDED:
      {
        const blog = action.payload;
        if (!blog) return state;
        return Object.assign({}, state, {
          [blog.id]: blog
        });
      }

    case BLOGS_SAVE_BLOG_CACHE:
      {
        const {
          blog
        } = action;
        if (!blog) return state;
        return Object.assign({}, state, {
          [blog.id]: blog
        });
      }

    default:
      return state;
  }
};

const blogDefault = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_FETCH_DEFAULT_BLOG:
      {
        const {
          response
        } = action;
        if (!response) return state;
        const {
          attributes
        } = response;
        if (!attributes || attributes.length < 1) return state;
        const value = attributes[0].value;
        const defaultBlogId = typeof value === 'string' ? parseInt(value, 10) : value;
        return Object.assign({}, state, {
          [DEFAULT]: defaultBlogId
        });
      }

    default:
      return state;
  }
};

const blogsCache = (state = {}, action) => {
  switch (action.type) {
    case BLOGS_FETCH_BLOGS:
      {
        const {
          response
        } = action;
        if (!response) return state;
        if (!(response && response.objects)) return state;
        return response.objects.reduce((hash, blog) => {
          hash[blog.id] = blog;
          return hash;
        }, Object.assign({}, state));
      }

    case BLOGS_CLEAR_CACHE:
      {
        if (Object.keys(state).length) return {};
        return state;
      }

    default:
      return state;
  }
};

function blogsCacheRequestStatus(state = RequestStatus.UNINITIALIZED, action) {
  switch (action.type) {
    case BLOGS_FETCH_BLOGS:
      if (action.response) return RequestStatus.SUCCEEDED;
      if (action.error) return RequestStatus.FAILED;
      return RequestStatus.PENDING;

    default:
      return state;
  }
}

export default combineReducers({
  blogs,
  blogDefault,
  blogsCache,
  blogsCacheRequestStatus,
  requestStatus: createGenericRequestStatusReducer(BLOGS_FETCH_BLOG),
  requestStatusDefault: createGenericRequestStatusReducer(BLOGS_FETCH_DEFAULT_BLOG)
});