import { userInfoSync } from 'hub-http/userInfo';
import { useEffect, useState, useCallback } from 'react';
import { MSG_TYPE_UPDATE_COMMENT_META_DESCRIPTION } from 'collaboration-sidebar/constants/IFrameMessageTypes';
export default function useUpdateMetaDescriptions({
  metaDescription,
  moduleParentComment,
  ctaUrl,
  broadcastCommentsMessage,
  userInfo
}) {
  const [updatedDescription, setUpdatedDescription] = useState(moduleParentComment && moduleParentComment.commentMetaDescription);
  const getUserId = useCallback(() => {
    // use a callback to avoid calling userInfoSync implicitly from dependents' unit tests
    const {
      user: {
        user_id
      }
    } = userInfo || userInfoSync();
    return user_id;
  }, [userInfo]);
  useEffect(() => {
    // If the description options has changed, update it on the comment.
    if (metaDescription && moduleParentComment && moduleParentComment.commentMetaDescription && JSON.stringify(updatedDescription) !== JSON.stringify(metaDescription) && (moduleParentComment.commentMetaDescription.options && metaDescription.options && JSON.stringify(metaDescription.options) !== JSON.stringify(moduleParentComment.commentMetaDescription.options) || metaDescription.translationKey !== moduleParentComment.commentMetaDescription.translationKey) && getUserId() === moduleParentComment.userId) {
      setUpdatedDescription(metaDescription);
      broadcastCommentsMessage({
        type: MSG_TYPE_UPDATE_COMMENT_META_DESCRIPTION,
        comment: moduleParentComment,
        ctaUrl: ctaUrl || window.location.href,
        updatedMetaDescription: Object.assign({}, moduleParentComment.commentMetaDescription, {}, metaDescription)
      });
    }
  }, [getUserId, broadcastCommentsMessage, ctaUrl, metaDescription, moduleParentComment, updatedDescription]);
}