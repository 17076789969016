'use es6';

import { getContentTypeLimit } from 'ContentEditorUI/redux/selectors/limitSelectors';
import { getIsLandingPage, getIsSitePage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getPageLimitRequestState } from '../selectors/pageRequestStatesSelectors';
import { basicSelectorWithStats } from 'ContentEditorUI/redux/selectors/helpers';
export const getPageLimitRequest = basicSelectorWithStats(state => {
  return getPageLimitRequestState(state);
});
export const getPageLimitLangKey = basicSelectorWithStats(state => {
  if (getIsLandingPage(state)) {
    return 'landingPage';
  } else if (getIsSitePage(state)) {
    return 'sitePage';
  }

  return null;
});
export const getShouldUseCmsStarterUpgradePoint = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit && pageLimit.pageLimit > 15 && pageLimit.quantityUsed < 40;
});
export const getNumberOfPagesOverLimit = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit ? pageLimit.quantityUsed - pageLimit.pageLimit : 0;
});
export const getPageLimitValue = basicSelectorWithStats(state => {
  const pageLimit = getContentTypeLimit(state);
  return pageLimit && pageLimit.pageLimit;
});