import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { omit } from 'underscore';
import PortalIdParser from 'PortalIdParser';
import { ACCEPTED_SOURCE_APPS, SIMPLE_WORKFLOW_SOURCE_APP } from 'ContentData/constants/workflows';
const FORM_SUBMISSION_FILTER_FAMILY = 'FormSubmission';
const EMPTY_FORM_SUBMISSION_CRITERIA = Object.freeze({
  operator: 'HAS_FILLED_OUT_FORM',
  withinTimeMode: 'PAST',
  filterFamily: FORM_SUBMISSION_FILTER_FAMILY
});
export function getWorkflowHybridPermissions(workflowHybrid, formGuid, contentId) {
  const {
    classicEnrollmentSettings,
    createMetadata
  } = workflowHybrid || {};
  const {
    sourceApp
  } = createMetadata || {};
  const {
    segmentCriteria = []
  } = classicEnrollmentSettings || {};
  let canDelete = false;
  let canEditActions = false;
  let canRemoveEnrollmentCriteria = false;
  let shouldShow = false;

  if (!ACCEPTED_SOURCE_APPS[sourceApp]) {
    return {
      canDelete,
      canEditActions,
      canRemoveEnrollmentCriteria,
      shouldShow
    };
  }

  const isCmsFormModuleFieldSourceApp = sourceApp === ACCEPTED_SOURCE_APPS.CMS_FORM_MODULE_FIELD;

  if (isCmsFormModuleFieldSourceApp) {
    canDelete = true;
  }

  for (const criteriaGroup of segmentCriteria) {
    for (const criteria of criteriaGroup) {
      if (criteria.filterFamily === FORM_SUBMISSION_FILTER_FAMILY && criteria.form === formGuid) {
        if (!contentId) {
          // If viewing outside the context of a page, show all workflow dependencies
          shouldShow = true;
          break;
        } else {
          // If viewing on a certain page, only show workflows that reference the form on that page or all pages
          const {
            page
          } = criteria;
          const pageMatches = page === `${contentId}`;

          if (!page || pageMatches) {
            shouldShow = true; // If we're on a page and the workflow has an enrollment criteria for a form submission on that
            // particular page, allow removing that enrollment criteria
            // NOTE: for sourceApp=CMS_FORM_MODULE_FIELD we disable editing enrollment criteria if it contains
            // an email action we allow editing that action

            if (pageMatches) {
              if (isCmsFormModuleFieldSourceApp) {
                canEditActions = true;
              } else {
                canRemoveEnrollmentCriteria = true;
              }

              break;
            }
          }
        }
      }
    }
  }

  return {
    canDelete,
    canEditActions,
    canRemoveEnrollmentCriteria,
    shouldShow
  };
}
export function createNewWorkflowWithEnrollmentCriteria(workflowName, formId, contentId) {
  return {
    name: workflowName,
    objectTypeId: '0-1',
    uuid: `form-field-followup-${contentId}-${new Date().getTime()}`,
    classicEnrollmentSettings: {
      enrollOnCriteriaUpdate: false,
      segmentCriteria: [[Object.assign({}, EMPTY_FORM_SUBMISSION_CRITERIA, {
        page: contentId,
        form: formId
      })]]
    }
  };
}
const EMAIL_ACTION_ID = 1;
const EMAIL_ACTION_TYPE = 'EMAIL';
export function createNewFollowupEmailWorkflowWithEnrollmentCriteria(formId, contentId, followUpEmailId, workflowName) {
  return {
    name: workflowName,
    objectTypeId: '0-1',
    uuid: `cms-form-module-field-${formId}-${contentId}-${new Date().getTime()}`,
    classicEnrollmentSettings: {
      enrollOnCriteriaUpdate: false,
      segmentCriteria: [[Object.assign({}, EMPTY_FORM_SUBMISSION_CRITERIA, {
        page: contentId,
        form: formId
      })]]
    },
    isEnabled: true,
    firstActionId: EMAIL_ACTION_ID,
    actions: {
      [EMAIL_ACTION_ID]: {
        actionId: EMAIL_ACTION_ID,
        actionType: EMAIL_ACTION_TYPE,
        actionTypeId: '0-4',
        connection: null,
        metadata: {
          actionType: EMAIL_ACTION_TYPE,
          appId: null,
          contentId: followUpEmailId,
          recipients: [],
          utmSource: 'hs_automation'
        }
      }
    }
  };
}
export function editFollowupEmailActionInWorkflow(workflowHybrid, followUpEmailId, workflowName) {
  if (!workflowHybrid) return undefined;
  let newWorkflowHybrid = workflowHybrid;

  if (workflowName) {
    newWorkflowHybrid = Object.assign({}, newWorkflowHybrid, {
      name: workflowName
    });
  }

  const emailAction = getFollowupEmailAction(newWorkflowHybrid);

  if (emailAction) {
    const {
      metadata = {}
    } = emailAction;
    const newEmailAction = Object.assign({}, emailAction, {
      metadata: Object.assign({}, metadata, {
        contentId: followUpEmailId
      })
    });
    const {
      actions = {}
    } = newWorkflowHybrid;
    return Object.assign({}, newWorkflowHybrid, {
      actions: Object.assign({}, actions, {
        [emailAction.actionId]: newEmailAction
      })
    });
  }

  return newWorkflowHybrid;
} // Adds a new form submission enrollment criteria to a workflow for a specific page

export function addFormEnrollmentCriteriaToWorkflow(workflowHybrid, formId, contentId) {
  const {
    classicEnrollmentSettings
  } = workflowHybrid;

  const {
    segmentCriteria
  } = classicEnrollmentSettings,
        rest = _objectWithoutPropertiesLoose(classicEnrollmentSettings, ["segmentCriteria"]);

  return Object.assign({}, workflowHybrid, {
    classicEnrollmentSettings: Object.assign({}, omit(rest, 'enrollmentFilters'), {
      enrollOnCriteriaUpdate: false,
      segmentCriteria: [[Object.assign({}, EMPTY_FORM_SUBMISSION_CRITERIA, {
        page: contentId,
        form: formId
      })], ...segmentCriteria]
    })
  });
} // Removes any form submission enrollment criteria with the given form ID and page ID

export function removeFormEnrollmentCriteriaFromWorkflow(workflowHybrid, formId, contentId) {
  const _workflowHybrid$class = workflowHybrid.classicEnrollmentSettings,
        {
    segmentCriteria = []
  } = _workflowHybrid$class,
        rest = _objectWithoutPropertiesLoose(_workflowHybrid$class, ["segmentCriteria"]);

  return Object.assign({}, workflowHybrid, {
    classicEnrollmentSettings: Object.assign({}, omit(rest, 'enrollmentFilters'), {
      segmentCriteria: segmentCriteria.reduce((acc, criteriaGroup) => {
        const filteredCriteria = criteriaGroup.filter(criteria => !(criteria.filterFamily === FORM_SUBMISSION_FILTER_FAMILY && criteria.form === formId && criteria.page === `${contentId}`)); // If filtering the rule out makes the array empty, keep it out of the new segment criteria

        if (filteredCriteria.length) {
          acc.push(filteredCriteria);
        }

        return acc;
      }, [])
    })
  });
} // Determines if workflow is a simple workflow attached to form specified by formId

export function isSimpleWorkflowForForm(workflowHybrid, formId) {
  const {
    createMetadata,
    isEnabled,
    uuid
  } = workflowHybrid || {};
  const {
    sourceApp
  } = createMetadata || {};
  return !!(sourceApp === SIMPLE_WORKFLOW_SOURCE_APP && isEnabled && uuid.startsWith(`${PortalIdParser.get()}:${formId}`));
}
export function getFollowupEmailAction(workflowHybrid) {
  const {
    actions = {}
  } = workflowHybrid || {};
  const actionId = Object.keys(actions).find(_actionId => {
    const action = actions[_actionId];
    return action && action.actionType === EMAIL_ACTION_TYPE;
  });
  return actionId ? actions[actionId] : undefined;
}