'use es6';

import { REQUEST_GLOBAL_MODULES, FETCH_GLOBAL_MODULES_SUCCESS, FETCH_GLOBAL_MODULES_FAIL, REQUEST_CUSTOM_MODULES, FETCH_CUSTOM_MODULES_SUCCESS, FETCH_CUSTOM_MODULES_FAIL, FETCH_MODULE_SCHEMAS_SUCCEEDED, FETCH_MODULE_SCHEMAS_FAILED, REHYDRATE_MODULE_SCHEMAS_SUCCEEDED, REHYDRATE_MODULE_SCHEMAS_FAILED, FETCH_MODULE_SCHEMAS_STARTED } from 'ContentEditorUI/redux/actions/actionTypes';
import * as ModuleSchemasApi from 'ContentEditorUI/api/ModuleSchemasApi';
import { raceToFirstSucceeded } from 'ContentEditorUI/lib/promiseHelpers';
import { COMBINED_MODULE_DEFINITION_API_KEY } from 'ContentEditorUI/lib/userAndLocalCacheSettings/constants';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getContentTypeName } from 'ContentEditorUI/redux/selectors/contentTypeSelector';
import { getHasDeveloperProjectsAccess } from 'ContentEditorUI/redux/selectors/authSelectors';
import { getPerPortalSuperstoreInstance } from '../../lib/superstore';
import { getIsUngatedForFilteredModuleSchemaFetching } from 'ContentEditorUI/redux/selectors/authSelectors';
export const requestGlobalModules = () => ({
  type: REQUEST_GLOBAL_MODULES
});
export const receiveGlobalModules = response => ({
  type: FETCH_GLOBAL_MODULES_SUCCESS,
  response
});
export const receiveGlobalModulesFailure = error => ({
  type: FETCH_GLOBAL_MODULES_FAIL,
  error
});
export const requestCustomModules = () => ({
  type: REQUEST_CUSTOM_MODULES
});
export const receiveCustomModules = (response, {
  fromSuperStoreCache = false
} = {}) => ({
  type: FETCH_CUSTOM_MODULES_SUCCESS,
  response,
  fromSuperStoreCache
});
export const receiveCustomModulesFailure = error => ({
  type: FETCH_CUSTOM_MODULES_FAIL,
  error
});
export const fetchModuleSchemasSucceeded = (response, {
  fromSuperStoreCache = false
} = {}) => ({
  type: FETCH_MODULE_SCHEMAS_SUCCEEDED,
  response,
  fromSuperStoreCache
});
export const fetchModuleSchemasFailed = error => ({
  type: FETCH_MODULE_SCHEMAS_FAILED,
  error
});
export const rehydrateModuleSchemasSucceeded = response => ({
  type: REHYDRATE_MODULE_SCHEMAS_SUCCEEDED,
  response
});
export const rehydrateModuleSchemasFailed = error => ({
  type: REHYDRATE_MODULE_SCHEMAS_FAILED,
  error
});
export const moduleSchemasReady = () => {};

const getCachedApiResultFromSuperstore = (superstorePromise, key) => {
  return superstorePromise.then(store => store.get(key));
};

const saveApiResultInSuperstore = (superstorePromise, key, result) => {
  return superstorePromise.then(store => store.set(key, result)).catch(e => {
    console.error('Error in saving api result in superstore: ', e);
  });
}; // Note, this is only v1 globals (v2 globals will come in the main "combined" modules request)


export const fetchGlobalModules = () => dispatch => {
  dispatch(requestGlobalModules());

  const success = resp => dispatch(receiveGlobalModules(resp));

  const error = resp => dispatch(receiveGlobalModulesFailure(resp));

  return ModuleSchemasApi.fetchGlobalModules().then(success, error);
};
export const fetchCustomModules = ({
  contentId,
  superstorePromise,
  hasDeveloperProjectsAccess
}) => (dispatch, getState) => {
  const state = getState();
  const isUngatedForFilteredModuleSchemaFetching = getIsUngatedForFilteredModuleSchemaFetching(state);
  dispatch(requestCustomModules());

  const success = resp => dispatch(receiveCustomModules(resp, true));

  const error = resp => dispatch(receiveCustomModulesFailure(resp));

  const successAndCache = resp => {
    saveApiResultInSuperstore(superstorePromise, COMBINED_MODULE_DEFINITION_API_KEY, resp);
    return success(resp);
  };

  if (isUngatedForFilteredModuleSchemaFetching) {
    superstorePromise.catch(e => {
      console.error('Error in accessing superstore instance', e);
    });
    return ModuleSchemasApi.fetchCustomModules(contentId).then(success, error);
  } //HACK (brodgers) Always bypass the cache here since there will be new versions
  // of each module for every Project build. This is not an ideal long-term solution.


  if (hasDeveloperProjectsAccess) {
    return ModuleSchemasApi.fetchCustomModules(contentId).then(successAndCache);
  }

  const cachedResultPromise = getCachedApiResultFromSuperstore(superstorePromise, COMBINED_MODULE_DEFINITION_API_KEY).then(cachedResult => {
    if (!cachedResult) {
      return Promise.reject(new Error('Cache does not have module schemas'));
    }

    return dispatch(receiveCustomModules(cachedResult, {
      fromSuperStoreCache: true
    }));
  }); // Both of these will be called regardless of whichever promise resolves first
  // (i.e if the cache promise resolves first, successAndCache will still be called
  //  which wil allow us to update the module list we have in the redux store)

  return raceToFirstSucceeded([cachedResultPromise, ModuleSchemasApi.fetchCustomModules().then(successAndCache)]).catch(error);
};
export const rehydrateModuleSchemasWithLanguage = language => (dispatch, getState) => {
  const success = resp => dispatch(rehydrateModuleSchemasSucceeded(resp));

  const error = resp => dispatch(rehydrateModuleSchemasFailed(resp));

  return ModuleSchemasApi.fetchUsedModuleSchemasWithTranslations(getContentModelId(getState()), language).then(success, error);
};
export const fetchModuleSchemas = (contentSchemaPromise, superstorePromise, contentId, hasDeveloperProjectsAccess) => (dispatch, getState) => {
  const state = getState();

  if (!state.appStatus.get('moduleSchemasFetched')) {
    dispatch({
      type: FETCH_MODULE_SCHEMAS_STARTED
    });

    const error = resp => {
      return dispatch(fetchModuleSchemasFailed(resp));
    };

    const success = requests => {
      const [globalModules, customModules] = requests;
      const categoryName = getContentTypeName(getState()); // Need to check for errors again, because we already disapatch the failure action for each
      // of the sub-requests, which turns it into a resolved promise again

      for (const request of requests) {
        if (request.error) {
          return error(request);
        }
      }

      return dispatch(fetchModuleSchemasSucceeded({
        categoryName,
        globalModules: globalModules.response,
        customModules: customModules.response
      }, {
        fromSuperStoreCache: customModules.fromSuperStoreCache
      }));
    };

    const fetchSpread = [dispatch(fetchGlobalModules()), dispatch(fetchCustomModules({
      contentId,
      hasDeveloperProjectsAccess,
      superstorePromise
    }))];
    if (contentSchemaPromise) fetchSpread.push(contentSchemaPromise);
    Promise.all(fetchSpread).then(success, error).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
    return undefined;
  }

  return null;
}; // A shim for scalable editor "editor config" requests to utilize existing complex module fetching logic

export const standardFetchCustomModules = () => (dispatch, getState) => {
  // parseEditorUrl comes from earlyRequester.js, may need to rethink how that works
  const {
    contentId
  } = window.parseEditorUrl(); // hasDeveloperProjectsAccess selector...

  const hasDeveloperProjectsAccess = getHasDeveloperProjectsAccess(getState()); // Kick off super store request

  const superstorePromise = getPerPortalSuperstoreInstance();
  return dispatch(fetchCustomModules({
    contentId,
    superstorePromise,
    hasDeveloperProjectsAccess
  }));
}; // A shim for sclaable editor

export const standardFetchGlobalV1Modules = () => dispatch => {
  return dispatch(fetchGlobalModules());
};