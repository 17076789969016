'use es6';

import { userInfoSync } from 'hub-http/userInfo';
import { COMMENT_INDICATOR_IDENTIFIER, COMMENTABLE_AREA_IDENTIFIER } from 'collaboration-sidebar/constants/identifiers';
import { COMMENT_TYPES } from 'collaboration-sidebar/constants/commentObjectChildTypes'; // Required to stop propogation when clicking a comment
// TODO: Can be removed by adding tracking of if a single
// commentable area is active or not.

export const isTargetCommentElement = target => {
  if (!target) {
    return false;
  } // If the click is in the main commentable area


  if (target.className.includes(COMMENTABLE_AREA_IDENTIFIER)) {
    return true;
  } // If the click has no parent we can't check anything
  // else if it's a commentable area


  if (!target.parentNode) {
    return false;
  } // If the parent is the comment indicator icon, capture it as a click.


  return target.parentNode.className.includes(COMMENT_INDICATOR_IDENTIFIER) || target.parentNode.id === COMMENT_INDICATOR_IDENTIFIER;
};
export function isFromCurrentUser(comment) {
  return comment.userId === userInfoSync().user.user_id;
}
export function getTimeSincePreviousCommentIfFromDifferentUser(comments) {
  const previousComment = comments.slice().reverse().find(comment => comment.commentObjectChildType !== COMMENT_TYPES.THREAD);

  if (!previousComment || isFromCurrentUser(previousComment)) {
    return null;
  }

  return Date.now() - previousComment.createdAt;
}