import offerBuilderApi from '../core/api/offerBuilderApi';
import quantityUpgradesApi from '../core/api/quantityUpgradesApi';
import { createOfferPayload } from '../core/utilities/createOfferPayload';
import { createQuantityUpgradesPayload } from '../core/utilities/createQuantityUpgradesPayload';
import { validateMultipleOfferPurchseConfig, validatePurchaseConfig } from 'self-service-api/core/validators';
import { getIsSkuOwned } from './getIsProductOwned';
import { getSkuMap, getProductMap, getProductInfoFromSkuId } from '../core/utilities/productUtils';
import { QUANTITY_PACK } from 'self-service-api/constants/SkuTypes';
import { SALES_HUB_STARTER, SALES_HUB_PROFESSIONAL } from 'self-service-api/constants/ApiNames';
import VersionNumbers from 'self-service-api/constants/VersionNumbers';
import { isCoreSeatSku, isQuantifiableSalesOrServiceSeatSku } from '../core/utilities/seatUtils';

const isQuantityPackSkuId = (skuId, skuMap) => {
  const skuType = skuMap[skuId].skuType;
  return skuType === QUANTITY_PACK;
};

const getIsQuantityUpgrade = ({
  productConfigurations
}, products, skuMap) => {
  if (!productConfigurations || !productConfigurations.length) {
    return Promise.resolve(false);
  }

  const isQuantityUpgradePromises = productConfigurations.map(({
    skuId
  }) => {
    // Add special case for sales starter and sales pro legacy additional seat purchase
    const productInfo = getProductInfoFromSkuId(products, skuId);
    const versionNumber = skuMap[skuId].versionNumber;

    if (productInfo && productInfo.apiName === SALES_HUB_STARTER && versionNumber === VersionNumbers[SALES_HUB_STARTER].LEGACY) {
      return getIsSkuOwned(skuId);
    }

    if (productInfo && productInfo.apiName === SALES_HUB_PROFESSIONAL && versionNumber === VersionNumbers[SALES_HUB_PROFESSIONAL].LEGACY_BUSINESS_USER) {
      return getIsSkuOwned(skuId);
    } // Update for Seats -> treat additional core seat purchases as if they're quantity packs


    if (productInfo && isCoreSeatSku(productInfo.apiName)) {
      return getIsSkuOwned(skuId);
    } // Update for Seats -> treat sales/service at pro/ent as if they're quantity packs


    if (productInfo && isQuantifiableSalesOrServiceSeatSku(products, skuMap, skuId)) {
      return getIsSkuOwned(skuId);
    }

    return Promise.resolve(isQuantityPackSkuId(skuId, skuMap));
  });
  return Promise.all(isQuantityUpgradePromises).then(isQuantityUpgrade => {
    return isQuantityUpgrade.every(upgrade => !!upgrade);
  });
};

const getPurchaseOrderId = (purchaseConfig, products) => {
  const skuMap = getSkuMap(products);
  const productMap = getProductMap(products);
  validatePurchaseConfig(purchaseConfig, productMap, skuMap);
  const {
    offerId,
    currencyCode,
    productConfigurations,
    productBasedOffers = []
  } = purchaseConfig;
  return getIsQuantityUpgrade(purchaseConfig, products, skuMap).then(isQuantityUpgrade => {
    if (isQuantityUpgrade) {
      const quantityUpgradesPayload = createQuantityUpgradesPayload(productConfigurations, products);
      return quantityUpgradesApi.post(quantityUpgradesPayload);
    }

    const offerPayload = createOfferPayload({
      offerId,
      productConfigurations,
      productBasedOffers,
      products,
      requestedCurrency: currencyCode
    });
    return offerBuilderApi.post({
      offerPayload
    }).then(response => response.id);
  });
};

const getIsMultipleOfferQuantityUpgrade = ({
  offerProductConfigurations
}, products, skuMap) => {
  if (!offerProductConfigurations || !offerProductConfigurations.length) {
    return Promise.resolve(false);
  }

  const skuIdsInConfig = offerProductConfigurations.map(({
    productConfigurations
  }) => productConfigurations).flat().map(({
    skuId
  }) => skuId);
  const isQuantityUpgradePromises = skuIdsInConfig.map(skuId => {
    // Add special case for sales starter and sales pro legacy additional seat purchase
    const productInfo = getProductInfoFromSkuId(products, skuId);
    const versionNumber = skuMap[skuId].versionNumber;

    if (productInfo && productInfo.apiName === SALES_HUB_STARTER && versionNumber === VersionNumbers[SALES_HUB_STARTER].LEGACY) {
      return getIsSkuOwned(skuId);
    }

    if (productInfo && productInfo.apiName === SALES_HUB_PROFESSIONAL && versionNumber === VersionNumbers[SALES_HUB_PROFESSIONAL].LEGACY_BUSINESS_USER) {
      return getIsSkuOwned(skuId);
    } // Update for Seats -> treat additional core seat purchases as if they're quantity packs


    if (productInfo && isCoreSeatSku(productInfo.apiName)) {
      return getIsSkuOwned(skuId);
    } // Update for Seats -> treat sales/service at pro/ent as if they're quantity packs


    if (productInfo && isQuantifiableSalesOrServiceSeatSku(products, skuMap, skuId)) {
      return getIsSkuOwned(skuId);
    }

    return Promise.resolve(isQuantityPackSkuId(skuId, skuMap));
  });
  return Promise.all(isQuantityUpgradePromises).then(isQuantityUpgrade => {
    return isQuantityUpgrade.every(upgrade => !!upgrade);
  });
};

export const getMultipleOfferPurchaseOrderId = (purchaseConfig, products) => {
  const skuMap = getSkuMap(products);
  validateMultipleOfferPurchseConfig(purchaseConfig);
  const {
    offerProductConfigurations,
    nonRecurringProductConfigurations,
    currencyCode,
    productBasedOffers = []
  } = purchaseConfig;
  return getIsMultipleOfferQuantityUpgrade(purchaseConfig, products, skuMap).then(isQuantityUpgrade => {
    if (isQuantityUpgrade) {
      const quantityUpgradesPayload = offerProductConfigurations.map(({
        productConfigurations: configs
      }) => configs).flat();
      return quantityUpgradesApi.post(quantityUpgradesPayload);
    }

    const offerPayload = {
      nonRecurringProductConfigurations,
      requestedCurrency: currencyCode,
      offerProductConfigurations: [...offerProductConfigurations, ...productBasedOffers.map(productBasedOfferId => ({
        offerId: productBasedOfferId,
        productConfigurations: []
      }))]
    };
    return offerBuilderApi.post({
      offerPayload
    }).then(response => response.id);
  });
};
export default getPurchaseOrderId;