export const APP_AUTH = 'APP_AUTH';
export const CONTACT = 'CONTACT';
export const LIST_MEMBERSHIP = 'LIST_MEMBERSHIP';
export const SSO_LOGIN = 'SSO_LOGIN';
export const MEMBERSHIP_LOGIN = 'MEMBERSHIP_LOGIN';
export const SSO_WITH_LIST_MEMBERSHIP = 'SSO_WITH_LIST_MEMBERSHIP';
export const PublicAccessRuleTypes = {
  APP_AUTH,
  CONTACT,
  LIST_MEMBERSHIP,
  SSO_LOGIN,
  MEMBERSHIP_LOGIN,
  SSO_WITH_LIST_MEMBERSHIP
};