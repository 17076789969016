'use es6';

import { createSelector } from 'reselect';
import I18n from 'I18n';
import { basicSelectorWithStats } from './helpers';
import { getIsReadOnlyMode } from './appStatusSelectors';
import { getIsAdvancedPageTemplateWithoutAccess } from './templateInfoSelectors';
import { getIsPublished } from './publishSelectors';
import { getAllowDomainChangeForNinetyDaysAfterDowngrade, getCustomDomainsPurgeDate } from './portalSelectors';
import { getContentTypeLimit } from './limitSelectors';
import { getIsLandingPage, getIsSitePage } from './contentReadOnlyDataSelectors';
import { getHasWebsitePagesNoBranding, getIsUngatedForCMSFreeBeta, getHasPaymentsReadAccess, getHasPersonalizationAccess, getHasSmartContentScope, getHasCtaAccess, getHasHubspotVideoAccess } from './authSelectors';
import { isRichTextModule, isRichTextModuleWithCta, isRichTextModuleWithHsVideo, isVideoModuleWithHsVideo, isOrHasCtaModule, isOrHasVideoModuleWithHsVideo, searchModuleRichTextFieldsForRegexValidation, getKeysThatMatchRegex } from './moduleSelectorHelpers';
import { hsPersonalizeConfig } from 'tinymce-plugins/constants/hspersonalize';
import { getTokenPattern, getPropertyOnlyTokenPattern, getTokenPatternWithDefault } from 'tinymce-plugins/hspersonalize/utils';
import { getSupportedCrmObjectFQNs } from 'ContentEditorUI/redux/selectors/featureSelectors';
import { getModuleIdsByBuiltinType, getSchemaForModule } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getModules, getModuleById, getIsGlobalModule, getIsCustomCmv2Module, getAnyModulesHaveSmartContent } from './moduleSelectors';
import { getIsBlogPost } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
export const getIsReadOnlyButSlugChangeAllowedAfterDowngrades = createSelector([getIsReadOnlyMode, getIsAdvancedPageTemplateWithoutAccess, getIsPublished], (isReadOnlyMode, isAdvancedTemplateWithoutAccess, isPublished) => isReadOnlyMode && isAdvancedTemplateWithoutAccess && !isPublished);
export const getIsDomainChangeAllowedAfterDowngrades = createSelector([getIsReadOnlyMode, getIsAdvancedPageTemplateWithoutAccess, getAllowDomainChangeForNinetyDaysAfterDowngrade, getCustomDomainsPurgeDate, getContentTypeLimit], (isReadOnlyMode, isAdvancedTemplateWithoutAccess, allowDomainChange, customDomainsPurgeDateForFreeLP, contentTypeLimit) => !isReadOnlyMode && // has advanced access or page template isn't advanced
!isAdvancedTemplateWithoutAccess && ( // within grace period after losing multi-domain-publishing
allowDomainChange || // grace period for losing custom domains in downgrade to free
!!contentTypeLimit && contentTypeLimit.pageLimit && customDomainsPurgeDateForFreeLP));
export const getIsFreeLPPortal = createSelector([getIsLandingPage, getContentTypeLimit], (isLandingPage, contentTypeLimit) => isLandingPage && contentTypeLimit && contentTypeLimit.pageLimit > 0);
export const getIsFreePagesPortal = createSelector([getIsLandingPage, getIsSitePage, getContentTypeLimit, getHasWebsitePagesNoBranding, getIsUngatedForCMSFreeBeta], (isLandingPage, isSitePage, contentTypeLimit, hasWebsitePagesNoBranding, isUngatedForCMSFreeBeta) => {
  return !!contentTypeLimit && contentTypeLimit.pageLimit > 0 && (isLandingPage || isUngatedForCMSFreeBeta && isSitePage && !hasWebsitePagesNoBranding);
});
const advancedModuleIdsWithoutAccessMap = new Map();

const getHasCtaField = (state, module) => {
  if (module.get('type') === 'cta') {
    return true;
  }

  const moduleId = module.get('module_id');
  const builtInMapping = getModuleIdsByBuiltinType(state);
  const id = module.get('id');

  if (moduleId) {
    if (getIsGlobalModule(state, id) || getIsCustomCmv2Module(state, id)) {
      const moduleSpec = !!module && getSchemaForModule(state, module);
      const fields = moduleSpec.fields;
      return fields.some(field => field.type === 'cta');
    }

    return isOrHasCtaModule(module, builtInMapping);
  } else if (module.get('id') === 'post_body') {
    return isRichTextModuleWithCta(module, builtInMapping);
  }

  return false;
};

const getHasHsVideoField = (state, module) => {
  const moduleId = module.get('module_id');
  const builtInMapping = getModuleIdsByBuiltinType(state);

  if (moduleId) {
    const id = module.get('id');

    if (getIsGlobalModule(state, id) || getIsCustomCmv2Module(state, id)) {
      const moduleSpec = !!module && getSchemaForModule(state, module);
      const fields = moduleSpec.fields;
      return fields.some(field => field.type === 'videoplayer');
    }

    return isOrHasVideoModuleWithHsVideo(module, builtInMapping);
  } else if (module.get('id') === 'post_body') {
    return isRichTextModuleWithHsVideo(module, builtInMapping);
  }

  return false;
};

const getIsOrHasCTAWithoutAccess = (state, module) => {
  const hasCtaAccess = getHasCtaAccess(state);

  if (hasCtaAccess) {
    return false;
  }

  return getHasCtaField(state, module);
};

const getIsOrHasHsVideoWithoutAccess = (state, module) => {
  const hasHsVideoAccess = getHasHubspotVideoAccess(state);

  if (hasHsVideoAccess) {
    return false;
  }

  return getHasHsVideoField(state, module);
};

const getIsSomeAdvancedModuleWithoutAccess = (state, module) => getIsOrHasCTAWithoutAccess(state, module) || getIsOrHasHsVideoWithoutAccess(state, module);

export const getIsMissingAccessToSomeAdvancedModule = createSelector([getHasCtaAccess, getHasHubspotVideoAccess], (hasCtaAccess, hasHsVideoAccess) => !hasCtaAccess || !hasHsVideoAccess);
export const getIsAdvancedModuleWithoutAccess = basicSelectorWithStats((state, module) => {
  if (!module || !getIsMissingAccessToSomeAdvancedModule(state)) {
    return false;
  }

  const isAdvancedModuleWithoutAccess = getIsSomeAdvancedModuleWithoutAccess(state, module);

  if (isAdvancedModuleWithoutAccess) {
    const moduleIdsByBuiltinType = getModuleIdsByBuiltinType(state);
    advancedModuleIdsWithoutAccessMap.set(module.get('id'), {
      isRichTextModule: isRichTextModule(module, moduleIdsByBuiltinType),
      isHsVideoModule: isVideoModuleWithHsVideo(module, moduleIdsByBuiltinType)
    });
  } else if (advancedModuleIdsWithoutAccessMap.has(module.get('id'))) {
    advancedModuleIdsWithoutAccessMap.delete(module.get('id'));
  }

  return isAdvancedModuleWithoutAccess;
});
let prevModules = null;
let cachedVideoAndCtaModulesWithoutAccess = null; // Currently the advanced modules/fields are:
//  - HS videos
//  - CTAs
// If you need to add more, just add it to the map below and add your check
// in the module loop as well

const getAdvancedModulesToRemoveByTypeWithoutCache = (state, modules) => {
  const modulesWithoutAccessByTypeMap = {
    videos: [],
    ctas: []
  };
  modules.forEach(module => {
    const isCtaWithoutAccess = getIsOrHasCTAWithoutAccess(state, module);
    const isVideoWithoutAccess = getIsOrHasHsVideoWithoutAccess(state, module);

    if (isCtaWithoutAccess) {
      modulesWithoutAccessByTypeMap.ctas.push(module);
    }

    if (isVideoWithoutAccess) {
      modulesWithoutAccessByTypeMap.videos.push(module);
    }
  });
  return modulesWithoutAccessByTypeMap;
};

export const getAdvancedModulesToRemoveByType = basicSelectorWithStats(state => {
  const isMissingAccessToSomeAdvancedModule = getIsMissingAccessToSomeAdvancedModule(state);

  if (!isMissingAccessToSomeAdvancedModule) {
    return null;
  }

  const modules = getModules(state);

  if (!prevModules) {
    prevModules = modules;
  } else if (prevModules === modules) {
    // Workaround since we can't really use createSelector here
    // Just do a referential equality check on the modules and return the previously
    // computed modules without access (by type) if the module references are the same
    return cachedVideoAndCtaModulesWithoutAccess;
  }

  const videoAndCtaModulesWithoutAccess = getAdvancedModulesToRemoveByTypeWithoutCache(state, modules);
  prevModules = modules;
  cachedVideoAndCtaModulesWithoutAccess = videoAndCtaModulesWithoutAccess;
  return videoAndCtaModulesWithoutAccess;
});
export const getIsModuleIdInAdvancedModuleIdsWithoutAccessMap = id => {
  return advancedModuleIdsWithoutAccessMap.has(id);
};
export const getAdvancedModuleWithoutAccessInfo = id => advancedModuleIdsWithoutAccessMap.get(id);
export const getHasCtaModulesWithoutAccess = createSelector([getAdvancedModulesToRemoveByType], advancedModulesToRemoveByType => advancedModulesToRemoveByType && advancedModulesToRemoveByType.ctas.length > 0);
export const getHasHsVideoModulesWithoutAccess = createSelector([getAdvancedModulesToRemoveByType], advancedModulesToRemoveByType => advancedModulesToRemoveByType && advancedModulesToRemoveByType.videos.length > 0);
export const getHasSmartContentWithoutAccess = createSelector([getAnyModulesHaveSmartContent, getHasSmartContentScope], (anyModulesHaveSmartContent, hasSmartContentScope) => anyModulesHaveSmartContent && !hasSmartContentScope);
const paymentsModulesWithoutAccessSet = new Set();

const getIsPaymentModule = (state, module, builtInMapping) => {
  const moduleId = module.get('module_id');

  if (moduleId) {
    const id = module.get('id');

    if (getIsGlobalModule(state, id) || getIsCustomCmv2Module(state, id)) {
      const moduleSpec = !!module && getSchemaForModule(state, module);
      const fields = moduleSpec.fields;
      return fields.find(field => field.type === 'payment');
    } else {
      return builtInMapping['payments'] === moduleId;
    }
  }

  return false;
};

export const getModuleHasPaymentsFieldWithoutAccess = basicSelectorWithStats((state, module) => {
  if (!module || getHasPaymentsReadAccess(state)) {
    return false;
  }

  const builtInMapping = getModuleIdsByBuiltinType(state);
  const hasPaymentsFieldWithoutAccess = getIsPaymentModule(state, module, builtInMapping);

  if (hasPaymentsFieldWithoutAccess) {
    paymentsModulesWithoutAccessSet.add(module.get('id'));
  }

  return hasPaymentsFieldWithoutAccess;
});
let cachedModulesWithPaymentFieldWithoutAccess = null;
let prevCheckedModulesForPayments = null;
export const getModulesWithPaymentFieldWithoutAccess = basicSelectorWithStats(state => {
  if (getHasPaymentsReadAccess(state)) {
    return null;
  }

  const modules = getModules(state);
  const builtInMapping = getModuleIdsByBuiltinType(state);

  if (!prevCheckedModulesForPayments) {
    prevCheckedModulesForPayments = modules;
  } else if (prevCheckedModulesForPayments === modules) {
    return cachedModulesWithPaymentFieldWithoutAccess;
  }

  const modulesWithPaymentsFieldWithoutAccess = [];
  modules.forEach(module => {
    if (getIsPaymentModule(state, module, builtInMapping)) {
      modulesWithPaymentsFieldWithoutAccess.push(module);
    }
  });
  cachedModulesWithPaymentFieldWithoutAccess = modulesWithPaymentsFieldWithoutAccess;
  prevCheckedModulesForPayments = modules;
  return modulesWithPaymentsFieldWithoutAccess;
});
export const getContentHasPaymentsFieldWithoutAccess = createSelector([getModulesWithPaymentFieldWithoutAccess], modulesWithPaymentsFieldWithoutAccess => modulesWithPaymentsFieldWithoutAccess ? modulesWithPaymentsFieldWithoutAccess.length > 0 : false);
export const getIsModuleIdInPaymentsModulesWithoutAccessSet = id => {
  return paymentsModulesWithoutAccessSet.has(id);
};
const getPersonalizationRegexes = createSelector([getSupportedCrmObjectFQNs], supportedCrmObjectFQNs => {
  const hsPersonalizeSettings = {
    [hsPersonalizeConfig.SUPPORTED_CRM_OBJECT_FQNS]: supportedCrmObjectFQNs
  };
  const tokenPatternRegex = getTokenPattern(hsPersonalizeSettings);
  const propertyOnlyTokenPatternRegex = getPropertyOnlyTokenPattern(hsPersonalizeSettings);
  const tokenPatternWithDefaultRegex = getTokenPatternWithDefault(hsPersonalizeSettings);
  return [tokenPatternRegex, propertyOnlyTokenPatternRegex, tokenPatternWithDefaultRegex];
}); // Structure of richTextFieldsWithPTokens
// {
//  id: {
//    moduleReference: Module - used to check if the module changed at all
//    fieldKeyPaths: [['key', 'array']],
//  }
// }

let richTextFieldsWithPTokens = null;
const builtInRTEFieldPath = [['html']];
let cachedModulesForPTokenDowngrades = null;
export const getModulesWithPersonalizationTokensWithoutAccess = basicSelectorWithStats(state => {
  // If they have personalization access or it's a blog post, then we don't need to check for downgrades
  if (getHasPersonalizationAccess(state) || getIsBlogPost(state)) {
    return null;
  }

  const modules = getModules(state); // Make sure we don't throw if this is called before the content schema comes back

  if (!modules) {
    return null;
  } // Check if this is our first time computing this selector


  const isFirstComputation = richTextFieldsWithPTokens === null;

  if (!richTextFieldsWithPTokens) {
    richTextFieldsWithPTokens = {};
  } else if (Object.keys(richTextFieldsWithPTokens).length === 0) {
    // If it's been computed once and there aren't any values, then there are no pTokens
    // Thus just return the empty object because the user can't add any pTokens
    return richTextFieldsWithPTokens;
  } // If the module list is the same, then no module has changed
  // In that case, just return the previously computed value


  if (!cachedModulesForPTokenDowngrades) {
    cachedModulesForPTokenDowngrades = modules;
  } else if (modules === cachedModulesForPTokenDowngrades) {
    return richTextFieldsWithPTokens;
  } // We have to build the regexes to look for pTokens dynamically, and we get
  // an array of regexes to check from this selector


  const personalizationRegexes = getPersonalizationRegexes(state);
  const builtInMapping = getModuleIdsByBuiltinType(state);
  let anythingChanged = false; // If this is the first computation, we don't know what, if any, modules have pTokens
  // So, we have to iterate through all of them and search all of their fields for a richtext field
  // with pTokens

  if (isFirstComputation) {
    modules.forEach(module => {
      const id = module.get('id');

      if (isRichTextModule(module, builtInMapping)) {
        const html = module.getIn(['body', 'html']);

        if (html && personalizationRegexes.some(r => !!html.match(r))) {
          richTextFieldsWithPTokens[id] = {
            moduleReference: module,
            fieldKeyPaths: builtInRTEFieldPath
          };
        }
      } else if (getIsGlobalModule(state, id) || getIsCustomCmv2Module(state, id)) {
        const moduleSpec = !!module && getSchemaForModule(state, module);
        const moduleBody = module.get('body'); // If we have a custom module, we don't know where to look for richtext fields to
        // pattern match against our regex's
        // So, we traverse the entire tree of fields (because of field groups) looking for
        // richtext fields that have pTokens. This util will traverse the fields for us and
        // return an array of field key arrays
        // NOTE: This does not check default values, only values overwritten on the content object

        const keysWithPTokens = searchModuleRichTextFieldsForRegexValidation(moduleBody, moduleSpec, personalizationRegexes);

        if (keysWithPTokens.length > 0) {
          richTextFieldsWithPTokens[id] = {
            moduleReference: module,
            fieldKeyPaths: keysWithPTokens
          };
        }
      }
    });
  } else {
    // If this isn't our first iteration, then we don't need to search through all the modules
    // because we know that the user can't add any more pTokens
    // So at this point, we should have found all the pTokens, the only thing a user can do now
    // is delete them
    Object.keys(richTextFieldsWithPTokens).forEach(id => {
      const previouslyComputedPTokenMapping = richTextFieldsWithPTokens[id];
      const module = getModuleById(state, id); // We compare the module in Redux with the module reference we already have from the last time
      // this module was checked.
      // If the reference is the same, we know that the module hasn't changed and thus doesn't need to
      // be checked again

      if (module && previouslyComputedPTokenMapping.moduleReference !== module) {
        anythingChanged = true;
        const moduleBody = module.get('body'); // Get any remaining field key arrays that still match one of the regex's

        const updatedKeysWithPTokens = getKeysThatMatchRegex(moduleBody, previouslyComputedPTokenMapping.fieldKeyPaths, personalizationRegexes);

        if (updatedKeysWithPTokens.length > 0) {
          richTextFieldsWithPTokens[id] = {
            moduleReference: module,
            fieldKeyPaths: updatedKeysWithPTokens
          };
        } else {
          // If the user has deleted all the pTokens, then remove the module from the map
          // since we know they can't add them back
          delete richTextFieldsWithPTokens[id];
        }
      }
    });
  }

  if (!isFirstComputation && anythingChanged) {
    // Make sure we return a new reference if any values changed
    richTextFieldsWithPTokens = Object.assign({}, richTextFieldsWithPTokens);
  }

  cachedModulesForPTokenDowngrades = modules;
  return richTextFieldsWithPTokens;
});
export const getModuleHasPersonalizationTokensWithoutAccess = basicSelectorWithStats((state, module) => {
  // If they have personalization access or it's a blog post, then we don't need to check for downgrades
  if (getHasPersonalizationAccess(state) || getIsBlogPost(state) || !module) {
    return false;
  }

  const modulesWithPTokensWithoutAccess = getModulesWithPersonalizationTokensWithoutAccess(state);
  return modulesWithPTokensWithoutAccess.hasOwnProperty(module.get('id'));
});
export const getIsModuleInPersonalizationTokensWithoutAccessMap = id => richTextFieldsWithPTokens && richTextFieldsWithPTokens.hasOwnProperty(id);
export const getContentHasPersonalizationTokensWithoutAccess = createSelector([getModulesWithPersonalizationTokensWithoutAccess], modulesWithPTokensWithoutAccess => {
  return Boolean(modulesWithPTokensWithoutAccess && Object.keys(modulesWithPTokensWithoutAccess).length > 0);
});
export const getAdvancedModuleWithoutAccessError = createSelector([getAdvancedModulesToRemoveByType], advancedModulesToRemoveByType => {
  if (!advancedModulesToRemoveByType) {
    return {};
  }

  const errors = {};
  const videos = advancedModulesToRemoveByType.videos;
  const ctas = advancedModulesToRemoveByType.ctas;

  if (videos.length > 0) {
    const title = `components.header.validationErrorPopover.removeModulesErrors.${videos.length === 1 ? 'module' : 'modules'}`;
    errors.videosToRemove = {
      pane: 'content',
      type: 'advancedModuleWithoutAccess',
      fieldSchema: {
        title: I18n.text(title),
        amount: videos.length,
        moduleType: I18n.text('components.header.validationErrorPopover.moduleType.video')
      },
      moduleId: videos[0].get('id')
    };
  }

  if (ctas.length > 0) {
    const title = `components.header.validationErrorPopover.removeModulesErrors.${ctas.length === 1 ? 'module' : 'modules'}`;
    errors.ctasToRemove = {
      pane: 'content',
      type: 'advancedModuleWithoutAccess',
      fieldSchema: {
        title: I18n.text(title),
        amount: ctas.length,
        moduleType: I18n.text('components.header.validationErrorPopover.moduleType.cta')
      },
      moduleId: ctas[0].get('id')
    };
  }

  return errors;
});
export const getPaymentsFieldWithoutAccessError = createSelector([getModulesWithPaymentFieldWithoutAccess], modulesWithPaymentFieldWithoutAccess => {
  if (!modulesWithPaymentFieldWithoutAccess) {
    return {};
  }

  const errors = {};

  if (modulesWithPaymentFieldWithoutAccess.length > 0) {
    errors.paymentFieldsToRemove = {
      pane: 'content',
      type: 'paymentFieldWithoutAccess',
      fieldSchema: {
        amount: modulesWithPaymentFieldWithoutAccess.length
      },
      moduleId: modulesWithPaymentFieldWithoutAccess[0].get('id')
    };
  }

  return errors;
});
export const getPersonalizationTokensWithoutAccessError = createSelector([getModulesWithPersonalizationTokensWithoutAccess], modulesWithPersonalizationTokensWithoutAccess => {
  if (!modulesWithPersonalizationTokensWithoutAccess) {
    return {};
  }

  const errors = {};

  if (Object.keys(modulesWithPersonalizationTokensWithoutAccess).length > 0) {
    let total = 0;
    let firstId = null;
    Object.values(modulesWithPersonalizationTokensWithoutAccess).forEach(moduleWithPToken => {
      total += moduleWithPToken.fieldKeyPaths.length;

      if (!firstId) {
        firstId = moduleWithPToken.moduleReference.get('id');
      }
    });
    errors.personaliationTokensToRemove = {
      pane: 'content',
      type: 'personalizationTokenWithoutAccess',
      fieldSchema: {
        amount: total
      },
      moduleId: firstId
    };
  }

  return errors;
});