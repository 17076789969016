'use es6';

import { createSelector } from 'reselect';
import { isEmpty } from 'underscore';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { stringify } from 'hub-http/helpers/params';
const getBasePreviewIframeUrl = basicSelector(state => state.iframes.previewIframeUrl.split('?')[0]);
const getDefaultPreviewQueryParams = basicSelector(state => state.iframes.defaultPreviewQueryParams);
export const getSmartRulesPreviewParams = basicSelector(state => state.iframes.smartRulesPreviewParams || {});
export const getIsSmartContentPreview = createSelector([getSmartRulesPreviewParams], smartRulesPreviewParams => !isEmpty(smartRulesPreviewParams));
export const getPreviewIframeUrl = createSelector([getBasePreviewIframeUrl, getDefaultPreviewQueryParams, getIsSmartContentPreview, getSmartRulesPreviewParams], (basePreviewUrl, defaultPreviewQueryParams, isPreviewingSmartContent, smartRulesPreviewParams) => {
  const queryParams = Object.assign({}, defaultPreviewQueryParams, {}, smartRulesPreviewParams, {
    hsSmartContentDefault: !isPreviewingSmartContent
  });
  return `${basePreviewUrl}?${stringify(queryParams)}`;
});
export const getSidebarIframeUrl = basicSelector(state => state.iframes.sidebarIframeUrl);
export const getPreviewDomain = basicSelector(state => state.iframes.previewDomain);
export const getHublet = basicSelector(state => state.iframes.hublet);