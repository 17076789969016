import { parse } from 'hub-http/helpers/params';
export const getQueryString = () => window.location.search.slice(1);
export const getQueryParams = () => parse(getQueryString());
export const getQueryParam = key => {
  const params = getQueryParams();
  return params[key] || null;
};
export const getTourIdFromQueryParams = () => {
  const {
    search
  } = document.location;

  if (!search) {
    return null;
  }

  const parsedParams = getQueryParams();
  return parsedParams.tourId || parsedParams.onboardingTourId;
};