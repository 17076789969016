import { CMS_ENTERPRISE, CMS_PROFESSIONAL } from 'self-service-api/constants/UpgradeProducts';
import { activateCms } from '../api/activateByProduct';
export const productActivationProducts = [CMS_ENTERPRISE, CMS_PROFESSIONAL];
export const getActivationFn = upgradeProduct => {
  switch (upgradeProduct) {
    case CMS_PROFESSIONAL:
    case CMS_ENTERPRISE:
      return activateCms;

    default:
      return null;
  }
};