'use es6';

import { Map as ImmutableMap } from 'immutable';
import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
const getDeleteRequestState = basicSelector(state => state.deleteRequest);
const getLayoutSectionDeleteRequest = createSelector([getDeleteRequestState], state => state.get('layoutSectionDeleteRequest') || new ImmutableMap());
export const getModuleIdToDelete = createSelector([getDeleteRequestState], state => state.get('moduleIdToDelete'));
export const getLayoutSectionModuleIdToDelete = createSelector(getLayoutSectionDeleteRequest, deleteRequest => deleteRequest.get('moduleIdToDelete'));
export const getLayoutSectionColumnIdToDelete = createSelector(getLayoutSectionDeleteRequest, deleteRequest => deleteRequest.get('columnIdToDelete'));
export const getLayoutSectionRowIdToDelete = createSelector(getLayoutSectionDeleteRequest, deleteRequest => deleteRequest.get('rowIdToDelete'));
export const getLayoutSectionIdForDeleteRequest = createSelector(getLayoutSectionDeleteRequest, deleteRequest => deleteRequest.get('layoutSectionId'));
export const getIsLayoutSectionDeleteRequested = createSelector(getModuleIdToDelete, getLayoutSectionModuleIdToDelete, getLayoutSectionColumnIdToDelete, getLayoutSectionRowIdToDelete, (moduleIdToDelete, layoutSectionModuleIdToDelete, columnIdToDelete, rowIdToDelete) => !!moduleIdToDelete || !!layoutSectionModuleIdToDelete || !!columnIdToDelete || !!rowIdToDelete);