'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import { createNewModuleFromSchema } from 'ContentUtils/helpers/ModuleCreationHelpers';

const makePlaceholder = moduleName => {
  const placeholder = document.createElement('div');
  placeholder.id = `hs_cos_wrapper_${moduleName}`;
  return placeholder;
};

export const makeModuleWrapper = (name, hubl, id, renderedModuleNode) => {
  const wrapper = document.createElement('div');
  wrapper.dataset.hsPlaceholder = true;
  wrapper.dataset.mceHubspotObject = true;
  wrapper.dataset.mceSanitizeAllow = true;
  wrapper.dataset.mceHubspotModuleCid = id;
  wrapper.dataset.mceHubspotModuleName = name;
  wrapper.dataset.mceHubspotModuleHubl = escape(hubl);
  wrapper.classList.add('mceNonEditable');
  wrapper.setAttribute('contenteditable', false);
  const child = renderedModuleNode || makePlaceholder(name);
  wrapper.appendChild(child);
  return wrapper;
};
export const isModuleNode = node => node.matches && node.matches('[data-mce-hubspot-module-name]');
export const getModuleWrapper = node => {
  while (node) {
    if (isModuleNode(node)) return node;
    if (!node.parentNode) break;
    node = node.parentNode;
  }

  return null;
};
const HUBL_REGEX = /{% module "(.*?)" (.*?) %\}/;

const makeIdentifyingHublRegex = moduleId => {
  return RegExp(`{% module "${moduleId}" (.*?) %}`);
};

const nodeIteratorToArray = nodeIterator => {
  const nodes = [];
  let node = nodeIterator.nextNode();

  while (node) {
    nodes.push(node);
    node = nodeIterator.nextNode();
  }

  return nodes;
};

const parseFields = rawFields => {
  const fieldStrings = rawFields.split(', ');
  const fieldKeyValues = fieldStrings.map(fieldString => {
    const [key, value] = fieldString.split('=');
    return [key.trim(), JSON.parse(value)];
  });
  return Object.fromEntries(fieldKeyValues);
};

export const parseHublModulesFromText = textNode => {
  const regex = RegExp(HUBL_REGEX, 'g');
  const matches = [...textNode.textContent.matchAll(regex)];
  const hublModules = matches.map(match => {
    const [hubl, name, rawFields] = match;
    const fields = parseFields(rawFields);

    const {
      label,
      path
    } = fields,
          body = _objectWithoutPropertiesLoose(fields, ["label", "path"]);

    return {
      hubl,
      path,
      module: {
        type: 'module',
        id: name,
        name,
        label,
        body
      },
      parent_name: 'post_body'
    };
  });
  return hublModules;
};
/**
 * Finds hubl text nodes within the dom body by using a text node iterator
 */

export const findHublTextNodes = rootNode => {
  const textNodeIterator = document.createNodeIterator(rootNode, NodeFilter.SHOW_TEXT, node => {
    if (HUBL_REGEX.test(node.textContent)) {
      return NodeFilter.FILTER_ACCEPT;
    }

    return NodeFilter.FILTER_REJECT;
  }, false);
  return nodeIteratorToArray(textNodeIterator);
};
export const makeModuleHubl = (module, schema) => {
  const {
    name,
    body
  } = module;
  const {
    label,
    path: module_path
  } = schema;
  const bodyString = Object.entries(body).map(([key, value]) => `${key}=${JSON.stringify(value)}`).join(', ');
  return `{% module "${name}" path="${module_path}", label="${label}", ${bodyString} %}`;
};
export const updateHublModule = (html, module, schema) => {
  const identifyingRegexp = makeIdentifyingHublRegex(module.name);
  return html.replace(identifyingRegexp, makeModuleHubl(module, schema));
};
export const deleteHublModule = (html, module) => {
  const identifyingRegexp = makeIdentifyingHublRegex(module.name);
  return html.replace(identifyingRegexp, '');
};
export const makeRichTextModule = schema => {
  const name = `widget_${Date.now()}`;
  const moduleInstance = createNewModuleFromSchema(schema, {}, name, name);
  moduleInstance.parent_name = 'post_body'; //TODO figure out how to make this dynamic so it works with real rich text fields

  moduleInstance.body = Object.assign({}, moduleInstance.body, {
    module_id: schema.moduleId,
    schema_version: schema.schemaVersion,
    css_class: 'rich-text-module',
    tag: 'module',
    no_wrapper: false
  });
  return moduleInstance;
};