/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import * as actionTypes from 'ContentEditorUI/redux/actions/actionTypes';
import { getActiveDomainName } from '../selectors/baseContentModelSelectors';
import { fetchDomainExcludedReason as requestDomainExclusionReason } from '../../api/Domains';
import { getCurrentDomainIsNotPermitted } from '../selectors/portalDomainsSelectors';
import { getUserId } from '../selectors/authSelectors';
import { getCategoryId } from '../selectors/contentReadOnlyDataSelectors';

const fetchDomainExclusionReasonSucceeded = response => ({
  type: actionTypes.FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS,
  payload: response
});

const fetchDomainExclusionReasonFailed = error => ({
  type: actionTypes.FETCH_DOMAIN_EXCLUSION_REASON_FAILURE,
  error
});

export const fetchDomainExclusionReason = () => (dispatch, getState) => {
  const state = getState(); // Content schema with domains list MUST be in state before calling this

  if (!getCurrentDomainIsNotPermitted(state)) return;
  dispatch({
    type: actionTypes.FETCH_DOMAIN_EXCLUSION_REASON_REQUEST
  });

  const success = resp => dispatch(fetchDomainExclusionReasonSucceeded(resp));

  const error = resp => dispatch(fetchDomainExclusionReasonFailed(resp));

  requestDomainExclusionReason(getActiveDomainName(state), getUserId(state), getCategoryId(state)).then(success, error).catch(err => {
    setTimeout(() => {
      throw err;
    });
  });
};