'use es6';

import PropTypes from 'prop-types';
export const TrialActivationButtonPropsInterface = {
  trialId: PropTypes.number,
  shouldHideSurvey: PropTypes.bool,
  onSuccess: PropTypes.func,
  successModalHeader: PropTypes.node,
  successModalSubText: PropTypes.node,
  successModalCtaText: PropTypes.node,
  showSuccessModalIllustration: PropTypes.bool
};