'use es6';

import I18n from 'I18n';
import StateStatusGroups from 'ContentUtils/constants/StateStatusGroups';
import States from 'ContentUtils/constants/States';
export const getContentTypeStringFromCategoryId = (categoryId, subcategory) => {
  let contentType;

  switch (`${categoryId}`) {
    case '1':
      if (subcategory === 'site_page') {
        contentType = 'sitePage';
      } else {
        contentType = 'landingPage';
      }

      break;

    case '2':
      contentType = 'email';
      break;

    case '3':
      contentType = 'blogPost';
      break;

    case '4':
      contentType = 'sitePage';
      break;

    default:
      return '';
  }

  return I18n.text(`contentTypeFromId.${contentType}`);
};
export const getContentAppPathFromCategoryId = id => {
  let path;

  switch (id) {
    case '1':
      path = 'content';
      break;

    case '4':
      path = 'content';
      break;

    case '2':
      path = 'email';
      break;

    case '3':
      path = 'blog';
      break;

    default:
      return '';
  }

  return path;
};
export const publishDateIsBackdated = publishDate => {
  const pickerDate = I18n.moment.utc(publishDate).portalTz();
  const cutoffTime = I18n.moment().subtract(60, 'minutes');
  return pickerDate.isBefore(cutoffTime);
};
export const publishDateIsScheduled = (publishDate, shouldPublishImmediately) => {
  if (typeof shouldPublishImmediately === 'boolean') {
    return !shouldPublishImmediately && publishDate > I18n.moment.portalTz();
  }

  return publishDate > I18n.moment.portalTz();
};
export const isPublishedState = state => StateStatusGroups.PUBLISHED.includes(state);
export const isScheduledState = state => StateStatusGroups.SCHEDULED.includes(state);
export const isAutomatedState = state => state === States.AUTOMATED;