'use es6';

import { createSelector } from 'reselect';
import { basicSelector } from 'ContentEditorUI/redux/selectors/helpers';
import { getModuleIdsByBuiltinType } from 'ContentEditorUI/redux/selectors/moduleSchemaSelectors';
import { getDisplayName } from 'ContentEditorUI/redux/selectors/moduleSelectors';
import { SETTING_REC_TYPE_TO_LABEL, RECOMMENDATION_TARGET_TYPES } from 'ContentEditorUI/lib/xray/constants';
import SEO_RECOMMENDATIONS from 'ContentEditorUI/lib/xray/seoRecommendations';
const getOptimizationState = basicSelector(state => state.optimizationState ? state.optimizationState.toJS() : {});
export const getOptimizations = createSelector(getOptimizationState, optimizationState => optimizationState.optimizations ? optimizationState.optimizations : []);
export const getPredicateMap = createSelector(getModuleIdsByBuiltinType, builtInMapping => {
  return {
    ADD_IMAGE_ALT_TEXT: module => {
      return module.get('id') === 'module_16582492808948' || module.get('id') === 'widget_1657653087095' || module.get('module_id') === builtInMapping.linked_image && !module.getIn(['body', 'img', 'alt']);
    }
  };
});
export const getOptToTargetMap = createSelector(getOptimizationState, optimizationState => optimizationState.optToTargetMap);
export const getHighlightedField = createSelector(getOptimizationState, optimizationState => optimizationState.highlightedField);
export const getTargetToDisplayNameMap = (state, optToTargetMap) => {
  const targetToDisplayNameMap = {};
  const mapEntries = Object.entries(optToTargetMap);
  mapEntries.forEach(mapEntry => {
    const recType = mapEntry[0];
    const targetType = SEO_RECOMMENDATIONS[recType].targetType;

    switch (targetType) {
      case RECOMMENDATION_TARGET_TYPES.MODULE:
        {
          mapEntry[1].forEach(module => {
            const moduleId = module.id;
            targetToDisplayNameMap[moduleId] = getDisplayName(state, moduleId);
          });
          break;
        }

      case RECOMMENDATION_TARGET_TYPES.SETTING:
        {
          const setting = mapEntry[1];
          targetToDisplayNameMap[setting] = SETTING_REC_TYPE_TO_LABEL[setting];
          break;
        }

      default:
        {
          throw new Error(`Optimization type ${recType} not recognized`);
        }
    }
  });
  return targetToDisplayNameMap;
};