/* hs-eslint ignored failing-rules */

/* eslint-disable promise/catch-or-return */
'use es6';

import { SHOW_LOADING, HIDE_LOADING, MAB_EXPERIMENT_MODAL_LOADED, FORCE_SHOW_END_TEST_MODAL, MAB_EXPERIMENT_MODAL_LOADING } from 'ContentEditorUI/redux/actions/actionTypes';
import ABTestApi from 'ContentEditorUI/api/ABTestApi';
import ContentRoutes from 'ContentUtils/Routes';
import { setTemporaryNewAbTestBit } from 'ContentEditorUI/utils/temporaryLocalStorageState';
import { getABTest, getAbActiveVariantId, getAbMasterId } from 'ContentEditorUI/redux/selectors/abTestSelectors';
import { getBaseApiUrl } from 'ContentEditorUI/redux/selectors/extraInitialStateSelectors';
import { getCategoryId } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getContentModelId, getContentModelName } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { showErrorWithHTML } from 'ContentEditorUI/utils/errorUtils';
import UsageTracker from 'ContentEditorUI/utils/UsageTracker';
import { getHasCurrentTranslations } from 'ContentEditorUI/redux/selectors/translationSelectors';
import { getSubcategory } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getBaseContentUrl } from 'ContentEditorUI/api/ContentApi';
import { getAdditionalTranslationOptionsForSaveError } from 'ContentEditorUI/utils/errorUtils';
import { setTemporaryNewAbTestSetup } from 'ContentEditorUI/utils/temporaryLocalStorageState';
import { AB_VERSION } from 'ContentComponents/constants/AbConstants';
import { AB_CANCELED_PARAM, AB_ENDED_WITH_WINNER_PARAM } from 'ContentComponents/abPattern/AbTestConcludedAlert';
import Categories from 'ContentUtils/constants/Categories';
export const createABTestAndMaybeUpdateMasterName = (masterName, variationName, stayOnMaster = false) => {
  return (dispatch, getState) => {
    const appState = getState();
    const categoryId = getCategoryId(appState);
    const subcategory = getSubcategory(appState);
    const baseContentUrl = getBaseContentUrl({
      baseUrl: getBaseApiUrl(appState),
      categoryId,
      subcategory,
      contentId: getContentModelId(appState)
    });
    const contentName = getContentModelName(appState);
    const hasCurrentTranslations = getHasCurrentTranslations(appState);
    dispatch({
      type: MAB_EXPERIMENT_MODAL_LOADING
    });

    if (hasCurrentTranslations) {
      UsageTracker.trackAsBeacon('editorInteraction', {
        action: 'Experiment Action',
        event: 'clicked-create-ab-experiment-for-multi-lang'
      });
    } else {
      UsageTracker.trackAsBeacon('editorInteraction', {
        action: 'Experiment Action',
        event: 'clicked-create-ab-experiment'
      });
    }

    const renameMasterPromise = masterName === contentName ? Promise.resolve() : ABTestApi.renameMasterVariation(baseContentUrl, masterName);
    renameMasterPromise.then(() => ABTestApi.createAbTest(baseContentUrl, variationName).then(variation => {
      if (!variation || !variation.id) {
        return;
      }

      setTemporaryNewAbTestBit();
      dispatch({
        type: MAB_EXPERIMENT_MODAL_LOADED
      });

      if (stayOnMaster) {
        location.reload();
      } else {
        window.location.href = ContentRoutes.edit(variation.id, categoryId);
      }
    })).catch(error => {
      showErrorWithHTML({
        response: error,
        action: 'abTestCreationFailed',
        additionalTranslationOptions: getAdditionalTranslationOptionsForSaveError(error.responseJSON, categoryId, subcategory)
      });
      dispatch({
        type: MAB_EXPERIMENT_MODAL_LOADED
      });
    });
  };
};
export const setupAbTest = ({
  versionA,
  versionB,
  distribution,
  winningMetric,
  duration
}) => {
  setTemporaryNewAbTestSetup({
    abTestPercentage: distribution,
    abSuccessMetric: winningMetric,
    abHoursToWait: duration
  });
  return createABTestAndMaybeUpdateMasterName(versionA, versionB, true);
};
export const cancelAbTest = testId => {
  return (dispatch, getState) => {
    const appState = getState();
    const abtest = getABTest(appState);
    const contentId = getContentModelId(appState);
    const categoryId = getCategoryId(appState);
    const baseContentUrl = getBaseContentUrl({
      baseUrl: getBaseApiUrl(appState),
      categoryId,
      subcategory: getSubcategory(appState)
    });
    const masterId = abtest.getIn(['master', 'id']);
    dispatch({
      type: SHOW_LOADING
    });
    ABTestApi.cancelAbTest(baseContentUrl, testId).then(() => {
      dispatch({
        type: HIDE_LOADING
      });
      let newLocation = window.location.href.replace(contentId, masterId);

      if (categoryId === Categories.email.id) {
        const url = new URL(newLocation);
        url.searchParams.append(AB_CANCELED_PARAM, 'true');
        newLocation = url.toString();
      }

      if (newLocation === window.location.href) {
        window.location.reload();
      } else {
        window.location = newLocation;
      }
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
};
export const chooseAbTestWinner = selectedVersion => {
  return (dispatch, getState) => {
    const appState = getState();
    const abtest = getABTest(appState);
    const abTestId = abtest.get('abTestId');
    const contentId = getContentModelId(appState);
    const categoryId = getCategoryId(appState);
    const baseContentUrl = getBaseContentUrl({
      baseUrl: getBaseApiUrl(appState),
      categoryId,
      subcategory: getSubcategory(appState)
    });
    const winnerId = abtest.getIn([selectedVersion === AB_VERSION.A ? 'master' : 'activeVariant', 'id']);
    dispatch({
      type: SHOW_LOADING
    });
    ABTestApi.endAbTest(baseContentUrl, abTestId, winnerId).then(() => {
      dispatch({
        type: HIDE_LOADING
      });
      let newLocation = window.location.href.replace(contentId, winnerId);

      if (categoryId === Categories.email.id) {
        const url = new URL(newLocation);
        url.searchParams.append(AB_ENDED_WITH_WINNER_PARAM, selectedVersion);
        newLocation = url.toString();
      }

      if (newLocation === window.location.href) {
        window.location.reload();
      } else {
        window.location = newLocation;
      }
    }).catch(err => {
      setTimeout(() => {
        throw err;
      });
    });
  };
};
export const forceEndTestModal = value => {
  return dispatch => {
    dispatch({
      type: FORCE_SHOW_END_TEST_MODAL,
      value
    });
  };
};
export const updatePagesAbTest = ({
  testId,
  deleteAbTest = false,
  createNewTest = false,
  renameVariationValue,
  renameMasterValue,
  newVariationName
}) => (dispatch, getState) => {
  const appState = getState();
  const categoryId = getCategoryId(appState);

  const baseContentUrl = contentId => getBaseContentUrl({
    baseUrl: getBaseApiUrl(appState),
    categoryId,
    subcategory: getSubcategory(appState),
    contentId
  });

  const masterId = getAbMasterId(appState);
  const variationId = getAbActiveVariantId(appState);
  dispatch({
    type: SHOW_LOADING
  });
  const deleteAbTestPromise = deleteAbTest ? ABTestApi.cancelAbTest(baseContentUrl(), testId) : Promise.resolve();
  deleteAbTestPromise.then(() => {
    const renameMasterPromise = renameMasterValue ? ABTestApi.renameMasterVariation(baseContentUrl(masterId), renameMasterValue) : Promise.resolve();
    const renameVariationPromise = renameVariationValue && !deleteAbTest ? ABTestApi.renameTestVariation(baseContentUrl(variationId), renameVariationValue) : Promise.resolve();
    return Promise.all([renameMasterPromise, renameVariationPromise]);
  }).then(() => {
    const createNewTestPromise = deleteAbTest && createNewTest && newVariationName ? ABTestApi.createAbTest(baseContentUrl(masterId), newVariationName) : Promise.resolve();
    return createNewTestPromise;
  }).then(variation => {
    dispatch({
      type: HIDE_LOADING
    });
    let pageId = masterId;

    if (variation && variation.id) {
      pageId = variation.id;
    }

    window.location.href = ContentRoutes.edit(pageId, categoryId);
  });
};