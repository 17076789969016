'use es6';

import shallowEqual from 'ContentEditorUI/utils/shallowEqual';
import { isEmpty, difference, intersection } from 'underscore';

const getValue = (obj, key) => {
  if (!obj) {
    return obj;
  }

  return typeof obj.get !== 'undefined' ? obj.get(key) : obj[key];
};

const isEqual = (val1, val2, isStrict) => {
  return val1 === val2 || !isStrict && !val1 && !val2; // we just assume falsey values are equal in non-strict
};

export const calculateDiff = (prevState, nextState, currentPath = '', opts = {}) => {
  const {
    isStrict = true,
    ignoreRegex = null
  } = opts;

  if (ignoreRegex && ignoreRegex.test(currentPath) || isEqual(prevState, nextState, isStrict)) {
    return null;
  }

  if ((prevState === null || prevState === undefined) && nextState !== null) {
    return {
      prev: prevState,
      next: nextState
    };
  }

  if (nextState === null || nextState === undefined) {
    return {
      prev: prevState,
      next: nextState
    };
  }

  const diff = {};
  const keysInPrevState = Object.keys(prevState);
  const keysInNextState = Object.keys(nextState);
  [...difference(keysInNextState, keysInPrevState), ...difference(keysInPrevState, keysInNextState)].forEach(key => {
    const path = `${currentPath}/${key}`;

    if (!(ignoreRegex && ignoreRegex.test(path)) && (isStrict || prevState[key] || nextState[key])) {
      diff[key] = {
        prev: prevState[key],
        next: nextState[key]
      };
    }
  });
  intersection(keysInNextState, keysInPrevState).forEach(key => {
    const path = `${currentPath}/${key}`;

    if (!(ignoreRegex && ignoreRegex.test(path))) {
      const prevStateForKey = getValue(prevState, key);
      const nextStateForKey = getValue(nextState, key);

      if (typeof prevStateForKey === 'object') {
        const nestedDiff = calculateDiff(prevStateForKey, nextStateForKey, path, opts);

        if (nestedDiff && !isEmpty(nestedDiff)) {
          diff[key] = nestedDiff;
        }
      } else if (!shallowEqual(prevStateForKey, nextStateForKey)) {
        diff[key] = {
          prev: prevStateForKey,
          next: nextStateForKey
        };
      }
    }
  });
  return diff;
};
export const hasDiff = (prevState, nextState) => {
  const diff = calculateDiff(prevState, nextState);
  return diff && Object.keys(diff).length !== 0;
};
export const arrayShallowCompare = (a, b) => {
  if (a.length !== b.length) return false;
  return a.reduce((acc, el, idx) => acc && el === b[idx], true);
};