'use es6';

import { Map as ImmutableMap } from 'immutable';
import { FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED } from '../actions/actionTypes';

const accountVerificationStatusReducer = (state = ImmutableMap(), {
  type,
  payload
}) => {
  switch (type) {
    case FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED:
      return ImmutableMap(payload);

    default:
      return state;
  }
};

export default accountVerificationStatusReducer;