'use es6';

export const ACTION_TYPE_PREFIX = '@@inpage-redux-action/';
export const OPEN_INPAGE_MODAL = `${ACTION_TYPE_PREFIX}OPEN_INPAGE_MODAL`;
export const CLOSE_INPAGE_MODAL = `${ACTION_TYPE_PREFIX}CLOSE_INPAGE_MODAL`;
export const OPEN_RICH_TEXT_EDITOR_FIREFOX = `${ACTION_TYPE_PREFIX}OPEN_RICH_TEXT_EDITOR_FIREFOX`;
export const CLOSE_RICH_TEXT_EDITOR_FIREFOX = `${ACTION_TYPE_PREFIX}CLOSE_RICH_TEXT_EDITOR_FIREFOX`;
export const OPEN_EDIT_TEMPLATE_MODAL = `${ACTION_TYPE_PREFIX}OPEN_EDIT_TEMPLATE_MODAL`;
export const CLOSE_EDIT_TEMPLATE_MODAL = `${ACTION_TYPE_PREFIX}CLOSE_EDIT_TEMPLATE_MODAL`;
export const LAYOUT_SECTION_ADD_TEMPORARY_OVERLAY = `${ACTION_TYPE_PREFIX}LAYOUT_SECTION_ADD_TEMPORARY_OVERLAY`;
export const LAYOUT_SECTION_REMOVE_TEMPORARY_OVERLAY = `${ACTION_TYPE_PREFIX}LAYOUT_SECTION_REMOVE_TEMPORARY_OVERLAY`;