export const CONTACT_FQN = 'contact';
export const COMPANY_FQN = 'company';
export const DEAL_FQN = 'deal';
export const TICKET_FQN = 'ticket';
export const LINE_ITEM_FQN = 'line_item';
export const QUOTE_FQN = 'quote';
export const SUBSCRIPTION_FQN = 'subscription';
export const FEEDBACK_SUBMISSION_FQN = 'feedback_submission';
export const PAYMENT_FQN = 'payment';
export const PropertyCategories = {
  CONTACT: CONTACT_FQN,
  COMPANY: COMPANY_FQN,
  PRIMARY_COMPANY: 'primary_company',
  DEAL: DEAL_FQN,
  LINE_ITEM: LINE_ITEM_FQN,
  SITE_SETTINGS: 'site_settings',
  // Office Location!?
  SUBSCRIPTION_TYPE: 'subscription_type',
  TICKET: TICKET_FQN,
  QUOTE: QUOTE_FQN,
  FEEDBACK_SUBMISSION: FEEDBACK_SUBMISSION_FQN,
  CONVERSATION: 'conversation',
  SUBSCRIPTION: SUBSCRIPTION_FQN,
  PAYMENT: PAYMENT_FQN
};
export const PropertyTypes = {
  DATE: 'date',
  DATE_TIME: 'datetime'
};
export const CategoryOrder = [PropertyCategories.COMPANY, PropertyCategories.CONTACT, PropertyCategories.SITE_SETTINGS, PropertyCategories.DEAL, PropertyCategories.LINE_ITEM, PropertyCategories.SUBSCRIPTION_TYPE, PropertyCategories.TICKET, PropertyCategories.QUOTE, PropertyCategories.SUBSCRIPTION, PropertyCategories.PAYMENT];
export const SiteSettings = ['company_name', 'company_street_address_1', 'company_street_address_2', 'company_city', 'company_state', 'company_zip', 'company_country', 'company_phone'];
export const UserPropertyOptions = [{
  value: 'fullname',
  text: 'Full name'
}, {
  value: 'firstname',
  text: 'First name'
}, {
  value: 'lastname',
  text: 'Last name'
}, {
  value: 'email',
  text: 'Email'
}, {
  value: 'signature',
  text: 'Signature'
}, {
  value: 'meetings_link',
  text: 'Meetings link'
}];
export const PropertyOnlyTokenToCategory = {
  subscription_name: 'subscription_type'
};
export const PropertyOnlyTokens = ['subscription_name'];
export const DeprecatedProperties = ['ownername', 'owneremail'];
export const USER_PROPERTY_TYPE = 'OWNER';
export const HUBSPOT_OWNER_PROPERTY_NAME = 'hubspot_owner_id';