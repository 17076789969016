'use es6';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { debounce } from 'underscore';
import { getContentModelId } from 'ContentEditorUI/redux/selectors/baseContentModelSelectors';
import { getFailedInpageAssets, getInpageJsLoadedMultipleTimes } from 'ContentEditorUI/redux/selectors/inpageSelectors';

const mapStateToProps = state => ({
  contentModelId: getContentModelId(state),
  failedInpageAssets: getFailedInpageAssets(state),
  inpageJsLoadedMultipleTimes: getInpageJsLoadedMultipleTimes(state)
}); // Temporary component to get a handle on how many customers have pages that seemingly have
// "critial failures" before figureing out how/where to show errors in the UI


class TrackCriticalAssetFailuresInNewRelic extends Component {
  constructor(props) {
    super(props);
    this.logCritialFailuresInNewRelic = debounce(this.logCritialFailuresInNewRelic, 3000);
  }

  componentDidMount() {
    const {
      contentModelId,
      failedInpageAssets,
      inpageJsLoadedMultipleTimes
    } = this.props;

    if (failedInpageAssets.size > 0) {
      this.logCritialFailuresInNewRelic();
    }

    if (inpageJsLoadedMultipleTimes) {
      this.logMultipleInpageJSinNewRelic(contentModelId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      contentModelId,
      failedInpageAssets,
      inpageJsLoadedMultipleTimes
    } = this.props;

    if (prevProps.failedInpageAssets !== failedInpageAssets && failedInpageAssets.size > 0) {
      this.logCritialFailuresInNewRelic();
    }

    if (inpageJsLoadedMultipleTimes) {
      this.logMultipleInpageJSinNewRelic(contentModelId);
    }
  } // Send a new browser interaction to new relic for failed inpage assets (once per editor session)
  // https://insights.newrelic.com/accounts/1393184/query?query=SELECT%20browserInteractionName,%20contentId,%20failedAssets%20%20%20from%20BrowserInteraction%20where%20browserInteractionName%20%3D%20%27critial-inpage-editor-assets-failed%27%20since%207%20days%20ago&hello=overview


  logCritialFailuresInNewRelic() {
    const {
      contentModelId,
      failedInpageAssets
    } = this.props;

    if (this.alreadyLoggedCritialFailuresToNewRelicOnce) {
      return;
    }

    this.alreadyLoggedCritialFailuresToNewRelicOnce = true;
    const failedAssetsString = failedInpageAssets.toArray().toString();

    if (window.newrelic && window.newrelic.interaction) {
      const nrInteraction = window.newrelic.interaction(); // Our NR wrapper will make interaction() return undefined when not deployed

      if (nrInteraction) {
        nrInteraction.setName('critial-inpage-editor-assets-failed').setAttribute('contentId', contentModelId).setAttribute('failedAssets', failedAssetsString).setAttribute('numFailedAssets', failedInpageAssets.size).save();
      }
    }
  }

  logMultipleInpageJSinNewRelic(contentModelId) {
    if (this.alreadyLoggedMultipleInpageJsToNewRelicOnce) {
      return;
    }

    this.alreadyLoggedMultipleInpageJsToNewRelicOnce = true;

    if (window.newrelic && window.newrelic.interaction) {
      const nrInteraction = window.newrelic.interaction(); // Our NR wrapper will make interaction() return undefined when not deployed

      if (nrInteraction) {
        nrInteraction.setName('inpage-editor-js-run-multiple-times').setAttribute('contentId', contentModelId).save();
      }
    }
  }

  render() {
    return null;
  }

}

TrackCriticalAssetFailuresInNewRelic.propTypes = {
  contentModelId: PropTypes.number.isRequired,
  failedInpageAssets: PropTypes.instanceOf(List),
  inpageJsLoadedMultipleTimes: PropTypes.bool.isRequired
};
export default connect(mapStateToProps)(TrackCriticalAssetFailuresInNewRelic);