'use es6';

import { getOptimizations, getFilteredOptimizationsAndTargets } from 'ContentEditorUI/api/OptimizeApi';
import { FETCH_OPTIMIZATIONS_STARTED, FETCH_OPTIMIZATIONS_SUCCEEDED, FETCH_OPTIMIZATIONS_FAILED, SET_HIGHLIGHTED_FIELD } from 'ContentEditorUI/redux/actions/actionTypes';
import { getOptimizationsRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestFailedOrUninitialized } from 'redux-request-state/util/requestStateCheckers';
import { updateSelectedItem } from 'ContentEditorUI/redux/actions/selectionActions';
import { openConfirmEditGlobal } from 'ContentEditorUI/redux/actions/appActions';
import { setScrollTo } from 'ContentEditorUI/redux/actions/baseSettingsActions';
import { clearInpageUIState } from 'ContentEditorUI/redux/actions/inpageActions';
import { routeToPage } from 'ContentEditorUI/redux/actions/routingActions';
import { toCamelCase } from 'ContentEditorUI/utils/helpers';
export const fetchOptimizations = contentModelId => {
  return (dispatch, getState) => {
    const state = getState();

    if (requestFailedOrUninitialized(getOptimizationsRequest(state))) {
      dispatch({
        type: FETCH_OPTIMIZATIONS_STARTED
      });
      getOptimizations(contentModelId).then(resp => getFilteredOptimizationsAndTargets(resp, state)).then(resultsArray => {
        dispatch({
          type: FETCH_OPTIMIZATIONS_SUCCEEDED,
          payload: {
            optimizations: resultsArray[0],
            optToTargetMap: resultsArray[1]
          }
        });
      }).catch(resp => {
        dispatch({
          type: FETCH_OPTIMIZATIONS_FAILED,
          payload: resp
        });
      });
    }
  };
};
export const setModuleHighlightedField = field => {
  return {
    type: SET_HIGHLIGHTED_FIELD,
    payload: {
      field
    }
  };
};
export const selectModuleForXray = moduleId => {
  return dispatch => {
    dispatch(updateSelectedItem(moduleId));
  };
};
export const openConfirmEditGlobalForXray = moduleId => {
  return dispatch => {
    dispatch(openConfirmEditGlobal(moduleId));
  };
};
export const openSettingsForXRay = field => {
  const camelCaseField = toCamelCase(field);
  return dispatch => {
    dispatch(clearInpageUIState());
    dispatch(routeToPage('settings'));
    dispatch(setScrollTo(camelCaseField));
  };
};