import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import * as UpgradeProducts from 'self-service-api/constants/UpgradeProducts';
export const i18nKeysByUpgradeProduct = {
  [UpgradeProducts.SALES_PRO]: 'salesPro',
  [UpgradeProducts.SALES_STARTER]: 'salesStarter',
  [UpgradeProducts.SALES_PROFESSIONAL]: 'salesProfessional',
  [UpgradeProducts.SALES_ENTERPRISE]: 'salesEnterprise',
  [UpgradeProducts.SUITE_STARTER]: 'suiteStarter',
  [UpgradeProducts.MARKETING_STARTER]: 'marketingStarter',
  [UpgradeProducts.MARKETING_STARTER_EMAIL]: 'marketingStarterEmail',
  [UpgradeProducts.MARKETING_BASIC]: 'marketingBasic',
  [UpgradeProducts.MARKETING_PRO]: 'marketingProfessional',
  [UpgradeProducts.MARKETING_ENTERPRISE]: 'marketingEnterprise',
  [UpgradeProducts.SERVICE_STARTER]: 'serviceStarter',
  [UpgradeProducts.SERVICE_PROFESSIONAL]: 'serviceProfessional',
  [UpgradeProducts.SERVICE_ENTERPRISE]: 'serviceEnterprise',
  [UpgradeProducts.OPERATIONS_STARTER]: 'operations-starter',
  [UpgradeProducts.OPERATIONS_PROFESSIONAL]: 'operations-professional',
  [UpgradeProducts.OPERATIONS_ENTERPRISE]: 'operations-enterprise',
  [UpgradeProducts.ADS]: 'ads',
  [UpgradeProducts.WEBSITE]: 'website',
  [UpgradeProducts.REPORTING]: 'reporting',
  [UpgradeProducts.DEDICATED_IP]: 'dedicatedIp',
  [UpgradeProducts.TRANSACTIONAL_EMAIL]: 'transactionalEmail',
  [UpgradeProducts.ADDITIONAL_ACCOUNT]: 'additionalAccount',
  [UpgradeProducts.DESIGNATED_TECHNICAL_SUPPORT]: 'designatedTechnicalSupport',
  [UpgradeProducts.IN_PERSON_TRAINING]: 'inPersonTraining',
  [UpgradeProducts.ENTERPRISE]: 'enterprise',
  [UpgradeProducts.CMS_ENTERPRISE]: 'cmsEnterprise',
  [UpgradeProducts.CMS_PROFESSIONAL]: 'cmsProfessional',
  [UpgradeProducts.CMS_STARTER]: 'cmsStarter',
  [UpgradeProducts.CUSTOM_SSL]: 'customSsl',
  [UpgradeProducts.DOMAINS_LIMIT_INCREASE]: 'domains-limit-increase',
  [UpgradeProducts.SANDBOX_LIMIT_INCREASE]: 'sandbox-limit-increase',
  [UpgradeProducts.CALLING_LIMIT_INCREASE]: 'calling-limit-increase',
  [UpgradeProducts.PHONE_LIMIT_INCREASE]: 'phone-limit-increase',
  [UpgradeProducts.WHATSAPP_LIMIT_INCREASE]: 'whatsapp-limit-increase',
  [UpgradeProducts.VIDEO_LIMIT_INCREASE]: 'video-limit-increase',
  [UpgradeProducts.STARTER_CONTACTS]: 'starter-contacts'
};
export const getProductNameText = upgradeProduct => {
  const productTitleKey = i18nKeysByUpgradeProduct[upgradeProduct];
  return I18n.text(`upgrades.products.${productTitleKey}`);
};
export const getProductNameTextOrDefault = upgradeProduct => {
  const productTitleKey = i18nKeysByUpgradeProduct[upgradeProduct];
  const messageKey = `upgrades.products.${productTitleKey}`;
  if (isValidI18nKey(messageKey)) return I18n.text(messageKey);
  return I18n.text('upgrades.products.default');
};