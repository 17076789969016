'use es6';

import CellAndRowsTree from '../CellAndRowsTree';
import LayoutDataCell from './LayoutDataCell';
import RowWithSortedColumns from './RowWithSortedColumns';
import { totalColumnWidthForRow, defaultSizeForNewColumn, adjustWidthForAllColumns, nextUniqueTimestampBasedName, sortColumnsNamesBiggestToSmallestThenByDistance, sortColumnsNamesSmallestToBiggestThenByDistance, removeNullKeysFromObject } from './helpers';
import { remapNodeToTree } from '../CellAndRowsTree/privateHelpers';
import { BOOTSTRAP_2_NUM_COLUMNS, CELL_TYPE, REQUIRED_PROPERTIES_ON_LEAF_MODULE, REQUIRED_PARAM_PROPERTIES_ON_LEAF_MODULE, CELL_NAME_PREFIX, MODULE_NAME_PREFIX, ROW_NAME_PREFIX } from './constants';
/* TODOs (othe than those listed inline):
    - Any params stuff related to CSS (stripDuplicateClasses, makeGetCssClassData, etc)

  Other potential TODOs/additions:
     - Bring in things like smartObjectHelper (and smart content specific semantics?)
 */

export default class LayoutDataTree extends CellAndRowsTree {
  constructor({
    rootName,
    snapshot,
    numColumns = BOOTSTRAP_2_NUM_COLUMNS,
    shouldPreventEmptyRows = true,
    CellClass = LayoutDataCell,
    RowClass = RowWithSortedColumns
  } = {}) {
    super({
      rootName,
      snapshot,
      thisProperties: {
        numColumns,
        shouldPreventEmptyRows,
        CellClass,
        RowClass
      }
    });
  } // New methods


  getNumberColumnsInGrid() {
    return this.numColumns;
  }

  modifyCellWidth(cellName, width) {
    return this.modifySpecificKeyInCellValue(cellName, 'width', width);
  }

  modifyCellParams(cellName, newParamsValue) {
    const previousCell = this.findCell(cellName);
    const newValue = Object.assign({}, previousCell.getValue(), {
      params: newParamsValue
    });
    return this.modifyCellValue(cellName, newValue);
  }

  mergeIntoRowStyles(rowName, partialStylesValue, {
    path = []
  } = {}) {
    path.unshift('styles');
    const row = this.findRow(rowName);
    const newValue = Object.assign({}, row.getValue());

    this._mergeIntoPath(newValue, partialStylesValue, path);

    return this.modifyRowValue(row.getName(), Object.assign({}, newValue, {
      styles: removeNullKeysFromObject(newValue.styles)
    }));
  }

  mergeIntoCellStyles(cellName, partialStylesValue, {
    path = []
  } = {}) {
    path.unshift('styles');
    const cell = this.findCell(cellName);
    const newValue = Object.assign({}, cell.getValue());

    this._mergeIntoPath(newValue, partialStylesValue, path);

    return this.modifyCellValue(cell.getName(), Object.assign({}, newValue, {
      styles: removeNullKeysFromObject(newValue.styles)
    }));
  }

  mergeIntoCellParams(cellName, partialParamsValue, {
    path = []
  } = {}) {
    path.unshift('params');
    return this.mergeIntoCellValue(cellName, partialParamsValue, {
      path
    });
  } // TODO I probably should move this to RowWithSortedColumns, now that I actually have a dedicated Row class for LayoutDataTree


  isInvalidRow(row) {
    const errors = [];

    if (row.getColumnNames().length > this.getNumberColumnsInGrid()) {
      errors.push(`Row has more than ${this.getNumberColumnsInGrid()} columns (${row.getColumnNames().length})`);
    } else if (totalColumnWidthForRow(row) > this.getNumberColumnsInGrid()) {
      errors.push(`Row has more than ${this.getNumberColumnsInGrid()} width across all child columns (${totalColumnWidthForRow(row)})`);
    }

    return errors.length > 0 ? errors : false;
  } // TODO I probably should move this to LayoutDataCell, now that I actually have a dedicated Row class for LayoutDataTree


  isInvalidCell(cell) {
    const errors = []; // For convience (testing, etc), ignore validation for cells that have no value at all

    if (cell.hasValue()) {
      const cellValue = cell.getValue(); // TODO validate inner cells (module groups) differently

      if (cell.isModule() && !cell.isRoot()) {
        for (const requiredProp of REQUIRED_PROPERTIES_ON_LEAF_MODULE) {
          if (!Object.prototype.hasOwnProperty.call(cellValue, requiredProp)) {
            errors.push(`Cell is missing required property: ${requiredProp}`);
          }
        }

        if (cellValue.params) {
          for (const requiredParamsProp of REQUIRED_PARAM_PROPERTIES_ON_LEAF_MODULE) {
            if (!Object.prototype.hasOwnProperty.call(cellValue.params, requiredParamsProp)) {
              errors.push(`Cell is missing required param: ${requiredParamsProp}`);
            }
          }
        }
      }

      if (cellValue.width > this.getNumberColumnsInGrid()) {
        errors.push(`Cell width is greater than ${this.getNumberColumnsInGrid()} (${cellValue.width})`);
      }
    }

    return errors.length > 0 ? errors : false;
  }

  allModules() {
    return this.allLeafCells().filter(cell => {
      // Skip any module groups that have no children rows
      return cell.isModule();
    });
  } // Modified/extended methods


  clone(newState) {
    return new LayoutDataTree({
      snapshot: newState || this.stateSnapshot(),
      numColumns: this.numColumns,
      shouldPreventEmptyRows: this.shouldPreventEmptyRows,
      CellClass: this.CellClass,
      RowClass: this.RowClass
    });
  } // Make FE generated wrapper cells and cloned cells have names like `module_\d+` and `cell_\d+`
  // (and include a `-<int>` suffix if there was more than one name generated in a single millisecond).


  _nextCellName({
    newCellValue
  }) {
    let prefix = CELL_NAME_PREFIX;

    if (newCellValue && newCellValue.type && newCellValue.type !== 'cell') {
      prefix = MODULE_NAME_PREFIX;
    }

    return {
      tree: this,
      newName: nextUniqueTimestampBasedName(prefix)
    };
  }

  _nextRowName() {
    return {
      tree: this,
      newName: nextUniqueTimestampBasedName(ROW_NAME_PREFIX)
    };
  }

  cloneNewCellBelow(cellNameToClone) {
    const superResult = super.cloneNewCellBelow(cellNameToClone);
    const {
      tree: finalTree
    } = superResult.tree.modifyCellWidth(superResult.newCell.getName(), superResult.tree.getNumberColumnsInGrid());
    const result = {
      tree: finalTree,
      originTree: superResult.originTree,
      modifiedColumns: superResult.modifiedColumns,
      modifiedRows: superResult.modifiedRows,
      mapOfClonedToOldNodeName: superResult.mapOfClonedToOldNodeName
    };

    if (superResult.newRow) {
      result.newRow = remapNodeToTree(superResult.newRow, finalTree);
    }

    if (superResult.newCell) {
      result.newCell = remapNodeToTree(superResult.newCell, finalTree);
    }

    if (superResult.newGrandParentCell) {
      result.newGrandParentCell = finalTree.findCell(superResult.newGrandParentCell.getName());
    }

    return result;
  } // No need to override cloneNewCellToRight


  insertRow(cellNameToInsertIn, insertRowOptions) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const oldTree = this;
    const mutableTree = oldTree.makeTemporaryMutableClone();
    insertRowOptions._treeToOperateOn = mutableTree;
    const superResult = super.insertRow(cellNameToInsertIn, insertRowOptions); // If the insert was a no-op, abort

    if (superResult.nothingChanged) {
      return {
        tree: this,
        nothingChanged: superResult.nothingChanged
      };
    } // If moving a single column to a row, resize it to be the full width of the row (w = 12)


    if (superResult.newCell && insertRowOptions.existingCellName === superResult.newCell.getName()) {
      mutableTree.modifyCellWidth(superResult.newCell.getName(), mutableTree.getNumberColumnsInGrid());
    } // Don't validate/clone if we already were a mutable tree before this function was called


    let finalTree;

    if (oldTree.isMutableTree()) {
      finalTree = mutableTree;
    } else {
      mutableTree.validateWholeTree();
      finalTree = mutableTree.clone();
    }

    const result = {
      tree: finalTree,
      originTree: superResult.originTree,
      modifiedColumns: superResult.modifiedColumns,
      modifiedRows: superResult.modifiedRows
    };

    if (superResult.newRow) {
      result.newRow = remapNodeToTree(superResult.newRow, finalTree);
    }

    if (superResult.newCell) {
      result.newCell = remapNodeToTree(superResult.newCell, finalTree);
    }

    if (superResult.idsMoved) {
      result.idsMoved = superResult.idsMoved;
    }

    if (superResult.mapOfClonedToOldNodeName) {
      result.mapOfClonedToOldNodeName = superResult.mapOfClonedToOldNodeName;
    }

    if (superResult.newGrandParentCell) {
      result.newGrandParentCell = remapNodeToTree(superResult.newGrandParentCell, finalTree);
    }

    return result;
  }

  removeRow(rowName, {
    automaticallyDeleteEmptyParents,
    automaticallyDeleteDescendents = true,
    ancestorToNotAutoDelete,
    _treeToOperateOn,
    addWidthFromSiblings = true
  } = {}) {
    const oldTree = _treeToOperateOn || this;
    const mutableTree = oldTree.makeTemporaryMutableClone();
    const {
      deletedColumns,
      deletedRows
    } = super.removeRow(rowName, {
      automaticallyDeleteEmptyParents,
      automaticallyDeleteDescendents,
      ancestorToNotAutoDelete,
      _treeToOperateOn: mutableTree,
      addWidthFromSiblings
    }); // Note the IDE doesn't have the same "stealing" algorithm, rather re-uses splitEvenly for adding removing a new column

    if (addWidthFromSiblings === true) {
      this.adjustWidthAfterDeletingColumn(mutableTree, oldTree, deletedRows, deletedColumns);
    } // Don't validate/clone if we already were a mutable tree before this function was called


    if (oldTree.isMutableTree()) {
      return {
        tree: oldTree,
        deletedColumns,
        deletedRows
      };
    }

    mutableTree.validateWholeTree();
    const finalTree = mutableTree.clone();
    return {
      tree: finalTree,
      deletedColumns,
      deletedRows
    };
  }

  insertColumn(rowNameToInsertIn, {
    destColumnIndex,
    existingCellName,
    existingRowName,
    cellNameToClone,
    newCellName,
    newCellValue,
    originTree,
    stealWidthFromSiblings = true
  }) {
    const existingCellParentName = !originTree && existingCellName ? this.findCell(existingCellName).getParentName() : null;
    const movingCellInSameRow = rowNameToInsertIn === existingCellParentName; // Note the IDE doesn't have the same "stealing" algorithm, rather re-uses splitEvenly for adding removing a new column

    if (movingCellInSameRow || !stealWidthFromSiblings) {
      return super.insertColumn(rowNameToInsertIn, {
        destColumnIndex,
        existingCellName,
        existingRowName,
        cellNameToClone,
        newCellName,
        newCellValue,
        _removeCellOptions: {
          addWidthFromSiblings: false
        }
      });
    } // eslint-disable-next-line @typescript-eslint/no-this-alias


    const oldTree = this;
    let mutableTree = oldTree.makeTemporaryMutableClone();
    const superResult = super.insertColumn(rowNameToInsertIn, {
      destColumnIndex,
      existingCellName,
      existingRowName,
      cellNameToClone,
      newCellName,
      newCellValue,
      originTree,
      _treeToOperateOn: mutableTree
    }); // If the insert was a no-op, abort

    if (superResult.nothingChanged) {
      return {
        tree: this,
        nothingChanged: superResult.nothingChanged
      };
    }

    let newCell = superResult.newCell;
    const parentRow = mutableTree.findRow(rowNameToInsertIn); // Calculate the the width of the newly inserted or moved cell

    const numColumnsBeforeInsert = parentRow.getColumnNames().length - 1;
    const sizeForBrandNewColumn = defaultSizeForNewColumn(numColumnsBeforeInsert, this.getNumberColumnsInGrid());
    const newColumnWidth = Math.min(newCell.getWidth(), sizeForBrandNewColumn); // Apply that value

    ({
      tree: mutableTree,
      newCell
    } = mutableTree.modifyCellWidth(newCell.getName(), newColumnWidth));
    const otherSortedColumnNames = sortColumnsNamesBiggestToSmallestThenByDistance(newCell.getParent().getColumns(), newCell.getName());
    const columnWidthAdjustmentsByName = adjustWidthForAllColumns(otherSortedColumnNames.map(colName => mutableTree.findCell(colName)), newColumnWidth, -1, this.getNumberColumnsInGrid());
    Object.keys(columnWidthAdjustmentsByName).forEach(colName => {
      const delta = columnWidthAdjustmentsByName[colName];

      if (delta !== 0) {
        const previousWidth = mutableTree.findCell(colName).getWidth();
        mutableTree.modifyCellWidth(colName, previousWidth + delta);
      }
    }); // Don't validate/clone if we already were a mutable tree before this function was called

    let finalTree;

    if (oldTree.isMutableTree()) {
      finalTree = mutableTree;
    } else {
      mutableTree.validateWholeTree();
      finalTree = mutableTree.clone();
    }

    const result = {
      tree: finalTree,
      originTree: superResult.originTree,
      newCell: remapNodeToTree(newCell, finalTree)
    };

    if (superResult.modifiedColumns) {
      result.modifiedColumns = superResult.modifiedColumns;
    }

    if (superResult.mapOfClonedToOldNodeName) {
      result.mapOfClonedToOldNodeName = superResult.mapOfClonedToOldNodeName;
    }

    if (superResult.modifiedRows) {
      result.modifiedRows = superResult.modifiedRows;
    }

    return result;
  }

  splitCell(...args) {
    // TODO, split the width of the cell targeted (and and it isn't implmented in the parent anyway)
    // Consider sharing splitEvenly in https://git.hubteam.com/HubSpot/DesignManagerUI/blob/234bad2386d620b4d1399f2777c66007bcb475c8/DesignData/static/js/reducers/unifiedLayouts/LayoutData.js#L72-L76
    return super.splitCell(...args);
  }

  removeCell(cellName, {
    automaticallyDeleteEmptyParents,
    automaticallyDeleteDescendents = true,
    ancestorToNotAutoDelete,
    _treeToOperateOn,
    addWidthFromSiblings = true
  } = {}) {
    const oldTree = _treeToOperateOn || this;
    const mutableTree = oldTree.makeTemporaryMutableClone();
    const {
      deletedColumns,
      deletedRows
    } = super.removeCell(cellName, {
      automaticallyDeleteEmptyParents,
      automaticallyDeleteDescendents,
      ancestorToNotAutoDelete,
      _treeToOperateOn: mutableTree
    }); // Note the IDE doesn't have the same "stealing" algorithm, rather re-uses splitEvenly for adding removing a new column

    if (addWidthFromSiblings === true) {
      this.adjustWidthAfterDeletingColumn(mutableTree, oldTree, deletedRows, deletedColumns);
    } // Don't validate/clone if we already were a mutable tree before this function was called


    if (oldTree.isMutableTree()) {
      return {
        tree: oldTree,
        deletedColumns,
        deletedRows
      };
    }

    mutableTree.validateWholeTree();
    const finalTree = mutableTree.clone();
    return {
      tree: finalTree,
      deletedColumns,
      deletedRows
    };
  }

  adjustWidthAfterDeletingColumn(mutableTree, oldTree, deletedRows, deletedColumns) {
    const topmostDeletedRow = deletedRows[0];
    const topmostDeletedCell = deletedColumns[0];
    const topmostDeletedCellIsChildOfDeletedRow = topmostDeletedRow && topmostDeletedCell && topmostDeletedCell.getParentName() === topmostDeletedRow.getName(); // Steal width from siblings if the topmost deleted thing is a cell

    if (topmostDeletedCell && !topmostDeletedCellIsChildOfDeletedRow) {
      // Temporary fix for issue where the topmostDeletedCell above is not actually the top
      // most deleted cell.
      const topMostDeletedCellsParentStillExistsInMutableTree = mutableTree.hasRow(topmostDeletedCell.getParentName());

      if (topMostDeletedCellsParentStillExistsInMutableTree) {
        const oldParentRow = oldTree.findRow(topmostDeletedCell.getParentName());
        const otherSortedColumnNames = sortColumnsNamesSmallestToBiggestThenByDistance(oldParentRow.getColumns(), topmostDeletedCell.getName());
        const columnWidthAdjustmentsByName = adjustWidthForAllColumns(otherSortedColumnNames.map(colName => mutableTree.findCell(colName)), -1 * topmostDeletedCell.getWidth(), 1, this.getNumberColumnsInGrid());
        Object.keys(columnWidthAdjustmentsByName).forEach(colName => {
          const delta = columnWidthAdjustmentsByName[colName];

          if (delta !== 0) {
            const previousWidth = mutableTree.findCell(colName).getWidth();
            mutableTree.modifyCellWidth(colName, previousWidth + delta);
          }
        });
      }
    }
  }

  splitCellInToFirstRowOfNewCellWrapper(cellToInsertIn, {
    _treeToOperateOn
  } = {}) {
    const oldTree = _treeToOperateOn || this;
    const mutableTree = oldTree.makeTemporaryMutableClone();
    const originalWidth = cellToInsertIn.getWidth(); // Existing cell that will moved down needs to become full-width

    const {
      newCell: modifiedCell
    } = mutableTree.modifyCellWidth(cellToInsertIn.getName(), this.getNumberColumnsInGrid());
    const {
      newGrandParentCell
    } = super.splitCellInToFirstRowOfNewCellWrapper(modifiedCell, {
      newGrandParentValue: {
        width: originalWidth,
        type: CELL_TYPE // type of a "module group"

      },
      _treeToOperateOn: mutableTree
    }); // Don't validate/clone if we already were a mutable tree before this function was called

    if (oldTree.isMutableTree()) {
      return {
        tree: oldTree,
        newGrandParentCell
      };
    }

    mutableTree.validateWholeTree();
    const finalTree = mutableTree.clone();
    return {
      tree: finalTree,
      newGrandParentCell: remapNodeToTree(newGrandParentCell, finalTree)
    };
  }

  assertIntegrity() {
    super.assertIntegrity(); // Also assert widths of rows always adds up to 12

    this.allRows().forEach(row => {
      if (row.getNumberColumns() > 0) {
        const totalRowWidth = row.getColumns().reduce((widthAccum, column) => widthAccum + column.getWidth(), 0);

        if (totalRowWidth !== this.getNumberColumnsInGrid()) {
          throw new Error(`Sum of width of all columns in ${row.getName()} !== ${this.getNumberColumnsInGrid()} (${totalRowWidth})`);
        }
      }
    });
  }

}