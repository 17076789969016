'use es6';

import { Map as ImmutableMap } from 'immutable';
import { SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP, SELECTED_ITEM_UPDATED, SELECTED_ITEM_CLEARED, CLEAR_SMART_VIEW, UPDATE_SMART_VIEW, UPDATED_MODULE_SMART_RULES } from 'ContentEditorUI/redux/actions/actionTypes';
const initialState = ImmutableMap({
  criterionId: null,
  moduleId: null,
  order: null,
  targetType: null,
  smartViewUpdatedAt: null
});

const smartViewReducer = (state = initialState, {
  type,
  payload
}) => {
  switch (type) {
    case SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP:
    case SELECTED_ITEM_UPDATED:
    case CLEAR_SMART_VIEW:
    case SELECTED_ITEM_CLEARED:
      return state.merge({
        criterionId: null,
        moduleId: null,
        order: null,
        targetType: null,
        smartViewUpdatedAt: Date.now()
      });

    case UPDATE_SMART_VIEW:
      {
        return state.merge({
          criterionId: payload.criterionId,
          moduleId: payload.moduleId,
          order: payload.order,
          targetType: payload.targetType,
          smartViewUpdatedAt: Date.now()
        });
      }

    case UPDATED_MODULE_SMART_RULES:
      {
        return state.merge({
          smartViewUpdatedAt: Date.now()
        });
      }

    default:
      return state;
  }
};

export default smartViewReducer;