'use es6';

import Immutable from 'immutable';
import { CLOSE_CODE_EDITOR_MODAL, CLOSE_CTA_PICKER, CLOSE_EDIT_VIDEO_MODAL, CLOSE_FILE_MANAGER_MODAL, CLOSE_FILE_MANAGER_MODAL_COMPLETE, CLOSE_LINK_MODAL, CLOSE_LINK_MODAL_COMPLETE, CLOSE_MODAL_COMPLETE, INSERT_CTA, INSERT_FILE, INSERT_IMAGE, MEDIA_MODAL_CLOSE, MEDIA_MODAL_OPEN, MEDIA_MODAL_SUBMIT, OPEN_CODE_EDITOR_MODAL, OPEN_CTA_PICKER, CLOSE_FORM_PICKER, OPEN_EDIT_VIDEO_2_MODAL, OPEN_FILE_MANAGER_MODAL, OPEN_LINK_MODAL, SAVE_LINK, SAVE_SOURCE_CODE, SAVE_EDIT_VIDEO_MODAL, GOOGLE_MODAL_OPEN, GOOGLE_MODAL_CLOSE, GOOGLE_MODAL_SUBMIT, OPEN_VIDEO_PICKER, CLOSE_VIDEO_PICKER, INSERT_VIDEO, OPEN_VIDEO_CONVERSION_ASSET_OPTIONS, CLOSE_VIDEO_CONVERSION_ASSET_OPTIONS, CONTENT_GENERATION_MODAL_CLOSE, CONTENT_GENERATION_MODAL_OPEN, CONTENT_GENERATION_MODAL_INSERT } from '../actions/ActionTypes';
import { ModalTypes } from '../constants/inpage';
const initialState = new Immutable.Map({
  modalType: null,
  modalId: null,
  modalProps: null
});

const InpageModals = (state = initialState, action) => {
  const {
    type,
    props,
    html,
    modalId,
    url,
    embed,
    settings,
    defaultTab
  } = action;

  switch (type) {
    case OPEN_LINK_MODAL:
      return state.merge({
        modalType: ModalTypes.LINK,
        modalProps: props,
        modalId
      });

    case OPEN_CODE_EDITOR_MODAL:
      return state.merge({
        modalType: ModalTypes.HTML_EDITOR,
        modalProps: new Immutable.Map({
          html,
          settings
        })
      });

    case OPEN_FILE_MANAGER_MODAL:
      {
        const {
          fileType
        } = action;
        return state.merge({
          modalType: ModalTypes.FILE_MANAGER,
          modalProps: new Immutable.Map({
            fileType
          }),
          modalId
        });
      }

    case OPEN_CTA_PICKER:
      return state.merge({
        modalType: ModalTypes.CTA,
        modalProps: new Immutable.Map(props),
        modalId
      });

    case OPEN_VIDEO_PICKER:
      {
        return state.merge({
          modalType: ModalTypes.VIDEO_PICKER
        });
      }

    case OPEN_EDIT_VIDEO_2_MODAL:
      {
        const {
          videoId
        } = action;
        return state.merge({
          modalType: ModalTypes.EDIT_VIDEO_MODAL,
          modalProps: new Immutable.Map({
            videoId
          }),
          modalId
        });
      }

    case OPEN_VIDEO_CONVERSION_ASSET_OPTIONS:
      {
        const {
          conversionAsset,
          video
        } = action;
        return state.merge({
          modalType: ModalTypes.VIDEO_CONVERSION_ASSET_OPTIONS,
          modalProps: new Immutable.Map({
            conversionAsset,
            video
          })
        });
      }

    case CONTENT_GENERATION_MODAL_OPEN:
      {
        return state.merge({
          modalType: ModalTypes.CONTENT_GENERATION,
          modalId
        });
      }

    case CLOSE_CTA_PICKER:
    case CLOSE_FORM_PICKER:
    case CLOSE_CODE_EDITOR_MODAL:
    case CLOSE_FILE_MANAGER_MODAL:
    case CLOSE_EDIT_VIDEO_MODAL:
    case CLOSE_VIDEO_PICKER:
    case CLOSE_LINK_MODAL:
    case INSERT_IMAGE:
    case INSERT_CTA:
    case INSERT_FILE:
    case INSERT_VIDEO:
    case SAVE_LINK:
    case SAVE_SOURCE_CODE:
    case SAVE_EDIT_VIDEO_MODAL:
    case MEDIA_MODAL_CLOSE:
    case MEDIA_MODAL_SUBMIT:
    case CLOSE_VIDEO_CONVERSION_ASSET_OPTIONS:
    case CONTENT_GENERATION_MODAL_CLOSE:
    case CONTENT_GENERATION_MODAL_INSERT:
      return state.merge({
        modalType: null,
        modalProps: null
      });

    case CLOSE_LINK_MODAL_COMPLETE:
    case CLOSE_FILE_MANAGER_MODAL_COMPLETE:
    case CLOSE_MODAL_COMPLETE:
      return state.set('modalId', null);

    case MEDIA_MODAL_OPEN:
      return state.merge({
        modalType: ModalTypes.MEDIA_MODAL,
        modalProps: new Immutable.Map({
          url,
          embed,
          settings,
          defaultTab
        }),
        modalId
      });

    case GOOGLE_MODAL_OPEN:
      return state.merge({
        modalType: ModalTypes.GOOGLE_MODAL,
        modalProps: new Immutable.Map({
          url
        }),
        modalId
      });

    case GOOGLE_MODAL_CLOSE:
    case GOOGLE_MODAL_SUBMIT:
      return state.merge({
        modalType: null,
        modalProps: null
      });

    default:
      return state;
  }
};

export default InpageModals;