'use es6';

import { FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST, FETCH_HUBDB_TABLES_REQUEST, FETCH_CONTACT_LISTS_REQUEST, FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST, FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST, FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST, FETCH_DATA_QUERIES_REQUEST } from 'ContentEditorUI/utils/requestStatesKeys';
import * as pageRequestKeys from 'page-editor-ui/utils/requestStatesKeys';
import { buildRequestStateSelector } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
export const getAvailablePageStylesheetsRequest = buildRequestStateSelector(FETCH_AVAILABLE_PAGE_STYLESHEETS_REQUEST);
export const getHubDbTablesRequest = buildRequestStateSelector(FETCH_HUBDB_TABLES_REQUEST);
export const getDataQueriesRequest = buildRequestStateSelector(FETCH_DATA_QUERIES_REQUEST);
export const getContactListsRequest = buildRequestStateSelector(FETCH_CONTACT_LISTS_REQUEST);
export const getContactListsForContentRequest = buildRequestStateSelector(FETCH_CONTACT_LISTS_FOR_CONTENT_REQUEST);
export const getContactRegistrationListsRequest = buildRequestStateSelector(FETCH_CONTACT_LIST_REGISTRATIONS_REQUEST);
export const getFeaturedImageThumbnailUrlsRequest = buildRequestStateSelector(FETCH_PAGE_FEATURED_IMAGE_THUMBNAIL_URLS_REQUEST);
export const getPageLimitRequestState = buildRequestStateSelector(pageRequestKeys.FETCH_PAGE_LIMIT_REQUEST);
export const getCrmObjectTypesRequest = buildRequestStateSelector(pageRequestKeys.FETCH_CRM_OBJECT_TYPES_REQUEST);
export const getCrmObjectSchemaRequest = buildRequestStateSelector(pageRequestKeys.FETCH_CRM_OBJECT_SCHEMA_REQUEST);