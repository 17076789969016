'use es6';

import { PriceApi } from 'self-service-api/core/api/priceApi';
export const getPrice = ({
  apiNames = [],
  currencyCode = null
}) => {
  return PriceApi.fetchPrice(apiNames, currencyCode).then(({
    prices
  }) => prices);
};
export const getPriceAndCurrencyCode = ({
  apiNames = [],
  currencyCode = null
}) => {
  return PriceApi.fetchPrice(apiNames, currencyCode).then(({
    prices,
    customerCurrencyCode
  }) => ({
    currencyCode: customerCurrencyCode,
    prices
  }));
};