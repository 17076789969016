export const getAttachedElement = (attachTo, targetDocument = document) => {
  if (typeof attachTo === 'string') {
    return targetDocument.querySelector(attachTo);
  } else if (typeof attachTo === 'function') {
    return attachTo();
  } // 'attachTo' is already an element


  return attachTo;
};
export const getBorderRadiusNumber = element => {
  const {
    borderRadius
  } = window.getComputedStyle(element);

  if (/^\d+px$/.test(borderRadius)) {
    return parseInt(borderRadius, 10);
  }

  return undefined;
};
export const isScrollableOnXAxis = (element, {
  overflowX
}) => {
  const isScrollable = overflowX !== 'hidden' && overflowX !== 'visible';
  return isScrollable && element.scrollWidth >= element.clientWidth;
};
export const isScrollableOnYAxis = (element, {
  overflowY
}) => {
  const isScrollable = overflowY !== 'hidden' && overflowY !== 'visible';
  return isScrollable && element.scrollHeight >= element.clientHeight;
};
export const getScrollParent = (element, isScrollable) => {
  if (!element) {
    return null;
  }

  const isHtmlElement = element instanceof HTMLElement;

  if (!isHtmlElement) {
    return getScrollParent(element.parentElement, isScrollable);
  }

  const computedStyle = window.getComputedStyle(element);

  if (isScrollable(element, computedStyle)) {
    return element;
  }

  const {
    position
  } = computedStyle;

  if (position === 'fixed') {
    return null;
  }

  return getScrollParent(element.parentElement, isScrollable);
};
export const getElementPosition = element => {
  const elementRect = element.getBoundingClientRect();
  const left = elementRect.x || elementRect.left;
  const right = elementRect.right || left + elementRect.width;
  const top = elementRect.y || elementRect.top;
  const bottom = elementRect.bottom || top + elementRect.height;
  return {
    left,
    right,
    top,
    bottom
  };
};
export const getElementVisibleRect = (element, scrollParents) => {
  const {
    x: scrollParentOnXAxis,
    y: scrollParentOnYAxis
  } = scrollParents;
  let {
    left,
    right,
    top,
    bottom
  } = getElementPosition(element); // If element has scrollable parent, exclude overflow width and height from return value

  if (scrollParentOnXAxis) {
    const parentPosition = getElementPosition(scrollParentOnXAxis);
    left = Math.max(left, parentPosition.left);
    right = Math.min(right, parentPosition.right);
  }

  if (scrollParentOnYAxis) {
    const parentPosition = getElementPosition(scrollParentOnYAxis);
    top = Math.max(top, parentPosition.top);
    bottom = Math.min(bottom, parentPosition.bottom);
  }

  const height = Math.max(bottom - top, 0); // Default to 0 if height is negative

  const width = Math.max(right - left, 0); // Default to 0 if width is negative

  return {
    x: left,
    y: top,
    width,
    height
  };
};
export const getCurrentFrameElement = frameWindow => {
  const parentWidow = frameWindow.parent;
  const parentFrames = Array.from(parentWidow.frames.document.querySelectorAll('iframe')); // Get all frames in parent window and find the one that matches the frame window

  const frameElement = parentFrames.find(frame => frame.contentWindow === frameWindow) || null;
  return {
    frameElement,
    parentWidow
  };
};
export const getCurrentFrameVisibleRect = targetElement => {
  const rect = {
    x: 0,
    y: 0
  }; // Get the window where the element is located

  let currentWindow = targetElement.ownerDocument.defaultView;

  while (currentWindow && currentWindow !== window) {
    // Get frame window corresponding frame element in parent window
    const {
      frameElement,
      parentWidow
    } = getCurrentFrameElement(currentWindow);

    if (!frameElement) {
      break;
    } // Accumulate the frame element position


    const frameRect = frameElement.getBoundingClientRect();
    rect.x += frameRect.x || frameRect.left;
    rect.y += frameRect.y;
    currentWindow = parentWidow;
  }

  return rect;
};
export const getBoundingVisibleRect = (targetElement, scrollParents, radius) => {
  const {
    x,
    y,
    width,
    height
  } = getElementVisibleRect(targetElement, scrollParents); // Get absolute visible rect if current window is a frame

  const currentFrameRect = getCurrentFrameVisibleRect(targetElement); // getBoundingClientRect is not consistent.
  // Some browsers use x and y, while others use left and top

  return {
    width,
    height,
    x: x + currentFrameRect.x,
    y: y + currentFrameRect.y,
    r: radius
  };
};
export const getSpacedRect = (rect, padding) => {
  const {
    width,
    height,
    x,
    y,
    r
  } = rect;
  return {
    width: width + padding * 2,
    height: height + padding * 2,
    x: x - padding,
    y: y - padding,
    r
  };
};
export const isElementVisible = element => {
  if (!element) {
    return false;
  }

  return Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};