'use es6';

import { Map as ImmutableMap } from 'immutable';
import { CLOSE_CLONE_REQUEST_MODAL, LAYOUT_SECTION_REQUESTED_CLONE } from 'ContentEditorUI/redux/actions/actionTypes';
const INITIAL_STATE = new ImmutableMap({
  actionToDispatch: null,
  typeToClone: null
});

const cloneRequestReducer = (state = INITIAL_STATE, {
  type,
  payload = {}
}) => {
  if (!payload) {
    return state;
  }

  const {
    actionToDispatch,
    typeToClone
  } = payload;

  switch (type) {
    case CLOSE_CLONE_REQUEST_MODAL:
      return INITIAL_STATE;

    case LAYOUT_SECTION_REQUESTED_CLONE:
      return state.merge({
        actionToDispatch,
        typeToClone
      });

    default:
      {
        return state;
      }
  }
};

export default cloneRequestReducer;