import Raven from 'Raven';
import enviro from 'enviro';
const NEW_RELIC_PAGE_ACTION_EVENT = 'FileManagerLibEvent';
const RAVEN_REQUEST_DATA_LENGTH_LIMIT = 1000;
const isDeployed = enviro.deployed('sentry') || enviro.deployed('newrelic');
const isDebug = Boolean(enviro.debug('newrelic'));
export function logNewRelicPageAction(eventName, attrs = {}) {
  if (!(window.newrelic && window.newrelic.addPageAction)) {
    return;
  }

  if (isDebug) {
    console.log('[newrelic addPageAction]', eventName, attrs);
  }

  attrs.eventName = eventName;
  window.newrelic.addPageAction(NEW_RELIC_PAGE_ACTION_EVENT, attrs);
}
export function logNewRelicError(error, attrs = {}) {
  if (!(window.newrelic && window.newrelic.noticeError)) {
    return;
  }

  if (isDebug) {
    console.log('[newrelic noticeError]', error, attrs);
  }

  attrs.fromFileManager = true;
  window.newrelic.noticeError(error, attrs);
}
export function reportMessage(message, extra = {}) {
  console.warn(message, extra);

  if (!isDeployed) {
    return;
  }

  Raven.captureMessage(message, {
    extra
  });
  logNewRelicPageAction('log', Object.assign({}, extra, {
    message
  }));
}
export function reportError(err, extra = {}) {
  console.error(err);

  if (!isDeployed) {
    return;
  }

  const actionName = extra.action || extra.type || 'UNKNOWN';

  if ('status' in err && typeof err.status === 'number') {
    let tags = {
      status: err.status
    };

    if (err instanceof XMLHttpRequest) {
      const errorMessage = `Failed quickFetch request for ${actionName} with status: ${err.status}`;
      logNewRelicPageAction('quickFetchFailure', Object.assign({}, extra, {}, tags, {
        isError: true
      }));
      Raven.captureMessage(errorMessage, {
        tags,
        extra
      });
    } else if ('options' in err && err.options) {
      tags = Object.assign({}, tags, {
        requestUrl: err.options.url,
        requestMethod: err.options.method
      });
      const errorMessage = `Failed hub-http request for ${actionName} with status: ${err.status}`;
      logNewRelicPageAction('hubHttpFailure', Object.assign({}, extra, {}, tags, {
        isError: true
      }));

      if (err.options.data && typeof err.options.data === 'string') {
        extra.requestBody = err.options.data.substr(0, RAVEN_REQUEST_DATA_LENGTH_LIMIT);
      }

      if (typeof err.responseText === 'string') {
        extra.responseBody = err.responseText.substr(0, RAVEN_REQUEST_DATA_LENGTH_LIMIT);
      }

      Raven.captureMessage(errorMessage, {
        tags,
        extra
      });
    }

    return;
  }

  Raven.captureException(err, {
    extra
  });
  logNewRelicError(String(err) || 'unknown', Object.assign({}, extra, {
    isError: true
  }));
}
export function mockCurrentApp(currentApp) {
  window._currentApp = currentApp;
}
export function getCurrentApp() {
  if (window._currentApp) {
    return window._currentApp;
  }

  return window.hubspot && // @ts-expect-error No hubspot type in global window yet
  window.hubspot.bender && // @ts-expect-error No hubspot type in global window yet
  window.hubspot.bender.currentProject;
}
export function getCurrentAppVersion() {
  if (window._currentApp) {
    return null;
  }

  return window.hubspot && // @ts-expect-error No hubspot type in global window yet
  window.hubspot.bender && // @ts-expect-error No hubspot type in global window yet
  window.hubspot.bender.currentProjectVersion;
}
export function serializeFileInfo(file) {
  return {
    fileName: file.name,
    fileType: file.type,
    fileSize: file.size
  };
}
export const logBadConversionAssetData = (failureMessage, error = '') => {
  console.warn(`[Video HubL Tag] ${failureMessage}. HubL tag will ignore it. ${error}`);
};