import { EARTH_LAYER, MARS_LAYER } from 'HubStyleTokens/sizes';
export const DELETE_COMMENT_POPOVER_ID = 'delete-comment-popover';
export const MINI_COMMENTER_ID = 'mini-commenter-popover';
export const DEFAULT_OFFSET = 23;
export const COLLAPSED_SIDEBAR_WIDTH = 43;
export const FLOATING_COLLAPSED_SIDEBAR_WIDTH = 56;
export const MIN_SIDEBAR_WIDTH = 350;
export const MAX_SIDEBAR_WIDTH = 500;
export const DEFAULT_WIDTH_CUTOFF = 1440;
export const SIDEBAR_ANIMATION_DURATION_SECONDS = 0.5;
export const SIDEBAR_ANIMATION_DURATION_MILLISECONDS = SIDEBAR_ANIMATION_DURATION_SECONDS * 1000;
export const DROP_AREA_WIDTH = FLOATING_COLLAPSED_SIDEBAR_WIDTH + 20;
export const DROP_AREA_MINUS_FLOATING_SIDEBAR = DROP_AREA_WIDTH - FLOATING_COLLAPSED_SIDEBAR_WIDTH;
export const DROP_AREA_BASE_Z_INDEX = EARTH_LAYER;
export const DROP_AREA_TOP_Z_INDEX = MARS_LAYER;
const DROP_AREA_GRADIENT_SHADOW_WIDTH = DROP_AREA_WIDTH + 50; // rgba(124, 152, 182, 0.3) is same as the EERIE color token with a 30% opacity

export const EERIE_OPACITY_30_PERCENT = 'rgba(124, 152, 182, 0.3)'; // rgba(66, 91, 118, 0.6) is same as the HEFFALUMP color token with a 60% opacity

export const HEFFALUMP_OPACITY_30_PERCENT = 'rgba(66, 91, 118, 0.3)';
const HEFFALUMP_GRADIENT = `
  transparent 0%,
  transparent ${DROP_AREA_WIDTH}px,
  ${HEFFALUMP_OPACITY_30_PERCENT} ${DROP_AREA_GRADIENT_SHADOW_WIDTH}px,
  ${HEFFALUMP_OPACITY_30_PERCENT} calc(100% - ${DROP_AREA_GRADIENT_SHADOW_WIDTH}px),
  transparent calc(100% - ${DROP_AREA_WIDTH}px),
  transparent 100%
`;
export const DROP_AREA_HORIZONTAL_HEFFALUMP_GRADIENT = `
  linear-gradient(
    90deg,
    ${HEFFALUMP_GRADIENT}
    );
  `;
export const DROP_AREA_VERTICAL_HEFFALUMP_GRADIENT = `
  linear-gradient(
    transparent 0%,
    ${HEFFALUMP_GRADIENT}
  );
`;