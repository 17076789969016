import PortalIdParser from 'PortalIdParser';
export const CAN_CREATE_RESOURCE = Object.freeze({
  emails: 'emails',
  workflows: 'workflows',
  forms: 'forms'
});
const LIMIT_QUERY = Object.freeze({
  query: {
    limit: 1000
  }
});
export const API_URLS = Object.freeze({
  blogs: 'blog-settings/v1/blogs',
  ctas: 'ctas/v3/placements',
  emails: 'cosemail/v1/emails',
  forms: 'forms/v2/forms',
  hubdb: 'hubdb/api/v1/tables',
  menus: 'content/api/v4/menus',
  tags: 'blogs/v3/tags',
  users: 'users/v1/app/hub-users',
  workflows: 'automation/v3/workflows',
  pages: 'content/api/v4/contents',
  meetings: 'meetings/v1/link/portal',
  salesforceCampaigns: `sfdc/v1/campaigns/${PortalIdParser.get({
    preserveGlobalId: true
  })}`,
  salesforceIntegrationStatus: 'sfdc/v1/integration-status'
});
export const FETCH_DEFAULTS = Object.freeze({
  blogs: LIMIT_QUERY,
  ctas: LIMIT_QUERY,
  emails: {
    query: {
      limit: 1000,
      state__in: ['automated_for_form', 'automated_for_form_draft']
    }
  },
  forms: {
    property: ['guid', 'name']
  },
  hubdb: LIMIT_QUERY,
  menus: LIMIT_QUERY,
  tags: LIMIT_QUERY,
  workflows: LIMIT_QUERY,
  ticketWorkflows: {
    createdSourceApp: ['TICKET_PIPELINE_SETTINGS', 'WORKFLOWS_APP'],
    limit: 1000,
    flowObjectType: 'TICKET',
    clienttimeout: 7000
  },
  meetings: LIMIT_QUERY,
  pages: {
    query: {
      limit: 1000,
      property: ['name', 'id', 'url']
    }
  }
});
export const CREATE_DEFAULTS = Object.freeze({
  workflows: {
    data: {
      type: 'DRIP_DELAY'
    }
  },
  ticketWorkflows: {
    data: {
      flowObjectType: 'TICKET'
    }
  },
  forms: {
    data: {
      guid: null,
      type: 'FORM',
      version: 'new',
      formFieldGroups: [{
        fields: [{
          name: 'firstname',
          label: 'First Name',
          type: 'string',
          fieldType: 'text',
          description: '',
          groupName: 'contactinformation',
          required: false,
          selectedOptions: [],
          options: [],
          hidden: false
        }],
        default: true,
        isSmartGroup: false
      }, {
        fields: [{
          name: 'lastname',
          label: 'Last Name',
          type: 'string',
          fieldType: 'text',
          description: '',
          groupName: 'contactinformation',
          required: false,
          selectedOptions: [],
          options: [],
          hidden: false
        }],
        default: true,
        isSmartGroup: false
      }, {
        fields: [{
          name: 'email',
          label: 'Email',
          type: 'string',
          fieldType: 'text',
          description: '',
          groupName: 'contactinformation',
          required: true,
          selectedOptions: [],
          options: [],
          validation: {
            name: 'email',
            message: 'Please enter a valid email address'
          },
          hidden: false
        }],
        default: true,
        isSmartGroup: false
      }],
      action: '',
      cssClass: 'hs-form stacked',
      embeddedCode: '',
      followUpId: '',
      leadNurturingCampaignId: '',
      method: 'POST',
      name: 'New Form',
      notifyRecipients: '',
      redirect: '',
      inlineMessage: '',
      submitText: 'Submit',
      deletable: true,
      captchaEnabled: false
    }
  },
  emails: {
    query: {
      casing: 'snake'
    },
    data: {
      template_path: 'hubspot_default/email/responsive/email.html',
      state: 'AUTOMATED_FOR_FORM_DRAFT',
      subcategory: 'automated_for_form'
    }
  }
});