'use es6';

export const APP_STATUS = {
  READY: 'READY',
  LOADING: 'LOADING',
  FATAL_ERROR: 'FATAL_ERROR'
};
export const SUPPORTED_DEVICE_STYLING_CONTROLS = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP'
}; // These must match their references in the builtInTypesByModuleIntegerId map
// Ideally, the backend would be able to tell us that the user doesn't have access
// to the module and also give us the pql id that goes along with it

export const UPGRADABLE_MODULES = {
  CTA: 'cta',
  PAYMENTS: 'payments'
}; // A module path to use to figure out if we use should use a "new" ribbon
// on a module in the add module sidebar. Example below:

export const MODULES_BY_PATH_MARKED_AS_NEW = [//'@hubspot/linked_image',
];
export const upgradeableModuleTypeToPQLMap = {
  [UPGRADABLE_MODULES.PAYMENTS]: 'payments-cms-module'
}; // When adding a feature flag, make sure to add its default value to the DEFAULT_FEATURE_FLAGS
// in EditorConfigSingleton

export const FEATURE_FLAGS = {
  cssAssets: 'cssAssets',
  collaborationSidebar: 'collaborationSidebar',
  templateTypes: 'templateTypes',
  shouldUseNopeZonesForAllModules: 'shouldUseNopeZonesForAllModules',
  shouldFocusSingleStaticModule: 'shouldFocusSingleStaticModule',
  hideLockedEditFormFields: 'hideLockedEditFormFields',
  inlineStyles: 'inlineStyles',
  undoRedo: 'undoRedo',
  breakpoints: 'breakpoints',
  sections: 'sections',
  smartContent: 'smartContent',
  aiGeneratedContent: 'aiGeneratedContent'
};
export const COMMERCE_PAYMENT_FQN = 'COMMERCE_PAYMENT';