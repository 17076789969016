'use es6';

import { withRouter } from 'react-router';
import { TITLE_OPTIMIZATION_TYPES, META_DESCRIPTION_OPTIMIZATION_TYPES } from 'ContentEditorUI/constants/OptimizationTypes';
import { useOpenPatagoniaSettingsModalIfNecessary, useOpenPatagoniaPublishingModalIfNecessary } from 'ContentEditorUI/components/settings/utils';

function PatagoniaModalQueryChecker({
  location
}) {
  const optimizationTypesArray = [TITLE_OPTIMIZATION_TYPES, META_DESCRIPTION_OPTIMIZATION_TYPES];
  useOpenPatagoniaSettingsModalIfNecessary(location, optimizationTypesArray);
  useOpenPatagoniaPublishingModalIfNecessary();
  return null;
}

export default withRouter(PatagoniaModalQueryChecker);