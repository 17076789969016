'use es6';

import { createSelector } from 'reselect';
import { getIsSitePage, getIsLandingPage } from 'ContentEditorUI/redux/selectors/contentReadOnlyDataSelectors';
import { getIsDynamicPage, getIsPrivatePage, getIsAudienceAccessPage } from './pageContentModelSelectors';
import { getDynamicPageAccessPurgeDate, getSitePagesPasswordProtectionPurgeDate, getLandingPagesPasswordProtectionPurgeDate, getAudienceAccessPurgeDate } from 'ContentEditorUI/redux/selectors/portalSelectors';
import { getDynamicPageAccessPurgeDateRequest, getSitePagesPasswordProtectionPurgeDateRequest, getLandingPagesPasswordProtectionPurgeDateRequest, getAudienceAccessPurgeDateRequest } from 'ContentEditorUI/redux/selectors/requestStatesSelectors';
import { requestSucceeded } from 'redux-request-state/util/requestStateCheckers';
export const getShouldShowSitePagesPrivatePageDowngradeBanner = createSelector([getIsSitePage, getIsPrivatePage, getSitePagesPasswordProtectionPurgeDateRequest, getSitePagesPasswordProtectionPurgeDate], (isSitePage, isPrivatePage, sitePagesPasswordProtectionPurgeDateRequest, sitePagesPasswordProtectionPurgeDate) => isSitePage && isPrivatePage && requestSucceeded(sitePagesPasswordProtectionPurgeDateRequest) && sitePagesPasswordProtectionPurgeDate !== null);
export const getShouldShowLandingPagesPrivatePageDowngradeBanner = createSelector([getIsLandingPage, getIsPrivatePage, getLandingPagesPasswordProtectionPurgeDateRequest, getLandingPagesPasswordProtectionPurgeDate], (isLandingPage, isPrivatePage, landingPagesPasswordProtectionPurgeDateRequest, landingPagesPasswordProtectionPurgeDate) => isLandingPage && isPrivatePage && requestSucceeded(landingPagesPasswordProtectionPurgeDateRequest) && landingPagesPasswordProtectionPurgeDate !== null);
export const getShouldShowAudienceAccessPageDowngradeBanner = createSelector([getIsLandingPage, getIsSitePage, getIsAudienceAccessPage, getAudienceAccessPurgeDateRequest, getAudienceAccessPurgeDate, getLandingPagesPasswordProtectionPurgeDateRequest, getLandingPagesPasswordProtectionPurgeDate, getSitePagesPasswordProtectionPurgeDateRequest, getSitePagesPasswordProtectionPurgeDate], (isLandingPage, isSitePage, isAudienceAccessPage, audienceAccessPurgeDateRequest, audienceAccessPurgeDate, landingPagesPasswordProtectionPurgeDateRequest, landingPagesPasswordProtectionPurgeDate, sitePagesPasswordProtectionPurgeDateRequest, sitePagesPasswordProtectionPurgeDate) => isAudienceAccessPage && requestSucceeded(audienceAccessPurgeDateRequest) && audienceAccessPurgeDate !== null && (isLandingPage && requestSucceeded(landingPagesPasswordProtectionPurgeDateRequest) && landingPagesPasswordProtectionPurgeDate === null || isSitePage && requestSucceeded(sitePagesPasswordProtectionPurgeDateRequest) && sitePagesPasswordProtectionPurgeDate === null));
export const getShouldShowDynamicPageDowngradeBanner = createSelector([getIsSitePage, getIsDynamicPage, getDynamicPageAccessPurgeDateRequest, getDynamicPageAccessPurgeDate], (isSitePage, isDynamicPage, dynamicPageAccessPurgeDateRequest, dynamicPageAccessPurgeDate) => isSitePage && isDynamicPage && requestSucceeded(dynamicPageAccessPurgeDateRequest) && dynamicPageAccessPurgeDate !== null);