export const APP_STARTED = 'APP_STARTED';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SHOW_LOADING = 'SHOW_LOADING';
export const CANCEL_SCHEDULED_EMAIL_ERROR = 'CANCEL_SCHEDULED_EMAIL_ERROR';
export const FETCH_USER_ATTRIBUTES_STARTED = 'FETCH_USER_ATTRIBUTES_STARTED';
export const FETCH_USER_ATTRIBUTES_SUCCEEDED = 'FETCH_USER_ATTRIBUTES_SUCCEEDED';
export const FETCH_USER_ATTRIBUTES_FAILED = 'FETCH_USER_ATTRIBUTES_FAILED';
export const TOGGLE_UNPUBLISH_MODAL = 'TOGGLE_UNPUBLISH_MODAL';
export const TOGGLE_PERSONALIZATION_EDITOR_MODAL = 'TOGGLE_PERSONALIZATION_EDITOR_MODAL';
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';
export const TOGGLE_PUBLISH_MODAL_OPEN = 'TOGGLE_PUBLISH_MODAL_OPEN';
export const TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN = 'TOGGLE_PUBLISH_OPTIONS_MODAL_OPEN';
export const TOGGLE_RUN_TEST_MODAL = 'TOGGLE_RUN_TEST_MODAL';
export const LOG_SENTRY_WITH_HISTORY = 'LOG_SENTRY_WITH_HISTORY';
export const SNAPSHOT_STATE_IN_TIME = 'SNAPSHOT_STATE_IN_TIME';
export const UPDATE_USER_ATTRIBUTE = 'UPDATE_USER_ATTRIBUTE';
export const UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE = 'UPDATE_USER_ATTRIBUTE_ALSO_CACHED_IN_SUPERSTORE';
export const UPDATE_SUPERSTORE_USER_SETTING = 'UPDATE_SUPERSTORE_USER_SETTING';
export const UPDATE_SUPERSTORE_PER_CONTENT_SETTING = 'UPDATE_SUPERSTORE_PER_CONTENT_SETTING';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_POPOVER = 'OPEN_POPOVER';
export const CLOSE_POPOVER = 'CLOSE_POPOVER';
export const OPEN_POPOUT_EDITOR = 'OPEN_POPOUT_EDITOR';
export const CLOSE_POPOUT_EDITOR = 'CLOSE_POPOUT_EDITOR';
export const DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON = 'DISPLAY_EXIT_PREVIEW_AND_LAYOUT_BUTTON';
export const CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON = 'CLOSE_EXIT_PREVIEW_AND_LAYOUT_BUTTON';
export const I18N_READY = 'I18N_READY';
export const SET_IFRAME_LOADING = 'SET_IFRAME_LOADING';
export const SET_IFRAME_READY = 'SET_IFRAME_READY';
export const SET_IFRAME_RENDERED = 'SET_IFRAME_RENDERED';
export const SET_IFRAME_NEEDS_REFRESH = 'SET_IFRAME_NEEDS_REFRESH';
export const SET_IFRAME_HIDDEN = 'SET_IFRAME_HIDDEN';
export const SET_ADD_MODULE_SIDEBAR_LOADED = 'SET_ADD_MODULE_SIDEBAR_LOADED';
export const NAVIGATION_TO_SAME_PAGE = 'NAVIGATION_TO_SAME_PAGE';
export const NAVIGATE_TO_PREVIEW = 'NAVIGATE_TO_PREVIEW';
export const FORCE_SHOW_END_TEST_MODAL = 'FORCE_SHOW_END_TEST_MODAL';
export const FETCH_SUPPRESSED_CONTACTS_COUNT_SUCCESS = 'FETCH_SUPPRESSED_CONTACTS_COUNT_SUCCESS';
export const FETCH_SUPPRESSED_CONTACTS_COUNT_FAIL = 'FETCH_SUPPRESSED_CONTACTS_COUNT_FAIL';
export const FETCH_SELECTED_CONTACTS_COUNT_FAIL = 'FETCH_SELECTED_CONTACTS_COUNT_FAIL';
export const FETCH_ALL_RECIPIENT_COUNTS_START = 'FETCH_ALL_RECIPIENT_COUNTS_START';
export const FETCH_ALL_RECIPIENT_COUNTS_SUCCESS = 'FETCH_ALL_RECIPIENT_COUNTS_SUCCESS';
export const FETCH_ALL_RECIPIENT_COUNTS_FAIL = 'FETCH_ALL_RECIPIENT_COUNTS_FAIL';
export const FETCH_LISTS_SUCCESS = 'FETCH_LISTS_SUCCESS';
export const FETCH_LISTS_FAIL = 'FETCH_LISTS_FAIL';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAIL = 'FETCH_CONTACTS_FAIL';
export const RECIPIENTS_UPDATED = 'RECIPIENTS_UPDATED';
export const START_RECIPIENTS_FETCH = 'START_RECIPIENTS_FETCH';
export const UPDATE_GRAYMAIL_SUPPRESSION = 'UPDATE_GRAYMAIL_SUPPRESSION';
export const SUPPRESSION_CHECK_FAILED = 'SUPPRESSION_CHECK_FAILED';
export const SUPPRESSION_CHECK_SUCCESS = 'SUPPRESSION_CHECK_SUCCESS';
export const RESET_SEARCH_CONTACTS = 'RESET_SEARCH_CONTACTS';
export const RESET_SEARCH_LISTS = 'RESET_SEARCH_LISTS';
export const START_LIST_CREATION = 'START_LIST_CREATION';
export const COMPLETE_LIST_CREATION = 'COMPLETE_LIST_CREATION';
export const UPDATE_EMAIL_TYPE = 'UPDATE_EMAIL_TYPE';
export const FETCH_PORTAL_SETTINGS_SUCCESS = 'FETCH_PORTAL_SETTINGS_SUCCESS';
export const UPDATE_LITMUS_CLIENT_SEARCH = 'UPDATE_LITMUS_CLIENT_SEARCH';
export const START_LITMUS_HISTORY_FETCH = 'START_LITMUS_HISTORY_FETCH';
export const UPDATE_LITMUS_HISTORY = 'UPDATE_LITMUS_HISTORY';
export const FETCH_LITMUS_HISTORY_FAIL = 'FETCH_LITMUS_HISTORY_FAIL';
export const SELECT_LITMUS_TEST_RESULTS = 'SELECT_LITMUS_TEST_RESULTS';
export const LITMUS_LIMIT_INFO_CHANGED = 'LITMUS_LIMIT_INFO_CHANGED';
export const FETCH_LITMUS_CLIENTS_SUCCESS = 'FETCH_LITMUS_CLIENTS_SUCCESS';
export const FETCH_LITMUS_CLIENTS_FAIL = 'FETCH_LITMUS_CLIENTS_FAIL';
export const CREATE_LITMUS_TEST_FAIL = 'CREATE_LITMUS_TEST_FAIL';
export const CREATE_LITMUS_TEST_SUCCESS = 'CREATE_LITMUS_TEST_SUCCESS';
export const FETCH_LITMUS_TEST_RESULTS_FAIL = 'FETCH_LITMUS_TEST_RESULTS_FAIL';
export const LITMUS_STATS_UPDATE = 'LITMUS_STATS_UPDATE';
export const LITMUS_STOP_POLLING = 'LITMUS_STOP_POLLING';
export const LITMUS_START_POLLING = 'LITMUS_START_POLLING';
export const FETCH_LITMUS_PROGRESSION_SUCCESS = 'FETCH_LITMUS_PROGRESSION_SUCCESS';
export const FETCH_LITMUS_PROGRESSION_FAIL = 'FETCH_LITMUS_PROGRESSION_FAIL';
export const FETCH_LITMUS_LIMITS_FAIL = 'FETCH_LITMUS_LIMITS_FAIL';
export const FETCH_LITMUS_LIMITS_SUCCESS = 'FETCH_LITMUS_LIMITS_SUCCESS';
export const SELECT_LITMUS_TAB = 'SELECT_LITMUS_TAB';
export const START_LITMUS_TEST_CREATION = 'START_LITMUS_TEST_CREATION';
export const FETCH_TEMPLATE_INFO_FAIL = 'FETCH_TEMPLATE_INFO_FAIL';
export const FETCH_TEMPLATE_INFO_REQUEST = 'FETCH_TEMPLATE_INFO_REQUEST';
export const FETCH_TEMPLATE_INFO_SUCCESS = 'FETCH_TEMPLATE_INFO_SUCCESS';
export const FETCH_TEMPLATE_PREVIEW_URL_REQUEST = 'FETCH_TEMPLATE_PREVIEW_URL_REQUEST';
export const FETCH_TEMPLATE_PREVIEW_URL_SUCCESS = 'FETCH_TEMPLATE_PREVIEW_URL_SUCCESS';
export const FETCH_TEMPLATE_PREVIEW_URL_FAIL = 'FETCH_TEMPLATE_PREVIEW_URL_FAIL';
export const FETCH_SUBSCRIPTION_DEFINITIONS_SUCCESS = 'FETCH_SUBSCRIPTION_DEFINITIONS_SUCCESS';
export const FETCH_SUBSCRIPTION_DEFINITIONS_FAIL = 'FETCH_SUBSCRIPTION_DEFINITIONS_FAIL';
export const FETCH_SUBSCRIPTION_GROUPS_STARTED = 'FETCH_SUBSCRIPTION_GROUPS_STARTED';
export const FETCH_SUBSCRIPTION_GROUPS_SUCCEEDED = 'FETCH_SUBSCRIPTION_GROUPS_SUCCEEDED';
export const FETCH_SUBSCRIPTION_GROUPS_FAILED = 'FETCH_SUBSCRIPTION_GROUPS_FAILED';
export const FETCH_EMAIL_SETTINGS_SLUG_REQUEST = 'FETCH_EMAIL_SETTINGS_SLUG_REQUEST';
export const FETCH_EMAIL_SETTINGS_SLUG_FAIL = 'FETCH_EMAIL_SETTINGS_SLUG_FAIL';
export const UNLINK_EMAIL_SLUG_FROM_TITLE = 'UNLINK_EMAIL_SLUG_FROM_TITLE';
export const SET_HAS_BEEN_EDITED_IN_EDITOR = 'SET_HAS_BEEN_EDITED_IN_EDITOR';
export const UPDATE_EMAIL_SETTING = 'UPDATE_EMAIL_SETTING';
export const UPDATE_RSS_SETTING = 'UPDATE_RSS_SETTING';
export const UPDATE_EMAIL_SETTINGS_SLUG_RAW = 'UPDATE_EMAIL_SETTINGS_SLUG_RAW';
export const UPDATE_CAN_SPAM_SETTING_ID = 'UPDATE_CAN_SPAM_SETTING_ID';
export const UPDATE_WEBVERSION_ENABLED = 'UPDATE_WEBVERSION_ENABLED';
export const UPDATE_CAN_SPAM_SETTINGS_SUCCESS = 'UPDATE_CAN_SPAM_SETTINGS_SUCCESS';
export const UPDATE_CAN_SPAM_SETTINGS_FAIL = 'UPDATE_CAN_SPAM_SETTINGS_FAIL';
export const DELETE_CAN_SPAM_SETTING_SUCCESS = 'DELETE_CAN_SPAM_SETTING_SUCCESS';
export const DELETE_CAN_SPAM_SETTING_FAIL = 'DELETE_CAN_SPAM_SETTING_FAIL';
export const REQUEST_PREVIEW_EMAIL = 'REQUEST_PREVIEW_EMAIL';
export const UPDATE_PREVIEW_EMAIL = 'UPDATE_PREVIEW_EMAIL';
export const UPDATE_PREVIEW_CONTACT = 'UPDATE_PREVIEW_CONTACT';
export const UPDATE_PLATFORM_OPEN = 'UPDATE_PLATFORM_OPEN';
export const UPDATE_PREVIEW_PRODUCT_IDS = 'UPDATE_PREVIEW_PRODUCT_IDS';
export const START_RSS_VALIDATION = 'START_RSS_VALIDATION';
export const FETCH_RSS_VALIDATION_SUCCESS = 'FETCH_RSS_VALIDATION_SUCCESS';
export const FETCH_RSS_VALIDATION_ERROR = 'FETCH_RSS_VALIDATION_ERROR';
export const DISMISS_REVIEW_ITEM = 'DISMISS_REVIEW_ITEM';
export const UNDISMISS_REVIEW_ITEM = 'UNDISMISS_REVIEW_ITEM';
export const FETCH_EMAIL_PLAINTEXT_SUCCESS = 'FETCH_EMAIL_PLAINTEXT_SUCCESS';
export const FETCH_EMAIL_PLAINTEXT_FAIL = 'FETCH_EMAIL_PLAINTEXT_FAIL';
export const SET_EMAIL_SETTINGS_SCROLL_TO = 'SET_EMAIL_SETTINGS_SCROLL_TO';
export const FETCH_CONTENT_STARTED = 'FETCH_CONTENT_STARTED';
export const FETCH_CONTENT_SUCCEEDED = 'FETCH_CONTENT_SUCCEEDED';
export const FETCH_CONTENT_FAILED = 'FETCH_CONTENT_FAILED';
export const FETCH_CONTENT_SCHEMA_FAILED = 'FETCH_CONTENT_SCHEMA_FAILED';
export const FETCH_CONTENT_SCHEMA_STARTED = 'FETCH_CONTENT_SCHEMA_STARTED';
export const FETCH_CONTENT_SCHEMA_SUCCEEDED = 'FETCH_CONTENT_SCHEMA_SUCCEEDED';
export const FETCH_LAYOUT_DATA_STARTED = 'FETCH_LAYOUT_DATA_STARTED';
export const FETCH_LAYOUT_DATA_SUCCEEDED = 'FETCH_LAYOUT_DATA_SUCCEEDED';
export const FETCH_LAYOUT_DATA_FAILED = 'FETCH_LAYOUT_DATA_FAILED';
export const CLEAR_CONTENT_MODEL_FEATURED_IMAGE = 'CLEAR_CONTENT_MODEL_FEATURED_IMAGE';
export const UPDATE_CONTENT_MODEL_CAMPAIGN = 'UPDATE_CONTENT_MODEL_CAMPAIGN';
export const UPDATE_CONTENT_MODEL_DOMAIN = 'UPDATE_CONTENT_MODEL_DOMAIN';
export const UPDATE_CONTENT_MODEL_EXPIRY_DATE = 'UPDATE_CONTENT_MODEL_EXPIRY_DATE';
export const UPDATE_CONTENT_MODEL_EXPIRY_ENABLED = 'UPDATE_CONTENT_MODEL_EXPIRY_ENABLED';
export const UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL = 'UPDATE_CONTENT_MODEL_EXPIRY_REDIRECT_URL';
export const UPDATE_CONTENT_MODEL_HTML_TITLE = 'UPDATE_CONTENT_MODEL_HTML_TITLE';
export const UPDATE_CONTENT_MODEL_META_DESCRIPTION = 'UPDATE_CONTENT_MODEL_META_DESCRIPTION';
export const UPDATE_CONTENT_MODEL_NAME = 'UPDATE_CONTENT_MODEL_NAME';
export const UPDATE_CONTENT_MODEL_SLUG = 'UPDATE_CONTENT_MODEL_SLUG';
export const UPDATE_CONTENT_MODEL_CANONICAL_URL = 'UPDATE_CONTENT_MODEL_CANONICAL_URL';
export const UPDATE_CONTENT_MODEL_TEMPLATE_PATH = 'UPDATE_CONTENT_MODEL_TEMPLATE_PATH';
export const UPDATE_CONTENT_MODEL_USE_FEATURED_IMAGE = 'UPDATE_CONTENT_MODEL_USE_FEATURED_IMAGE';
export const UPDATE_CONTENT_MODEL_FEATURED_IMAGE = 'UPDATE_CONTENT_MODEL_FEATURED_IMAGE';
export const UPLOAD_FEATURED_IMAGE = 'UPLOAD_FEATURED_IMAGE';
export const UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES = 'UPDATE_CONTENT_MODEL_THEME_PAGE_OVERRIDES';
export const CLEAR_UPDATED_THEME_SETTINGS = 'CLEAR_UPDATED_THEME_SETTINGS';
export const FETCH_SETTINGS_SLUG_FAIL = 'FETCH_SETTINGS_SLUG_FAIL';
export const FETCH_SETTINGS_SLUG_REQUEST = 'FETCH_SETTINGS_SLUG_REQUEST';
export const UNLINK_SLUG_FROM_TITLE = 'UNLINK_SLUG_FROM_TITLE';
export const UPDATE_SETTINGS_SCHEDULE_DATE = 'UPDATE_SETTINGS_SCHEDULE_DATE';
export const UPDATE_SETTINGS_SHOW_ALL_ERRORS = 'UPDATE_SETTINGS_SHOW_ALL_ERRORS';
export const UPDATE_SETTINGS_SLUG_RAW = 'UPDATE_SETTINGS_SLUG_RAW';
export const SET_SETTINGS_SCROLL_TO = 'SET_SETTINGS_SCROLL_TO';
export const FETCH_SUPPORTED_LANGUAGES_FAILED = 'FETCH_SUPPORTED_LANGUAGES_FAILED';
export const FETCH_SUPPORTED_LANGUAGES_STARTED = 'FETCH_SUPPORTED_LANGUAGES_STARTED';
export const FETCH_SUPPORTED_LANGUAGES_SUCCEEDED = 'FETCH_SUPPORTED_LANGUAGES_SUCCEEDED';
export const UPDATE_RENDER_LANGUAGE = 'UPDATE_RENDER_LANGUAGE';
export const REQUEST_GLOBAL_MODULES = 'REQUEST_GLOBAL_MODULES';
export const FETCH_GLOBAL_MODULES_SUCCESS = 'FETCH_GLOBAL_MODULES_SUCCESS';
export const FETCH_GLOBAL_MODULES_FAIL = 'FETCH_GLOBAL_MODULES_FAIL';
export const REQUEST_CUSTOM_MODULES = 'REQUEST_CUSTOM_MODULES';
export const FETCH_CUSTOM_MODULES_SUCCESS = 'FETCH_CUSTOM_MODULES_SUCCESS';
export const FETCH_CUSTOM_MODULES_FAIL = 'FETCH_CUSTOM_MODULES_FAIL';
export const FETCH_MODULE_SCHEMAS_FAILED = 'FETCH_MODULE_SCHEMAS_FAILED';
export const FETCH_MODULE_SCHEMAS_STARTED = 'FETCH_MODULE_SCHEMAS_STARTED';
export const FETCH_MODULE_SCHEMAS_SUCCEEDED = 'FETCH_MODULE_SCHEMAS_SUCCEEDED';
export const REHYDRATE_MODULE_SCHEMAS_FAILED = 'REHYDRATE_MODULE_SCHEMAS_FAILED';
export const REHYDRATE_MODULE_SCHEMAS_STARTED = 'REHYDRATE_MODULE_SCHEMAS_STARTED';
export const REHYDRATE_MODULE_SCHEMAS_SUCCEEDED = 'REHYDRATE_MODULE_SCHEMAS_SUCCEEDED';
export const CONTENT_SAVE_REQUEST_FAILED = 'CONTENT_SAVE_REQUEST_FAILED';
export const CONTENT_SAVE_REQUEST_STARTED = 'CONTENT_SAVE_REQUEST_STARTED';
export const CONTENT_SAVE_REQUEST_SUCCEEDED = 'CONTENT_SAVE_REQUEST_SUCCEEDED';
export const PERMANENTLY_DISABLE_SAVING = 'PERMANENTLY_DISABLE_SAVING';
export const SET_DIRTY_FLAG = 'SET_DIRTY_FLAG';
export const SET_MODULES = 'SET_MODULES';
export const DELETED_MODULE = 'DELETED_MODULE';
export const CLOSE_DELETE_REQUEST_MODAL = 'CLOSE_DELETE_REQUEST_MODAL';
export const REQUESTED_MODULE_DELETE = 'REQUESTED_MODULE_DELETE';
export const ADDED_MODULE = 'ADDED_MODULE';
export const ADDED_MODULES_TO_FAKE_MODULES = 'ADDED_MODULES_TO_FAKE_MODULES';
export const ADDED_MODULE_TO_POST_BODY = 'ADDED_MODULE_TO_POST_BODY';
export const ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES = 'ADDED_CUSTOM_UPDATES_FOR_FAKE_MODULES';
export const MOVED_MODULE = 'MOVED_MODULE';
export const SAVED_MODULE = 'SAVED_MODULE';
export const UPDATED_CONTAINERS_FROM_PREVIEW = 'UPDATED_CONTAINERS_FROM_PREVIEW';
export const SET_MODULE_BODY = 'SET_MODULE_BODY';
export const MERGE_MODULE_BODY = 'MERGE_MODULE_BODY';
export const MERGE_MODULE_DATA = 'MERGE_MODULE_DATA';
export const LAYOUT_SECTION_SET_LABEL = 'LAYOUT_SECTION_SET_LABEL';
export const SCROLL_TO_LAYOUT_FRAGMENT = 'SCROLL_TO_LAYOUT_FRAGMENT';
export const MERGE_PREVIEW_TEXT_MODULE_BODY = 'MERGE_PREVIEW_TEXT_MODULE_BODY';
export const MERGE_MODULE_META_DATA = 'MERGE_MODULE_META_DATA';
export const SET_MODULE_SMART_OBJECT_BODY = 'SET_MODULE_SMART_OBJECT_BODY';
export const MERGE_MODULE_SMART_OBJECT_BODY = 'MERGE_MODULE_SMART_OBJECT_BODY';
export const SET_MODULE_BREAKPOINT_STYLES = 'SET_MODULE_BREAKPOINT_STYLES';
export const SET_MODULE_STYLES = 'SET_MODULE_STYLES';
export const REMOVE_FAKE_BODY_MODULE_STYLES = 'REMOVE_FAKE_BODY_MODULE_STYLES';
export const UPDATED_MODULE_SMART_RULES = 'UPDATED_MODULE_SMART_RULES';
export const REMOVED_MODULE_SMART_RULES = 'REMOVED_MODULE_SMART_RULES';
export const RESYNCHRONIZE_MODULE_SMART_OBJECTS = 'RESYNCHRONIZE_MODULE_SMART_OBJECTS';
export const SET_DEPRECATED_STYLES_FLAGS = 'SET_DEPRECATED_STYLES_FLAGS';
export const MODULE_REDUCER_CHANGE_UNDO = 'MODULE_REDUCER_CHANGE_UNDO';
export const MODULE_REDUCER_CHANGE_REDO = 'MODULE_REDUCER_CHANGE_REDO';
export const MODULE_REDUCER_CLEAR_UNDO_REDO = 'MODULE_REDUCER_CLEAR_UNDO_REDO';
export const MODULE_REDUCER_MAYBE_CLEAR_EMAIL_SELECTION = 'MODULE_REDUCER_MAYBE_CLEAR_EMAIL_SELECTION';
export const INITIALIZE_FAKE_UNDOABLE_ITEMS = 'INITIALIZE_FAKE_UNDOABLE_ITEMS';
export const UPDATE_FAKE_UNDOABLE_ITEMS = 'UPDATE_FAKE_UNDOABLE_ITEMS';
export const EDIT_SIDEBAR_ACTIVE_TAB_UPDATED = 'EDIT_SIDEBAR_ACTIVE_TAB_UPDATED';
export const SELECTED_ITEM_CLEARED = 'SELECTED_ITEM_CLEARED';
export const SELECTED_ITEM_UPDATED = 'SELECTED_ITEM_UPDATED';
export const SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP = 'SELECTED_ITEM_UPDATED_STYLE_SECTION_TOUR_STEP';
export const SELECTED_ITEM_REVERTED_TO_PREVIOUS = 'SELECTED_ITEM_REVERTED_TO_PREVIOUS';
export const SELECT_BREAKPOINT = 'SELECT_BREAKPOINT';
export const SET_BREAKPOINT_STYLES_FOR_TREE_NODE = 'SET_BREAKPOINT_STYLES_FOR_TREE_NODE';
export const UPDATE_DEVICE_WHEN_LAYOUT_SIDEBAR_OPEN = 'UPDATE_DEVICE_WHEN_LAYOUT_SIDEBAR_OPEN';
export const SELECT_MODULE_IN_LAYOUT_AREA_IN_MOBILE = 'SELECT_MODULE_IN_LAYOUT_AREA_IN_MOBILE';
export const ADD_PAGES = 'ADD_PAGES';
export const FETCH_PAGE_FAIL = 'FETCH_PAGE_FAIL';
export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const SEARCH_PAGES_FAILURE = 'SEARCH_PAGES_FAILURE';
export const SEARCH_PAGES_REQUEST = 'SEARCH_PAGES_REQUEST';
export const UPDATE_PAGE_CONTENT_MODEL_CSS = 'UPDATE_PAGE_CONTENT_MODEL_CSS';
export const FETCH_PAGE_CSS_REQUEST = 'FETCH_PAGE_CSS_REQUEST';
export const FETCH_PAGE_CSS_SUCCESS = 'FETCH_PAGE_CSS_SUCCESS';
export const FETCH_PAGE_CSS_FAILED = 'FETCH_PAGE_CSS_FAILED';
export const OPTIMISTICALLY_ADD_CSS_ASSET = 'OPTIMISTICALLY_ADD_CSS_ASSET';
export const UPDATE_EDITOR_RENDERED_CSS_ASSET = 'UPDATE_EDITOR_RENDERED_CSS_ASSET';
export const OPENED_PANEL = 'OPENED_PANEL';
export const CLOSED_PANEL = 'CLOSED_PANEL';
export const OPENED_PREVIOUS_PANEL = 'OPENED_PREVIOUS_PANEL';
export const OPENED_BAYMAX_SIDEBAR = 'OPENED_BAYMAX_SIDEBAR';
export const CLOSED_BAYMAX_SIDEBAR = 'CLOSED_BAYMAX_SIDEBAR';
export const BAYMAX_SIDEBAR_ANIMATION_FINISHED = 'BAYMAX_SIDEBAR_ANIMATION_FINISHED';
export const OPENED_PATAGONIA_SIDEBAR = 'OPENED_PATAGONIA_SIDEBAR';
export const CLOSED_PATAGONIA_SIDEBAR = 'CLOSED_PATAGONIA_SIDEBAR';
export const PATAGONIA_SIDEBAR_ANIMATION_FINISHED = 'PATAGONIA_SIDEBAR_ANIMATION_FINISHED';
export const UPDATE_PUBLISH_DATE = 'UPDATE_PUBLISH_DATE';
export const UPDATE_PUBLISH_IMMEDIATELY = 'UPDATE_PUBLISH_IMMEDIATELY';
export const CONTENT_PUBLISH_REQUEST_SUCCEEDED = 'CONTENT_PUBLISH_REQUEST_SUCCEEDED';
export const SET_MODULE_ERRORS = 'SET_MODULE_ERRORS';
export const FETCH_MODULE_ERRORS_STARTED = 'FETCH_MODULE_ERRORS_STARTED';
export const FETCH_MODULE_ERRORS_SUCCEEDED = 'FETCH_MODULE_ERRORS_SUCCEEDED';
export const FETCH_MODULE_ERRORS_FAILED = 'FETCH_MODULE_ERRORS_FAILED';
export const UPDATE_SITE_SETTINGS = 'UPDATE_SITE_SETTINGS';
export const UPDATE_SITE_SETTINGS_DOMAIN_LANGUAGE = 'UPDATE_SITE_SETTINGS_DOMAIN_LANGUAGE';
export const UPDATE_SITE_SETTINGS_FAILED = 'UPDATE_SITE_SETTINGS_FAILED';
export const UPDATE_SITE_SETTINGS_STARTED = 'UPDATE_SITE_SETTINGS_STARTED';
export const UPDATE_SITE_SETTINGS_SUCCEEDED = 'UPDATE_SITE_SETTINGS_SUCCEEDED';
export const CLEAR_SMART_VIEW = 'CLEAR_SMART_VIEW';
export const UPDATE_SMART_VIEW = 'UPDATE_SMART_VIEW';
export const UPDATE_EDITING_INFO = 'UPDATE_EDITING_INFO';
export const EDIT_FORM_VALIDATION_FINISHED = 'EDIT_FORM_VALIDATION_FINISHED';
export const LEGACY_EVENT_SET_MODE = 'LEGACY_EVENT_SET_MODE';
export const INPAGE_UI_STATE_CLEARED = 'INPAGE_UI_STATE_CLEARED';
export const PREVIEW_HTML_CHANGED = 'PREVIEW_HTML_CHANGED';
export const RESET_HIGHLIGHTED_WIDGET_ID = 'RESET_HIGHLIGHTED_WIDGET_ID';
export const SYNC_INAPP_STATE = 'SYNC_INAPP_STATE';
export const UPDATE_FOCUSED_WIDGET_ID = 'UPDATE_FOCUSED_WIDGET_ID';
export const UPDATE_HIGHLIGHTED_WIDGET_ID = 'UPDATE_HIGHLIGHTED_WIDGET_ID';
export const UPDATE_INPAGE_IS_DRAGGING_MODULE = 'UPDATE_INPAGE_IS_DRAGGING_MODULE';
export const UPDATE_INPAGE_MODE = 'UPDATE_INPAGE_MODE';
export const UPDATE_POST_BODY = 'UPDATE_POST_BODY';
export const UPDATE_POST_CONTENT_MODEL_TITLE = 'UPDATE_POST_CONTENT_MODEL_TITLE';
export const UPDATE_SCROLL_TO_MODULE_ID = 'UPDATE_SCROLL_TO_MODULE_ID';
export const CLEAR_SCROLL_TO_NODE_POSITION_INFO = 'CLEAR_SCROLL_TO_NODE_POSITION_INFO ';
export const UPDATE_TITLE_FROM_INLINE = 'UPDATE_TITLE_FROM_INLINE';
export const PREVIEW_VIEWPORT_DIMENSIONS_UPDATED = 'PREVIEW_VIEWPORT_DIMENSIONS_UPDATED';
export const INPAGE_PREVIEW_UNEXPECTEDLY_UNLOADED = 'INPAGE_PREVIEW_UNEXPECTEDLY_UNLOADED';
export const TRACKED_FAILED_INPAGE_ASSETS = 'TRACKED_FAILED_INPAGE_ASSETS';
export const TRACKED_INPAGE_JS_LOADING_MULTIPLE_TIMES = 'TRACKED_INPAGE_JS_LOADING_MULTIPLE_TIMES';
export const PREVIEW_SCROLL_POSITION_CHANGED = 'PREVIEW_SCROLL_POSITION_CHANGED';
export const OPEN_INPAGE_MODAL = 'OPEN_INPAGE_MODAL';
export const CLOSE_INPAGE_MODAL = 'CLOSE_INPAGE_MODAL';
export const IFRAME_CLICKED = 'IFRAME_CLICKED';
export const SET_VALIDATION_POPOVER_OPEN = 'SET_VALIDATION_POPOVER_OPEN';
export const SET_CLICKED_VALIDATION_ERROR = 'SET_CLICKED_VALIDATION_ERROR';
export const MODULE_SET_LAYOUT_STYLES = 'MODULE_SET_LAYOUT_STYLES';
export const MODULE_RESET_LAYOUT_STYLES = 'MODULE_RESET_LAYOUT_STYLES';
export const LAYOUT_SECTION_COLUMN_INSERTED_AFTER = 'LAYOUT_SECTION_COLUMN_INSERTED_AFTER';
export const LAYOUT_SECTION_COLUMN_INSERTED_BEFORE = 'LAYOUT_SECTION_COLUMN_INSERTED_BEFORE';
export const LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW = 'LAYOUT_SECTION_INSERTED_IN_EMPTY_ROW';
export const LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN = 'LAYOUT_SECTION_INSERTED_IN_EMPTY_COLUMN';
export const LAYOUT_SECTION_ROW_APPENDED = 'LAYOUT_SECTION_ROW_APPENDED';
export const LAYOUT_SECTION_ROW_PREPENDED = 'LAYOUT_SECTION_ROW_PREPENDED';
export const LAYOUT_SECTION_ROW_INSERTED_AFTER = 'LAYOUT_SECTION_ROW_INSERTED_AFTER';
export const LAYOUT_SECTION_ROW_INSERTED_BEFORE = 'LAYOUT_SECTION_ROW_INSERTED_BEFORE';
export const LAYOUT_SECTION_ROW_CLONED_BELOW = 'LAYOUT_SECTION_ROW_CLONED_BELOW';
export const LAYOUT_SECTION_CELL_CLONED_TO_RIGHT = 'LAYOUT_SECTION_CELL_CLONED_TO_RIGHT';
export const LAYOUT_SECTION_COLUMNS_RESIZED = 'LAYOUT_SECTION_COLUMNS_RESIZED';
export const LAYOUT_SECTION_MODULE_DELETED = 'LAYOUT_SECTION_MODULE_DELETED';
export const LAYOUT_SECTION_ROW_DELETED = 'LAYOUT_SECTION_ROW_DELETED';
export const LAYOUT_SECTION_COLUMN_DELETED = 'LAYOUT_SECTION_COLUMN_DELETED';
export const LAYOUT_SECTION_SET_CELL_CSS_ID = 'LAYOUT_SECTION_SET_CELL_CSS_ID';
export const LAYOUT_SECTION_SET_CELL_CSS_STYLES = 'LAYOUT_SECTION_SET_CELL_CSS_STYLES';
export const LAYOUT_SECTION_SET_CELL_CSS_CLASS = 'LAYOUT_SECTION_SET_CELL_CSS_CLASS';
export const LAYOUT_SECTION_SET_ROW_CSS_CLASS = 'LAYOUT_SECTION_SET_ROW_CSS_CLASS';
export const LAYOUT_SECTION_ADD_CLASS_TO_CELL = 'LAYOUT_SECTION_ADD_CLASS_TO_CELL';
export const LAYOUT_SECTION_REMOVE_CLASS_FROM_CELL = 'LAYOUT_SECTION_REMOVE_CLASS_FROM_CELL';
export const LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES = 'LAYOUT_SECTION_CELL_SET_LAYOUT_STYLES';
export const LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES = 'LAYOUT_SECTION_ROW_SET_LAYOUT_STYLES';
export const LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT = 'LAYOUT_SECTION_ROW_SET_VERTICAL_ALIGNMENT';
export const LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES = 'LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES';
export const LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES = 'LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES';
export const MOBILE_LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES = 'MOBILE_LAYOUT_SECTION_CELL_RESET_LAYOUT_STYLES';
export const MOBILE_LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES = 'MOBILE_LAYOUT_SECTION_ROW_RESET_LAYOUT_STYLES';
export const SELECTED_ROW_OR_COLUMN_UPDATED = 'SELECTED_ROW_OR_COLUMN_UPDATED';
export const UPDATE_COMPUTED_STYLES_FOR_SELECTORS = 'UPDATE_COMPUTED_STYLES_FOR_SELECTORS';
export const CLONED_MODULES_QUEUE_CLEARED = 'CLONED_MODULES_QUEUE_CLEARED';
export const MAB_EXPERIMENT_MODAL_LOADING = 'MAB_EXPERIMENT_MODAL_LOADING';
export const MAB_EXPERIMENT_MODAL_LOADED = 'MAB_EXPERIMENT_MODAL_LOADED';
export const CLOSE_CLONE_REQUEST_MODAL = 'CLOSE_CLONE_REQUEST_MODAL';
export const LAYOUT_SECTION_REQUESTED_CLONE = 'LAYOUT_SECTION_REQUESTED_CLONE';
export const FETCH_REVISIONS_STARTED = 'FETCH_REVISIONS_STARTED';
export const FETCH_REVISIONS_SUCCEEDED = 'FETCH_REVISIONS_SUCCEEDED';
export const FETCH_REVISIONS_FAILED = 'FETCH_REVISIONS_FAILED';
export const FETCH_SCHEDULED_VERSION_STARTED = 'FETCH_SCHEDULED_VERSION_STARTED';
export const FETCH_SCHEDULED_VERSION_SUCCEEDED = 'FETCH_SCHEDULED_VERSION_SUCCEEDED';
export const FETCH_SCHEDULED_VERSION_FAILED = 'FETCH_SCHEDULED_VERSION_FAILED';
export const SET_CRITSIT_FLAGS = 'SET_CRITSIT_FLAGS';
export const FETCH_THEME_FAILED = 'FETCH_THEME_FAILED';
export const FETCH_THEME_STARTED = 'FETCH_THEME_STARTED';
export const FETCH_THEME_SUCCEEDED = 'FETCH_THEME_SUCCEEDED';
export const FETCH_CUSTOM_FONTS_SUCCEEDED = 'FETCH_CUSTOM_FONTS_SUCCEEDED';
export const FETCH_CUSTOM_FONTS_FAILED = 'FETCH_CUSTOM_FONTS_FAILED';
export const FETCH_CUSTOM_FONTS_STARTED = 'FETCH_CUSTOM_FONTS_STARTED';
export const CLEAR_PREVIEW_STATE = 'CLEAR_PREVIEW_STATE';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const UPDATE_SMART_CONTENT_SELECTOR_VALUE = 'UPDATE_SMART_CONTENT_SELECTOR_VALUE';
export const UPDATE_PREVIEW_DRILLDOWN = 'UPDATE_PREVIEW_DRILLDOWN';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_AMP_AND_LISTING = 'UPDATE_AMP_AND_LISTING';
export const UPDATED_TEST_FLAGS = 'UPDATED_TEST_FLAGS';
export const FETCH_EDITABLE_SLUG_INFO_REQUEST = 'FETCH_EDITABLE_SLUG_INFO_REQUEST';
export const FETCH_EDITABLE_SLUG_INFO_FAILED = 'FETCH_EDITABLE_SLUG_INFO_FAILED';
export const FETCH_EDITABLE_SLUG_INFO_SUCCESS = 'FETCH_EDITABLE_SLUG_INFO_SUCCESS';
export const FETCH_PORTAL_SCOPES_REQUEST = 'FETCH_PORTAL_SCOPES_REQUEST';
export const FETCH_PORTAL_SCOPES_SUCCESS = 'FETCH_PORTAL_SCOPES_SUCCESS';
export const FETCH_PORTAL_SCOPES_FAILED = 'FETCH_PORTAL_SCOPES_FAILED';
export const FETCH_PORTAL_INFO_REQUEST = 'FETCH_PORTAL_INFO_REQUEST';
export const FETCH_PORTAL_INFO_SUCCESS = 'FETCH_PORTAL_INFO_SUCCESS';
export const FETCH_PORTAL_INFO_FAILED = 'FETCH_PORTAL_INFO_FAILED';
export const FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST = 'FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_REQUEST';
export const FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_FAILED = 'FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_FAILED';
export const FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS = 'FETCH_MULTI_DOMAIN_PUBLISHING_SCOPE_CHANGE_SUCCESS';
export const FETCH_HARD_SAVED_CONTENT_MODEL_STARTED = 'FETCH_HARD_SAVED_CONTENT_MODEL_STARTED';
export const FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED = 'FETCH_HARD_SAVED_CONTENT_MODEL_SUCCEEDED';
export const FETCH_HARD_SAVED_CONTENT_MODEL_FAILED = 'FETCH_HARD_SAVED_CONTENT_MODEL_FAILED';
export const FETCH_USERS_STARTED = 'FETCH_USERS_STARTED';
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const RELOAD_WITHOUT_WARNING = 'RELOAD_WITHOUT_WARNING';
export const FETCH_CUSTOM_DOMAINS_PURGE_REQUEST = 'FETCH_CUSTOM_DOMAINS_PURGE_REQUEST';
export const FETCH_CUSTOM_DOMAINS_PURGE_FAILED = 'FETCH_CUSTOM_DOMAINS_PURGE_FAILED';
export const FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS = 'FETCH_CUSTOM_DOMAINS_PURGE_SUCCESS';
export const FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST = 'FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST';
export const FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED = 'FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED';
export const FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS = 'FETCH_SITE_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS';
export const FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST = 'FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_REQUEST';
export const FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED = 'FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_FAILED';
export const FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS = 'FETCH_LANDING_PAGES_PASSWORD_PROTECTION_PURGE_DATE_SUCCESS';
export const FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST = 'FETCH_AUDIENCE_ACCESS_PURGE_DATE_REQUEST';
export const FETCH_AUDIENCE_ACCESS_PURGE_DATE_FAILED = 'FETCH_AUDIENCE_ACCESS_PURGE_DATE_FAILED';
export const FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS = 'FETCH_AUDIENCE_ACCESS_PURGE_DATE_SUCCESS';
export const FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST = 'FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_REQUEST';
export const FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_FAILED = 'FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_FAILED';
export const FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS = 'FETCH_HUBDB_PAGE_TEMPLATE_PURGE_DATE_SUCCESS';
export const START_ONBOARDING_TOUR_IN_PARENT = 'START_ONBOARDING_TOUR_IN_PARENT';
export const SHOW_ONBOARDING_TOUR_QUIT_MODAL = 'SHOW_ONBOARDING_TOUR_QUIT_MODAL';
export const HIDE_ONBOARDING_TOUR_QUIT_MODAL = 'HIDE_ONBOARDING_TOUR_QUIT_MODAL';
export const FINISH_TOUR_INPAGE = 'FINISH_TOUR_INPAGE';
export const GO_TO_STYLE_SECTION = 'GO_TO_STYLE_SECTION';
export const SWITCH_TO_SMART_CONTENT_PREVIEW = 'SWITCH_TO_SMART_CONTENT_PREVIEW';
export const TRACK_LP_ONBOARDING_TOUR_PROGRESSION = 'TRACK_LP_ONBOARDING_TOUR_PROGRESSION';
export const CLEAR_PROGRESSION_TO_TRACK = 'CLEAR_PROGRESSION_TO_TRACK';
export const FETCH_BRAND_COLORS_STARTED = 'FETCH_BRAND_COLORS_STARTED';
export const FETCH_BRAND_COLORS_FAILED = 'FETCH_BRAND_COLORS_FAILED';
export const FETCH_BRAND_COLORS_SUCCEEDED = 'FETCH_BRAND_COLORS_SUCCEEDED';
export const TOGGLE_APPLY_CHANGES_MODAL_OPEN = 'TOGGLE_APPLY_CHANGES_MODAL_OPEN';
export const SET_SCROLL_TO_IN_MODULE_LIST = 'SET_SCROLL_TO_IN_MODULE_LIST';
export const DISMISS_SCOPES_HAVE_CHANGED_MODAL = 'DISMISS_SCOPES_HAVE_CHANGED_MODAL';
export const CLEAR_USE_SCROLL_IF_MODULE_OUT_OF_BOUNDS_AT_ALL = 'CLEAR_USE_SCROLL_IF_MODULE_OUT_OF_BOUNDS_AT_ALL';
export const SHOW_MATCHING_APP_MODAL = 'SHOW_MATCHING_APP_MODAL';
export const HIDE_MATCHING_APP_MODAL = 'HIDE_MATCHING_APP_MODAL';
export const FETCH_ACCOUNT_VERIFICATION_STATUS_FAILED = 'FETCH_ACCOUNT_VERIFICATION_STATUS_FAILED';
export const FETCH_ACCOUNT_VERIFICATION_STATUS_STARTED = 'FETCH_ACCOUNT_VERIFICATION_STATUS_STARTED';
export const FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED = 'FETCH_ACCOUNT_VERIFICATION_STATUS_SUCCEEDED';
export const SET_APPLY_CHANGES_BUTTON_OPEN = 'SET_APPLY_CHANGES_BUTTON_OPEN';
export const OPEN_ADD_SECTION_PANEL = `OPEN_ADD_SECTION_PANEL`;
export const CLOSE_ADD_SECTION_PANEL = `CLOSE_ADD_SECTION_PANEL`;
export const OPEN_CREATE_NEW_SECTION_PANEL = `OPEN_CREATE_NEW_SECTION_PANEL`;
export const CLOSE_CREATE_NEW_SECTION_PANEL = `CLOSE_CREATE_NEW_SECTION_PANEL`;
export const FETCH_ADDABLE_SECTIONS_SUCCESS = 'FETCH_ADDABLE_SECTIONS_SUCCESS';
export const FETCH_ADDABLE_SECTIONS_FAILURE = 'FETCH_ADDABLE_SECTIONS_FAILURE';
export const FETCH_ADDABLE_SECTIONS_REQUEST = 'FETCH_ADDABLE_SECTIONS_REQUEST';
export const MARK_ADDABLE_SECTIONS_AS_STATE = 'MARK_ADDABLE_SECTIONS_AS_STATE';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';
export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST';
export const CUSTOM_LAYOUT_SECTION_ADDED = 'CUSTOM_LAYOUT_SECTION_ADDED';
export const UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO = 'UPDATE_CURRENT_LAYOUT_SECTION_TO_ADD_TO';
export const SAVE_SECTION_REQUEST = 'SAVE_SECTION_REQUEST';
export const SAVE_SECTION_SUCCESS = 'SAVE_SECTION_SUCCESS';
export const SAVE_SECTION_FAILURE = 'SAVE_SECTION_FAILURE';
export const FETCH_MARKETER_SECTIONS_REQUEST = 'FETCH_MARKETER_SECTIONS_REQUEST';
export const FETCH_MARKETER_SECTIONS_SUCCESS = 'FETCH_MARKETER_SECTIONS_SUCCESS';
export const FETCH_MARKETER_SECTIONS_FAILURE = 'FETCH_MARKETER_SECTIONS_FAILURE';
export const REDIRECT_TO_DELETE_SECTIONS = 'REDIRECT_TO_DELETE_SECTIONS';
export const SELECTED_FIELD_UPDATED = 'SELECTED_FIELD_UPDATED';
export const FETCH_DOMAIN_EXCLUSION_REASON_REQUEST = 'FETCH_DOMAIN_EXCLUSION_REASON_REQUEST';
export const FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS = 'FETCH_DOMAIN_EXCLUSION_REASON_SUCCESS';
export const FETCH_DOMAIN_EXCLUSION_REASON_FAILURE = 'FETCH_DOMAIN_EXCLUSION_REASON_FAILURE';
export const UPDATE_SELECTED_UPGRADABLE_MODULE_PQL = 'UPDATE_SELECTED_UPGRADABLE_MODULE_PQL';
export const ACTIVATE_DEVELOPER_MODE = 'ACTIVATE_DEVELOPER_MODE';
export const SET_ADD_SECTION_MISSING_MODULE_ERRORS = 'SET_ADD_SECTION_MISSING_MODULE_ERRORS';
export const CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS = 'CLEAR_ADD_SECTION_MISSING_MODULE_ERRORS'; // Fetch Generated HubL for layout fragment info

export const FETCH_GENERATED_LAYOUT_HUBL_SUCCESS = 'FETCH_GENERATED_LAYOUT_HUBL_SUCCESS';
export const FETCH_GENERATED_LAYOUT_HUBL_FAILURE = 'FETCH_GENERATED_LAYOUT_HUBL_FAILURE';
export const FETCH_GENERATED_LAYOUT_HUBL_STARTED = 'FETCH_GENERATED_LAYOUT_HUBL_STARTED'; //Email Template Editor

export const FETCH_TEMPLATE_SCHEMA_FAILED = 'FETCH_TEMPLATE_SCHEMA_FAILED';
export const FETCH_TEMPLATE_SCHEMA_STARTED = 'FETCH_TEMPLATE_SCHEMA_STARTED';
export const FETCH_TEMPLATE_SCHEMA_SUCCEEDED = 'FETCH_TEMPLATE_SCHEMA_SUCCEEDED';
export const UNIVERSAL_APP_IS_READY = 'UNIVERSAL_APP_IS_READY';
export const UPDATE_STATIC_MODULE_WITH_NEW_ID = 'UPDATE_STATIC_MODULE_WITH_NEW_ID';
export const UPDATE_NOPE_ZONE_SELECTOR = 'UPDATE_NOPE_ZONE_SELECTOR';
export const UPDATE_PREVIEW_URL = 'UPDATE_PREVIEW_URL';
export const FLUSH_SIDEBAR_HIDDEN_STATE = 'FLUSH_SIDEBAR_HIDDEN_STATE'; // SEO Settings Popover

export const SEEN_SEO_POPOVER = 'SEEN_SEO_POPOVER'; // Contents Sidebar Tree View actions

export const CLOSE_TREE_NODES = 'CLOSE_TREE_NODES';
export const OPEN_TREE_NODES = 'OPEN_TREE_NODES';
export const RESET_LAYOUT_SECTION_TO_TEMPLATE_DEFAULT = 'RESET_LAYOUT_SECTION_TO_TEMPLATE_DEFAULT';
export const SET_SIDEBAR_SEARCH_TERM = 'SET_SIDEBAR_SEARCH_TERM';
export const SET_SIDEBAR_ACTIVE_MATCH = 'SET_SIDEBAR_ACTIVE_MATCH';
export const SET_SIDEBAR_MATCH_IDS = 'SET_SIDEBAR_MATCH_IDS';
export const OPEN_EXPANDABLE_MENU = 'OPEN_EXPANDABLE_MENU';
export const CLEAR_OPEN_EXPANDABLE_MENU = 'CLEAR_OPEN_EXPANDABLE_MENU';
export const SAVE_SIDEBAR_SCROLL_TOP = 'SAVE_SIDEBAR_SCROLL_TOP';
export const SHOW_ALL_ROWS_FOR_DND_AREA = 'SHOW_ALL_ROWS_FOR_DND_AREA';
export const SHOW_STANDARD_ROWS_FOR_DND_AREA = 'SHOW_STANDARD_ROWS_FOR_DND_AREA'; //patagonia sidebar actions

export const SELECTED_CATEGORY_UPDATE = 'SELECTED_CATEGORY_UPDATE';
export const SELECTED_SUB_CATEGORY_UPDATE = 'SELECTED_SUB_CATEGORY_UPDATE';
export const SET_PATAGONIA_ACTIVE_PANEL = 'SET_PATAGONIA_ACTIVE_PANEL';
export const UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM = 'UPDATE_PATAGONIA_SETTINGS_MODAL_ACTIVE_NAV_ITEM'; // X-Ray optimization actions

export const FETCH_OPTIMIZATIONS_STARTED = 'FETCH_OPTIMIZATIONS_STARTED';
export const FETCH_OPTIMIZATIONS_SUCCEEDED = 'FETCH_OPTIMIZATIONS_SUCCEEDED';
export const FETCH_OPTIMIZATIONS_FAILED = 'FETCH_OPTIMIZATIONS_FAILED';
export const SET_HIGHLIGHTED_FIELD = 'SET_HIGHLIGHTED_FIELD';
export const UPDATE_COLLABORATION_SIDEBAR_STATE = 'UPDATE_COLLABORATION_SIDEBAR_STATE';
export const UPDATE_HEADER_ACTIVE_ITEM = 'UPDATE_HEADER_ACTIVE_ITEM';
export const SHOULD_CLEAR_HEADER_ACTIVE_ITEM = 'SHOULD_CLEAR_HEADER_ACTIVE_ITEM';