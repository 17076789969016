'use es6';

import http from 'hub-http/clients/apiClient';
const BASE_URL = 'cos-rendering/v1/internal/render/widget';
const URL_WITH_CONTEXT = `${BASE_URL}-and-content`;
const URL_WITH_TEMPLATE_CONTEXT = `${BASE_URL}-and-template`;
const URL_WITH_META = `${BASE_URL}-with-meta`;
export const render = data => {
  return http.post(BASE_URL, {
    data,
    responseType: 'text'
  });
};
export const renderWithContext = (data, getParamString = '') => {
  return http.post(`${URL_WITH_CONTEXT}${getParamString}`, {
    data,
    responseType: 'text'
  });
};
export const renderWithTemplateContext = data => {
  return http.post(URL_WITH_TEMPLATE_CONTEXT, {
    data,
    responseType: 'text'
  });
};
export const renderWithMeta = (data, query) => {
  return http.post(URL_WITH_META, {
    data,
    query
  });
};