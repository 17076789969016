import { findDOMNode } from 'react-dom';
import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import Raven from 'Raven';
import PortalIdParser from 'PortalIdParser';
import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { parse, stringify } from 'hub-http/helpers/params';
import enviro from 'enviro';
import { getIframeModalHost } from '../_core/utils/getIframeModalHost';
import { sources } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import { sourceKeys } from 'ui-addon-upgrades/_core/common/data/upgradeData/properties/sources';
import { i18nKeysByUpgradeProduct } from 'ui-addon-upgrades/_core/common/adapters/getProductNameText';
import { omit } from '../utils';
const upgradePointPromises = {};
export const upgradePointViews = {};
export function getApp() {
  return window.location.pathname.split('/')[1];
}
export function getScreen() {
  return window.location.pathname;
}
export function getHubAndTier(upgradeProduct) {
  return {
    hub: upgradeProduct.split('-')[0],
    tier: upgradeProduct.split('-')[1]
  };
}
export function verifyMessage(ref, event) {
  const node = findDOMNode(ref.current);
  return node && node.contentWindow === event.source;
}
export function matchMessage(event, message) {
  return event.data === message;
}
export function createFrameSrc({
  upgradeKey,
  app,
  screen,
  portalId,
  params
}) {
  const formattedParams = params ? `&${params}` : '';
  return `${getIframeModalHost()}/ums-modal/${portalId}/${upgradeKey}?app=${app}&screen=${screen}${formattedParams}`;
}
export function getFrameSrc(upgradeKey) {
  return createFrameSrc({
    upgradeKey,
    app: getApp(),
    screen: getScreen(),
    portalId: PortalIdParser.get(),
    params: stringify(parse(window.location.search.substring(1)))
  });
}

function fetchUpgradePoint(upgradeKey) {
  if (!upgradeKey) {
    return Promise.resolve();
  }

  return http.get(`${getFullUrl('app-api', {})}/upgrade-management-service/v1/upgrade-configs/${upgradeKey}`).catch(error => {
    // 0 indicates user aborted requests, which we don't want to log as errors
    let message = 'UpgradeManagement API Failure';

    if (error && error.status === 0) {
      return;
    }

    if (error && error.status === 404) {
      message = `Attempted to fetch upgradeConfig for upgrade key that doesn't exist.`;

      if (enviro.isProd()) {
        message += ` Make sure ${upgradeKey} was cloned to Prod. For more info refer to the Upgrade Management System docs (https://product.hubteam.com/docs/upgrade-points/guides/implementing-upgrade-modals.html)`;
      }
    }

    Raven.captureException(new Error(message), {
      extra: {
        error
      }
    });
    throw new Error(message);
  });
}

export function delayUntilIdle(callback) {
  window.setTimeout(callback, 5000);
}
export function getUpgradePointPromise({
  upgradeKey,
  delayCall = true //modals aren't needed right away so delay loading them to improve page performance

}) {
  if (upgradePointPromises[upgradeKey]) {
    return upgradePointPromises[upgradeKey];
  }

  const upgradePointPromise = new Promise(resolve => {
    if (delayCall) {
      delayUntilIdle(() => {
        resolve(fetchUpgradePoint(upgradeKey));
      });
    } else {
      resolve(fetchUpgradePoint(upgradeKey));
    }
  });
  upgradePointPromises[upgradeKey] = upgradePointPromise;
  return upgradePointPromise;
}
export function getTrackingProperties(upgradePoint) {
  const {
    upgradeProduct
  } = upgradePoint;
  const {
    hub,
    tier
  } = getHubAndTier(upgradeProduct);
  const filteredUpgPoint = omit(upgradePoint, ['fetched', 'pointLocation']);
  return Object.assign({}, filteredUpgPoint, {
    app: getApp(),
    hub,
    tier,
    screen: getScreen()
  });
}
export function getUpgradePointTrackingProperties(upgradeData) {
  const {
    upgradeProduct
  } = upgradeData;
  const {
    hub,
    tier
  } = getHubAndTier(upgradeProduct);
  return {
    app: upgradeData.app,
    screen: upgradeData.screen,
    feature: upgradeData.feature,
    upgradeKey: upgradeData.upgradeKey,
    pointType: upgradeData.pointType,
    pointStatus: upgradeData.pointStatus,
    primaryColor: upgradeData.primaryColor,
    upgradeProduct,
    hub,
    tier
  };
}
export const getSource = (app, screen, uniqueId) => `${app}-${screen}-${sources[sourceKeys.GENERAL]}-${uniqueId}`;
export const pointTypes = {
  default: 'LOCKED_FEATURE',
  modal: 'LOCKED_FEATURE',
  removeBrandingBanner: 'REMOVE_BRANDING_BANNER',
  usageLimitBanner: 'USAGE_LIMIT_BANNER',
  lockedFeatureBanner: 'LOCKED_FEATURE_BANNER',
  usageLimit: 'USAGE_LIMIT',
  limitPackBanner: 'LIMIT_PACK_BANNER'
};
export const getBannerI18nTitleKey = (titleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${titleI18nPrefix}.${featureTranslationKey}`)) {
    return `${titleI18nPrefix}.${featureTranslationKey}`;
  }

  return `${titleI18nPrefix}.placeholder`;
}; // remove branding banners have static subtext and don't call this function

export const getBannerI18nSubTitleKey = (subtitleI18nPrefix, featureTranslationKey) => {
  if (isValidI18nKey(`${subtitleI18nPrefix}.${featureTranslationKey}`)) {
    return `${subtitleI18nPrefix}.${featureTranslationKey}`;
  }

  return `${subtitleI18nPrefix}.placeholder`;
};
export const getProductI18nName = (subtitleI18nPrefix, upgradeProduct) => {
  const keyByProduct = i18nKeysByUpgradeProduct[upgradeProduct];

  if (keyByProduct && isValidI18nKey(`${subtitleI18nPrefix}.${keyByProduct}`)) {
    return I18n.text(`${subtitleI18nPrefix}.${keyByProduct}`);
  }

  return null;
};
export const warnArchivedUpgradePointUse = upgradeKey => {
  console.warn(`Upgrade point '${upgradeKey}' is archived, and should not be used in-app. If this upgrade point should not be archived, it can be restored through the UMS at https://tools.hubteamqa.com/ums/upgrade-points/${upgradeKey}.`);
};