import Url from 'urlinator/Url';
import PortalIdParser from 'PortalIdParser';
import { userInfoSync } from 'hub-http/userInfo';
import { isCdn } from './file';
import { BYTES_IN_MEGABYTE } from '../constants/fileSizes';
import { getCdnHostnameForPortal } from './cdnHostnames';
import { reportMessage } from './logging';

function filterParams(params) {
  const filteredParams = {};
  const validKeys = ['width', 'height', 'upscale', 'upsize', 'length', 'quality'];
  validKeys.forEach(key => {
    if (key in params && params[key]) {
      filteredParams[key] = params[key];
    }
  });
  return filteredParams;
}

export function getIsFileTooLargeForResizing(file) {
  return file.get('size') >= BYTES_IN_MEGABYTE * 5;
}
const DEFAULT_RESIZE_OPTIONS = {
  convertCustomDomainToCdn: false
};
/**
 * Transforms FM urls to structure needed to route to image resizing service
 * and appends resize query params
 *
 * @param {string} url
 * @param {object} params
 * @param {object} options
 */

export function resize(url, params = {}, options = {}, auth) {
  if (!auth) {
    try {
      auth = userInfoSync();
    } catch (e) {
      const errorMessage = '[FileManagerLib/resize] No auth passed to resize and userInfoSync threw error';
      console.error(errorMessage, e);
      reportMessage(errorMessage);
      auth = {
        gates: []
      };
    }
  }

  const parsed = new Url(url);
  const pathSegments = parsed.pathname.split('/');
  const hubfsSegment = pathSegments.indexOf('hubfs');
  options = Object.assign({}, DEFAULT_RESIZE_OPTIONS, {}, options); // url is not a file manager url, so return

  if (hubfsSegment < 0) {
    console.error('[FileManagerLib/resize] URL is not file manager URL. Image resize not applied.');
    return url;
  } // resize does not support relative urls


  if (!parsed.hostname) {
    console.error('[FileManagerLib/resize] URL is relative. Image resize not applied.');
    return url;
  }

  const updatedParams = Object.assign({}, parsed.params, {}, filterParams(params));

  if (isCdn(parsed.hostname, auth) && pathSegments.indexOf('hub') < 0) {
    const pathSuffix = pathSegments.slice(3).join('/');
    const hubId = parseInt(pathSegments[hubfsSegment + 1], 10);

    if (!hubId) {
      return url;
    }

    parsed.update({
      pathname: `/hub/${hubId}/hubfs/${pathSuffix}`,
      params: updatedParams
    });
  } else if (pathSegments[1] === 'hubfs') {
    const pathSuffix = pathSegments.slice(2).join('/');
    parsed.update({
      pathname: `/hs-fs/hubfs/${pathSuffix}`,
      params: updatedParams
    });

    if (options.convertCustomDomainToCdn) {
      parsed.update({
        pathname: `/hub/${PortalIdParser.get()}/hubfs/${pathSuffix}`,
        hostname: getCdnHostnameForPortal(auth)
      });
    }
  } else {
    parsed.update({
      params: updatedParams
    });
  }

  return parsed.href;
}