'use es6';

export const SET_AUTH = 'SET_AUTH';
export const FETCH_CONTACT_LIST = 'FETCH_CONTACT_LIST'; // inpage actions types will be passed through event bus

export const ACTION_TYPE_PREFIX = '@@smart-content/';
export const CREATE_SMART_DEFINITION = `${ACTION_TYPE_PREFIX}CREATE_SMART_DEFINITION`;
export const FETCH_SMART_DEFINITION = `${ACTION_TYPE_PREFIX}FETCH_SMART_DEFINITION`;
export const FETCH_SMART_DEFINITIONS = `${ACTION_TYPE_PREFIX}FETCH_SMART_DEFINITIONS`;
export const DELETE_SMART_DEFINITION_CRITERIA = `${ACTION_TYPE_PREFIX}DELETE_SMART_DEFINITION_CRITERIA`;
export const RESET_DEFINITIONS_FETCH_STATUS = `${ACTION_TYPE_PREFIX}RESET_DEFINITIONS_FETCH_STATUS`;
export const CREATE_SMART_CRITERION = `${ACTION_TYPE_PREFIX}CREATE_SMART_CRITERION`;
export const DELETE_SMART_CRITERION = `${ACTION_TYPE_PREFIX}DELETE_SMART_CRITERION`;
export const BULK_DELETE_SMART_CRITERIA = `${ACTION_TYPE_PREFIX}BULK_DELETE_SMART_CRITERIA`;
export const UPDATE_SMART_CRITERION_PARAMETERS = `${ACTION_TYPE_PREFIX}UPDATE_SMART_CRITERION_PARAMETERS`;
export const UPDATE_SMART_CRITERION_PRECEDENCE = `${ACTION_TYPE_PREFIX}UPDATE_SMART_CRITERION_PRECEDENCE`;
export const FETCH_ALL_CONTACT_LISTS = `${ACTION_TYPE_PREFIX}FETCH_ALL_CONTACT_LISTS`;
export const FETCH_CUSTOM_LIFECYCLE_STAGES = `${ACTION_TYPE_PREFIX}FETCH_CUSTOM_LIFECYCLE_STAGES`;
export const FETCH_LIFECYCLE_STAGES = `${ACTION_TYPE_PREFIX}FETCH_LIFECYCLE_STAGES`;
export const FETCH_USER_ATTRIBUTES = `${ACTION_TYPE_PREFIX}FETCH_USER_ATTRIBUTES`;
export const UPDATE_USER_ATTRIBUTE = `${ACTION_TYPE_PREFIX}UPDATE_USER_ATTRIBUTE`;
export const FETCH_ALL_AD_ACCOUNTS = `${ACTION_TYPE_PREFIX}FETCH_ALL_AD_ACCOUNTS`;
export const FETCH_AD_GROUP = `${ACTION_TYPE_PREFIX}FETCH_AD_GROUP`;
export const FETCH_ADS_SINGLETONS = `${ACTION_TYPE_PREFIX}FETCH_ADS_SINGLETONS`;
export const FETCH_AD_CAMPAIGNS = `${ACTION_TYPE_PREFIX}FETCH_AD_CAMPAIGNS`;
export const UPDATE_SMART_CRITERION = `${ACTION_TYPE_PREFIX}UPDATE_SMART_CRITERION`;
export const OPEN_SMART_MODAL = `${ACTION_TYPE_PREFIX}OPEN_SMART_MODAL`;
export const CLOSE_SMART_MODAL = `${ACTION_TYPE_PREFIX}CLOSE_SMART_MODAL`;